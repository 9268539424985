import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { ApplicationState } from '../redux/store';
import { UnitConversion, unitSystems } from '../uom/UnitConversion';
import { setUnitConversion } from '../redux/store/unitSystem/actions';
import * as Redux from 'redux';
import { Button, Grid, IconButton, TextField } from '@material-ui/core';
import { getLatestEpaString } from './EpaUtils';
import { CurrencyEdit } from './CurrencyEdit';
import { ApiContext } from '../components/ApiContext';
import { Icon } from '@equinor/eds-core-react';

interface UserPreferencesDialogProps {
    open: boolean;
    onClose: () => void;
    unitConversion: UnitConversion;
    setUnitConversion: (unitConversion: UnitConversion) => void;
}

const UserPreferencesDialogComponent = ({
    open,
    onClose,
    unitConversion,
    setUnitConversion
}: UserPreferencesDialogProps) => {
    const api = useContext(ApiContext);

    const [selectedEpa, setSelectedEpa] = useState<string>('');

    useEffect(() => {
        const load = async () => {
            const epaString = await getLatestEpaString(api.epaApi);
            setSelectedEpa(epaString);
            localStorage.setItem('epaAssumptions', epaString);
        };
        if (open) {
            load();
        }
    });

    const [openCurrencyEdit, setOpenCurrencyEdit] = useState(false);

    const onUnitSystemChange = useCallback(
        (event) => {
            const unitSystem = unitSystems.find((sys) => sys.name === event.target.value);
            unitSystem && setUnitConversion(new UnitConversion(unitSystem));
        },
        [setUnitConversion]
    );
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>User preferences</DialogTitle>
            <DialogContent>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={5}>
                        <FormControl fullWidth={true}>
                            <InputLabel>Units</InputLabel>
                            <Select value={unitConversion.unitSystem.name} onChange={onUnitSystemChange}>
                                {unitSystems.map((unitSystem) => {
                                    return (
                                        <MenuItem key={unitSystem.name} value={unitSystem.name}>
                                            {unitSystem.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            value={selectedEpa}
                            label={'EPA Assumptions'}
                            helperText={'Uses latest EPA estimation by default'}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: 20 }}>
                        <IconButton size="small" color="primary" onClick={() => setOpenCurrencyEdit(true)}>
                            <Icon name={'visibility'} />
                        </IconButton>
                    </Grid>
                </Grid>
                {openCurrencyEdit && <CurrencyEdit open={openCurrencyEdit} close={() => setOpenCurrencyEdit(false)} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        unitConversion: state.unitConversion
    };
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
    return {
        setUnitConversion: (unitConversion: UnitConversion) => dispatch(setUnitConversion(unitConversion))
    };
};

export const UserPreferencesDialog = connect(mapStateToProps, mapDispatchToProps)(UserPreferencesDialogComponent);
