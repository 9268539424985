/* eslint-disable */
export type Problem = string;

export function problemsExist(problems: Problems | Problem | undefined) {
    if (!problems) {
        return false;
    }
    if (typeof problems === 'string') {
        return problems !== '';
    }
    return Object.keys(problems).length > 0;
}

export interface Problems {
    [s: string]: Problem | Problems;
}
