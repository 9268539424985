import { FileUpload, WtgIndex } from '@lcoe/lcoe-client';
import { WtgIndexRequest } from '@lcoe/lcoe-client/dist/models/WtgIndexRequest';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isUserCostEstimator } from 'utils/permission/permissionUtil';
import { ApiContext } from '../components/ApiContext';
import ConfirmationDialog from '../components/ConfirmationDialog';
import LoadingDialog from '../components/LoadingDialog';
import { UploadButton } from '../components/UploadButton';
import { ApplicationState } from '../redux/store';
import { parseWtgIndexFromExcel } from './ExcelParser';
import { WtgIndexChart } from './WtgIndexChart';
import { WtgListTable } from './WtgListTable';
import { NavBarMasterData } from '../components/NavBarMasterData';
import styles from './WtgIndexList.module.css';
import { PopoverInfo } from '../elements/visual/PopoverInfo';
import { Button, Typography } from '@equinor/eds-core-react';

export const WtgIndexList = (): JSX.Element => {
    const user = useSelector((state: ApplicationState) => state.user);
    const [wtgIndex, setWtgIndex] = useState<WtgIndex>();
    const [newWtgIndex, setNewWtgIndex] = useState<WtgIndexRequest>();

    const [openConfirm, setOpenConfirm] = useState(false);
    const [confirmText, setConfirmText] = useState('');
    const [loading, setLoading] = useState(false);

    const api = useContext(ApiContext);

    useEffect(() => {
        if (!wtgIndex && !newWtgIndex) {
            api.turbineApi.getWtgIndex().then((index) => {
                setWtgIndex(index);
            });
        }
    });

    const handleUploadIndex = (file: FileUpload) => {
        setNewWtgIndex(parseWtgIndexFromExcel(file.content));
    };

    const openConfirmSave = () => {
        if (currentSeasonExists()) {
            setConfirmText(
                'Updating WTG index inside current estimation season will trigger recalculation of ' +
                    'all cases, but not be highlighted in case view. Recalculation takes approximately 15 minutes.'
            );
        } else {
            setConfirmText(
                'Saving a new estimation season will trigger automatic version update of all cases. ' +
                    'Recalculation takes approximately 15 minutes.'
            );
        }
        setOpenConfirm(true);
    };

    const handleSaveIndex = () => {
        setLoading(true);
        let promise: Promise<WtgIndex>;
        if (currentSeasonExists()) {
            promise = api.turbineApi.updateWtgIndex({ wtgIndexRequest: newWtgIndex });
        } else {
            promise = api.turbineApi.createWtgIndex({ wtgIndexRequest: newWtgIndex });
        }
        promise
            .then((index) => {
                setWtgIndex(index);
                setNewWtgIndex(undefined);
            })
            .finally(() => {
                setOpenConfirm(false);
                setLoading(false);
            });
    };

    const currentSeasonExists = () => {
        if (wtgIndex) {
            const currentYear = new Date().getFullYear();
            const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
            return wtgIndex.year === currentYear && wtgIndex.quarter === currentQuarter;
        } else {
            return false;
        }
    };

    const handleCancel = () => {
        setNewWtgIndex(undefined);
    };

    const popover_element = (
        <div>
            <div>Please use the attached Excel template for uploading a new WTG Index.</div>
            <a href={'/files/wtg_index_template.xlsx'}>Download Excel Template</a>
        </div>
    );

    return (
        <>
            <NavBarMasterData />
            <div className={styles.page}>
                <div className={styles.box}>
                    <div className={styles.banner}>
                        <Typography variant={'h3'}>WTG Index</Typography>
                        <div className={styles.ButtonIcon}>
                            <PopoverInfo title={'How to import'} body_element={popover_element}></PopoverInfo>
                        </div>
                    </div>

                    <div className={styles.uploadbutton}>
                        {isUserCostEstimator(user) && (
                            <UploadButton
                                id="wtg-index-import-button"
                                onUpload={handleUploadIndex}
                                accept={'.xls,.xlsx'}
                                variant="outlined"
                            >
                                Import from Excel
                            </UploadButton>
                        )}
                    </div>

                    {newWtgIndex && (
                        <>
                            <Typography variant={'h3'}>Imported data:</Typography>
                            <WtgIndexChart index={newWtgIndex} />
                        </>
                    )}
                    {wtgIndex && !newWtgIndex && (
                        <>
                            <WtgListTable
                                index={wtgIndex}
                                definitions={wtgListTitles()}
                                versionMeta={wtgIndex.versionMeta}
                            />
                            {isUserCostEstimator(user) && <WtgIndexChart index={wtgIndex} />}
                        </>
                    )}

                    {newWtgIndex && (
                        <>
                            <Button onClick={handleCancel}>Cancel</Button> &nbsp;
                            <Button onClick={openConfirmSave}>Save</Button>
                        </>
                    )}

                    <ConfirmationDialog
                        title="Please confirm save"
                        text={confirmText}
                        open={openConfirm}
                        onCancel={() => setOpenConfirm(false)}
                        onConfirm={handleSaveIndex}
                    />
                    <LoadingDialog open={loading} />
                </div>
            </div>
        </>
    );
};

const wtgListTitles = () => {
    return [
        {
            title: 'Estimation season'
        },
        {
            title: 'Uploaded by'
        },
        {
            title: 'Uploaded time'
        },
        {
            title: 'Version'
        }
    ];
};
