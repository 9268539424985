import { CoeCapexNode, CoeOpexNode } from './ResultDefinitions';
import { Unit } from 'mathjs';
import { Production, ValueWithUnit } from '@lcoe/lcoe-client';
import { asUnitObj } from '../../uom/UnitConversion';

export interface CoeNode<T> {
    children: CoeNode<T>[];
    name: string;
    cost: Unit;
    percentCost: number;
    learningEffect: Unit;
    learningEffectPercent: number;
    coe: Unit;
    capexBase?: ValueWithUnit | undefined;
    quantity?: ValueWithUnit | undefined;
    contingency?: ValueWithUnit | undefined;
    overrideNodeIsActive?: boolean | undefined;
}

export interface ResultEntry {
    name: string;
    id: string;
    level: number;
    parent: string;
    isParent: boolean;
    isActive: boolean;
    capexCost: number;
    contingency: number;
    quantity: number;
    learningEffect: number;
    quantityUnit: string | undefined;
    COE: number;
    expanded: boolean;
}

// Recalc levels to fill in blank parents that are missing from original object structure
export const recalcLevel = (level: number, arrayIn: ResultEntry[]): void => {
    const levelEntries = arrayIn.filter((entryRow) => entryRow.level === level && entryRow.isParent);
    levelEntries.forEach((element) => {
        let sumCapex = 0;
        let sumCOE = 0;
        let sumContingency = 0;
        const elementChildren = arrayIn.filter((entryRow) => entryRow.parent === element.id);
        elementChildren.forEach((elementChild) => {
            sumCapex = sumCapex + elementChild.capexCost;
            sumCOE = sumCOE + elementChild.COE;
            sumContingency = sumContingency + elementChild.contingency;
        });
        element.capexCost = +sumCapex.toFixed(3);
        element.contingency = +sumContingency.toFixed(3);
        element.COE = +sumCOE.toFixed(3);
    });
};

export const flatCapex = (capexNode: CoeCapexNode, useCalcVersion: boolean): ResultEntry[] => {
    const arrayFromCapexNode: ResultEntry[] = [];

    // capex object data into new structure
    const flatNode = (
        capexNode: CoeCapexNode,
        level: number,
        startID: number,
        currentID: string,
        useCalcVersion: boolean
    ) => {
        level = level + 1;
        const newElement = {
            name: capexNode.name,
            id: currentID,
            level: level,
            parent: currentID.length >= 2 ? currentID.slice(0, -2) : '',
            isParent: capexNode.children.length > 0,
            isActive: true,
            capexCost: +(capexNode.cost.toNumber('MEUR')
                ? (
                      capexNode.cost.toNumber('MEUR') -
                      (capexNode.contingency ? +capexNode.contingency.value : 0) / 1000
                  ).toFixed(3)
                : 0),
            contingency: capexNode.contingency ? +capexNode.contingency.value.toFixed(0) / 1000 : 0,
            quantity: capexNode.quantity ? capexNode.quantity.value : 0,
            learningEffect: capexNode.learningEffect.toNumber('MEUR'),
            quantityUnit: capexNode.quantity ? capexNode.quantity.unit : '',
            COE: +capexNode.coe.toJSON().value,
            expanded: false
        };
        arrayFromCapexNode.push(newElement);
        if (capexNode.children.length !== 0) {
            for (let i = 0; i < capexNode.children.length; i++) {
                flatNode(capexNode.children[i], level, i + 1, currentID + '_' + (i + 1), useCalcVersion);
            }
        }
    };

    flatNode(capexNode, 0, 1, '1', useCalcVersion);
    return arrayFromCapexNode;
};

export const flatOpex = (opexNode: CoeOpexNode, useCalcVersion: boolean): ResultEntry[] => {
    const arrayFromOpexNode: ResultEntry[] = [];

    // opex object data into new structure
    const flatNode = (
        opexNode: CoeOpexNode,
        level: number,
        startID: number,
        currentID: string,
        useCalcVersion: boolean
    ) => {
        level = level + 1;
        const newElement = {
            name: opexNode.name,
            id: currentID,
            level: level,
            parent: currentID.length >= 2 ? currentID.slice(0, -2) : '',
            isParent: opexNode.children.length > 0,
            isActive: true,
            capexCost: +opexNode.cost.toNumber('MEUR'),
            contingency: 0,
            quantity: 0,
            learningEffect: opexNode.learningEffect.toNumber('MEUR'),
            quantityUnit: '',
            COE: +opexNode.coe.toJSON().value,
            expanded: false
        };
        arrayFromOpexNode.push(newElement);
        if (opexNode.children.length !== 0) {
            for (let i = 0; i < opexNode.children.length; i++) {
                flatNode(opexNode.children[i], level, i + 1, currentID + '_' + (i + 1), useCalcVersion);
            }
        }
    };

    flatNode(opexNode, 0, 1, '2', useCalcVersion);
    return arrayFromOpexNode;
};

export const downloadResultCSV = (
    capexDataTable: ResultEntry[],
    production: Production,
    maxTheoreticalProduction: Unit
) => {
    const rows = [['name', 'cost', '% of total', 'learning effect', '% of item', 'contingency', '% of item']];

    let totalCapex = 0;
    let totalOpex = 0;
    capexDataTable.map((el) => {
        if (el.id == '2') {
            totalOpex = el.capexCost + el.contingency;
        } else if (el.id == '1') {
            totalCapex = el.capexCost + el.contingency;
        }

        const learningEffectPercentage = (
            (el.learningEffect / (el.capexCost + el.contingency + el.learningEffect)) *
            100
        ).toString();

        const contingencyPercentage = ((el.contingency / el.capexCost) * 100).toString();

        rows.push([
            '_'.repeat(el.level * 2 - 2) + el.name,
            (el.capexCost + el.contingency).toString(),
            (((el.capexCost + el.contingency) / (el.id[0] == '1' ? totalCapex : totalOpex)) * 100).toString(),
            el.learningEffect.toString(),
            learningEffectPercentage,
            el.contingency.toString(),
            contingencyPercentage
        ]);
    });

    rows.push(['', '', '', '', '', '', '']);

    rows.push([
        'Net annual production',
        production.netAnnualProduction.value.toString() + ' ' + production.netAnnualProduction.unit,
        +((asUnitObj(production.netAnnualProduction).divide(maxTheoreticalProduction) as unknown as number) * 100) +
            '%',
        '',
        '',
        '',
        ''
    ]);
    rows.push([
        '__Gross production',
        production.grossAnnualProduction.value + ' ' + production.grossAnnualProduction.unit,
        +((asUnitObj(production.grossAnnualProduction).divide(maxTheoreticalProduction) as unknown as number) * 100) +
            '%',
        '',
        '',
        '',
        ''
    ]);
    rows.push(['__Total losses', '', (production.totalLossesRatio * 100).toString() + '%', '', '', '', '']);
    rows.push([
        '____Technical availability',
        '',
        (production.technicalAvailabilityRatio * 100).toString() + '%',
        '',
        '',
        '',
        ''
    ]);
    rows.push(['____Wake losses', '', (production.wakeLossRatio * 100).toString() + '%', '', '', '', '']);
    rows.push([
        '____Blockage',
        '',
        production?.blockageLossesRatio ? (production.blockageLossesRatio * 100).toString() + '%' : '',
        '',
        '',
        '',
        ''
    ]);
    rows.push([
        '____Turbine performance loss',
        '',
        production?.turbineLossesRatio ? (production.turbineLossesRatio * 100).toString() + '%' : '',
        '',
        '',
        '',
        ''
    ]);
    rows.push([
        '____Electrical losses',
        '',
        production?.electricalLossesRatio ? (production.electricalLossesRatio * 100).toString() + '%' : '0.0%',
        '',
        '',
        '',
        ''
    ]);
    rows.push([
        '____Project specific energy loss',
        '',
        production?.otherLossesRatio ? (production.otherLossesRatio * 100).toString() + '%' : '',
        '',
        '',
        '',
        ''
    ]);

    const csvContent = 'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'case_result.csv');
    document.body.appendChild(link);

    link.click();
};
