/* eslint-disable */
import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiContext } from '../components/ApiContext';
import { LogLevelEnum } from '../LogView/AppLogLevelEnum';
import { ApplicationState } from '../redux/store';
import { addLogLine } from '../redux/store/log/actions';
import { LoginState } from '../redux/store/user/types';
import { Api } from './Api';
import { ErrorCallbackParameters } from './openapi/middleware';

function getDataApiUrl(): string {
    if (window.location.hostname.includes('.azurewebsites')) {
        return window.location.protocol + '//' + window.location.hostname.replace(/(\.azurewebsites)/, '-api$1'); // place '-api' before '.azurewebsites'
    } else if (window.location.hostname === 'lcoe.equinor.com') {
        return 'https://api-lcoeapi-prod.radix.equinor.com';
    } else if (window.location.hostname === 'lcoe-test.equinor.com') {
        return 'https://lcoe-test-api.azurewebsites.net';
    } else if (window.location.hostname === 'gui-lcoe-dev.radix.equinor.com') {
        return 'https://api-lcoeapi-dev.radix.equinor.com';
    } else if (window.location.hostname === 'gui-lcoe-radixdev.radix.equinor.com') {
        return 'https://api-lcoeapi-radixdev.radix.equinor.com';
    } else if (window.location.hostname === 'gui-lcoe-qa.radix.equinor.com') {
        return 'https://api-lcoeapi-qa.radix.equinor.com';
    } else if (window.location.hostname === 'gui-lcoe-prod.radix.equinor.com') {
        return 'https://api-lcoeapi-prod.radix.equinor.com';
    } else {
        // This should now be localhost
        return window.location.protocol + '//' + window.location.hostname + ':5000';
    }
}

interface Props {
    children: any;
}

function errorCallbackToLogLine({ request, response, errorResponse, details }: ErrorCallbackParameters) {
    return {
        status: response && response.ok ? LogLevelEnum.Info : LogLevelEnum.Error,
        time: new Date(),
        title: errorResponse.title || '',
        detail: errorResponse.detail || '',
        details,
        response,
        request
    };
}

export const ApiProvider = ({ children }: Props) => {
    const accessToken = useSelector((state: ApplicationState) =>
        state.user.loginState !== LoginState.SUCCESS ? null : state.user.accessToken
    );
    const dispatch = useDispatch();
    const api = useMemo(() => {
        const handleLog = (errorCallbackParameters: ErrorCallbackParameters) => {
            dispatch(addLogLine(errorCallbackToLogLine(errorCallbackParameters)));
        };
        return new Api(getDataApiUrl(), handleLog);
    }, [addLogLine]);

    if (accessToken) {
        api.token = accessToken;
    }

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};
