export interface CaseRef {
    id: string;
    version?: number;
}

export const ADD_CASE_COMPARE = 'ADD_CASE_COMPARE';
export const REMOVE_CASE_COMPARE = 'REMOVE_CASE_COMPARE';
export const CLEAR_CASE_COMPARE = 'CLEAR_CASE_COMPARE';
export const TOGGLE_CASE_COMPARE = 'TOGGLE_CASE_COMPARE';

export interface AddCaseCompareAction {
    type: typeof ADD_CASE_COMPARE;
    caseRef: CaseRef;
}

export interface RemoveCaseCompareAction {
    type: typeof REMOVE_CASE_COMPARE;
    caseRef: CaseRef;
}

export interface ToggleCaseCompareAction {
    type: typeof TOGGLE_CASE_COMPARE;
    caseRef: CaseRef;
}

export interface ClearCaseCompareAction {
    type: typeof CLEAR_CASE_COMPARE;
}

export type CaseCompareAction =
    | AddCaseCompareAction
    | RemoveCaseCompareAction
    | ClearCaseCompareAction
    | ToggleCaseCompareAction;
