export const tenantId = '3aa4a235-b6e2-48d5-9195-7fcf05b459b0';

export const getClientId = () => {
    if (window.location.origin == 'https://lcoe.equinor.com') {
        return '03b0edab-ad08-4be8-97b4-75011478ea05';
    } else if (window.location.origin == 'https://gui-lcoe-prod.radix.equinor.com') {
        return '03b0edab-ad08-4be8-97b4-75011478ea05';
    } else if (window.location.origin == 'https://gui-lcoe-qa.radix.equinor.com') {
        return '03b0edab-ad08-4be8-97b4-75011478ea05';
    } else {
        return '115488b4-4c92-427d-b066-c5b12736897e';
    }
};

export const getApiScope = () => {
    if (window.location.origin == 'https://lcoe.equinor.com') {
        return 'api://03b0edab-ad08-4be8-97b4-75011478ea05/access_lcoe';
    } else if (window.location.origin == 'https://gui-lcoe-prod.radix.equinor.com') {
        return 'api://03b0edab-ad08-4be8-97b4-75011478ea05/access_lcoe';
    } else if (window.location.origin == 'https://gui-lcoe-qa.radix.equinor.com') {
        return 'api://03b0edab-ad08-4be8-97b4-75011478ea05/access_lcoe';
    } else {
        return 'api://115488b4-4c92-427d-b066-c5b12736897e/access_lcoe';
    }
};
