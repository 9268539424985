import React from 'react';
import styles from './NotFound.module.css';
import { Typography } from '@equinor/eds-core-react';

export const NotFound = (): JSX.Element => {
    return (
        <div className={styles.content}>
            <div className={styles.upload_box}>
                <Typography variant={'h4'}>Page not found</Typography>
                <br />
                <Typography variant={'body_short'}>
                    Go back to <a href={'/'}>Homepage</a>.
                </Typography>
                <br />
            </div>
        </div>
    );
};
