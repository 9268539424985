import { ExperienceMonopiles } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import LoadingDialog from 'components/LoadingDialog';
import React, { useContext, useEffect, useState } from 'react';
import styles from './MonopileExperiencePageDashboard.module.css';
import { MonopileExperienceDashboard } from '../../Experience/MonopileExperienceDashboard';
import { NavBarMasterData } from '../../components/NavBarMasterData';

export const MonopileExperiencePageDashboard = (): JSX.Element => {
    const [experienceMonopiles, setExperienceMonopiles] = useState({} as ExperienceMonopiles);

    const api = useContext(ApiContext);
    useEffect(() => {
        api.getExperienceMonopiles().then((response: ExperienceMonopiles) => {
            setExperienceMonopiles(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    return (
        <>
            <NavBarMasterData />
            {!experienceMonopiles && <LoadingDialog />}
            {experienceMonopiles && experienceMonopiles.monopiles && experienceMonopiles.monopiles.length > 0 && (
                <div className={styles.MonopileExperiencePage}>
                    <MonopileExperienceDashboard monopiles={experienceMonopiles}></MonopileExperienceDashboard>
                </div>
            )}
        </>
    );
};
