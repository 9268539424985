import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import * as PropTypes from 'prop-types';
import styles from './FormSection.module.css';
import { Typography } from '@equinor/eds-core-react';

export const FormSection = ({ legend, children, alignItems }: any): JSX.Element => {
    return (
        <FormControl component="fieldset" className={styles.form_section}>
            <Typography variant="h3" className={styles.legend}>
                {legend}
            </Typography>
            <Grid className={styles.grid} container spacing={1} alignItems={alignItems}>
                {children}
            </Grid>
        </FormControl>
    );
};

FormSection.propTypes = {
    legend: PropTypes.string.isRequired,
    children: PropTypes.node,
    alignItems: PropTypes.string
};
