import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useInput<T>(inputState: T): [T, Dispatch<SetStateAction<T>>] {
    /*

    Similar to useState, but it will also change the variableState when the inputState changes.
    
     */

    const [variableState, setVariableState] = useState<T>(inputState);
    useEffect(() => {
        setVariableState(inputState);
    }, [inputState]);

    return [variableState, setVariableState];
}
