import { Turbine, TurbineProfile } from '@lcoe/lcoe-client';
import React, { useContext } from 'react';
import { calculatePercentOfMaxCapacity } from 'utils/turbine/turbineUtils';
import { ApiContext } from '../ApiContext';
import { Icon, Button } from '@equinor/eds-core-react';

interface IProps {
    object: Turbine;
    onChange: (diff: Partial<Turbine>) => void;
}

export const CalcDudgeonAdornment = (props: IProps): JSX.Element => {
    const api = useContext(ApiContext);

    const calcDudgeonProd = () => {
        api.calcApi
            .calculateDudgeonProd({
                calcDudgeonProdRequest: {
                    turbineProfile: props.object.profile as TurbineProfile,
                    airGap: props.object.airGap as number,
                    rotorDiameter: props.object.rotorDiameter as number
                }
            })
            .then((grossProd) => {
                const grossProdRounded = Math.round(Number(grossProd) * 100) / 100;
                props.onChange({
                    grossProdDud: grossProdRounded,
                    grossProdPercent: calculatePercentOfMaxCapacity(props.object.capacity, grossProdRounded)
                });
            });
    };
    return (
        <Button variant={'ghost_icon'} onClick={calcDudgeonProd} title="Calculate">
            <Icon name={'refresh'} size={18} />
        </Button>
    );
};
