import { Button, Dialog, Icon, SingleSelect } from '@equinor/eds-core-react';
import { Case } from '@lcoe/lcoe-client';
import { Grid } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { UnitConversion } from '../uom/UnitConversion';
import styles from './ChartDialog.module.css';
import { ComparisonChart } from './ComparisonChart';
import { extractCaseNames } from './ComparisonChartData';

export interface IProps {
    handleDialogClose: () => void;
    uc: UnitConversion;
    cases: Case[];
    open: boolean;
}

export const ChartDialog = (props: IProps): JSX.Element => {
    const [showChart, setShowChart] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7>(1);
    const [selectedProject1, setSelectedProject1] = useState<string>();
    const [selectedProject2, setSelectedProject2] = useState<string>();
    const [isPerMw, setIsPerMW] = useState<boolean>(false);
    return (
        <Dialog
            style={{ minWidth: 1400, minHeight: 600 }}
            open={props.open}
            isDismissable={true}
            onClose={() => props.handleDialogClose()}
        >
            <Dialog.CustomContent>
                <Button className={styles.icon_close} variant="ghost_icon" onClick={() => props.handleDialogClose()}>
                    <Icon size={16} name="close" title={'Primary'}></Icon>
                </Button>

                <div className={styles.row}>
                    <div onClick={() => setShowChart(1)}>Capex & Opex</div>
                    <div onClick={() => setShowChart(2)}>Capex & Opex breakdown</div>
                    <div onClick={() => setShowChart(3)}>Capex breakdown</div>
                    <div onClick={() => setShowChart(4)}>Opex Tariff breakdown</div>
                    <div onClick={() => setShowChart(5)}>Opex Other breakdown</div>
                    {!isPerMw && <div onClick={() => setShowChart(6)}>Waterfall</div>}
                    {!isPerMw && <div onClick={() => setShowChart(7)}>LCoE</div>}
                    {!isPerMw && <Button onClick={() => setIsPerMW(true)}>Compare per MW installed</Button>}
                    {isPerMw && <Button onClick={() => setIsPerMW(false)}>Compare in total cost</Button>}
                </div>

                {showChart === 1 && (
                    <ComparisonChart uc={props.uc} comparisonLevel={1} cases={props.cases} isPerMW={isPerMw} />
                )}
                {showChart === 2 && (
                    <ComparisonChart uc={props.uc} comparisonLevel={2} cases={props.cases} isPerMW={isPerMw} />
                )}
                {showChart === 3 && (
                    <ComparisonChart uc={props.uc} comparisonLevel={3} cases={props.cases} isPerMW={isPerMw} />
                )}
                {showChart === 4 && (
                    <ComparisonChart uc={props.uc} comparisonLevel={4} cases={props.cases} isPerMW={isPerMw} />
                )}
                {showChart === 5 && (
                    <ComparisonChart uc={props.uc} comparisonLevel={5} cases={props.cases} isPerMW={isPerMw} />
                )}
                {showChart === 6 && (
                    <Grid container>
                        <Grid item xs={12} sm={9}>
                            <ComparisonChart
                                uc={props.uc}
                                comparisonLevel={6}
                                cases={props.cases}
                                case1={selectedProject1}
                                case2={selectedProject2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <div style={{ width: 320, marginTop: 50, height: 60 }}>
                                <SingleSelect
                                    items={extractCaseNames(props.cases)}
                                    initialSelectedItem={''}
                                    selectedOption={selectedProject1}
                                    label="First project to compare"
                                    handleSelectedItemChange={({ selectedItem }) => {
                                        setSelectedProject1(selectedItem as string);
                                    }}
                                />
                            </div>
                            <div style={{ width: 320, marginLeft: 0, height: 60 }}>
                                <SingleSelect
                                    items={extractCaseNames(props.cases)}
                                    initialSelectedItem={''}
                                    selectedOption={selectedProject2}
                                    label="Second project to compare"
                                    handleSelectedItemChange={({ selectedItem }) => {
                                        setSelectedProject2(selectedItem as string);
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                )}
                {showChart === 7 && (
                    <ComparisonChart uc={props.uc} comparisonLevel={7} cases={props.cases} isPerMW={false} />
                )}
            </Dialog.CustomContent>
        </Dialog>
    );
};
