/* eslint-disable */
import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as PropTypes from 'prop-types';

function inputPropsForType({ type, min, max, unit, endAdornment }: any) {
    const InputProps = {} as any;
    if (type === 'number' || type === 'integer') {
        InputProps.type = 'number';
        InputProps.inputProps = { min, max };
    }
    if (endAdornment || unit) {
        InputProps.endAdornment = (
            <InputAdornment position="end">
                {unit}
                {endAdornment}
            </InputAdornment>
        );
    }
    return InputProps;
}

function valueOrSpace(value: any) {
    if (value === undefined || value === null) {
        return '';
    }
    return value;
}

export const SimpleField = ({
    label,
    name,
    onChange,
    type,
    min,
    max,
    helperText,
    unit,
    value,
    problem,
    warning,
    disabled,
    required = true,
    endAdornment,
    sm
}: any) => {
    const InputProps = useMemo(
        () =>
            inputPropsForType({
                type,
                min,
                max,
                unit,
                endAdornment
            }),
        [type, min, max, unit, endAdornment]
    );
    const error = problem || warning;
    return (
        <Grid item xs={12} sm={sm}>
            <TextField
                label={label}
                InputProps={InputProps}
                required={required}
                fullWidth
                name={name}
                onChange={onChange}
                helperText={error}
                error={!!error}
                disabled={disabled}
                value={valueOrSpace(value)}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </Grid>
    );
};

SimpleField.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['number', 'string', 'integer']),
    min: PropTypes.number,
    max: PropTypes.number,
    helperText: PropTypes.string,
    wide: PropTypes.bool,
    unit: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    problem: PropTypes.string,
    warning: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    endAdornment: PropTypes.node,
    sm: PropTypes.number
};

SimpleField.defaultProps = {
    type: 'string',
    required: true
};
