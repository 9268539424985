import styles from './Labels.module.css';
import React from 'react';
import { mapValueToColor } from '../utils/map/MapValueToColor';
interface IProps {
    className?: string;
    color: string;
    value: string;
}

const LabelRow = (props: IProps): JSX.Element => {
    return (
        <div className={styles.row}>
            {props.value}
            <div className={styles.square} style={{ backgroundColor: props.color }} />
        </div>
    );
};
interface labelProps {
    className?: string;
    colors: Array<string>;
    values: Array<string>;
}

export const Labels = (props: labelProps): JSX.Element => {
    const legends = mapValueToColor(props.values, props.colors);
    return (
        <div className={styles.column}>
            {legends.map((legend, index) => {
                return <LabelRow color={legend.color} value={legend.value} key={index} className={styles.row} />;
            })}
        </div>
    );
};
