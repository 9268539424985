import * as React from 'react';
import { CoeCapexNode, CoeOpexNode } from './ResultDefinitions';
import { ResultEntry, flatCapex, flatOpex, recalcLevel, downloadResultCSV } from './ResultUtils';
import { Button, Icon, Typography } from '@equinor/eds-core-react';
import { asUnitObj, UnitConversion } from '../../uom/UnitConversion';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import styles from './ResultDetailCalculated.module.css';
import { Unit } from 'mathjs';
import { ResultStructure } from '@lcoe/lcoe-client';

interface IProps {
    capexNode: CoeCapexNode;
    opexNode: CoeOpexNode;
    hierarchy: ResultStructure;
    maxTheoreticalProduction: Unit;
    unitConversion: UnitConversion;
}

export const ResultDetailCalculated = (props: IProps): JSX.Element => {
    // CAPEX
    const arrCapexResult: ResultEntry[] = flatCapex(props.capexNode, false);
    recalcLevel(4, arrCapexResult);
    recalcLevel(3, arrCapexResult);
    recalcLevel(2, arrCapexResult);
    recalcLevel(1, arrCapexResult);

    // OPEX
    const arrOpexResult: ResultEntry[] = flatOpex(props.opexNode, false);
    recalcLevel(4, arrOpexResult);
    recalcLevel(3, arrOpexResult);
    recalcLevel(2, arrOpexResult);
    recalcLevel(1, arrOpexResult);

    const resultStructure = arrCapexResult.concat(arrOpexResult);
    resultStructure.forEach((el) => {
        if (el.level > 1) {
            el.isActive = false;
        }
    });

    return (
        <ResultTableCalculated
            uc={props.unitConversion}
            resultEntries={resultStructure}
            hierarchy={props.hierarchy}
            maxTheoreticalProduction={props.maxTheoreticalProduction}
        ></ResultTableCalculated>
    );
};

interface IPropsTable {
    resultEntries: ResultEntry[];
    uc: UnitConversion;
    hierarchy: ResultStructure;
    maxTheoreticalProduction: Unit;
}

const ResultTableCalculated = (props: IPropsTable): JSX.Element => {
    const totalCapex = props.resultEntries[0].capexCost + props.resultEntries[0].contingency;
    let totalOpex = 0;

    const [capexDataTable, setCapexDataTable] = useState(props.resultEntries);
    const [prodTableOpen, setProdTableOpen] = useState(false);

    const production = props.hierarchy.production;

    const handleExpandCollapse = (id: string) => {
        const newDatatable = cloneDeep(capexDataTable);

        let isExpanded = true;

        const currentEl = newDatatable.filter((entryRow) => entryRow.id == id);
        currentEl.forEach((element) => {
            element.expanded = !element.expanded;
            isExpanded = element.expanded;
        });

        if (isExpanded) {
            const resChildren = newDatatable.filter(
                (entryRow) => String(entryRow.id).startsWith(id + '_') && entryRow.id.length == id.length + 2
            );
            resChildren.forEach((element) => {
                element.isActive = true;
            });
        } else {
            const resChildren = newDatatable.filter((entryRow) => String(entryRow.id).startsWith(id + '_'));
            resChildren.forEach((element) => {
                element.isActive = false;
                element.expanded = false;
            });
        }

        setCapexDataTable(newDatatable);
    };

    const handleAllExpandCollapse = (expand: boolean) => {
        const newDatatable = cloneDeep(capexDataTable);

        if (expand) {
            newDatatable.forEach((element) => {
                element.expanded = true;
                element.isActive = true;
            });
        } else {
            newDatatable.forEach((element) => {
                if (element.level > 1) {
                    element.expanded = false;
                    element.isActive = false;
                } else {
                    element.expanded = false;
                }
            });
        }

        setCapexDataTable(newDatatable);
        setProdTableOpen(expand);
    };

    const prodTableRow = (level: number, val1: string, val2: string, val3: string) => {
        return (
            <tr className={styles.row}>
                <td
                    style={{
                        textAlign: 'left',
                        fontWeight: level == 1 ? 'bold' : 'normal'
                    }}
                    className={styles.row}
                >
                    {level == 1 && (
                        <div
                            className={styles.tree_parent_node}
                            onClick={() => {
                                setProdTableOpen(!prodTableOpen);
                            }}
                        >
                            <Icon size={16} name={prodTableOpen ? 'chevron_up' : 'chevron_down'} />
                            {val1}
                        </div>
                    )}
                    {level > 1 && <div style={{ paddingLeft: level == 2 ? 30 : 50 }}>{val1}</div>}
                </td>
                <td className={styles.row}>{val2}</td>
                <td className={styles.row}>{val3}</td>
                <td width={'28%'} />
            </tr>
        );
    };

    return (
        <>
            <div className={styles.row_coe}>
                <div style={{ paddingTop: 5 }}>
                    <Typography variant={'h3'}>
                        <b>COE: {props.uc.convertUnitToString(asUnitObj(props.hierarchy.coe))}</b>
                    </Typography>
                </div>
                <div>
                    <Button
                        title={'Export the result structure as csv'}
                        style={{ textAlign: 'right' }}
                        onClick={() => downloadResultCSV(capexDataTable, production, props.maxTheoreticalProduction)}
                    >
                        <Icon size={18} name={'download'} /> Export result
                    </Button>
                </div>
            </div>
            <div className={styles.expand_collapse}>
                <div title={'Expand all levels'} onClick={() => handleAllExpandCollapse(true)}>
                    + expand
                </div>
                <div title={'Collapse all levels'} onClick={() => handleAllExpandCollapse(false)}>
                    - collapse
                </div>
            </div>
            <table className={styles.result_table}>
                <tbody>
                    <tr className={styles.row_header}>
                        <td></td>
                        <td>Cost</td>
                        <td>% of total</td>
                        <td>Learning effect</td>
                        <td>% of item</td>
                        <td>Contingency</td>
                        <td>% of item</td>
                    </tr>

                    {capexDataTable.map((el, index) => {
                        if (el.id == '2') {
                            totalOpex = el.capexCost + el.contingency;
                        }

                        if (el.isActive) {
                            const learningEffectPercentage = (
                                (el.learningEffect / (el.capexCost + el.contingency + el.learningEffect)) *
                                100
                            ).toFixed(2);

                            const contingencyPercentage = ((el.contingency / el.capexCost) * 100).toFixed(2);

                            return (
                                <tr key={index} className={styles.row}>
                                    <td
                                        className={styles.row}
                                        style={{
                                            textAlign: 'left',
                                            paddingLeft: el.level * 10 - 10,
                                            fontWeight: el.isParent || el.level <= 2 ? 'bold' : 'normal'
                                        }}
                                    >
                                        {el.isParent && (
                                            <div
                                                className={styles.tree_parent_node}
                                                onClick={() => {
                                                    handleExpandCollapse(el.id);
                                                }}
                                            >
                                                <Icon size={16} name={el.expanded ? 'chevron_up' : 'chevron_down'} />
                                                {el.name == 'Total OPEX' ? 'Total OPEX *' : el.name}
                                            </div>
                                        )}
                                        {!el.isParent && <div style={{ paddingLeft: 17 }}>{el.name}</div>}
                                    </td>
                                    <td>{props.uc.fromMEURToCurrency(el.capexCost + el.contingency)}</td>
                                    <td>
                                        {(
                                            ((el.capexCost + el.contingency) /
                                                (el.id[0] == '1' ? totalCapex : totalOpex)) *
                                            100
                                        ).toFixed(2)}{' '}
                                        %
                                    </td>
                                    <td>{props.uc.fromMEURToCurrency(el.learningEffect)}</td>
                                    <td>{isNaN(+learningEffectPercentage) ? 0 : learningEffectPercentage} %</td>
                                    <td>{props.uc.fromMEURToCurrency(el.contingency)}</td>
                                    <td>{isNaN(+contingencyPercentage) ? 0 : contingencyPercentage} %</td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </table>
            <table className={styles.result_table}>
                <tbody>
                    <tr>
                        <td> &nbsp; </td>
                    </tr>
                    <tr className={styles.row_header}>
                        <td></td>
                        <td>Energy</td>
                        <td>%</td>
                        <td colSpan={4} />
                    </tr>
                    {prodTableRow(
                        1,
                        'Net annual production',
                        production.netAnnualProduction.value.toFixed(2).toString() +
                            ' ' +
                            production.netAnnualProduction.unit,
                        +(
                            (asUnitObj(production.netAnnualProduction).divide(
                                props.maxTheoreticalProduction
                            ) as unknown as number) * 100
                        ).toFixed(1) + '%'
                    )}

                    {prodTableOpen && (
                        <>
                            {prodTableRow(
                                2,
                                'Gross production',
                                production.grossAnnualProduction.value.toFixed(1) +
                                    ' ' +
                                    production.grossAnnualProduction.unit,
                                +(
                                    (asUnitObj(production.grossAnnualProduction).divide(
                                        props.maxTheoreticalProduction
                                    ) as unknown as number) * 100
                                ).toFixed(1) + '%'
                            )}
                            {prodTableRow(
                                2,
                                'Total losses',
                                '',
                                (production.totalLossesRatio * 100).toFixed(1).toString() + '%'
                            )}
                            {prodTableRow(
                                3,
                                'Technical availability',
                                '',
                                (production.technicalAvailabilityRatio * 100).toFixed(1).toString() + '%'
                            )}
                            {prodTableRow(
                                3,
                                'Wake losses',
                                '',
                                (production.wakeLossRatio * 100).toFixed(1).toString() + '%'
                            )}
                            {prodTableRow(
                                3,
                                'Blockage',
                                '',
                                production?.blockageLossesRatio
                                    ? (production.blockageLossesRatio * 100).toFixed(1).toString() + '%'
                                    : ''
                            )}
                            {prodTableRow(
                                3,
                                'Turbine performance loss',
                                '',
                                production?.turbineLossesRatio
                                    ? (production.turbineLossesRatio * 100).toFixed(1).toString() + '%'
                                    : ''
                            )}
                            {prodTableRow(
                                3,
                                'Electrical losses',
                                '',
                                production?.electricalLossesRatio
                                    ? (production.electricalLossesRatio * 100).toFixed(1).toString() + '%'
                                    : '0.0%'
                            )}
                            {prodTableRow(
                                3,
                                'Project specific energy loss',
                                '',
                                production?.otherLossesRatio
                                    ? (production.otherLossesRatio * 100).toFixed(1).toString() + '%'
                                    : ''
                            )}
                        </>
                    )}
                </tbody>
            </table>
        </>
    );
};
