/* eslint-disable */
import Popover from '@material-ui/core/Popover';
import React from 'react';
import * as PropTypes from 'prop-types';

export const SimplePopover = ({ anchorEl, onClose, children }: any) => {
    return (
        <Popover
            anchorEl={anchorEl}
            open
            onClose={onClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        >
            <div className="wtg-popover">{children}</div>
        </Popover>
    );
};

SimplePopover.propTypes = {
    anchorEl: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
};
