import { AnyAction } from 'redux';
import { emailToUsername } from '../../../components/utils/textmanipulation';
import { AUTHENTICATED, LoginState, RoleAdmin, SET_ACCOUNT, SET_USER_STATE, UserState } from './types';

export const devUser = {
    loginState: LoginState.SUCCESS,
    name: 'Development mode',
    username: 'demo',
    idToken: 'development',
    accessToken: 'development',
    userId: 'development',
    roles: []
};

function defaultState(): UserState {
    if (process.env.NODE_ENV !== 'production') {
        return {
            ...devUser,
            roles: [RoleAdmin]
        };
    }
    return {
        loginState: LoginState.BEFORE,
        name: 'Login not attempted'
    };
}

export function userReducer(userState: UserState = defaultState(), action: AnyAction) {
    switch (action.type) {
        case SET_USER_STATE:
            return action.userState;
        case SET_ACCOUNT:
            return {
                ...userState,
                name: action.accountInfo.idTokenClaims.name,
                username: emailToUsername(action.accountInfo.idTokenClaims.preferred_username),
                userId: action.accountInfo.idTokenClaims.preferred_username
            };
        case AUTHENTICATED:
            return {
                ...userState,
                idToken: action.authResult.idToken,
                accessToken: action.authResult.accessToken,
                roles: action.authResult.idTokenClaims.roles,
                loginState: LoginState.SUCCESS
            };
        default:
            return userState;
    }
}
