import { Turbine, TurbinePerformanceTableRow } from '@lcoe/lcoe-client';

function ParseError(msg: string) {
    return new Error(msg);
}

export function parseTurbineFromXml(str: string): Partial<Turbine> {
    const xml = new DOMParser().parseFromString(str, 'text/xml');
    const turbineNode = xml.querySelector('WindTurbineGenerator');
    if (!turbineNode) {
        throw ParseError('No WindTurbineGenerator in XML');
    }
    const formatVersion = turbineNode.getAttribute('FormatVersion');
    console.log('Parsing turbine XML with format version', formatVersion);
    const description = turbineNode.getAttribute('Description') || undefined;
    const heightNode = turbineNode.querySelector('Height') as Element;
    const hubHeight = parseFloat(heightNode.textContent || '0');
    const rotorDiameter = parseFloat(turbineNode.getAttribute('RotorDiameter') || '0');
    const commentsNode = turbineNode.querySelector('Comments');
    const comments = (commentsNode && commentsNode.textContent) || '';

    const performanceTable = turbineNode.querySelector('PerformanceTable') as Element;
    const performanceTableCommentsNode = performanceTable.querySelector('Comments');

    const tableNodes = Array.from(performanceTable.querySelectorAll('DataTable DataPoint'));

    const convertedTable: TurbinePerformanceTableRow[] = tableNodes.map((node: Element) => {
        const ws = node.getAttribute('WindSpeed');
        const po = node.getAttribute('PowerOutput');
        const tco = node.getAttribute('ThrustCoEfficient');

        if (!ws || !po || !tco) {
            throw ParseError('Missing attributes in wind table');
        }
        return {
            windSpeedMps: parseFloat(ws),
            powerOutputWatt: parseFloat(po),
            thrustCoefficient: parseFloat(tco)
        };
    });

    const capacityMW = convertedTable.map((v) => v.powerOutputWatt).reduce((a, b) => Math.max(a, b), 0) / 1e6;

    const airGap = hubHeight - rotorDiameter / 2;

    return {
        name: description,
        comments,
        rotorDiameter,
        airGap,
        capacity: capacityMW,
        profile: {
            comments: (performanceTableCommentsNode && performanceTableCommentsNode.textContent) || '',
            performanceTable: convertedTable
        }
    };
}
