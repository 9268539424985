import { ExperienceMonopiles, ExperienceMonopile, FileUpload } from '@lcoe/lcoe-client';
import * as React from 'react';
import CSS from 'csstype';
import styles from './MonopileExperienceListView.module.css';
import { ApiContext } from 'components/ApiContext';
import { useContext } from 'react';
import { isUserFoundationAdmin } from 'utils/permission/permissionUtil';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../redux/store';
import { UploadButton } from '../components/UploadButton';
import XLSX from 'xlsx';
import { ListTable } from '../elements/visual/ListTable';
import { Button } from '@equinor/eds-core-react';
import { PageHeader } from '../elements/visual/PageHeader';
import { PopoverInfo } from '../elements/visual/PopoverInfo';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    monopiles: ExperienceMonopiles;
}

export const MonopileExperienceListView = (props: IProps): JSX.Element => {
    const user = useSelector((state: ApplicationState) => state.user);
    const isFoundationAdmin = isUserFoundationAdmin(user);
    const api = useContext(ApiContext);

    const columns = [
        { field: 'id', hide: true },
        { field: 'windproject', headerName: 'Wind project', width: 180 },
        { field: 'source', headerName: 'Source', width: 130 },
        { field: 'year', headerName: 'Year', width: 110 },
        { field: 'waterdepth', headerName: 'Water depth', width: 160 },
        { field: 'wind50yrs', headerName: 'Wind speed 50yrs', width: 200 },
        { field: 'hs50', headerName: 'HS50', width: 120 },
        { field: 'wind500yrs', headerName: 'Wind Cyclone 500yrs', width: 220 },
        { field: 'hs500', headerName: 'HS500', width: 130 },
        { field: 'soil', headerName: 'Soil type', width: 130 },
        { field: 'soilchar', headerName: 'Soil characteristics', width: 160 },
        { field: 'wtgdiameter', headerName: 'WTG rotor diameter', width: 170 },
        { field: 'wtgrating', headerName: 'WTG rating', width: 150 },
        { field: 'mpdiameter', headerName: 'MP diameter', width: 160 },
        { field: 'mplength', headerName: 'MP length', width: 140 },
        { field: 'mpweight', headerName: 'MP weight', width: 140 },
        { field: 'mppenetration', headerName: 'MP penetration', width: 170 },
        { field: 'tpdiameter', headerName: 'TP diameter', width: 150 },
        { field: 'tplength', headerName: 'TP length', width: 140 },
        { field: 'tpmainsteel', headerName: 'TP main steel weight', width: 200 },
        { field: 'tpsecondarysteel', headerName: 'TP secondary steel weight', width: 200 },
        { field: 'uploadedby', headerName: 'Updated by', width: 160 }
    ];

    const datarows = [] as Record<string, unknown>[];

    props.monopiles.monopiles.map((el: ExperienceMonopile, index: number) =>
        datarows.push({
            id: index,
            windproject: String(el.windProject),
            source: String(el.sourceReference),
            year: String(el.year),
            waterdepth: String(el.waterDepth),
            wind50yrs: String(el.windSpeed50y),
            hs50: String(el.hs50),
            wind500yrs: String(el.windCyclone500y),
            hs500: String(el.hs500),
            soil: String(el.soilType),
            soilchar: String(el.soilCharacteristics),
            wtgdiameter: String(el.wtgRotorDiameter),
            wtgrating: String(el.wtgRating),
            mpdiameter: String(el.mpDiameter),
            mplength: String(el.mpLength),
            mpweight: String(el.mpWeight),
            mppenetration: String(el.mpPenetration),
            tpdiameter: String(el.tpDiameter),
            tplength: String(el.tpLength),
            tpmainsteel: String(el.tpPrimarySteelWeight),
            tpsecondarysteel: String(el.tpSecondarySteelWeight),
            uploadedby: String(el.uploadedBy)
        })
    );

    function loadData(file: FileUpload) {
        const importMonopiles = {} as ExperienceMonopiles;
        const monopiles = [] as ExperienceMonopile[];
        const fileContent = XLSX.read(file.content);
        const data = XLSX.utils
            .sheet_to_csv(fileContent.Sheets.Monopiles, { RS: '@@', blankrows: false })
            .split('@@')
            .slice(1, -1);

        data.map((row: string) => {
            const el = row.split(',');

            monopiles.push({
                windProject: el[0],
                sourceReference: el[1],
                year: Number(el[2]),
                waterDepth: Number(el[3]),
                windSpeed50y: Number(el[4]),
                hs50: Number(el[5]),
                windCyclone500y: Number(el[6]),
                hs500: Number(el[7]),
                soilType: el[8],
                soilCharacteristics: el[9],
                wtgRotorDiameter: Number(el[10]),
                wtgRating: Number(el[11]),
                mpDiameter: Number(el[12]),
                mpLength: Number(el[13]),
                mpWeight: Number(el[14]),
                mpPenetration: Number(el[15]),
                tpDiameter: Number(el[16]),
                tpLength: Number(el[17]),
                tpPrimarySteelWeight: Number(el[18]),
                tpSecondarySteelWeight: Number(el[19]),
                uploadedBy: user.name
            } as ExperienceMonopile);
        });

        importMonopiles.monopiles = monopiles;
        const promise = api.importMonopiles(importMonopiles as ExperienceMonopiles);
        promise.then((savedCase) => {
            if (savedCase.monopiles.length > 0) {
                location.reload();
            }
        });
    }

    const popover_element = (
        <div>
            <div>Please use the attached Excel template when importing data.</div>
            <a href={'/files/import_mp_template.xlsx'}>Download Excel Template</a>
        </div>
    );

    const element = (
        <div className={styles.ButtonArea}>
            <div>
                <Button href="/foundations/experience-monopile-dashboard">Dashboard</Button>
            </div>
            {isFoundationAdmin && (
                <>
                    <div className={styles.ButtonAlign}>
                        <Button href="/foundations/experience-monopile-edit">Edit</Button>
                    </div>
                    <div className={styles.ButtonAlign}>
                        <UploadButton id="import-button" onUpload={loadData} accept={'.xlsx'} variant="outlined">
                            Import data
                        </UploadButton>
                    </div>
                    <div className={styles.ButtonIcon}>
                        <PopoverInfo title={'How to import'} body_element={popover_element}></PopoverInfo>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <>
            <PageHeader title={'Foundation experience data - Monopiles'} right_element={element}></PageHeader>
            {datarows.length > 0 && <ListTable datarows={datarows} columns={columns}></ListTable>}
        </>
    );
};
