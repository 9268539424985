/* eslint-disable */
import leaflet, { FeatureGroup, LatLngExpression, LayerGroup, Map, Marker } from 'leaflet';
import circle from './circle-grey.png';

export const circleIcon = new leaflet.Icon({
    iconSize: [10, 10],
    iconUrl: circle
});

export class TooltipLayer extends FeatureGroup<Marker> {
    private markers: Marker[] = [];

    addTo(map: Map | LayerGroup<any>): this {
        return super.addTo(map)
    }

    addTooltip(content: any, position: LatLngExpression) {
        const marker = new Marker(position, {
            icon: circleIcon
        }).bindTooltip(content, {
            permanent: true,
            sticky: false,
            interactive: true,
            direction: 'right'
        });
        marker.addTo(this);
        this.markers.push(marker);
    }
}
