/* eslint-disable */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { FileType, FileUpload } from '@lcoe/lcoe-client';
import {Icon} from "@equinor/eds-core-react";

interface UploadButtonProps {
    id?: string;
    children: string;
    accept?: string;
    variant?: 'text' | 'outlined' | 'contained' | undefined;
    color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
    onUpload: (payload: FileUpload) => void;
}

export const UploadButton = ({
    id = 'file-button',
    children = 'Upload file',
    accept = '.xml, .txt, .csv, .xlsx, .xlsb',
    variant,
    color,
    onUpload
}: UploadButtonProps) => {
    const [dragging, setDragging] = useState(false);
    const onChange = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        handleFile(file);
        event.target.value = null;
    };

    const handleFile = (file: any) => {
        const reader = new FileReader();
        const isExcel: boolean = file.name.toLowerCase().match(/\.(xlsx|xls)$/);
        const isCSV: boolean = file.name.toLowerCase().match(/\.(csv|txt)$/);
        reader.onload = ({ target }) => {
            if (target !== null) {
                let payload: FileUpload;
                if (isExcel) {
                    payload = { content: btoa(target.result as string), filename: file.name, type: FileType.Xlsx };
                } else if (isCSV) {
                    payload = { content: target.result as string, filename: file.name, type: FileType.Csv };
                }
                else {
                    payload = { content: target.result as string, filename: file.name, type: FileType.Other };
                }
                onUpload(payload);
            }
        };
        if (isExcel) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsText(file);
        }
    };

    const onDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        const file =
            event &&
            event.dataTransfer &&
            event.dataTransfer.files &&
            event.dataTransfer.files.length === 1 &&
            event.dataTransfer.files[0];
        if (file) {
            handleFile(file);
        }
    };

    const onDragOver = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    const onDragLeave = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    };

    return (
        <React.Fragment>
            <div
                onDropCapture={onDrop}
                onDragOverCapture={onDragOver}
                onDragEnterCapture={onDragOver}
                onDragLeaveCapture={onDragLeave}
            >
                <input type="file" accept={accept} onChange={onChange} style={{ display: 'none' }} id={id} />
                <label htmlFor={id}>
                    <Button variant={variant} component="span" color={color}>
                        {dragging && <Icon name={'download'} />} {children}
                    </Button>
                </label>
            </div>
        </React.Fragment>
    );
};
