/* eslint-disable */
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import * as React from 'react';
import { useCallback, useState } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import { Field, setIsIncluded } from './fields';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import { IconButton } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import { spaceForCamelCases } from '../components/utils/textmanipulation';
import {Icon} from "@equinor/eds-core-react";

interface ColumnSelectCardProps {
    fields: Field[];
    toggleField: (field: Field) => void;
    selectAll: () => void;
    resetSelection: () => void;
    selectLevel: (levelName: string) => void;
}

export const ColumnSelectCard = ({
    fields,
    toggleField,
    selectAll,
    resetSelection,
    selectLevel
}: ColumnSelectCardProps) => {
    const [filter, setFilter] = useState('');

    const onSearchChanged = useCallback(
        (event) => {
            setFilter(event.target.value);
        },
        [setFilter]
    );

    setIsIncluded(fields, filter);

    return (
        <Card style={{marginLeft: 4, marginTop: 8, marginRight: 4, marginBottom: 8 }}>
            <CardHeader title="Select columns" />
            <CardActions>
                <Button onClick={resetSelection}>Reset selection</Button>
                <Button onClick={() => selectLevel('Total CAPEX')}>Select capex elements</Button>
                <Button onClick={() => selectLevel('operationAndMaintenanceActivities')}>Select O&M activities</Button>
                <Button onClick={() => selectLevel('installationActivities')}>Select installation activities</Button>
                <Button onClick={selectAll}>Select all</Button>
            </CardActions>
            <CardContent className="card-scroll">
                <TextField placeholder="Search..." onChange={onSearchChanged} />
                <List dense>
                    {fields
                        .filter((field) => field.isIncluded)
                        .map((field) => {
                            return (
                                <FieldItem key={field.path.join()} field={field} level={0} toggleField={toggleField} />
                            );
                        })}
                </List>
            </CardContent>
        </Card>
    );
};

interface FieldItemProps {
    field: Field;
    level: number;
    toggleField: (field: Field) => void;
}

const FieldItem = ({ field, level, toggleField }: FieldItemProps) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);
    const expandedIcon = (opacity: number) =>
        expanded ? <Icon name={'chevron_right'} /> : <Icon name={'chevron_down'} />;
    const hasChildren = field.children !== undefined && field.children.length > 0;

    const listItemStyle = {
        paddingLeft: `${level * 15}px`
    };

    return (
        <>
            <ListItem style={listItemStyle}>
                <IconButton disabled={!hasChildren} onClick={toggleExpanded} size="small">
                    {expandedIcon(hasChildren ? 1 : 0)}
                </IconButton>
                <ListItemText primary={spaceForCamelCases(field.title)} />
                {field.hasValue && (
                    <ListItemSecondaryAction>
                        <Checkbox edge="end" checked={field.isActive} onChange={() => toggleField(field)} />
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            {expanded &&
                field.children !== undefined &&
                field.children
                    .filter((field) => field.isIncluded)
                    .map((field) => (
                        <FieldItem key={field.path.join()} field={field} level={level + 1} toggleField={toggleField} />
                    ))}
        </>
    );
};
