/* eslint-disable */
import React, {useCallback, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {SimplePopover} from '../components/forms/SimplePopover';
import Button from '@material-ui/core/Button';
import {Icon} from "@equinor/eds-core-react";

function maxWtgCount(areaKm2: any, spacingMeter: any) {
    const areaM2 = 1000000 * areaKm2;
    const areaM = Math.sqrt(areaM2);
    const turbinesPerDimension = areaM / spacingMeter + 1;
    return Math.max(0, Math.floor(turbinesPerDimension * turbinesPerDimension));
}

export const WtgCountAdornment = ({object, onChange}: any) => {
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const {wtg, wtgSpacingNd, location} = object;
    const maxCountTurbinesConcessionCapacity =
        location && wtg && Math.floor(location.concessionCapacity / wtg.capacity);
    const maxTurbinesDueToSpacingAndConcessionArea =
        location && wtg && wtgSpacingNd && maxWtgCount(location.concessionArea, wtg.rotorDiameter * wtgSpacingNd);

    const setCount = useCallback(
        (wtgCount) => {
            onChange({wtgCount});
            setPopoverAnchor(null);
        },
        [onChange]
    );

    const canCompute = maxCountTurbinesConcessionCapacity || maxTurbinesDueToSpacingAndConcessionArea;
    if (!canCompute) {
        return null;
    }

    return (
        <React.Fragment>
            <IconButton title="Calculate" onClick={(event) => setPopoverAnchor(event.target as any)}>
                <Icon name={'computer'}/>
            </IconButton>
            {popoverAnchor && (
                <SimplePopover anchorEl={popoverAnchor} onClose={() => setPopoverAnchor(null)}>
                    <Button
                        disabled={!maxCountTurbinesConcessionCapacity}
                        variant="outlined"
                        onClick={() => setCount(maxCountTurbinesConcessionCapacity)}
                    >
                        <Icon name={'vertical_align_bottom'}/>
                        Limited by capacity: {maxCountTurbinesConcessionCapacity || '?'}
                    </Button>
                    <Button
                        disabled={!maxTurbinesDueToSpacingAndConcessionArea}
                        variant="outlined"
                        onClick={() => setCount(maxTurbinesDueToSpacingAndConcessionArea)}
                    >
                        <Icon name={'vertical_align_bottom'}/>
                        Limited by spacing: {maxTurbinesDueToSpacingAndConcessionArea || '?'}
                    </Button>
                </SimplePopover>
            )}
        </React.Fragment>
    );
};
