export const onmStrategyOptions: Array<OnmStrategy> = [
    { name: 'Offshore', explanation: 'Approximately > 80 km from O&M port', offshoreConcept: true, onshoreNear: false },
    {
        name: 'Onshore Near',
        explanation: 'Aproximately < 50 km from O&M port',
        maxDistanceToOnmPort: 59,
        offshoreConcept: false,
        onshoreNear: true
    },
    {
        name: 'Onshore Far',
        explanation: 'Aproximately 50 - 80 km from O&M port',
        maxDistanceToOnmPort: 80,
        offshoreConcept: false,
        onshoreNear: false
    }
];

export interface OnmStrategy {
    name: string;
    explanation: string;
    offshoreConcept: boolean;
    onshoreNear: boolean;
    maxDistanceToOnmPort?: number;
}
