import { SimpleChipList } from './forms/SimpleChipList';
import React from 'react';
import Grid from '@material-ui/core/Grid';

interface SimpleTagsFieldProps {
    value: Array<string>;
    onChange: (diff: { tags: Array<string> }) => void;
}

export const SimpleTagsField = ({ value, onChange }: SimpleTagsFieldProps): JSX.Element => {
    return (
        <Grid item xs={12}>
            <SimpleChipList
                label="Tags"
                selectedItems={value || []}
                onSelectedItemsChange={(items: Array<string>) => onChange({ tags: items })}
                placeholder={undefined}
                helperText={undefined}
            />
        </Grid>
    );
};
