/* eslint-disable */
import React, { ChangeEvent, Component } from 'react';
import { SwaggerSchema, SwaggerTypeEnum } from '../../Api/model/SwaggerSpec';
import { Problems } from '../../Api/Problems';
import { extractValue, isObjectSubset } from '../utils/helpers';
import { subscript } from '../utils/subscript';
import { SimpleCheckbox } from './SimpleCheckbox';
import { SimpleField } from './SimpleField';
import { SimpleSelect } from './SimpleSelect';

type SelectOption = any;

interface SchemaFieldProperties<T> {
    schema: SwaggerSchema;
    name: string;
    onChange: (diff: { [key: string]: any }) => void;
    object: T;
    problems: Problems; 
    warnings?: Problems;
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

    dependentOn?: any;

    options?: Array<SelectOption>;
    disabled?: boolean;
    renderEditForm?: (props: any) => React.ReactNode;
    helperTextFromObject?: (object: T) => string;

    endAdornmentComponentRender?: any;
    tiny?: boolean;
}

export class SchemaField extends Component<SchemaFieldProperties<any>> {
    constructor(props: Readonly<SchemaFieldProperties<any>>) {
        super(props);
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.onChangeCheckboxEvent = this.onChangeCheckboxEvent.bind(this);
    }

    get prop() {
        if (!this.props.schema.properties) {
            throw new Error('Schema field references missing properties');
        }
        return this.props.schema.properties[this.props.name];
    }

    get name() {
        return this.props.name;
    }

    get value() {
        const value = this.props.object[this.props.name];
        if (this.props.options === undefined) return value;
        if (typeof value !== 'object') return value;

        // Return the same reference as in options to not get warnings
        const sameValue = this.props.options.find((option) => isObjectSubset(value, option));
        if (sameValue === undefined) return value; // This should not happen. Will give a warning.
        return sameValue;
    }

    get problem() {
        if (!this.props.problems) return undefined;
        return this.props.problems[this.props.name];
    }

    get warning() {
        if (!this.props.warnings) return undefined;
        return this.props.warnings[this.props.name];
    }

    get required() {
        return this.props.schema.required && this.props.schema.required.includes(this.name);
    }

    get options() {
        return this.props.options;
    }

    get label() {
        if (!this.prop.title) {
            return this.name;
        }
        if (this.prop.title.includes('_')) {
            return subscript(this.prop.title);
        }
        return this.prop.title;
    }

    onChangeEvent(event: ChangeEvent<{ value: string }>) {
        const value = extractValue(event.target);
        this.props.onChange({ [this.props.name]: value });
    }
    onChangeCheckboxEvent(event: ChangeEvent<{ checked: boolean }>) {
        this.props.onChange({ [this.props.name]: event.target.checked });
    }

    get helperText() {
        if (this.props.helperTextFromObject) {
            try {
                return this.props.helperTextFromObject(this.props.object);
            } catch (ignore) {
                // Ignore
            }
        }
        return this.prop.description;
    }

    get endAdornment() {
        if (this.props.endAdornmentComponentRender) {
            return this.props.endAdornmentComponentRender(this.props);
        }
        return undefined;
    }

    shouldComponentUpdate(
        nextProps: Readonly<SchemaFieldProperties<any>>,
        nextState: Readonly<{}>,
        nextContext: any
    ): boolean {
        const nextValue = nextProps.object[this.props.name];
        const nextProblem = nextProps.problems && nextProps.problems[this.props.name];
        const nextWarning = nextProps.warnings && nextProps.warnings[this.props.name];
        return (
            nextProps.onChange !== this.props.onChange ||
            nextProps.schema !== this.props.schema ||
            nextProps.name !== this.props.name ||
            nextProps.options !== this.props.options ||
            nextProps.dependentOn !== this.props.dependentOn ||
            nextValue !== this.value ||
            nextProblem !== this.problem ||
            nextWarning !== this.warning
        );
    }

    render() {
        if (this.prop.type === SwaggerTypeEnum.Boolean) {
            return this.renderCheckbox();
        } else if (
            this.props.options ||
            typeof this.value === 'object' // When showing a value without specifying options
        ) {
            return this.renderSelectField();
        } else {
            return this.renderSimpleField();
        }
    }

    private renderSimpleField() {
        return (
            <SimpleField
                label={this.label}
                value={this.value}
                name={this.name}
                onChange={this.onChangeEvent}
                problem={this.problem as string}
                warning={this.warning as string}
                type={this.prop.type}
                min={this.prop.minimum}
                max={this.prop.maximum}
                unit={this.prop['x-lcoe-unit']}
                helperText={this.helperText}
                required={this.required}
                disabled={this.props.disabled}
                endAdornment={this.endAdornment}
                sm={this.props.sm || 6}
            />
        );
    }

    private renderSelectField() {
        return (
            <SimpleSelect
                label={this.label as string}
                name={this.name}
                onChange={this.onChangeEvent}
                value={this.value}
                warning={this.warning}
                problem={this.problem}
                options={this.options}
                helperText={this.helperText}
                required={this.required}
                disabled={this.props.disabled}
                sm={this.props.sm || 6}
            />
        );
    }

    private renderCheckbox() {
        return (
            <SimpleCheckbox
                label={this.label}
                name={this.name}
                checked={this.value}
                helperText={this.helperText}
                onChange={this.onChangeCheckboxEvent}
                tiny={this.props.tiny ? this.props.tiny : false}
            />
        );
    }
}
