import { FileUpload, Turbine } from '@lcoe/lcoe-client';
import { LoadTurbineProfile } from 'components/turbine/LoadTurbineProfile';
import { NewTurbine } from 'components/turbine/NewTurbine';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseTurbineFromExcel } from 'utils/turbine/ExcelProfileParser';
import { parseTurbineFromXml } from 'utils/turbine/XMLProfileParser';
import { NavBar } from '../../components/NavBar';

export const NewTurbinePage = (): JSX.Element => {
    const navigate = useNavigate();
    const [turbine, setTurbine] = useState<Partial<Turbine>>({});

    const onLoadProfile = useCallback((file: FileUpload) => {
        let partialTurbine: Partial<Turbine>;
        if (file.filename.match(/\.xml$/)) {
            partialTurbine = parseTurbineFromXml(file.content);
        } else {
            partialTurbine = parseTurbineFromExcel(file.content);
        }
        setTurbine({ ...partialTurbine, airDensityNominal: 1.225 });
    }, []);

    const onSavedTurbine = (savedTurbine: Turbine) => {
        navigate(`/turbines/${savedTurbine.id}`);
    };

    if (!turbine.profile) {
        return (
            <>
                <NavBar />
                <LoadTurbineProfile onLoadProfile={onLoadProfile} />
            </>
        );
    } else {
        return (
            <>
                <NavBar />
                <NewTurbine turbineSuggestion={turbine} onSavedTurbine={onSavedTurbine} />
            </>
        );
    }
};
