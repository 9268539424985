import {
    ADD_CASE_COMPARE,
    AddCaseCompareAction,
    CaseRef,
    CLEAR_CASE_COMPARE,
    ClearCaseCompareAction,
    REMOVE_CASE_COMPARE,
    RemoveCaseCompareAction,
    TOGGLE_CASE_COMPARE,
    ToggleCaseCompareAction
} from './types';

export function addCaseCompare(caseRef: CaseRef): AddCaseCompareAction {
    return {
        type: ADD_CASE_COMPARE,
        caseRef
    };
}

export function removeCaseCompare(caseRef: CaseRef): RemoveCaseCompareAction {
    return {
        type: REMOVE_CASE_COMPARE,
        caseRef
    };
}

export function toggleCaseCompare(caseRef: CaseRef): ToggleCaseCompareAction {
    return {
        type: TOGGLE_CASE_COMPARE,
        caseRef
    };
}

export function clearCaseCompare(): ClearCaseCompareAction {
    return {
        type: CLEAR_CASE_COMPARE
    };
}
