import { InteractionType } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { DotProgress } from '@equinor/eds-core-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import DebugPane from '../components/DebugPane';
import { getApiScope } from '../config/ids';
import { setAccount, setAuthentication } from '../redux/store/user/actions';
import { pca } from '../services/auth/pca';
import styles from './Login.module.css';

interface LoginProps {
    children: ReactNode;
}

const Login = ({ children }: LoginProps): JSX.Element => {
    const requireAuthentication = process.env.NODE_ENV === 'production';
    const store = useStore();

    const accounts = pca.getAllAccounts();
    const [init, setInit] = useState<boolean>(false);
    const [reloadMessage, setReloadMessage] = useState<boolean>(false);

    const authRequest = {
        scopes: [getApiScope()]
    };

    const [authResult, setAuthResult] = useState<AuthenticationResult>();

    useEffect(() => {
        if (!init && accounts && accounts[0]) {
            pca.setActiveAccount(accounts[0]);
            store.dispatch(setAccount(accounts[0]));
            if (!authResult) {
                getToken();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);

    const getToken = async () => {
        const authenticationResult = await pca.acquireTokenSilent({
            scopes: authRequest.scopes
        });
        setAuthResult(authenticationResult);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setReloadMessage(true);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (authResult) {
            store.dispatch(setAuthentication(authResult));
            setInit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [init, authResult]);

    function ErrorComponent(e: unknown) {
        console.log('er', e);
        return (
            <p>
                An Authentication Error Occurred, please{' '}
                <b>
                    <a
                        target={'_blank'}
                        href="https://teams.microsoft.com/l/channel/19%3ac42923a39e694ca595b64219d54ad787%40thread.tacv2/General?groupId=a28ff0ef-7d0f-4e9f-9b2f-cbfab594085f&tenantId=3aa4a235-b6e2-48d5-9195-7fcf05b459b0"
                        rel="noreferrer"
                    >
                        CONTACT_LCOE_TEAM
                    </a>
                </b>{' '}
                or try refreshing the page
            </p>
        );
    }

    function LoadingComponent() {
        return (
            <div className={styles.Auth}>
                <DotProgress size={64} color="primary" />
                {reloadMessage && (
                    <span>
                        If the authentication takes too long, try refreshing the browser or{' '}
                        <a
                            target={'_blank'}
                            href="https://teams.microsoft.com/l/channel/19%3ac42923a39e694ca595b64219d54ad787%40thread.tacv2/General?groupId=a28ff0ef-7d0f-4e9f-9b2f-cbfab594085f&tenantId=3aa4a235-b6e2-48d5-9195-7fcf05b459b0"
                            rel="noreferrer"
                        >
                            CONTACT_LCOE_TEAM
                        </a>
                    </span>
                )}
            </div>
        );
    }

    if (requireAuthentication) {
        return (
            <>
                <MsalProvider instance={pca}>
                    <MsalAuthenticationTemplate
                        interactionType={InteractionType.Redirect}
                        authenticationRequest={authRequest}
                        errorComponent={ErrorComponent}
                        loadingComponent={LoadingComponent}
                    >
                        {store.getState().user && <> {children}</>}
                        {!store.getState().user && <>{LoadingComponent()}</>}
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            </>
        );
    } else {
        // development mode
        return (
            <>
                {children}
                <DebugPane />
            </>
        );
    }
};

export default Login;
