import { Button, Icon } from '@equinor/eds-core-react';
import { Case, Cases } from '@lcoe/lcoe-client';
import { Grid } from '@material-ui/core';
import cx from 'classnames';
import CSS from 'csstype';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Redux from 'redux';
import { ApiContext } from '../components/ApiContext';
import { NavBar } from '../components/NavBar';
import { ListTable } from '../elements/visual/ListTable';
import { PageHeader } from '../elements/visual/PageHeader';
import { ApplicationState } from '../redux/store';
import { clearCaseCompare } from '../redux/store/caseCompare/actions';
import { CaseRef } from '../redux/store/caseCompare/types';
import { setCurrentEPA } from '../Settings/EpaUtils';
import styles from './CaseListPage.module.css';
import { CasePreview } from './CasePreview';
import { SelectVersionDialog } from './SelectVersionDialog';

export const CaseListPage = (): JSX.Element => {
    const api = useContext(ApiContext);
    const [cases, setCases] = useState([] as Case[]);

    useEffect(() => {
        api.getCases({}).then((response: Cases) => {
            setCases(response.cases);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    setCurrentEPA();

    return <CaseList cases={cases}></CaseList>;
};

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
    return {
        clear: () => dispatch(clearCaseCompare())
    };
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        nCases: state.caseCompare.length
    };
};

interface IProps {
    style?: CSS.Properties;
    className?: string;
    cases: Case[];
    nCases?: number;
    clear: () => void;
}

export interface CaseDataRow {
    createdby: string | undefined;
    id: string | undefined;
    name: string;
    country: string;
    updatedTime: string | undefined;
    compare: Case;
    preview: Case;
}

export interface Column {
    field: string;
    hide?: boolean;
    headerName?: string;
    flex?: number;
    headerClassName?: string;
}

const CaseListComponent = (props: IProps): JSX.Element => {
    const nCases = props.nCases ? props.nCases : 0;

    const columns = [
        { field: 'id', hide: true },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            // eslint-disable-next-line
            renderCell: (params: any) => <a href={`/cases/${params.id}`}>{params.value}</a>
        },
        { field: 'country', headerName: 'Country', flex: 0.3 },
        { field: 'createdby', headerName: 'Created by', flex: 0.3 },
        { field: 'updatedTime', headerName: 'Last updated', flex: 0.3 },
        {
            field: 'compare',
            headerName: 'Compare',
            flex: 0.3,
            // eslint-disable-next-line
            renderCell: (params: any) => <SelectVersionDialog caseRef={params.value} />
        },
        {
            field: 'preview',
            headerName: 'Preview',
            flex: 0.25,
            // eslint-disable-next-line
            renderCell: (params: any) => (
                <Button variant="ghost_icon" onClick={() => showPreview(params.value)}>
                    <Icon size={16} name="visibility"></Icon>
                </Button>
            )
        }
    ];

    const clearCompareCases = () => {
        props.clear();
    };

    const datarows: Record<string, unknown>[] = [];
    props.cases.forEach((element) => {
        const time = element.updatedTime?.toISOString().split('T')[0];
        datarows.push({
            createdBy: element.createdBy,
            id: element.id,
            name: element.name,
            country: element.location.country,
            updatedTime: time,
            compare: element,
            preview: element
        });
    });
    const element = (
        <div className={styles.AlignButtons}>
            <Button href="/cases/new">Create new</Button>
            {nCases > 0 && <Button onClick={() => clearCompareCases()}>Clear all cases</Button>}
            {nCases > 0 && (
                <Button className={styles.Compare} href="/cases/compare">
                    <Icon name="shopping_card"></Icon>Compare {props?.nCases} cases
                </Button>
            )}
        </div>
    );

    const [preview, setPreview] = useState(false);
    const [previewCase, setPreviewCase] = useState({} as Case);
    const showPreview = (element: CaseRef) => {
        setPreview(true);
        setPreviewCase(element as Case);
    };
    return (
        <>
            <NavBar />
            <div className={cx(styles.CaseListPage, props.className)} style={props.style}>
                <PageHeader title={'Cases'} right_element={element}></PageHeader>
                {datarows.length > 0 && (
                    <Grid container>
                        <Grid item sm={preview ? 8 : 12}>
                            <ListTable datarows={datarows} columns={columns}></ListTable>
                        </Grid>
                        {preview && (
                            <Grid item sm={4}>
                                <CasePreview caze={previewCase} setPreview={setPreview}></CasePreview>
                            </Grid>
                        )}
                    </Grid>
                )}
            </div>
        </>
    );
};

export const CaseList = connect(mapStateToProps, mapDispatchToProps)(CaseListComponent);
