/* eslint-disable */
import { Field } from './fields';
import { isValueWithUnit } from '../components/utils/valueWithUnit';

export function getFieldsFromHierarchy(hierarchy: any, path: string[], namespace: string[]): Field[] | undefined {
    let fieldArray = [] as Field[];

    if (hierarchy === undefined) {
        return undefined;
    } else if (
        typeof hierarchy === 'number' ||
        typeof hierarchy === 'string' ||
        typeof hierarchy === 'boolean' ||
        hierarchy instanceof Date ||
        isValueWithUnit(hierarchy)
    ) {
        return [
            {
                title: path[path.length - 1],
                hasValue: true,
                path: path,
                namespace: namespace,
                isIncluded: false,
                isActive: false
            }
        ];
    } else if (Array.isArray(hierarchy)) {
        // is an array
        let allChildrenHaveNames = true;
        for (let i = 0; i < hierarchy.length; i++) {
            if (hierarchy[i].name === undefined) {
                allChildrenHaveNames = false;
                break;
            }
        }
        if (!allChildrenHaveNames) {
            // This array is then some sort of value, for example the performance table.
            return [
                {
                    title: path[path.length - 1],
                    hasValue: true,
                    path: path,
                    namespace: namespace,
                    isIncluded: false,
                    isActive: false
                }
            ];
        }

        // All children have names
        for (let i = 0; i < hierarchy.length; i++) {
            const element = hierarchy[i];
            const fieldToConcat = getFieldsFromHierarchy(element, path.concat(element.name), namespace);
            if (fieldToConcat !== undefined) {
                fieldArray = fieldArray.concat(fieldToConcat);
            }
        }
        return fieldArray;
    }

    // Assume hierarchy is an object with fields for each to use.
    let name = path[path.length - 1];
    if (path.includes('hierarchy') && hierarchy.name !== undefined) {
        // We are in hierarchy and want to use name value in field title
        name = hierarchy.name;
        namespace = namespace.concat(hierarchy.name);
    } else {
        namespace = namespace.concat(path[path.length - 1]);
    }
    for (const [key, value] of Object.entries(hierarchy)) {
        if (path.includes('hierarchy') && key === 'name') continue;

        const fieldToConcat = getFieldsFromHierarchy(value, path.concat(key), namespace);
        if (fieldToConcat !== undefined) {
            fieldArray = fieldArray.concat(fieldToConcat);
        }
    }
    return [
        {
            title: name,
            children: fieldArray,
            hasValue: false,
            path: path,
            namespace: namespace,
            isIncluded: false,
            isActive: false
        }
    ];
}
