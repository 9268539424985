import { Location } from '@lcoe/lcoe-client';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { locationValidationModel } from '../../Api/model/SwaggerSpec';
import { Problems, problemsExist } from '../../Api/Problems';
import { ApiContext } from '../../components/ApiContext';
import { BeforeUnload } from '../../components/BeforeUnload';
import { LocationDistanceDrawer } from '../../Location/LocationDistanceDrawer';
import { LocationEditForm } from '../../Location/LocationEditForm';
import { LocationWeatherMetaLoader } from '../../Location/LocationWeatherMetaLoader';
import { Icon } from '@equinor/eds-core-react';
import { NavBar } from '../../components/NavBar';
import styles from './NewLocationPage.module.css';

export const NewLocationPage = (): JSX.Element => {
    const [newLocation, setNewLocation] = useState<Location>({
        position: {},
        startInstallationSeason: new Date('2000-03-01'),
        endInstallationSeason: new Date('2000-11-01'),
        airDensityMean: 1.225, // 1.225 kg/m3 is the STP value
        distanceToConverterPort: 0.1,
        distanceToSubstationPort: 0.1
    } as Location);
    const [problems, setProblems] = useState<Problems>();
    const [hasProblems, setHasProblems] = useState(false);
    const [saving, setSaving] = useState(false);
    const [modified, setModified] = useState(false);
    const [step, setStep] = useState(1);

    const navigate = useNavigate();
    const api = useContext(ApiContext);

    const locationChange = useCallback(
        (diff) => {
            setModified(true);
            setNewLocation({ ...newLocation, ...diff });
        },
        [newLocation]
    );

    // validate
    useEffect(() => {
        const problems = locationValidationModel.validate(newLocation);
        setHasProblems(problemsExist(problems));
        setProblems(problems);
    }, [newLocation]);

    const save = () => {
        if (!hasProblems) {
            setSaving(true);
            api.createLocation(newLocation)
                .then((location) => {
                    navigate(`/locations/${location.id}`);
                })
                .finally(() => {
                    setSaving(false);
                });
        }
    };

    if (step === 1) {
        return <LocationWeatherMetaLoader setStep={setStep} locationChange={locationChange} />;
    } else if (step === 2) {
        return (
            <LocationDistanceDrawer
                setStep={setStep}
                locationPosition={newLocation.position}
                locationChange={locationChange}
            />
        );
    } else {
        return (
            <>
                <NavBar />
                <Grid container spacing={0}>
                    <Grid item sm={12} md={6}>
                        <Card className={styles.card_margin}>
                            <CardContent>
                                <LocationEditForm
                                    location={newLocation}
                                    problems={problems as Problems}
                                    locationChange={locationChange}
                                />
                            </CardContent>
                            <CardActions>
                                <Button component={Link} to="/locations" className={styles.button_right}>
                                    <Icon name={'chevron_left'} /> Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => save()}
                                    disabled={hasProblems || saving}
                                >
                                    Create
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <BeforeUnload when={modified && !saving} />
                </Grid>
            </>
        );
    }
};
