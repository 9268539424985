/* eslint-disable */
import { updateCurrencyUnits } from '../uom/UnitConversion';
import { EPAApi, EpaAssumptions } from '@lcoe/lcoe-client';
import {useContext, useEffect} from "react";
import { ApiContext } from '../components/ApiContext';

export const getSortedEpaStrings = (allEpa: EpaAssumptions[]) => {
    const allEpaStrings = allEpa.map((epa) => convertEpaToEpaString(epa));
    allEpaStrings.sort((a, b) => (a > b ? 1 : -1));
    return allEpaStrings;
};

export const convertEpaToEpaString = (epa: EpaAssumptions) => {
    return epa.year.toString() + 'Q' + epa.quarter.toString();
};

export const convertEpaStringToYearQuarter = (epaString: string) => {
    const splitString = epaString.split('Q');
    return [Number(splitString[0]), Number(splitString[1])];
};

export const updateSupportedCurrencyRates = (epa: EpaAssumptions) => {
    const conversionRates: { [currencyFrom: string]: number } = {};
    epa.rates.forEach((rate) => {
        conversionRates[rate.currencyFrom] = rate.conversionRate;
    });
    const { USD, GBP, EUR, SEK, DKK } = conversionRates;
    updateCurrencyUnits(USD, GBP, EUR, SEK, DKK);
};

export const getLatestEpaString = async (epaApi: EPAApi) => {
    const allEpa = await epaApi.getAllEpaAssumptions().then((allEpa) => allEpa);
    const latestEpa = allEpa.reverse()[0];
    updateSupportedCurrencyRates(latestEpa);
    return convertEpaToEpaString(latestEpa);
};

export const setCurrentEPA = () => {
    const api = useContext(ApiContext);

    useEffect(() => {
        const load = async () => {
            const allEpa = await api.epaApi.getAllEpaAssumptions().then((allEpa) => allEpa);
            const latestEpa = allEpa.reverse()[0];
            updateSupportedCurrencyRates(latestEpa);
        };
        // noinspection JSIgnoredPromiseFromCall
        load();
    }, [api]);

};

