export const SET_MAP_STATE = 'SET_MAP_STATE';

interface OverlayState {
    name: string;
    checked: boolean;
    index: layersEnum;
}

export type MapState = OverlayState[];

export enum layersEnum {
    windfarm = 0,
    wavesMaxHs,
    wavesMeanHs,
    bathymetry,
    avgWind100m
}

export interface SetMapStateAction {
    type: typeof SET_MAP_STATE;
    mapState: MapState;
}
