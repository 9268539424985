/* eslint-disable */
import { UnitConversion, unitSystems } from '../../../uom/UnitConversion';
import { SET_UNIT_SYSTEM, SetUnitSystemAction } from './types';

const LOCAL_STORE_KEY = 'unitsystem';

function loadLocalStore(): UnitConversion {
    const name = window.localStorage.getItem(LOCAL_STORE_KEY);
    if (name) {
        const unitSystem = unitSystems.find((sys) => sys.name === name);
        if (unitSystem) {
            return new UnitConversion(unitSystem);
        }
    }
    return new UnitConversion(unitSystems[0]);
}

function saveLocalStore(unitConversion: UnitConversion) {
    window.localStorage.setItem(LOCAL_STORE_KEY, unitConversion.unitSystem.name);
}

export function unitConversionReducer(unitConversion: UnitConversion = loadLocalStore(), action: SetUnitSystemAction) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case SET_UNIT_SYSTEM:
            saveLocalStore(action.unitConversion);
            return action.unitConversion;
        default:
            return unitConversion;
    }
}
