export const maxHsLabelSpecsColors: Array<string> = [
    'rgb(71, 155, 191, 255)',
    'rgb(94, 163, 184, 255)',
    'rgb(116, 171, 173, 255)',
    'rgb(136, 181, 166, 255)',
    'rgb(155, 191, 156, 255)',
    'rgb(172, 201, 147, 255)',
    'rgb(191, 212, 138, 255)',
    'rgb(206, 222, 129, 255)',
    'rgb(227, 235, 117, 255)',
    'rgb(243, 245, 108, 255)',
    'rgb(250, 240, 95, 255)',
    'rgb(252, 222, 86, 255)',
    'rgb(252, 199, 76, 255)',
    'rgb(252, 179, 68, 255)',
    'rgb(252, 160, 61, 255)',
    'rgb(250, 138, 52, 255)',
    'rgb(247, 119, 45, 255)',
    'rgb(245, 99, 37, 255)',
    'rgb(240, 76, 31, 255)',
    'rgb(237, 54, 26, 255)',
    'rgb(232, 16, 20, 255)'
];
export const maxHsLabelSpecsValues: Array<string> = [
    '0-1m',
    '1-2m',
    '2-3m',
    '3-4m',
    '4-5m',
    '5-6m',
    '6-7m',
    '7-8m',
    '8-9m',
    '9-10m',
    '10-11m',
    '11-12m',
    '12-13m',
    '13-14m',
    '14-15m',
    '15-16m',
    '16-17m',
    '17-18m',
    '18-19m',
    '19-20m',
    '20-21m'
];
