import { CalculationResult, Case, ErrorResponse, WeatherFile } from '@lcoe/lcoe-client';
import { CalcState, CalcStates } from './edit/CalcStates';
import { Problems, problemsExist } from '../Api/Problems';
import { caseValidationModel } from '../Api/model/SwaggerSpec';
import { caseWarnings } from '../Api/model/case';

export interface ResultState {
    caze: Case;
    result?: CalculationResult;
    calcState: CalcState;
    errorResponse?: ErrorResponse;
    calculatingCase?: Case;
    problems?: Problems;
    warnings?: Problems;
}

const validate = (caze: Partial<Case>) => {
    return {
        problems: caseValidationModel.validate(caze as Case),
        warnings: caseWarnings(caze as Case)
    };
};

interface ResultSuccess {
    type: 'success';
    result: CalculationResult;
    caze: Case;
}

interface ResultError {
    type: 'error';
    error: ErrorResponse;
    caze: Case;
}

interface ResultClear {
    type: 'clear';
}

interface UpdateCase {
    type: 'updateCase';
    modification: Partial<Case>;
}

interface SetCase {
    type: 'setCase';
    caze: Case;
}

function hasChangesToCalculate(modification: Partial<Case>) {
    const ignores = ['name', 'comments', 'tags', 'sharedWith'];
    const modKeys = Object.keys(modification);
    return !(modKeys.length === 1 && ignores.includes(modKeys[0]));
}

type ResultAction = ResultSuccess | ResultError | ResultClear | UpdateCase | SetCase;
export const reduceResult = (state: ResultState, action: ResultAction): ResultState => {
    console.log('dispatched action', action);
    switch (action.type) {
        case 'success':
            if (action.caze !== state.calculatingCase && state.calculatingCase) {
                return state;
            }
            return {
                caze: action.caze,
                result: action.result,
                calcState: CalcStates.SUCCESS,
                warnings: caseWarnings(state.caze)
            };
        case 'error':
            if (action.caze !== state.calculatingCase) {
                return state;
            }
            return {
                caze: state.caze,
                calcState: CalcStates.FAILED,
                errorResponse: action.error
            };
        case 'clear':
            return {
                caze: state.caze,
                calcState: CalcStates.NOT_STARTED
            };

        case 'updateCase':
            const updatedCase = {
                ...state.caze,
                ...action.modification
            };

            const isWeatherFileInLocationWeatherFiles: (weatherFile: WeatherFile) => boolean = (
                weatherFile: WeatherFile
            ) => {
                return (
                    updatedCase.location.files !== undefined &&
                    updatedCase.location.files.some((file) => file.fileId === weatherFile.fileId)
                );
            };

            const setBestWeatherFile = () => {
                if (
                    updatedCase.location.defaultWeatherFile &&
                    isWeatherFileInLocationWeatherFiles(updatedCase.location.defaultWeatherFile)
                ) {
                    updatedCase.weatherFile = updatedCase.location.defaultWeatherFile;
                } else if (updatedCase.location.files) {
                    updatedCase.weatherFile = updatedCase.location.files[0];
                }
            };

            const shouldAutoSetWeatherFile: () => boolean = () => {
                if (updatedCase.location) {
                    if (updatedCase.weatherFile) {
                        return !isWeatherFileInLocationWeatherFiles(updatedCase.weatherFile);
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            };

            if (shouldAutoSetWeatherFile()) {
                setBestWeatherFile();
            }

            // Validation
            const validation = validate(updatedCase);
            if (problemsExist(validation.problems)) {
                return {
                    ...state,
                    ...validation,
                    caze: updatedCase,
                    calculatingCase: undefined,
                    calcState: CalcStates.NOT_STARTED,
                    result: undefined
                };
            }

            const updatedState = { ...state, ...validation, caze: updatedCase };

            const hadProblemsInLastState = problemsExist(state.problems);

            if (hadProblemsInLastState || hasChangesToCalculate(action.modification)) {
                updatedState.calculatingCase = updatedCase as Case;
                updatedState.calcState = CalcStates.STARTED;
                updatedState.result = undefined;
            }

            return updatedState;

        case 'setCase':
            const newState = {
                ...state,
                caze: action.caze
            };
            if (action.caze.result) {
                newState.calcState = CalcStates.STARTED;
                // Initiate calculation:
                newState.calculatingCase = action.caze;
            }
            return newState;
    }
    return state;
};
