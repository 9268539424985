/* eslint-disable */
import React, { useState } from 'react';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = (theme: any) => ({
    root: {
        flexGrow: 1,
        height: 250
    },
    container: {
        flexGrow: 1,
        position: 'relative'
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
    },
    inputRoot: {
        flexWrap: 'wrap'
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1
    },
    divider: {
        height: theme.spacing(2)
    }
} as any);

function renderInput({ InputProps, classes, ref, ...other }: any) {
    return (
        <TextField
            InputProps={{
                inputRef: ref,
                classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput
                },
                ...InputProps
            }}
            {...other}
        />
    );
}

const SimpleChipListComponent = ({ label, selectedItems, classes, placeholder, onSelectedItemsChange, helperText }: any) => {
    const [inputValue, setInputValue] = useState('');

    const setSelectedItems = (items: any) => {
        !!onSelectedItemsChange && onSelectedItemsChange(items);
    };

    const handleKeyDown = (event: any) => {
        if (selectedItems.length && !inputValue.length && event.key === 'Backspace') {
            setSelectedItems(selectedItems.slice(0, selectedItems.length - 1));
        } else if ([' ', ','].includes(event.key)) {
            event.preventDefault();
            if (!!inputValue) {
                handleChange(inputValue);
            }
        } else if (['Tab', 'Enter'].includes(event.key) && !!inputValue) {
            event.preventDefault();
            handleChange(inputValue);
        } else if ('Escape' === event.key) {
            setInputValue('');
        }
    };

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const handleChange = (item: any) => {
        if (!item) {
            return;
        }
        if (selectedItems.indexOf(item) === -1) {
            setSelectedItems([...selectedItems, item]);
        }
        setInputValue('');
    };

    const handleDelete = (item: any) => () => {
        const newValue = [...selectedItems];
        newValue.splice(selectedItems.indexOf(item), 1);
        setSelectedItems(newValue);
    };

    const handleBlur = () => {
        if (!!inputValue) {
            handleChange(inputValue);
        }
    };

    return (
        <Grid item xs={12}>
            <Downshift inputValue={inputValue} selectedItem={selectedItems} onChange={handleChange}>
                {({ getInputProps, selectedItem }) => (
                    <div className={classes.container}>
                        {renderInput({
                            fullWidth: true,
                            classes,
                            InputProps: getInputProps({
                                startAdornment: selectedItem.map((item: any) => (
                                    <Chip
                                        key={item}
                                        tabIndex={-1}
                                        label={item}
                                        color={item === 'all' ? 'primary' : 'default'}
                                        className={classes.chip}
                                        onDelete={handleDelete(item)}
                                    />
                                )),
                                onChange: handleInputChange,
                                onKeyDown: handleKeyDown,
                                onBlur: handleBlur,
                                placeholder
                            }),
                            InputLabelProps: {
                                shrink: !!inputValue || (!!selectedItem && selectedItem.length !== 0)
                            },
                            helperText,
                            label: label
                        })}
                    </div>
                )}
            </Downshift>
        </Grid>
    );
};
export const SimpleChipList = withStyles(styles)(SimpleChipListComponent);
