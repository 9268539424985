/* eslint-disable */
import { Case, CompensationType, Turbine } from '@lcoe/lcoe-client';
import { Problems } from '../Problems';
import {collectionGridOptions} from "../grid-options";

export function emptyCase(latestEpa: string): Partial<Case> {
    return {
        discountRatePercent: 8,
        otherLossesPercent: 0,
        infrastructureSelection: {},
        substationOnshore: true,
        substationOffshore: true,
        reactiveCompensation: CompensationType.AndOffshore,
        frequency: 50,
        epaAssumptions: latestEpa,
        collectionGrid: collectionGridOptions[1]
    };
}

export function calculateSpacingM({ wtg, wtgSpacingNd }: { wtg?: Turbine; wtgSpacingNd?: number }) {
    if (wtg && wtgSpacingNd && wtg.rotorDiameter) {
        return `${Math.round(wtg.rotorDiameter * wtgSpacingNd)} m`;
    }
    return null;
}

const emptyObject = {};
Object.freeze(emptyObject);

export function caseWarnings(caze: Case) {
    const warnings: Problems = {};

    // O&M concept
    const strategy = caze.onmStrategy as any;
    if (caze.location && strategy && caze.location.distanceToOandMPort > strategy.maxDistanceToOnmPort) {
        warnings.onmStrategy = ` 
        Distance to O&M port in ${caze.location.name} is ${caze.location.distanceToOandMPort}. 
        ${strategy.name} is valid for distance up to ${strategy.maxDistanceToOnmPort}km.
        Results may be misleading.
        `;
    }
    if (caze.wtg && caze.fid && caze.wtg.dateAvailable && caze.fid < caze.wtg.dateAvailable.getFullYear()) {
        warnings.wtg = `FID is ${
            caze.fid
        }, but this turbine is not available until ${caze.wtg.dateAvailable.toLocaleDateString()}`;
    }

    if (caze.location && caze.location.averageDepth && caze.foundation) {
        const foundation = caze.foundation as any;
        const location = caze.location;
        if (location.averageDepth < foundation.minDepth || location.averageDepth > foundation.maxDepth) {
            warnings.foundation = foundation.depthWarning;
        }
    }

    if (Object.keys(warnings).length === 0) {
        return emptyObject;
    }
    return warnings;
}
