import { Turbine } from '@lcoe/lcoe-client';
import * as React from 'react';
import styles from './TurbineList.module.css';
import CSS from 'csstype';
import cx from 'classnames';
import { ListTable } from '../elements/visual/ListTable';
import { PageHeader } from '../elements/visual/PageHeader';
import { Button } from '@equinor/eds-core-react';

const columns = [
    { field: 'id', hide: true },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        // eslint-disable-next-line
        renderCell: (params: any) => (
            <a href={`/turbines/${params.id}`}>
                {params.value}
            </a>
        )
    },
    { field: 'capacity', headerName: 'Capacity', flex: 0.5 },
    { field: 'rotordiameter', headerName: 'Rotor diameter', flex: 0.5 },
    { field: 'topheadmass', headerName: 'Top head mass', flex: 0.5 },
    { field: 'towermass', headerName: 'Tower mass', flex: 0.5 },
    { field: 'createdby', headerName: 'Created by', flex: 0.5 },
    { field: 'updatedTime', headerName: 'Last updated', flex: 0.5 }
];

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    turbines: Turbine[];
}
export interface TurbineDataRow {
    createdby: string | undefined;
    id: string | undefined;
    name: string;
    capacity: number;
    rotordiameter: number;
    topheadmass: number;
    towermass: number;
    updatedTime: string | undefined;
}
export const TurbineList = (props: IProps): JSX.Element => {
    const datarows = [] as TurbineDataRow[];
    props.turbines.forEach((element) => {
        datarows.push({
            createdby: element.createdBy,
            id: element.id,
            name: element.name,
            capacity: element.capacity,
            rotordiameter: element.rotorDiameter,
            topheadmass: element.topHeadMass,
            towermass: element.towerMass,
            updatedTime: element.updatedTime?.toISOString().split('T')[0]
        });
    });

    const element = (
        <div>
            <Button href="/turbines/new">Create new</Button>
        </div>
    );

    return (
        <div className={cx(styles.TurbineList, props.className)} style={props.style}>
            <PageHeader title={'Turbines'} right_element={element}></PageHeader>
            {datarows.length > 0 && <ListTable datarows={datarows} columns={columns}></ListTable>}
        </div>
    );
};
