import { Cables } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import LoadingDialog from 'components/LoadingDialog';
import React, { useContext, useEffect, useState } from 'react';
import styles from './CableListPage.module.css';
import { CableList } from '../../Cable/CableList';
import { NavBarMasterData } from '../../components/NavBarMasterData';

export const CableListPage = (): JSX.Element => {
    const [cables, setCables] = useState({} as Cables);

    const api = useContext(ApiContext);
    useEffect(() => {
        api.getCables().then((response: Cables) => {
            setCables(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    return (
        <>
            <NavBarMasterData />
            {!cables && <LoadingDialog />}
            {cables && cables.cables && cables.cables.length > 0 && (
                <div className={styles.CableListPage}>
                    <CableList cables={cables}></CableList>
                </div>
            )}
        </>
    );
};
