/* eslint-disable */
import { useContext, useMemo, useState } from 'react';
import { FileResponse } from '@lcoe/lcoe-client';
import { ApiContext } from '../../../components/ApiContext';

export const useWeatherFile = (object: any) => {
    const [weatherFile, setWeatherFile] = useState((undefined as unknown) as FileResponse);
    const api = useContext(ApiContext);

    useMemo(() => {
        if (!!object.location && !!object.weatherFile) {
            api.downloadWeatherFile(object.location.id, object.weatherFile.fileId).then((downloadedWeatherFile) => {
                setWeatherFile(downloadedWeatherFile);
            });
        }
    }, [object.location, object.weatherFile, api]);

    return { weatherFile };
};
