import Grid from '@material-ui/core/Grid';
import LoadingDialog from '../components/LoadingDialog';
import Card from '@material-ui/core/Card';
import { CardActions, CardHeader } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import styles from './LocationWizardLayout.module.css';

interface LocationWizardLayoutProps {
    isLoading?: boolean;
    title: string;
    content: JSX.Element;
    buttons: JSX.Element;
    displayMap: JSX.Element;
}

export const LocationWizardLayout = ({
    isLoading = false,
    title,
    content,
    buttons,
    displayMap
}: LocationWizardLayoutProps): JSX.Element => {
    return (
        <Grid container direction="column" alignItems="center">
            <LoadingDialog open={isLoading} />

            <Grid item xs={12}>
                <Card className={styles.card}>
                    <CardHeader title={title} />
                    <CardContent>{content}</CardContent>
                    <CardActions style={{ justifyContent: 'center' }}>{buttons}</CardActions>
                </Card>
            </Grid>

            <Grid item xs={12}>
                {displayMap}
            </Grid>
        </Grid>
    );
};
