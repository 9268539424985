import {
    AdminApi,
    Cables,
    CalcApi,
    Case,
    CaseApi,
    CaseReference,
    Cases,
    CaseVersions,
    ConfigApi,
    ConfigElectrical,
    Configuration,
    EPAApi,
    ExperienceApi,
    FileResponse,
    FileType,
    ListCasesRequest,
    ListLocationsRequest,
    Location,
    LocationApi,
    Position,
    StringResponse,
    Turbine,
    TurbineApi,
    Turbines,
    WeatherMeta,
    WeatherSourceEnum,
    WeatherSources
} from '@lcoe/lcoe-client';
import { BearerTokenMiddleware, ErrorCallback, ErrorPropagationMiddleware } from './openapi/middleware';
import { infrastructurePresets, InfrastructureSelectionTemplate } from './infrastructure-presets';
import { CollectionGrid, collectionGridOptions, ExportGrid, exportGridOptions } from './grid-options';
import { Foundation, foundationOptions } from './foundation-options';
import { OnmStrategy, onmStrategyOptions } from './onm-strategy-options';
import { CaseRef } from '../redux/store/caseCompare/types';
import { ExperienceMonopiles, Locations } from '@lcoe/lcoe-client/src/models';
import { SaveElectricalConfigRequest } from '@lcoe/lcoe-client/src/apis/ConfigApi';

/**
 * Annotate methods on Api in order for connection errors to be logged to the provided logCallback.
 */
function logErrors() {
    return function (target: Api, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function () {
            const context: Api = this as Api;
            // eslint-disable-next-line
            const args = arguments;

            try {
                const r = originalMethod.apply(context, args);
                if (r instanceof Promise) {
                    return r.catch((reason) => {
                        if (reason && reason.message === 'Failed to fetch') {
                            console.log('Calling callback', context);
                            context.logCallback &&
                                context.logCallback({
                                    errorResponse: {
                                        detail: 'Failed to connect to backend',
                                        title: 'Connection error',
                                        status: 500
                                    },
                                    details: {
                                        method: propertyKey,
                                        arguments: args
                                    }
                                });
                        }
                        // Send this error further - we are just logging it
                        return Promise.reject(reason);
                    });
                }

                return r;
            } catch (e) {
                console.log('Caught exception', e);
            }
        };
    };
}

interface allOptions {
    locationOptions: Location[];
    wtgOptions: Turbine[];
    foundationOptions: Foundation[];
    collectionGridOptions: CollectionGrid[];
    exportGridOptions: ExportGrid[];
    onmStrategyOptions: OnmStrategy[];
    infrastructureOptions: InfrastructureSelectionTemplate[];
}

export class Api {
    set token(value: string) {
        this.bearerToken = value;
    }

    bearerToken = 'abc';
    adminApi: AdminApi;
    caseApi: CaseApi;
    configApi: ConfigApi;
    locationApi: LocationApi;
    turbineApi: TurbineApi;
    calcApi: CalcApi;
    epaApi: EPAApi;
    experienceApi: ExperienceApi;

    constructor(apiRoot: string, public logCallback?: ErrorCallback) {
        const config = new Configuration({
            basePath: apiRoot,
            middleware: [new BearerTokenMiddleware(() => this.bearerToken)]
        });
        if (logCallback) {
            config.middleware.push(new ErrorPropagationMiddleware(logCallback));
        }
        this.adminApi = new AdminApi(config);
        this.caseApi = new CaseApi(config);
        this.locationApi = new LocationApi(config);
        this.turbineApi = new TurbineApi(config);
        this.calcApi = new CalcApi(config);
        this.configApi = new ConfigApi(config);
        this.epaApi = new EPAApi(config);
        this.experienceApi = new ExperienceApi(config);
    }

    async getAllOptions(): Promise<allOptions> {
        const locations = this.getLocations();
        const turbines = this.getTurbines();
        const foundationOptions = Api.getFoundationOptions();
        const collectionGridOptions = Api.getCollectionGridOptions();
        const exportGridOptions = Api.getExportGridOptions();
        const onmStragegyOptions = Api.getOnmStrategyOptions();
        const infrastructureOptions = Api.getInfrastructureOptions();
        return {
            locationOptions: (await locations).locations,
            wtgOptions: (await turbines).turbines,
            foundationOptions: await foundationOptions,
            collectionGridOptions: await collectionGridOptions,
            exportGridOptions: await exportGridOptions,
            onmStrategyOptions: await onmStragegyOptions,
            infrastructureOptions: await infrastructureOptions
        };
    }

    @logErrors()
    getLocations(params: ListLocationsRequest = {}): Promise<Locations> {
        return this.locationApi.listLocations(params);
    }

    @logErrors()
    getLocation(locationId: string): Promise<Location> {
        return this.locationApi.getLocation({ locationId });
    }

    @logErrors()
    updateLocation(locationId: string, location: Location): Promise<Location> {
        return this.locationApi.updateLocation({ locationId, location });
    }

    @logErrors()
    deleteLocation(locationId: string): Promise<void> {
        return this.locationApi.deleteLocation({ locationId });
    }

    @logErrors()
    fetchValidSystems(locationId: string): Promise<WeatherSources> {
        return this.locationApi.fetchValidSystems({ locationId });
    }

    deleteCase(caseId: string): Promise<void> {
        return this.caseApi.deleteCase({ caseId });
    }

    @logErrors()
    createLocation(location: Location): Promise<Location> {
        return this.locationApi.createLocation({ location });
    }

    @logErrors()
    fetchWeather(
        locationId: string,
        source: WeatherSourceEnum,
        atmosSystem: string,
        atmosSystemId: number,
        position: Position
    ): Promise<void> {
        // Ensure 20 full years
        const currTime = new Date();
        let endYear = currTime.getFullYear() - 1;
        if (currTime.getMonth() < 2) {
            endYear--;
        }
        const startYear = endYear - 19;
        return this.locationApi.fetchWeatherFile({
            locationId: locationId,
            weatherRequest: {
                source: source,
                atmosSystem: atmosSystem,
                atmosSystemId: atmosSystemId,
                position: position,
                period: {
                    start: new Date(startYear.toString() + '-01-01'),
                    end: new Date(endYear.toString() + '-12-31')
                }
            }
        });
    }

    @logErrors()
    downloadWeatherFile(locationId: string, fileId: string): Promise<FileResponse> {
        return this.locationApi.getWeatherFile({ locationId, fileId });
    }

    @logErrors()
    saveWeatherFile(locationId: string, fileId: string): Promise<StringResponse> {
        return this.locationApi.downloadWeatherFile({ locationId, fileId });
    }

    @logErrors()
    uploadWeatherFile(locationId: string, filename: string, content: string, type: FileType): Promise<void> {
        return this.locationApi.uploadWeatherFile({ locationId, fileUpload: { filename, content, type } });
    }

    @logErrors()
    deleteWeatherFile(locationId: string, fileId: string): Promise<void> {
        return this.locationApi.deleteWeatherFile({ locationId, fileId });
    }

    @logErrors()
    getTurbines(): Promise<Turbines> {
        return this.turbineApi.listTurbines();
    }

    @logErrors()
    getTurbine(turbineId: string): Promise<Turbine> {
        return this.turbineApi.getTurbine({ turbineId });
    }

    @logErrors()
    createTurbine(turbine: Turbine): Promise<Turbine> {
        return this.turbineApi.createTurbine({ turbine });
    }

    @logErrors()
    updateTurbine(turbineId: string, turbine: Turbine): Promise<Turbine> {
        return this.turbineApi.updateTurbine({ turbineId, turbine });
    }

    @logErrors()
    deleteTurbine(turbineId: string): Promise<void> {
        return this.turbineApi.deleteTurbine({ turbineId });
    }

    @logErrors()
    getCases(request: ListCasesRequest): Promise<Cases> {
        return this.caseApi.listCases(request);
    }

    @logErrors()
    getCompareCases(request: CaseReference[]): Promise<Cases> {
        return this.caseApi.getCompareCases({ caseReference: request });
    }

    @logErrors()
    getCase({ id, version }: CaseRef): Promise<Case> {
        return this.caseApi.getCase({ caseId: id, version });
    }

    @logErrors()
    getCaseVersions(caseId: string): Promise<CaseVersions> {
        return this.caseApi.getCaseVersions({ caseId });
    }

    private static async getFoundationOptions() {
        return foundationOptions;
    }

    private static async getCollectionGridOptions() {
        return collectionGridOptions;
    }

    private static async getExportGridOptions() {
        return exportGridOptions;
    }

    private static async getOnmStrategyOptions() {
        return onmStrategyOptions;
    }

    private static async getInfrastructureOptions() {
        return infrastructurePresets;
    }

    @logErrors()
    calculate(caze: Case): Promise<Case> {
        return this.calcApi.calculateCase({ apiCalcRequest: { _case: caze } });
    }

    @logErrors()
    saveCase(caze: Case): Promise<Case> {
        return this.caseApi.createCase({ _case: caze });
    }

    @logErrors()
    updateCase(caseId: string, caze: Case): Promise<Case> {
        return this.caseApi.updateCase({ caseId, _case: caze });
    }

    @logErrors()
    resetEverything(): Promise<void> {
        return this.adminApi.resetApplication();
    }

    @logErrors()
    getClosestWeatherGridPosition(position: Position): Promise<Position> {
        return this.locationApi.getWeatherGridPosition({ position });
    }

    @logErrors()
    getClosestWeatherMeta(position: Position): Promise<WeatherMeta> {
        return this.locationApi.getWeatherMeta({ position });
    }

    @logErrors()
    getCables(): Promise<Cables> {
        return this.configApi.returnLatestCables();
    }

    @logErrors()
    saveCables(cables: Cables): Promise<Cables> {
        return this.configApi.saveCables({ cables });
    }

    @logErrors()
    getConfigElectrical(): Promise<ConfigElectrical> {
        return this.configApi.returnElectricalConfig();
    }

    @logErrors()
    saveConfigElectrical(elConf: SaveElectricalConfigRequest): Promise<ConfigElectrical> {
        return this.configApi.saveElectricalConfig(elConf);
    }

    @logErrors()
    getExperienceMonopiles(): Promise<ExperienceMonopiles> {
        return this.experienceApi.getMonopiles();
    }

    @logErrors()
    saveExperienceMonopiles(monopiles: ExperienceMonopiles): Promise<ExperienceMonopiles> {
        return this.experienceApi.saveMonopiles({ experienceMonopiles: monopiles });
    }

    @logErrors()
    importMonopiles(monopiles: ExperienceMonopiles): Promise<ExperienceMonopiles> {
        return this.experienceApi.importMonopiles({ experienceMonopiles: monopiles });
    }
}
