import { ConfigElectrical } from '@lcoe/lcoe-client';
import React, { useContext, useState } from 'react';
import cx from 'classnames';
import styles from './ElectricalConfig.module.css';
import { PageHeader } from '../elements/visual/PageHeader';
import CSS from 'csstype';
import { Button, Dialog } from '@equinor/eds-core-react';
import { ApiContext } from 'components/ApiContext';
import { isUserCableAdmin } from 'utils/permission/permissionUtil';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../redux/store';
import { DialogOnSave } from '../components/tableeditor/DialogOnSave';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    electricalConfig: ConfigElectrical;
}

export const ElectricalConfig = (props: IProps): JSX.Element => {
    const api = useContext(ApiContext);
    const user = useSelector((state: ApplicationState) => state.user);
    const isCableAdmin = isUserCableAdmin(user);
    const [savedData, setSavedData] = useState(false);

    const datarows = [
        {
            name: 'Converter Loss Rated Power (%)',
            type: 'decimal',
            value: String(props.electricalConfig.converterLossRatedPower),
            description: ''
        },
        {
            name: 'Converter No Load Loss (%)',
            type: 'decimal',
            value: String(props.electricalConfig.converterNoLoadLoss),
            description: ''
        },
        {
            name: 'cosPhi',
            type: 'integer',
            value: String(props.electricalConfig.cosPhi),
            description:
                'Factor to account for reactive current flow in the collection grid. The factor should describe the average power factor for the wind turbine. For wind turbines with full reactive control, the value should be 1.0'
        },
        {
            name: 'Derating Factor',
            type: 'decimal',
            value: String(props.electricalConfig.deratingFactor),
            description: 'Factor to account for that the current rating of the cables are reduced due to the j-tubes.'
        },
        {
            name: 'Max Cross Sections',
            type: 'integer',
            value: String(props.electricalConfig.maxCrossSections),
            description: ''
        },
        {
            name: 'Max Power Per String (MW)',
            type: 'integer',
            value: String(props.electricalConfig.maxPowerPerString),
            description: ''
        },
        {
            name: 'Max Power Per Substation (MW)',
            type: 'integer',
            value: String(props.electricalConfig.maxPowerPerSubstation),
            description: ''
        },
        {
            name: 'Max Strings Per Substation',
            type: 'integer',
            value: String(props.electricalConfig.maxStringsPerSubstation),
            description: ''
        },
        {
            name: 'Max Turbines Per String',
            type: 'integer',
            value: String(props.electricalConfig.maxTurbinesPerString),
            description: ''
        },
        {
            name: 'Min Export Cables',
            type: 'integer',
            value: String(props.electricalConfig.minExportCables),
            description: ''
        },
        {
            name: 'Num Substations Onshore',
            type: 'integer',
            value: String(props.electricalConfig.numSubstationsOnshore),
            description: ''
        },
        {
            name: 'Oversize Factor',
            type: 'integer',
            value: String(props.electricalConfig.oversizeFactor),
            description: ''
        },
        {
            name: 'Topside Connection Length (m)',
            type: 'integer',
            value: String(props.electricalConfig.topsideConnectionLength),
            description: ''
        },
        {
            name: 'Transformer Loss Rated Power (%)',
            type: 'decimal',
            value: String(props.electricalConfig.transformerLossRatedPower),
            description: ''
        },
        {
            name: 'Transformer No Load Loss (%)',
            type: 'decimal',
            value: String(props.electricalConfig.transformerNoLoadLoss),
            description: ''
        }
    ];

    const elementChange = (value: string, rowIndex: number, rowType: string) => {
        if (rowType === 'integer' && !(!isNaN(Number(value)) && Number(value) % 1 === 0)) {
            setVisibleNumberCheckText('Value should be a whole number');
            setVisibleNumberCheck(true);
        }

        if (rowType === 'decimal' && isNaN(Number(value))) {
            setVisibleNumberCheckText('Value should be a decimal');
            setVisibleNumberCheck(true);
        }

        datarows[rowIndex].value = value;
    };

    const saveDataset = () => {
        const elConfig = {} as ConfigElectrical;

        elConfig.converterLossRatedPower = Number(datarows[0].value);
        elConfig.converterNoLoadLoss = Number(datarows[1].value);
        elConfig.cosPhi = Number(datarows[2].value);
        elConfig.deratingFactor = Number(datarows[3].value);
        elConfig.maxCrossSections = Number(datarows[4].value);
        elConfig.maxPowerPerString = Number(datarows[5].value);
        elConfig.maxPowerPerSubstation = Number(datarows[6].value);
        elConfig.maxStringsPerSubstation = Number(datarows[7].value);
        elConfig.maxTurbinesPerString = Number(datarows[8].value);
        elConfig.minExportCables = Number(datarows[9].value);
        elConfig.numSubstationsOnshore = Number(datarows[10].value);
        elConfig.oversizeFactor = Number(datarows[11].value);
        elConfig.topsideConnectionLength = Number(datarows[12].value);
        elConfig.transformerLossRatedPower = Number(datarows[13].value);
        elConfig.transformerNoLoadLoss = Number(datarows[14].value);

        elConfig.configVersion = 1;

        const promise = api.saveConfigElectrical({ configElectrical: elConfig });

        promise.then((savedConfig) => {
            if (savedConfig) {
                setSavedData(true);
            }
        });
    };

    const element = isCableAdmin ? <Button onClick={() => saveDataset()}>Save</Button> : <></>;
    const [visibleNumberCheck, setVisibleNumberCheck] = useState(false);
    const [visibleNumberCheckText, setVisibleNumberCheckText] = useState('Input value not valid');

    return (
        <div className={cx(styles.ElectricalConfig, props.className)} style={props.style}>
            <DialogOnSave
                dialog_text={'Electrical config data has been saved.'}
                savedOpen={savedData}
                setSaved={setSavedData}
            ></DialogOnSave>
            <Dialog open={visibleNumberCheck} isDismissable={false}>
                <Dialog.CustomContent>
                    <div style={{ textAlign: 'center' }}>
                        {visibleNumberCheckText}
                        <br />
                        <br />
                        <Button onClick={() => setVisibleNumberCheck(false)}>OK</Button>
                    </div>
                </Dialog.CustomContent>
            </Dialog>

            <PageHeader title={'Electrical Configuration'} right_element={element}></PageHeader>
            <div className={styles.content}>
                <div className={styles.info}>
                    Variables used in calculations of electrical cables and infrastructure.
                </div>
                {props.electricalConfig && (
                    <table>
                        <tbody>
                            {datarows.map((element, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td className={styles.table_title_cell}>{element.name}</td>
                                    <td
                                        className={styles.table_edit_cell}
                                        contentEditable={isCableAdmin}
                                        onInputCapture={(e) => {
                                            elementChange(e.currentTarget.innerHTML, rowIndex, element.type);
                                        }}
                                    >
                                        {element.value}
                                    </td>
                                    <td className={styles.table_description_cell}>{element.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};
