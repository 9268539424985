/* eslint-disable */
import { EpaAssumptions } from '@lcoe/lcoe-client';
import { CurrencyRate } from '@lcoe/lcoe-client/dist/models/CurrencyRate';
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ApiContext } from '../components/ApiContext';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { EpaAssumptionsSelect } from './EpaAssumptionsSelect';
import { convertEpaStringToYearQuarter, convertEpaToEpaString, getSortedEpaStrings } from './EpaUtils';

const currentSupportedCurrencies = [
    { currencyFrom: 'DKK', currencyTo: 'NOK', conversionRate: 0.0 } as CurrencyRate,
    { currencyFrom: 'EUR', currencyTo: 'NOK', conversionRate: 0.0 } as CurrencyRate,
    { currencyFrom: 'GBP', currencyTo: 'NOK', conversionRate: 0.0 } as CurrencyRate,
    { currencyFrom: 'SEK', currencyTo: 'NOK', conversionRate: 0.0 } as CurrencyRate,
    { currencyFrom: 'USD', currencyTo: 'NOK', conversionRate: 0.0 } as CurrencyRate
];

interface CurrencyEditProps {
    open: boolean;
    close: () => void;
}

export const CurrencyEdit = ({ open, close }: CurrencyEditProps) => {
    const defaultSelection = localStorage.getItem('epaAssumptions') as string;
    const [selected, setSelected] = useState(defaultSelection);
    const [options, setOptions] = useState<string[]>([]);

    const [epaAssumptions, setEpaAssumptions] = useState<EpaAssumptions>();
    const [overwriteRates, setOverwriteRates] = useState<CurrencyRate[]>(currentSupportedCurrencies);

    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const newEpaString = currentYear.toString() + 'Q' + currentQuarter.toString();
    const [confirmTitle, setConfirmTitle] = useState('');
    const [confirmText, setConfirmText] = useState('');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [onConfirm, setOnConfirm] = useState<() => void>(() => {});

    const api = useContext(ApiContext);

    useEffect(() => {
        // initialize selection
        if (options.length === 0) {
            api.epaApi
                .getAllEpaAssumptions()
                .then((allEpa) => {
                    const sortedEpaStrings = getSortedEpaStrings(allEpa);
                    setOptions(sortedEpaStrings);
                })
                .catch((error) => console.log(error));
        }
    });

    useEffect(() => {
        const [year, quarter] = convertEpaStringToYearQuarter(selected);
        if (options?.includes(selected)) {
            api.epaApi
                .getEpaAssumptions({ year, quarter })
                .then((epaAssumptions) => {
                    setEpaAssumptions(epaAssumptions);
                    setOverwriteRates(epaAssumptions.rates);
                })
                .catch((error) => console.log(error));
        }
    }, [selected, options, api.epaApi]);

    const handleCreate = () => {
        api.epaApi
            .createEpaAssumptions({
                epaAssumptionsRequest: { year: currentYear, quarter: currentQuarter, rates: overwriteRates }
            })
            .then((epaAssumptions) => {
                setEpaAssumptions(epaAssumptions);

                const newEpaString = convertEpaToEpaString(epaAssumptions);
                setSelected(newEpaString);

                options.push(newEpaString);
                setOptions(options);
                setOpenConfirm(false);
            })
            .catch((error) => console.log(error));
    };

    const handleUpdate = () => {
        const [year, quarter] = convertEpaStringToYearQuarter(selected);
        api.epaApi
            .updateEpaAssumptions({
                epaAssumptionsRequest: { year, quarter, rates: overwriteRates }
            })
            .then((epaAssumptions) => setEpaAssumptions(epaAssumptions))
            .catch((error) => console.log(error));
    };

    const handleCurrencyOverwrite = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const rateToUpdate = overwriteRates.filter((rate) => rate.currencyFrom === event.target.name).pop();
        if (rateToUpdate) {
            rateToUpdate.conversionRate = Number(event.target.value);
            const listWithoutUpdated = overwriteRates.filter((rate) => rate.currencyFrom !== event.target.name);
            listWithoutUpdated.push(rateToUpdate);
            listWithoutUpdated.sort((a, b) => (a.currencyFrom < b.currencyFrom ? -1 : 1));
            setOverwriteRates(listWithoutUpdated);
        }
    };

    const confirmCreate = () => {
        setOpenConfirm(true);
        setConfirmTitle('Confirm create');
        setConfirmText('Creating new EPA assumptions will auto generate new case versions using new rates.');
        setOnConfirm(() => handleCreate);
    };

    const confirmUpdate = () => {
        setOpenConfirm(true);
        setConfirmTitle('Confirm update');
        setConfirmText('Updating EPA assumptions will affect all cases without generating new versions.');
        setOnConfirm(() => handleUpdate);
    };

    return (
        <Dialog open={open}>
            <DialogContent>
                <Grid container direction="column" spacing={1}>
                    <Grid item xs={8}>
                        <EpaAssumptionsSelect
                            options={options}
                            selected={selected}
                            onSelect={(e) => setSelected(e.target.value as string)}
                        />
                    </Grid>
                    {epaAssumptions?.versionMeta && (
                        <Grid item xs={12}>
                            <div>Created {epaAssumptions?.versionMeta.createdTime.toISOString().split('T')[0]} by {epaAssumptions?.versionMeta.createdBy}</div>
                        </Grid>
                    )}
                    {overwriteRates?.map((rate) => (
                        <Grid item xs={12} key={rate.currencyFrom}>
                            <TextField
                                variant="outlined"
                                type="number"
                                name={rate.currencyFrom}
                                label={rate.currencyFrom}
                                onChange={handleCurrencyOverwrite}
                                value={rate.conversionRate.toString()}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField value={newEpaString} label="Create for:" InputProps={{ readOnly: true }} />
                    </Grid>
                </Grid>
                <ConfirmationDialog
                    title={confirmTitle}
                    text={confirmText}
                    open={openConfirm}
                    onCancel={() => setOpenConfirm(false)}
                    onConfirm={onConfirm}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={confirmCreate} variant="contained" color="primary">
                    Create
                </Button>
                <Button onClick={confirmUpdate} variant="contained" color="primary">
                    Update
                </Button>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};
