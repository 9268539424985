import { Turbine } from '@lcoe/lcoe-client';
import { turbineValidationModel } from 'Api/model/SwaggerSpec';
import { Problems } from 'Api/Problems';
import { ApiContext } from 'components/ApiContext';
import { BeforeUnload } from 'components/BeforeUnload';
import { TurbineEditForm } from 'components/turbine/TurbineEditForm';
import { TurbineProfileView } from 'components/turbine/TurbineProfileView';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Icon, Typography, Button, Dialog } from '@equinor/eds-core-react';
import styles from './NewTurbine.module.css';

interface IProps {
    turbineSuggestion: Partial<Turbine>;
    onSavedTurbine: (savedTurbine: Turbine) => void;
}

export const NewTurbine = (props: IProps): JSX.Element => {
    const [turbine, setTurbine] = useState(props.turbineSuggestion);
    const [problems, setProblems] = useState<Problems>({});
    const [validationActive, setValidationActive] = useState(false);
    const [visibleWarning, setVisibleWarning] = useState(false);
    const [modified, setModified] = useState(true);
    const [saving, setSaving] = useState(false);

    const api = useContext(ApiContext);

    const validate = useCallback(() => {
        const problems: Problems = turbineValidationModel.validate(turbine as Turbine);
        setProblems(problems);
        setValidationActive(true);
        return Object.keys(problems).length === 0;
    }, [turbine]);

    const turbineChange = useCallback(
        (diff: Partial<Turbine>) => {
            const newTurbine: Partial<Turbine> = { ...turbine, ...diff };
            setTurbine(newTurbine);
            setModified(true);
        },
        [turbine, setTurbine]
    );

    useEffect(() => {
        if (validationActive) {
            validate();
        }
    }, [turbine, validationActive, validate]);

    const save = useCallback(async () => {
        const ok = validate();
        if (ok) {
            setSaving(true);
            api.createTurbine(turbine as Turbine)
                .then((responseTurbine: Turbine) => {
                    setSaving(false);
                    setModified(false);
                    props.onSavedTurbine(responseTurbine);
                })
                .catch(() => setSaving(false));
        } else {
            setVisibleWarning(true);
        }
    }, [turbine, api, validate, props]);

    return (
        <>
            <Dialog open={visibleWarning} isDismissable={false} style={{ width: '300px' }}>
                <Dialog.CustomContent>
                    <div style={{ textAlign: 'center' }}>
                        <Icon size={48} name={'warning_outlined'}></Icon>
                        <br />
                        Please complete the required values marked in red.
                        <br />
                        <br />
                        <Button onClick={() => setVisibleWarning(false)}>OK</Button>
                    </div>
                </Dialog.CustomContent>
            </Dialog>

            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                <div style={{ width: '50%' }}>
                    <div className={styles.content_box}>
                        <TurbineEditForm turbine={turbine} problems={problems} turbineChange={turbineChange} />
                        <Button href="/turbines">Cancel</Button> &nbsp;
                        <Button onClick={save}>Create</Button>
                    </div>
                </div>
                <div style={{ width: '50%' }}>
                    {turbine.profile && (
                        <div className={styles.content_box}>
                            <Typography variant="h3">Turbine profile</Typography>
                            <TurbineProfileView profile={turbine.profile} />
                        </div>
                    )}
                </div>
            </div>

            <BeforeUnload when={modified && !saving} />
        </>
    );
};
