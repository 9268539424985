/* eslint-disable */
import { default as React, useCallback, useContext, useMemo, useState } from 'react';
import { Field } from './fields';
import { asUnitObj, UnitConversion } from '../uom/UnitConversion';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { CaseRef } from '../redux/store/caseCompare/types';
import { UnitConversionContext } from '../uom/UnitConversionContext';
import { Box, IconButton, TableSortLabel, Tooltip } from '@material-ui/core';
import { spaceForCamelCases } from '../components/utils/textmanipulation';
import { Link } from 'react-router-dom';
import { CaseMap, caseRefToString, CaseWithResult } from './CaseComparisonPage';
import { Case, EpaAssumptions } from '@lcoe/lcoe-client';
import * as XLSX from 'xlsx';
import { formatNumberToIntOrSigDigits } from '../numbers';
import { isUndefinedValueWithUnit, isValueWithUnit } from '../components/utils/valueWithUnit';
import { updateSupportedCurrencyRates } from '../Settings/EpaUtils';
import {Icon} from "@equinor/eds-core-react";

export const valueFromPath = (caze: any, path: string[]) => {
    return path.reduce((curr, key) => {
        if (curr === '') return '';
        if (Array.isArray(curr)) {
            let elementWithName = undefined;
            curr.forEach((element) => {
                if (element.name === key) {
                    elementWithName = element;
                }
            });
            if (elementWithName !== undefined) {
                return elementWithName;
            }

            // Field with that key as name not found
            return '';
        }
        if (curr === undefined || curr[key] === undefined) return ''; // Should not return undefined, but rather an empty string.

        // Regular object indexing
        return curr[key];
    }, caze);
};

function formatHeader(caze: Case) {
    return `${caze.name} (v${caze.version}) by ${caze.createdBy}`;
}

interface CaseCompareCardProps {
    cases: CaseRef[];
    clear: () => void;
    removeCase: (caseRef: CaseRef) => void;
    fields: Field[];
    loadedCases: CaseMap;
}

export const CasesCompareCard = ({ cases, clear, removeCase, fields, loadedCases }: CaseCompareCardProps) => {
    const unitConversion = useContext(UnitConversionContext);

    const [sortAsc, setSortAsc] = useState(true);
    const [sortField, setSortField] = useState(undefined as Field | undefined);

    const changeSort = (field: Field) => {
        if (sortField !== field) {
            setSortField(field);
            setSortAsc(true);
        } else {
            setSortAsc(!sortAsc);
        }
    };

    const sortedCases = useMemo(() => {
        if (!sortField) {
            return cases;
        }
        return cases.sort((refA, refB) => {
            const caseA = loadedCases[caseRefToString(refA)];
            const caseB = loadedCases[caseRefToString(refB)];

            const caseAUndefined = typeof caseA === 'string' || caseA === undefined;
            const caseBUndefined = typeof caseB === 'string' || caseB === undefined;
            if (caseAUndefined && caseBUndefined) return 0;
            if (caseAUndefined) return -1;
            if (caseBUndefined) return 1;

            if (sortField === undefined) return 0;
            let a = valueFromPath(caseA as CaseWithResult, sortField.path);
            let b = valueFromPath(caseB as CaseWithResult, sortField.path);

            if (isValueWithUnit(a) && isValueWithUnit(b)) {
                if (isUndefinedValueWithUnit(a)) {
                    const cmp = 1;
                    return sortAsc ? cmp : -cmp;
                }
                if (isUndefinedValueWithUnit(b)) {
                    const cmp = -1;
                    return sortAsc ? cmp : -cmp;
                }
                a = asUnitObj(a).toNumber(a.unit);
                b = asUnitObj(b).toNumber(b.unit);
            }

            if (a === b) {
                return 0;
            }

            const cmp = a > b ? 1 : -1;
            if (!sortAsc) {
                return -cmp;
            }
            return cmp;
        });
    }, [cases, sortAsc, sortField, loadedCases]);

    const onExport = useCallback(() => {
        const activeFieldsToJson = (fields: Field[]): { [key: string]: any }[] => {
            return fields.reduce((acc, field) => {
                if (field.isActive) {
                    if (field.hasValue) {
                        let unit = undefined;
                        const row: { [key: string]: any } = {
                            '': `${field.namespace.join('.')}${field.namespace.length === 0 ? '' : '.'}${field.title}`
                        };
                        sortedCases
                            .map((caseRef) => loadedCases[caseRefToString(caseRef)])
                            .forEach((caze: string | CaseWithResult) => {
                                if (caze === undefined || typeof caze === 'string') {
                                } else {
                                    // Add row. Supported types: boolean, string, ValueWithUnit
                                    const maybeValueWithUnit = valueFromPath(caze, field.path);
                                    let value;
                                    if (isValueWithUnit(maybeValueWithUnit)) {
                                        if (isUndefinedValueWithUnit(maybeValueWithUnit)) {
                                            value = undefined; // Gives an empty cell in Excel.
                                        } else {
                                            const aUnit = asUnitObj(maybeValueWithUnit);
                                            const changedUnit = unitConversion.toBase(aUnit);
                                            const valueWithUnit = unitConversion.toValueWithUnit(changedUnit);
                                            value = valueWithUnit.value;
                                            unit = unitConversion.formatValuelessPartOfUnit(valueWithUnit.unit);
                                        }
                                    } else {
                                        value = maybeValueWithUnit;
                                    }
                                    row[formatHeader(caze)] = value;
                                }
                            });
                        row[''] += unit ? ` (${unit})` : '';
                        acc.push(row);
                    } else {
                        // The field is active so it should have children to show
                        if (field.children !== undefined) acc.push(...activeFieldsToJson(field.children));
                    }
                }
                return acc;
            }, [] as { [key: string]: any }[]);
        };
        const sheet = XLSX.utils.json_to_sheet(activeFieldsToJson(fields));
        const book = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(book, sheet);
        XLSX.writeFile(book, 'compare-cases.xlsx');
    }, [sortedCases, fields, loadedCases, unitConversion]);

    return (
        <Card style={{marginLeft: 4, marginTop: 8, marginRight: 4, marginBottom: 8 }}>
            <CardHeader
                title="Compare cases"
                action={
                    <>
                        <Button onClick={onExport}>Export to Excel</Button>
                        <Button onClick={clear}>
                            <Icon name={'delete_to_trash'} /> Clear
                        </Button>
                    </>
                }
            />
            <div className="card-scroll">
                <Table stickyHeader padding="none">
                    <TableHead>
                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            {sortedCases.map((caseRef) => {
                                const caze = loadedCases[caseRefToString(caseRef)];
                                return (
                                    <TableCell key={caseRefToString(caseRef)}>
                                        {caze !== undefined && typeof caze !== 'string' && (
                                            <Link to={`/cases/${caze.id}?version=${caze.version}`}>
                                                {formatHeader(caze)}
                                            </Link>
                                        )}
                                        <Button title="Remove case" onClick={() => removeCase(caseRef)}>
                                            <Icon name={'delete_to_trash'} />
                                        </Button>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field: Field) => {
                            return (
                                <FieldRows
                                    field={field}
                                    cases={sortedCases.map((caseRef) => loadedCases[caseRefToString(caseRef)])}
                                    level={0}
                                    changeSort={changeSort}
                                    sortAsc={sortAsc}
                                    sortField={sortField}
                                    key={field.path.join()}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Card>
    );
};

export function formatValue(v: any, unitConversion: UnitConversion, epa: EpaAssumptions): any {
    updateSupportedCurrencyRates(epa);
    if (typeof v === 'boolean') {
        return v ? 'Yes' : 'No';
    } else if (typeof v === 'number') {
        return formatNumberToIntOrSigDigits(v);
    } else if (isValueWithUnit(v)) {
        if (isUndefinedValueWithUnit(v)) return '';
        return unitConversion.convertValueAndUnitToString(v.value, v.unit);
    } else if (Array.isArray(v)) {
        let isStringArray = true;
        v.forEach((element) => {
            if (typeof element !== 'string') isStringArray = false;
        });
        if (isStringArray) return v.join(', ');
        return 'Array';
    } else if (v instanceof Date) {
        return v.toLocaleDateString();
    } else if (typeof v === 'object') {
        //This is an object and will give error in formatting
        return '';
    }
    return v;
}

interface FieldRowsProps {
    field: Field;
    cases: (string | CaseWithResult)[];
    level: number;
    changeSort: (field: Field) => void;
    sortAsc: boolean;
    sortField: Field | undefined;
}

const FieldRows = ({ field, cases, level, changeSort, sortAsc, sortField }: FieldRowsProps) => {
    const unitConversion = useContext(UnitConversionContext);

    const [expanded, setExpanded] = useState(true);
    const toggleExpanded = () => setExpanded(!expanded);
    const expandedIcon = (opacity: number) =>
        expanded ? <Icon name={'chevron_down'} /> : <Icon name={'chevron_right'} />;
    const hasChildren = field.children !== undefined && field.children.length > 0;

    if (!field.isActive) {
        return <></>;
    }

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton disabled={!hasChildren} onClick={toggleExpanded} size="small">
                        {expandedIcon(hasChildren ? 1 : 0)}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Box pl={`${level * 15}px`}>
                        <Tooltip title="Sort">
                            <TableSortLabel
                                active={sortField === field}
                                direction={sortAsc ? 'asc' : 'desc'}
                                onClick={() => changeSort(field)}
                            >
                                {spaceForCamelCases(field.title)}
                            </TableSortLabel>
                        </Tooltip>
                    </Box>
                </TableCell>
                {!field.hasValue &&
                    cases.map((caseOrProblem, index) => {
                        return <TableCell key={field.path.join() + index}> </TableCell>;
                    })}
                {field.hasValue &&
                    cases.map((caseOrProblem, index) => {
                        if (caseOrProblem === undefined || typeof caseOrProblem === 'string') {
                            return <TableCell key={field.path.join() + index}>Loading...</TableCell>;
                        }

                        let element: any = formatValue(
                            valueFromPath(caseOrProblem, field.path),
                            unitConversion,
                            caseOrProblem.epa
                        );
                        if (field.link) {
                            element = <Link to={field.link(caseOrProblem)}>{element}</Link>;
                        }
                        return (
                            <TableCell key={field.path.join() + caseOrProblem.id + caseOrProblem.version}>
                                <Box pl={`10px`}>{element}</Box>
                            </TableCell>
                        );
                    })}
            </TableRow>
            {expanded &&
                field.children !== undefined &&
                field.children.map((field) => (
                    <FieldRows
                        key={field.path.join()}
                        field={field}
                        level={level + 1}
                        cases={cases}
                        changeSort={changeSort}
                        sortAsc={sortAsc}
                        sortField={sortField}
                    />
                ))}
        </>
    );
};
