export const meanHsLabelSpecsColors: Array<string> = [
    'rgb(81, 158, 189, 255)',
    'rgb(113, 171, 176, 255)',
    'rgb(140, 184, 164, 255)',
    'rgb(167, 199, 151, 255)',
    'rgb(191, 212, 138, 255)',
    'rgb(215, 227, 125, 255)',
    'rgb(238, 242, 109, 255)',
    'rgb(250, 234, 92, 255)',
    'rgb(252, 207, 81, 255)',
    'rgb(252, 179, 68, 255)',
    'rgb(250, 150, 57, 255)',
    'rgb(247, 122, 45, 255)',
    'rgb(245, 96, 37, 255)',
    'rgb(240, 62, 26, 255)',
    'rgb(232, 16, 20, 255)'
];
export const meanHsLabelSpecsValues: Array<string> = [
    '0.0 - 0.5m',
    '0.5 - 1.0m',
    '1.0 - 1.5m',
    '1.5 - 2.0m',
    '2.0 - 2.5m',
    '2.5 - 3.0m',
    '3.0 - 3.5m',
    '3.5 - 4.0m',
    '4.0 - 4.5m',
    '4.5 - 5.0m',
    '5.0 - 5.5m',
    '5.5 - 6.0m',
    '6.0 - 6.5m',
    '6.5 - 7.0m',
    '7.0 - 7.5m'
];
