import * as React from 'react';
import {
    DataGrid,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridRowData
} from '@mui/x-data-grid';
import { IconButton, TextField } from '@material-ui/core';
import styles from './ListTable.module.css';
import CSS from 'csstype';
import cx from 'classnames';
import { escapeRegExp } from 'lodash';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import { Icon } from '@equinor/eds-core-react';
import { CaseDataRow, Column } from '../../Case/CaseListPage';
import { Case } from '@lcoe/lcoe-client';
import { LocationDataRow } from '../../Location/LocationList';
import { TurbineDataRow } from '../../Turbine/TurbineList';

interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}

function QuickSearchToolbar(props: QuickSearchToolbarProps) {
    return (
        <div className={styles.SearchBar}>
            <div>
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </div>
            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                className={styles.TextField}
                InputProps={{
                    startAdornment: <Icon name={'search'} />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <Icon name={'close'} />
                        </IconButton>
                    )
                }}
            />
        </div>
    );
}

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    columns: Column[];
    datarows: DataRow[];
}

export type DataRow =
    | LocationDataRow[keyof LocationDataRow]
    | CaseDataRow[keyof CaseDataRow]
    | TurbineDataRow[keyof TurbineDataRow]
    | Case[keyof Case];

export const ListTable = (props: IProps): JSX.Element => {
    const [searchText, setSearchText] = useState('');
    const [rowsToDisplay, setRowsToDisplay] = useState<DataRow[]>(cloneDeep(props.datarows));
    const rowsFullSet = cloneDeep(props.datarows) as [];
    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = rowsFullSet.filter((row) => {
            return Object.keys(row).some((field) => {
                return searchRegex.test(String(row[field])?.toString());
            });
        });
        setRowsToDisplay(filteredRows);
    };

    for (let i = 0; i < props.columns.length; i++) {
        props.columns[i].headerClassName = props.columns[i].headerClassName
            ? props.columns[i].headerClassName
            : styles.TableHeader;
    }

    return (
        <div className={cx(styles.ListTable, props.className)} style={props.style}>
            <DataGrid
                className={styles.DataGrid}
                components={{ Toolbar: QuickSearchToolbar }}
                rows={rowsToDisplay as GridRowData[]}
                columns={props.columns}
                autoPageSize={true}
                headerHeight={34}
                rowHeight={24}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch('')
                    }
                }}
            />
        </div>
    );
};
