import React, { useCallback, useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { Location, Position } from '@lcoe/lcoe-client';
import { LocationWizardLayout } from './LocationWizardLayout';
import { LocationDistanceLayer } from './LocationDistanceLayer';
import { BaseLeafletMap } from '../Map/BaseLeafletMap';
import { PopupMarker } from './WeatherFileMap';
import { LatLng } from 'leaflet';
import { round } from 'mathjs';
import { useSelector } from 'react-redux';
import { layersEnum } from '../redux/store/map/types';
import styles from './LocationDistanceDrawer.module.css';
import { Labels } from '../Map/Labels';
import { maxHsLabelSpecsColors, maxHsLabelSpecsValues } from '../Map/MaxHsLabelSpecs';
import { meanHsLabelSpecsColors, meanHsLabelSpecsValues } from '../Map/meanHsLabelSpecs';
import { defaultView } from '../utils/map/defaultView';
import { ApplicationState } from '../redux/store';

interface LocationDistanceLoaderProps {
    setStep: (step: number) => void;
    locationPosition: Position;
    locationChange: (diff: Partial<Location>) => void;
}

export const LocationDistanceDrawer = ({
    setStep,
    locationPosition,
    locationChange
}: LocationDistanceLoaderProps): JSX.Element => {
    const [subStep, setSubStep] = useState(0);

    const [newMarker, setNewMarker] = useState<LatLng>();
    const [markers] = useState<PopupMarker[]>([]);

    const [newDistance, setNewDistance] = useState<Partial<Distance>>(distanceTexts[0]);
    const [distances] = useState<Partial<Distance>[]>([]);
    const combineDistance = useCallback(
        (dist: number, fromPosition: LatLng, toPosition: LatLng) => {
            setNewDistance({
                ...newDistance,
                distance: round(dist, 2),
                fromPosition: fromPosition,
                toPosition: toPosition
            });
        },
        [newDistance]
    );

    const mapState = useSelector((state: ApplicationState) => state.map);
    const [displayMaxHsLabels, setDisplayMaxHsLabels] = useState<boolean>(false);
    useEffect(() => {
        if (mapState[layersEnum.wavesMaxHs].checked) {
            setDisplayMaxHsLabels(true);
        }

        if (mapState[layersEnum.wavesMeanHs].checked || !mapState[layersEnum.wavesMaxHs].checked) {
            setDisplayMaxHsLabels(false);
        }
    }, [mapState]);
    const handleConfirm = (): void => {
        if (newMarker) {
            markers.push({ latLng: newMarker, popupText: getDisplayText(subStep) });
            setNewMarker(undefined);
        }

        if (newDistance) {
            distances.push(newDistance);
            setNewDistance(distanceTexts[subStep + 1]);
            locationChange({ [newDistance.locationPropName as string]: newDistance.distance });
        }

        if (subStep < distanceTexts.length - 1) {
            setSubStep(subStep + 1);
        } else {
            setStep(3);
        }
    };

    const content = (): JSX.Element => {
        return (
            <>
                <Typography component="p">
                    Please use map to set distances needed for location. It is possible to change distances later.
                </Typography>
                <br />
                <Typography component="p">
                    {`Please put the marker (right click) on: ${newDistance?.displayText}`}
                </Typography>
            </>
        );
    };

    const buttons = (): JSX.Element => {
        return (
            <>
                <Button onClick={() => setStep(3)} variant="contained" color="primary">
                    Go to location form
                </Button>
                <Button onClick={() => handleConfirm()} variant="outlined" color="primary" disabled={!newMarker}>
                    Confirm distance
                </Button>
            </>
        );
    };

    const displayMap = (): JSX.Element => {
        return (
            <div className={styles.container}>
                <BaseLeafletMap style={{ height: window.innerHeight / 2, width: '1000px' }} defaultView={defaultView}>
                    <LocationDistanceLayer
                        locationPosition={locationPosition}
                        markers={markers}
                        newMarker={newMarker}
                        setNewMarker={setNewMarker}
                        distances={distances}
                        combineDistance={combineDistance}
                    />
                </BaseLeafletMap>
                <div className={styles.labels}>
                    {displayMaxHsLabels && <Labels colors={maxHsLabelSpecsColors} values={maxHsLabelSpecsValues} />}
                    {mapState[layersEnum.wavesMeanHs].checked && (
                        <Labels colors={meanHsLabelSpecsColors} values={meanHsLabelSpecsValues} />
                    )}
                </div>
            </div>
        );
    };
    return (
        <LocationWizardLayout
            title={'Define distances'}
            content={content()}
            buttons={buttons()}
            displayMap={displayMap()}
        />
    );
};

export type Distance = {
    fromPosition: LatLng;
    toPosition: LatLng;
    distance: number;
    popupText: string;
    displayText: string;
    locationPropName: string;
};

const distanceTexts: Partial<Distance>[] = [
    { displayText: 'Landfall', locationPropName: 'distanceToLandfall' },
    { displayText: 'O&M port', locationPropName: 'distanceToOandMPort' },
    { displayText: 'Cable port', locationPropName: 'distanceToCablePort' },
    { displayText: 'Substructure port', locationPropName: 'distanceToSubstructurePort' },
    { displayText: 'Turbine port', locationPropName: 'distanceToTurbinePort' }
];

const getDisplayText = (subStep: number): string => {
    return distanceTexts[subStep].displayText as string;
};
