import React, { CSSProperties } from 'react';
import { LatLngLiteral, LatLngBounds } from 'leaflet';
import { OceanLayer } from './OceanLayer';
import { MapContainer } from 'react-leaflet';
import { MultiLayerWithLabels } from './MultiLayerWithLabels';

interface BaseLeafletMapProps {
    style: CSSProperties;
    children: JSX.Element;
    defaultView: LatLngLiteral;
}

export const BaseLeafletMap = ({ style, children, defaultView }: BaseLeafletMapProps): JSX.Element => {
    return (
        <MapContainer
            style={style}
            center={defaultView}
            zoom={5}
            maxZoom={10}
            maxBounds={new LatLngBounds([-90, -Infinity], [90, Infinity])}
            bounceAtZoomLimits={false}
            maxBoundsViscosity={0.99}
            worldCopyJump={true}
        >
            <OceanLayer />
            <MultiLayerWithLabels />
            {children}
        </MapContainer>
    );
};
