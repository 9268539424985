import React from 'react';
import { Content } from './Content';
import { connect } from 'react-redux';
import { LoginState } from '../redux/store/user/types';
import { Dialog, Typography } from '@equinor/eds-core-react';

function MainComponentComponent({ loginState }: any) {
    switch (loginState) {
        case LoginState.SUCCESS:
            return (
                <div style={{ marginBottom: 30, justifyContent: 'center' }}>
                    <Content />
                </div>
            );
        case LoginState.TIMED_OUT:
            return (
                <Dialog open={true} style={{ width: 400, padding: 30 }}>
                    <Typography variant={'h3'}>
                        You have automatically been signed out, please <a href="/.auth/login/aad">sign in</a>.
                    </Typography>
                </Dialog>
            );
        case LoginState.FAILED:
            return (
                <Dialog open={true} style={{ width: 400, padding: 30 }}>
                    <Typography variant={'h3'}>You could not be signed in</Typography>
                    <Typography>
                        You do not have any roles in LCOE. Please apply for LCOE access through AccessIT.
                    </Typography>
                </Dialog>
            );
        default:
            return (
                <Dialog open={true} style={{ width: 400, padding: 30 }}>
                    <Typography variant={'h3'}>Signing you in, please wait...</Typography>
                </Dialog>
            );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loginState: state.user.loginState
    };
};

export const MainComponent = connect(mapStateToProps)(MainComponentComponent);
