import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BrowserRouter } from 'react-router-dom';
import { ApiProvider } from './Api/ApiProvider';
import Login from './auth/Login';
import { BottomBar } from './components/BottomBar';
import { MainComponent } from './components/MainComponent';
import { AppLogView } from './LogView/AppLogView';
import { UnitConversionProvider } from './uom/UnitConversionContextProvider';

const theme = createTheme({
    palette: {
        primary: {
            main: '#343a40'
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: ['Equinor', 'sans-serif'] as unknown as string[]
    } as unknown as TypographyOptions
});

const aiConnectionString =
    'InstrumentationKey=b9d5ae53-c0a6-4c41-ac82-8ce27f3accf9;IngestionEndpoint=' + getAiApiUrl() + '/ai';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: aiConnectionString,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true
    }
});

appInsights.loadAppInsights();

function App(): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Login>
                    <ApiProvider>
                        <UnitConversionProvider>
                            <MainComponent />
                        </UnitConversionProvider>
                    </ApiProvider>
                </Login>
            </BrowserRouter>
            <AppLogView />
            <BottomBar />
        </ThemeProvider>
    );
}

function getAiApiUrl(): string {
    if (window.location.hostname.includes('.azurewebsites')) {
        return window.location.protocol + '//' + window.location.hostname.replace(/(\.azurewebsites)/, '-api$1'); // place '-api' before '.azurewebsites'
    } else if (window.location.hostname === 'lcoe.equinor.com') {
        return 'https://api-lcoeapi-prod.radix.equinor.com';
    } else if (window.location.hostname === 'lcoe-test.equinor.com') {
        return 'https://lcoe-test-api.azurewebsites.net';
    } else if (window.location.hostname === 'gui-lcoe-dev.radix.equinor.com') {
        return 'https://api-lcoeapi-dev.radix.equinor.com';
    } else if (window.location.hostname === 'gui-lcoe-radixdev.radix.equinor.com') {
        return 'https://api-lcoeapi-radixdev.radix.equinor.com';
    } else if (window.location.hostname === 'gui-lcoe-qa.radix.equinor.com') {
        return 'https://api-lcoeapi-qa.radix.equinor.com';
    } else if (window.location.hostname === 'gui-lcoe-prod.radix.equinor.com') {
        return 'https://api-lcoeapi-prod.radix.equinor.com';
    } else {
        // This should now be localhost
        return window.location.protocol + '//' + window.location.hostname + ':5000';
    }
}

export default App;
