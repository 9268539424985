import { Location, Locations } from '@lcoe/lcoe-client/src/models';
import { ApiContext } from 'components/ApiContext';
import LoadingDialog from 'components/LoadingDialog';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { LocationList } from '../../Location/LocationList';
import { NavBar } from '../../components/NavBar';

export const LocationPage = (): JSX.Element => {
    const api = useContext(ApiContext);
    const [locations, setLocations] = useState<Location[]>([]);
    useEffect(() => {
        api.getLocations().then((response: Locations) => {
            setLocations(response.locations);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <NavBar />
            {!locations && <LoadingDialog />}
            {locations && <LocationList locations={locations}></LocationList>}
        </>
    );
};
