/* eslint-disable */
import React, { useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { SimplePopover } from '../components/forms/SimplePopover';
import Button from '@material-ui/core/Button';
import {Icon} from "@equinor/eds-core-react";

function roundToTenths(n: any) {
    return Math.round(n * 10) / 10.0;
}

function maxSpacingMeters(areaKm2: any, wtgCount: any) {
    const areaM2 = 1000000 * areaKm2;

    const magicConstant = 1.002068;
    return (magicConstant * Math.sqrt(areaM2)) / (Math.sqrt(wtgCount) - 1);
}

export const WtgSpacingAdornment = ({ object, onChange }: any) => {
    const { location, wtg, wtgCount } = object;
    const [popoverAnchor, setPopoverAnchor] = useState(null);

    const setSpacing = useCallback(
        (wtgSpacingNd) => {
            onChange({ wtgSpacingNd });
            setPopoverAnchor(null);
        },
        [onChange]
    );

    const maxSpacingGivenArea =
        location &&
        wtgCount &&
        wtg &&
        roundToTenths(maxSpacingMeters(location.concessionArea, wtgCount) / wtg.rotorDiameter);

    const canSuggest = !!maxSpacingGivenArea && maxSpacingGivenArea !== Infinity;
    if (!canSuggest) {
        return null;
    }
    return (
        <React.Fragment>
            <IconButton title="Calculate" onClick={(event) => setPopoverAnchor(event.target as any)}>
                <Icon name={'computer'}/>
            </IconButton>
            {popoverAnchor && (
                <SimplePopover anchorEl={popoverAnchor} onClose={() => setPopoverAnchor(null)}>
                    <Button variant="contained" onClick={() => setSpacing(maxSpacingGivenArea)}>
                        <Icon name={'vertical_align_bottom'}/>
                        Limited by area: {maxSpacingGivenArea}
                    </Button>
                </SimplePopover>
            )}
        </React.Fragment>
    );
};
