import { ConfigElectrical } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import CSS from 'csstype';
import { ElectricalConfig } from '../../config/ElectricalConfig';
import { NavBarMasterData } from '../../components/NavBarMasterData';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const ElectricalConfigPage = (props: IProps): JSX.Element => {
    const [electricalConfig, setElectricalConfig] = useState<undefined | ConfigElectrical>(undefined);

    const api = useContext(ApiContext);
    useEffect(() => {
        api.getConfigElectrical().then((response: ConfigElectrical) => {
            setElectricalConfig(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    return (
        <>
            <NavBarMasterData />
            <div className={cx(props.className)} style={props.style}>
                {electricalConfig && <ElectricalConfig electricalConfig={electricalConfig}></ElectricalConfig>}
            </div>
        </>
    );
};
