import React from 'react';
import * as packagejson from '../../package.json';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../redux/store';
import styles from './BottomBar.module.css';
import { Typography } from '@equinor/eds-core-react';

const RELEASE_NOTES_URL =
    'https://teams.microsoft.com/l/entity/com.microsoft.teamspace.tab.wiki/tab::22b42b50-a44d-4f25-a75b-2a310fc6fc99?context=%7B%22subEntityId%22%3A%22%7B%5C%22pageId%5C%22%3A2%2C%5C%22origin%5C%22%3A2%7D%22%2C%22channelId%22%3A%2219%3A15294aa1f6674f9093764f0ad3af5788%40thread.skype%22%7D&tenantId=3aa4a235-b6e2-48d5-9195-7fcf05b459b0';

export const BottomBar = (): JSX.Element => {
    const user = useSelector((state: ApplicationState) => state.user);

    return (
        <div className={styles.informationBox}>
            <div>
                <Typography link href={RELEASE_NOTES_URL}>
                    Version: {packagejson.dependencies['@lcoe/lcoe-client']}
                </Typography>
            </div>
            <div>Logged in as: {user.name}</div>
        </div>
    );
};
