import { ADD_LOG_LINE, AddLogLineAction, LogLine, SET_VISIBILITY, SetVisibilityAction } from './types';

export function addLogLine(logLine: LogLine): AddLogLineAction {
    return {
        type: ADD_LOG_LINE,
        log: logLine
    };
}

export function setVisibilityAction(visible: boolean): SetVisibilityAction {
    return {
        type: SET_VISIBILITY,
        visible
    };
}
