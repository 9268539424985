import { Turbine, Turbines } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import LoadingDialog from 'components/LoadingDialog';
import React, { useContext, useEffect, useState } from 'react';
import styles from './TurbineListPage.module.css';
import { TurbineList } from '../../Turbine/TurbineList';
import { NavBar } from '../../components/NavBar';

export const TurbineListPage = (): JSX.Element => {
    const [turbines, setTurbines] = useState<Turbine[]>([]);
    const api = useContext(ApiContext);

    useEffect(() => {
        api.getTurbines().then((response: Turbines) => {
            setTurbines(response.turbines);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <NavBar />
            {!turbines && <LoadingDialog />}
            {turbines && (
                <div className={styles.TurbineListPage}>
                    <TurbineList turbines={turbines}></TurbineList>
                </div>
            )}
        </>
    );
};
