/* eslint-disable */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListItemText from '@material-ui/core/ListItemText';
import * as PropTypes from 'prop-types';

export const SimpleSelect = function ({
    name,
    label,
    onChange,
    value,
    id,
    options,
    disabled,
    problem,
    warning,
    helperText,
    sm = 6,
    required = true
}: any) {
    const error = !!problem;
    const hasHelperText = problem || warning || helperText;
    return (
        <Grid item xs={12} sm={sm}>
            <FormControl fullWidth disabled={disabled} error={error}>
                <InputLabel required={required} htmlFor={id} error={error}>
                    {label}
                </InputLabel>
                <Select
                    inputProps={{ name, id }}
                    value={value || ''}
                    onChange={onChange}
                    renderValue={(v: any) => v.name || v.id || v.filename || v}
                >
                    {options &&
                        options.map((op: any) => {
                            if (op === undefined) {
                                return (
                                    <MenuItem key={'empty'} value={op}>
                                        <ListItemText primary={'None'} />
                                    </MenuItem>
                                );
                            } else {
                                return (
                                    <MenuItem key={op.id || op.name || op.filename || op} value={op.value || op}>
                                        <ListItemText
                                            primary={op.name || op.id || op.filename || op}
                                            secondary={op.explanation}
                                        />
                                    </MenuItem>
                                );
                            }
                        })}
                </Select>
                {hasHelperText && (
                    <FormHelperText error={error || !!warning}>{problem || warning || helperText}</FormHelperText>
                )}
            </FormControl>
        </Grid>
    );
};

SimpleSelect.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    id: PropTypes.string,
    options: PropTypes.array,
    sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    disabled: PropTypes.bool,
    problem: PropTypes.any,
    warning: PropTypes.any,
    helperText: PropTypes.string,
    required: PropTypes.bool
};
