import { Button, Dialog } from '@equinor/eds-core-react';
import React from 'react';
import styles from './DialogWarning.module.css';

export interface IProps {
    className?: string;
    visibleScrim: boolean;
    Content: React.ReactNode;
    handleClose: () => void;
    isDismissable: boolean;
}

export const DialogWarning = (props: IProps): JSX.Element => {
    return (
        <>
            {props.visibleScrim && (
                <Dialog onClose={props.handleClose} open={props.visibleScrim} isDismissable={props.isDismissable}>
                    <div className={styles.box}>
                        <>{props.Content}</>
                        <div className={styles.button}>
                            <Button onClick={props.handleClose}>Close</Button>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    );
};
