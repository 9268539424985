/* eslint-disable */
import numeral from 'numeral';

numeral.register('locale', 'lcoe', {
    delimiters: {
        thousands: ' ',
        decimal: '.'
    },
    abbreviations: {
        thousand: 'k',
        million: 'M',
        billion: 'G',
        trillion: 'T'
    }
} as any);
numeral.locale('lcoe');

export function formatNumber(number: any) {
    return numeral(number).format('0,0[.][000]');
}

export function formatNumberAbbrev(number: any) {
    return numeral(number).format('0,0[.][000] a');
}

export function formatFractionAsPercent(number: any) {
    const r = Math.ceil(Math.log(number * 100) / Math.LN10);
    return numeral(number).format(r === 3 ? '0%' : r === 2 ? '0.0%' : r === 1 ? '0.00%' : '0.000%');
}

/*
If number >= 10^nSigDig or is an integer, format as integer.
Else set number of significant digits.
 */
export function formatNumberToIntOrSigDigits(number: any) {
    const nSigDig = 4;
    const r = Math.ceil(Math.log10(Math.abs(number)));

    if (number % 1 === 0 || r >= nSigDig) return number.toFixed(0);

    const nDec = nSigDig - r;
    return number.toFixed(nDec);
}
