type LegendType = {
    value: string;
    color: string;
};

export const mapValueToColor = (values: Array<string>, colors: Array<string>): Array<LegendType> => {
    const mappedArray: Array<LegendType> = [];
    for (let i = 0; i < values.length; ++i) {
        const legend: LegendType = {
            value: values[i],
            color: colors[i]
        };
        mappedArray.push(legend);
    }
    return mappedArray;
};
