import { InfrastructureSelection, InfrastructureSubselection } from '@lcoe/lcoe-client';

export interface InfrastructureSelectionTemplate {
    name: string;
    infrastructureSelection: InfrastructureSelection;
}

const standAlone: InfrastructureSelection = {
    infieldCables: all(),
    substationOnshore: none(),
    exportCables: none(),
    substationToConverterCable: none(),
    substationOffshore: none(),
    hvdcConverter: none()
};

export const infrastructurePresets: Array<InfrastructureSelectionTemplate> = [
    {
        name: 'United Kingdom',
        infrastructureSelection: {
            infieldCables: all(),
            exportCables: {
                capex: true
            },
            hvdcConverter: {
                capex: true
            },
            substationOffshore: {
                capex: true
            },
            substationOnshore: {
                capex: true
            },
            substationToConverterCable: {
                capex: true
            }
        }
    },
    {
        name: 'Germany',
        infrastructureSelection: {
            infieldCables: all(),
            substationOffshore: all(),
            exportCables: none(),
            hvdcConverter: none(),
            substationOnshore: none(),
            substationToConverterCable: none()
        }
    },
    {
        name: 'Denmark',
        infrastructureSelection: standAlone
    },
    {
        name: 'Connected',
        infrastructureSelection: {
            exportCables: all(),
            hvdcConverter: all(),
            infieldCables: all(),
            substationOffshore: all(),
            substationOnshore: all(),
            substationToConverterCable: all()
        }
    },
    {
        name: 'Stand alone',
        infrastructureSelection: standAlone
    },
    {
        name: 'Hook-up',
        infrastructureSelection: {
            hvdcConverter: all(),
            infieldCables: all(),
            substationOffshore: all(),
            substationToConverterCable: all(),
            exportCables: none(),
            substationOnshore: none()
        }
    }
];

function none(): InfrastructureSubselection {
    return {};
}
function all(): InfrastructureSubselection {
    return {
        capex: true,
        energyLoss: true,
        onm: true
    };
}
