/* eslint-disable */
import React, { ChangeEvent } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

interface EpaAssumptionsSelectProps {
    options: string[] | undefined;
    selected: string;
    onSelect: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const EpaAssumptionsSelect = ({ onSelect, selected, options }: EpaAssumptionsSelectProps) => {
    return (
        <FormControl fullWidth={true}>
            <InputLabel>EPA Assumptions</InputLabel>
            <Select onChange={onSelect} value={selected ? selected : ''}>
                {options?.map((option) => {
                    return (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    );
                })}
            </Select>
            <FormHelperText>Select to view rates</FormHelperText>
        </FormControl>
    );
};
