import { round } from 'mathjs';
import { ChangeEvent, default as React, useContext, useState } from 'react';
import { UnitConversion } from '../../uom/UnitConversion';
import { Capex, Opex, OverrideResultStructure, Production } from '@lcoe/lcoe-client';
import { ApiContext } from '../../components/ApiContext';
import OverrideDialog from './overrideDialog';
import { ResultDetailOverrideStructure } from './ResultDetailOverrideStructure';
import { cloneDeep } from 'lodash';
import { CoeCapexNode } from './ResultDefinitions';
import { Button, Icon, Typography } from '@equinor/eds-core-react';
import { ResultEntry, flatCapex, recalcLevel } from './ResultUtils';
import styles from './ResultDetailOverride.module.css';

interface OverrideCoeTableProps {
    unitConversion: UnitConversion;
    capexNode: CoeCapexNode;
    opexNode: Opex;
    productionNode: Production;
    handleOverrideChange: (newResult: OverrideResultStructure) => void;
    useOverrideHierarchy: boolean;
    calcCapexRoot: CoeCapexNode;
    calcOpex: Opex;
    calcProduction: Production;
    parkLife: number;
    discountRate: number;
}

export const ResultDetailOverride = ({
    unitConversion,
    capexNode,
    opexNode,
    productionNode,
    handleOverrideChange,
    useOverrideHierarchy,
    calcCapexRoot,
    calcOpex,
    calcProduction,
    discountRate,
    parkLife
}: OverrideCoeTableProps): JSX.Element => {
    // PRODUCTION
    const newProduction = cloneDeep(productionNode);
    newProduction.electricalLossesRatio = 0;
    newProduction.otherLossesRatio = 0;
    newProduction.blockageLossesRatio = 0;
    newProduction.turbineLossesRatio = 0;
    newProduction.totalLossesRatio = 0;
    newProduction.wakeLossRatio = 0;
    newProduction.technicalAvailabilityRatio = 0;
    newProduction.grossAnnualProduction.value = 0;

    const [productionOverride, setProductionOverride] = useState(newProduction);

    const handleProductionChange = async (newValue: string) => {
        const newProductionOverride = Object.assign({}, productionOverride);
        newProductionOverride.netAnnualProduction.value = +newValue;

        // Update all coe for CAPEX part
        let sumCoe = 0;
        for (const capexNode1 of dataTable) {
            const index = dataTable.indexOf(capexNode1);
            const cost = capexNode1.capexCost + capexNode1.contingency;
            let coe = await fetchCoe(0, cost, 0);
            if (capexNode1.name == 'Decommissioning') {
                coe = await fetchCoe(0, 0, cost * 1000);
            }

            if (dataTable[index].level !== 1) {
                dataTable[index].COE = round(coe.value, 2);
                if (dataTable[index].level === 2) {
                    sumCoe = sumCoe + dataTable[index].COE;
                }
            }
            if (index === dataTable.length - 1) {
                dataTable[0].COE = sumCoe;
                setProductionOverride(newProductionOverride);
            }
        }

        await handleOpexChange((opexOverride.cost.value / 1000).toString());

        // Keep OverrideResultStructure object up to date
        const capexTreeFromOverrideTable = createCapexTree(dataTable, '');
        const resultStruct = {} as OverrideResultStructure;
        resultStruct.capex = capexTreeFromOverrideTable[0];
        resultStruct.opex = opexOverride;
        resultStruct.production = newProductionOverride;
        handleOverrideChange(resultStruct);
    };

    // OPEX
    const newOpex = cloneDeep(opexNode);
    if (newOpex.hasOwnProperty('children')) {
        delete newOpex.children;
        newOpex.learningEffect.value = 0;
    }
    const [opexOverride, setOpexOverride] = useState(newOpex);

    const handleOpexChange = async (newValue: string) => {
        const newOpexOverride = cloneDeep(opexOverride);
        newOpexOverride.cost.value = +newValue * 1000;
        setOpexOverride(newOpexOverride);

        // Calculate coe
        newOpexOverride.coe = await fetchCoe(newOpexOverride.cost.value, 0, 0);

        // Keep OverrideResultStructure object up to date
        const capexTreeFromOverrideTable = createCapexTree(dataTable, '');
        const resultStruct = {} as OverrideResultStructure;
        resultStruct.capex = capexTreeFromOverrideTable[0];
        resultStruct.opex = newOpexOverride;
        resultStruct.production = productionOverride;
        handleOverrideChange(resultStruct);
    };

    const arrCapexFromCalc: ResultEntry[] = flatCapex(capexNode, false);

    if (!useOverrideHierarchy) {
        recalcLevel(4, arrCapexFromCalc);
        recalcLevel(3, arrCapexFromCalc);
        recalcLevel(2, arrCapexFromCalc);
        recalcLevel(1, arrCapexFromCalc);
    }

    const [dataTable, setOverride] = useState(arrCapexFromCalc);

    const api = useContext(ApiContext);
    const fetchCoe = async (annualOpex: number, totalCapex: number, totalDecom: number) => {
        return await api.calcApi.calculateCoe({
            calcCoeRequest: {
                annualYieldedProduction: productionOverride.netAnnualProduction.value,
                annualOpex: annualOpex,
                discountRate: discountRate / 100,
                parkLife: parkLife,
                totalCapex: totalCapex * 1000,
                totalDecom: totalDecom
            }
        });
    };

    // handle user input and calculate changes in the table
    const handleCapexEdit = async (id: string, fieldType: string, newValue: string) => {
        const theDataTable = dataTable.map((l) => Object.assign({}, l));
        const filterID = [id];
        let currentEntry = theDataTable.filter(({ id }) => filterID.every((i) => id === i))[0];

        // Deactivate children
        if (currentEntry.isParent) {
            const resChildren = theDataTable.filter((entryRow) => String(entryRow.id).startsWith(currentEntry.id));
            resChildren.forEach((element) => {
                element.isActive = false;
            });
        }

        // Activate possible siblings
        const resSiblings = theDataTable.filter((entryRow) => entryRow.parent === currentEntry.parent);
        resSiblings.forEach((element) => {
            element.isActive = true;
        });

        // Set and calculate new values for row
        if (fieldType === 'capexBase') {
            currentEntry.contingency = +newValue * (currentEntry.contingency / currentEntry.capexCost);
            currentEntry.capexCost = +newValue;
        } else if (fieldType === 'contingency') {
            currentEntry.contingency = +newValue;
        } else if (fieldType === 'contingencyPercent') {
            currentEntry.contingency = Number(
                (unitConversion.fromMEURToCurrencyValue(currentEntry.capexCost) * +newValue) / 100
            );
        }

        // Calculate coe
        let cost = 0;
        if (isNaN(currentEntry.contingency)) {
            cost = currentEntry.capexCost;
            currentEntry.contingency = 0;
        } else {
            cost = currentEntry.contingency + currentEntry.capexCost;
        }

        let coe = await fetchCoe(0, cost, 0);

        if (currentEntry.name == 'Decommissioning') {
            coe = await fetchCoe(0, 0, cost * 1000);
        }

        currentEntry.COE = round(coe.value, 2);
        currentEntry.isActive = true;

        // Sum up all parent nodes
        while (currentEntry.parent !== '') {
            const filter = [currentEntry.parent];
            const res = theDataTable.filter(({ parent }) => filter.every((i) => parent === i));
            let sumCapexCost = 0;
            let sumContingency = 0;
            let sumCoe = 0;
            res.forEach((a) => {
                sumCapexCost += a.capexCost;
                sumContingency += isNaN(a.contingency) ? 0 : a.contingency;
                sumCoe += !!a.COE ? a.COE : 0;
            });
            theDataTable.filter(({ id }) => filter.every((i) => id === i))[0].contingency = +sumContingency.toFixed(3);
            theDataTable.filter(({ id }) => filter.every((i) => id === i))[0].capexCost = +sumCapexCost.toFixed(3);
            theDataTable.filter(({ id }) => filter.every((i) => id === i))[0].COE = round(sumCoe, 2);
            theDataTable.filter(({ id }) => filter.every((i) => id === i))[0].isActive = true;
            const filterID = [currentEntry.parent];
            currentEntry = theDataTable.filter(({ id }) => filterID.every((i) => id === i))[0];
        }

        setOverride(theDataTable);

        // Keep OverrideResultStructure object up to date
        const capexTreeFromOverrideTable = createCapexTree(theDataTable, '');
        const resultStruct = {} as OverrideResultStructure;
        resultStruct.capex = capexTreeFromOverrideTable[0];
        resultStruct.opex = opexOverride;
        resultStruct.production = productionOverride;
        handleOverrideChange(resultStruct);
    };

    const createCapexTree = (orgData: ResultEntry[], parentID: string) => {
        const levelThis: Capex[] = [];
        const levelEntries = orgData.filter((entryRow) => entryRow.parent === parentID);
        levelEntries.forEach((element) => {
            let childrenEntries: Capex[] = [];
            let newCapexElement;
            if (element.isParent) {
                childrenEntries = createCapexTree(orgData, element.id);
                newCapexElement = {
                    name: element.name,
                    coe: { value: element.COE, unit: 'kEUR/GWh' },
                    cost: { value: (element.capexCost + element.contingency) * 1000, unit: 'kEUR' },
                    learningEffect: { value: element.learningEffect, unit: 'kEUR' },
                    contingency: { value: element.contingency * 1000, unit: 'kEUR' },
                    capexBase: { value: element.capexCost * 1000, unit: 'kEUR' },
                    overrideNodeIsActive: element.isActive,
                    children: childrenEntries
                };
            } else {
                newCapexElement = {
                    name: element.name,
                    coe: { value: element.COE, unit: 'kEUR/GWh' },
                    cost: { value: (element.capexCost + element.contingency) * 1000, unit: 'kEUR' },
                    learningEffect: { value: element.learningEffect, unit: 'kEUR' },
                    contingency: { value: element.contingency * 1000, unit: 'kEUR' },
                    capexBase: { value: element.capexCost * 1000, unit: 'kEUR' },
                    overrideNodeIsActive: element.isActive
                };
            }
            levelThis.push(newCapexElement as Capex);
        });
        return levelThis;
    };

    // RESET FROM CALC RESULT
    const resetDataFromCalc = () => {
        const arrFromCalcOrg: ResultEntry[] = flatCapex(calcCapexRoot, true);
        setOverride(arrFromCalcOrg);

        recalcLevel(4, arrFromCalcOrg);
        recalcLevel(3, arrFromCalcOrg);
        recalcLevel(2, arrFromCalcOrg);
        recalcLevel(1, arrFromCalcOrg);

        const newOpexOverride = cloneDeep(opexOverride);
        newOpexOverride.cost.value = +calcOpex.cost.value;
        newOpexOverride.coe.value = +calcOpex.coe.value;
        setOpexOverride(newOpexOverride);

        const newProductionOverride = cloneDeep(productionOverride);
        newProductionOverride.netAnnualProduction.value = +calcProduction.netAnnualProduction.value;
        setProductionOverride(newProductionOverride);

        // Keep OverrideResultStructure object up to date
        const capexTreeFromOverrideTable = createCapexTree(arrFromCalcOrg, '');
        const resultStruct = {} as OverrideResultStructure;
        resultStruct.capex = capexTreeFromOverrideTable[0];
        resultStruct.opex = newOpexOverride;
        resultStruct.production = newProductionOverride;
        handleOverrideChange(resultStruct);
    };

    const getCoe = () => {
        let capexCoe;
        if (dataTable) {
            capexCoe = dataTable?.filter((cap) => cap.name === 'Total CAPEX')[0].COE;
        }
        const opexCoe = opexOverride.coe.value;
        return capexCoe && opexCoe ? capexCoe + opexCoe : 0;
    };

    // Dialog for editing values
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState(0);
    const [dialogVarName, setDialogVarName] = useState('');
    const [dialogType, setDialogType] = React.useState('');
    const [dialogID, setDialogID] = React.useState('');

    const handleDialogClickOpen = (id: string, type: string, currentValue: number, varName: string) => {
        const dialogValue =
            id === 'production' || 'contigencyPercent'
                ? currentValue
                : unitConversion.fromMEURToCurrencyValue(currentValue);

        setDialogValue(dialogValue);
        setDialogType(type);
        setDialogID(id);
        setDialogVarName(varName);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogSubmit = () => {
        const dialogValueString = unitConversion.fromCurrencyValueToMEUR(dialogValue).toString();

        if (dialogID === 'opex') {
            handleOpexChange(dialogValueString).finally(() => {
                setDialogOpen(false);
            });
        } else if (dialogID === 'production') {
            handleProductionChange(dialogValue.toString()).finally(() => {
                setDialogOpen(false);
            });
        } else {
            handleCapexEdit(dialogID, dialogType, dialogValueString).finally(() => {
                setDialogOpen(false);
            });
        }
    };

    const isEdit = window.location.href.includes('edit');

    return (
        <>
            <OverrideDialog
                baseCurrency={unitConversion.unitSystem.money}
                open={dialogOpen}
                onClose={handleDialogClose}
                dialogValue={dialogValue}
                dialogName={dialogVarName}
                onDialogChange={(e: ChangeEvent<HTMLInputElement>) => setDialogValue(+e.currentTarget.value)}
                onDialogSubmit={handleDialogSubmit}
            />

            <div className={styles.row_coe}>
                <div style={{ paddingTop: 5 }}>
                    <Typography variant={'h3'}>
                        <b>COE: {unitConversion.fromMEURToCoeString(getCoe())}</b>
                    </Typography>
                </div>
                {isEdit && (
                    <div>
                        <Button title={'Reset from calculated result'} onClick={resetDataFromCalc}>
                            <Icon size={18} name={'refresh'} /> Reset
                        </Button>
                    </div>
                )}
            </div>

            <ResultDetailOverrideStructure
                unitConversion={unitConversion}
                capexOverride={dataTable}
                onDialogOpen={handleDialogClickOpen}
                opexOverride={opexOverride}
                productionOverride={productionOverride}
            />
        </>
    );
};
