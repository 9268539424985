import { WtgIndexEnum } from '@lcoe/lcoe-client';

export const wtgIndexOptions: Array<WtgIndexOption> = [
    {
        name: WtgIndexEnum.Price,
        id: WtgIndexEnum.Price,
        explanation: 'Compete on price (previous generations)',
        value: WtgIndexEnum.Price
    },
    {
        name: WtgIndexEnum.Technology,
        id: WtgIndexEnum.Technology,
        explanation: 'Compete on technology (state of the art)',
        value: WtgIndexEnum.Technology
    }
];

export interface WtgIndexOption {
    name: string;
    id: WtgIndexEnum;
    explanation: string;
    value: string;
}
