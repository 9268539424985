import * as React from 'react';
import styles from './CasePreview.module.css';
import CSS from 'csstype';
import cx from 'classnames';
import { Case, ValueWithUnit } from '@lcoe/lcoe-client';
import { Button, Icon, Typography } from '@equinor/eds-core-react';
import { Grid } from '@material-ui/core';
import { ApplicationState } from '../redux/store';
import { asUnitObj, UnitConversion } from '../uom/UnitConversion';
import { connect } from 'react-redux';
import { round } from 'mathjs';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    setPreview: (preview: boolean) => void;
    caze: Case;
    unitConversion: UnitConversion;
}

const CasePreviewComponent = (props: IProps): JSX.Element => {
    const isManualOverride = props.caze.result?.overrideResultSwitch;

    const result = props.caze.result;
    const capex = isManualOverride ? props.caze.result?.overrideHierarchy?.capex : result?.hierarchy?.capex;
    const opex = isManualOverride ? props.caze.result?.overrideHierarchy?.opex : result?.hierarchy?.opex;
    const production = isManualOverride
        ? props.caze.result?.overrideHierarchy?.production
        : result?.hierarchy?.production;

    const renderGrid = (
        styles_level: string,
        name: string,
        costValue: ValueWithUnit,
        coeValue: ValueWithUnit
    ): JSX.Element => {
        return (
            <>
                <Grid className={styles_level} item sm={6}>
                    {name}
                </Grid>
                <Grid item sm={3} className={styles.number_column}>
                    {props.unitConversion.convertUnitToString(asUnitObj(costValue))}
                </Grid>
                <Grid item sm={3} className={styles.number_column}>
                    {props.unitConversion.convertUnitToString(asUnitObj(coeValue))}
                </Grid>
            </>
        );
    };

    const renderMetaData = (title: string, value: string): JSX.Element => {
        return (
            <>
                <Grid className={styles.meta} item sm={3}>
                    {title}
                </Grid>
                <Grid className={styles.metavalue} item sm={3}>
                    {value}
                </Grid>
            </>
        );
    };

    return (
        <div className={cx(styles.CasePreview, props.className)} style={props.style}>
            <div className={styles.header}>
                <div>
                    <Typography variant={'body_short_bold'}>
                        {props.caze.name} {' - '}
                        {!isManualOverride &&
                            result?.hierarchy &&
                            props.unitConversion.fromMEURToCoeString(result?.hierarchy.coe.value)}
                        {isManualOverride &&
                            capex &&
                            opex &&
                            props.unitConversion.fromMEURToCoeString(capex.coe.value + opex.coe.value)}
                    </Typography>
                </div>
                <div>
                    <Button variant="ghost_icon" onClick={() => props.setPreview(false)}>
                        <Icon size={16} name="close" title={'Primary'}></Icon>
                    </Button>
                </div>
            </div>

            <Grid container className={styles.table}>
                <Grid className={styles.leveloverrideheader} item sm={6}>
                    {isManualOverride && '(NB! Result is from Manual override)'}
                </Grid>
                <Grid item sm={3} className={styles.column_header}>
                    Cost
                </Grid>
                <Grid item sm={3} className={styles.column_header}>
                    COE
                </Grid>

                {/*CAPEX*/}
                {capex && (
                    <>
                        <Grid className={styles.leveldivider} item sm={12}></Grid>
                        {renderGrid(styles.level1, capex?.name, capex?.cost, capex?.coe)}
                    </>
                )}

                {capex?.children &&
                    capex.children.map((element) => (
                        <>
                            {renderGrid(styles.level2, element.name, element.cost, element.coe)}

                            {!isManualOverride &&
                                element.children &&
                                element.children.map((child) =>
                                    renderGrid(styles.level3, child.name, child.cost, child.coe)
                                )}
                        </>
                    ))}

                {/*OPEX*/}
                <Grid className={styles.leveldivider} item sm={12}></Grid>
                {opex && renderGrid(styles.level1, opex?.name + ' / year', opex?.cost, opex?.coe)}
                {opex?.children &&
                    opex.children.map((element) => renderGrid(styles.level2, element.name, element.cost, element.coe))}

                {/*NET ANNUAL PRODUCTION*/}
                <Grid className={styles.leveldivider} item sm={12}></Grid>
                <Grid className={styles.level1} item sm={6}>
                    NET Annual Production
                </Grid>
                <Grid item sm={3} className={styles.number_column}>
                    {production && round(production.netAnnualProduction.value, 3)}
                    &nbsp;
                    {production && production.netAnnualProduction.unit}
                </Grid>
                <Grid item sm={3}></Grid>
            </Grid>
            <div className={styles.leveldividerbottom}></div>
            <Grid container className={styles.metatable}>
                {renderMetaData('Case location', props.caze?.location.name)}
                {renderMetaData(
                    'Modified by',
                    (props.caze?.updatedBy ? props.caze?.updatedBy : props.caze?.createdBy) as string
                )}
                {renderMetaData('FID', props.caze?.fid.toString())}
                {renderMetaData(
                    'Date modified',
                    (props.caze?.updatedTime
                        ? props.caze?.updatedTime?.toISOString().split('T')[0]
                        : props.caze?.createdTime?.toISOString().split('T')[0]) as string
                )}
                {renderMetaData('Park life', props.caze?.parkLifeYears.toString())}
                {renderMetaData('Substructure', props.caze?.foundation.name as string)}
                {renderMetaData('Discount rate', props.caze?.discountRatePercent.toString())}
                {renderMetaData('O&M strategy', props.caze?.onmStrategy.name as string)}

                <Grid className={styles.leveldivider} item sm={12}></Grid>

                {renderMetaData('WTG capacity', props.caze?.wtg.capacity.toString())}
                {renderMetaData(
                    'Export solution',
                    (props.caze?.exportSolution.name as string).replace(' - EXPORT', '')
                )}
                {renderMetaData('WTG count', props.caze?.wtgCount.toString())}
                {renderMetaData(
                    'Inter-array cables',
                    (props.caze?.collectionGrid.name as string).replace(' - COLLECTION GRID', '')
                )}
                {renderMetaData('WTG spacing', props.caze?.wtgSpacingNd.toString())}
                {renderMetaData('Land lease', props.caze?.landLeaseOffshoreKnok.toString() + ' kNok/y')}
                {renderMetaData('WTG rotor diameter', props.caze?.wtg.rotorDiameter.toString())}
                {renderMetaData('Grid tariffs', (props.caze?.gridTariffsKnok.toString() as string) + ' kNok/y')}

                <Grid className={styles.leveldivider} item sm={12}></Grid>

                {renderMetaData('Distance to landfall', props.caze?.location.distanceToLandfall.toString())}
                {renderMetaData('Soil type', props.caze?.location.soilType)}
                {renderMetaData('TP 50yr', props.caze?.location.tp50.toString())}
                {renderMetaData('HS 50yr', props.caze?.location.hs50.toString())}
                {renderMetaData('Mean depth', props.caze?.location.averageDepth.toString())}
            </Grid>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    unitConversion: state.unitConversion
});

export const CasePreview = connect(mapStateToProps)(CasePreviewComponent);
