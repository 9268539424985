import { CaseWithResult } from './CaseComparisonPage';

/* Filed properties explanation
 * isIncluded: A variable used to say whether or not the field is relevant to a filter string, used in the search
 *   functionality.
 * path: An array of the keys to index a case with to get to this field. If there is an array of fields,
 *   the path contains the "name" of the correct element.
 * namespace: An array of the titles of fields to get to this field. Differs from path in that inside "hierarchy",
 *   the keys are not displayed, but rather the "name" of elements, if such exists. Keys like "children" which refers to an array are also net in the namespace.
 */
export interface Field {
    hasValue: boolean;
    link?: (caze: CaseWithResult) => string;
    title: string;
    children?: Field[];
    isIncluded: boolean;
    isActive: boolean;
    path: string[];
    namespace: string[];
}

export const getActiveFieldsFlat = (fields: Field[]): Field[] => {
    const fieldArrResult = [] as Field[];
    fields.forEach((field) => {
        if (field.isActive && field.hasValue) fieldArrResult.push(field);
        if (field.children !== undefined && field.children.length !== 0) {
            fieldArrResult.push(...getActiveFieldsFlat(field.children));
        }
    });
    return fieldArrResult;
};

export const activateAllFields = (fields: Field[]): void => {
    fields.forEach((field) => {
        field.isActive = true;
        if (field.children !== undefined && field.children.length !== 0) {
            activateAllFields(field.children);
        }
    });
};

export const activateFieldsForLevel = (fields: Field[], activateLevelName: string): void => {
    deactivateAllFields(fields);
    fields.forEach((field) => {
        if (field.title === activateLevelName) {
            if (field.hasValue) field.isActive = true;
            if (field.children !== undefined && field.children.length !== 0) {
                activateAllFields(field.children);
            }
        } else {
            if (field.children !== undefined && field.children.length !== 0) {
                activateFieldsForLevel(field.children, activateLevelName);
            }
        }
    });
};

export const deactivateAllFields = (fields: Field[]): void => {
    fields.forEach((field) => {
        field.isActive = false;
        if (field.children !== undefined && field.children.length !== 0) {
            deactivateAllFields(field.children);
        }
    });
};

export const mapActiveFields = (activeFields: Field[], toBeActiveFields: Field[]): void => {
    activeFields.forEach((activeField) => {
        const correspondingField = toBeActiveFields.find((fieldTest) => fieldTest.title === activeField.title);
        if (correspondingField === undefined) return;

        // Set the corresponding to active if the activeField is active
        correspondingField.isActive = activeField.isActive;

        if (correspondingField.children !== undefined && activeField.children !== undefined) {
            mapActiveFields(activeField.children, correspondingField.children);
        }
    });
};

const includeAllChildren = (field: Field): void => {
    if (field.children !== undefined) {
        field.children.forEach((field) => {
            field.isIncluded = true;
            includeAllChildren(field);
        });
    }
};

export const setIsIncluded = (hierarchy: Field[], filter: string): boolean => {
    let hasIncluded = false;

    hierarchy.forEach((field) => {
        if (field.children !== undefined && field.children.length !== 0) {
            const hasIncludedChildren = setIsIncluded(field.children, filter);
            if (hasIncludedChildren) {
                field.isIncluded = true;
                hasIncluded = true;
            } else {
                field.isIncluded = false;
            }
        } else {
            field.isIncluded = false;
        }

        if (
            field.title.toLowerCase().includes(filter.toLowerCase()) ||
            field.path.find((pathKey) => pathKey.toLowerCase().includes(filter.toLowerCase())) !== undefined ||
            field.namespace.find((name) => name.toLowerCase().includes(filter.toLowerCase())) !== undefined
        ) {
            field.isIncluded = true;
            hasIncluded = true;
            includeAllChildren(field);
        }
    });

    return hasIncluded;
};

export const setActiveParentsForActiveFields = (fields: Field[]): boolean => {
    let oneIsActive = false;

    fields.forEach((field) => {
        if (field.children === undefined) {
            if (field.hasValue && field.isActive) {
                oneIsActive = true;
            }
        } else {
            const hasActiveChildren = setActiveParentsForActiveFields(field.children);
            if (hasActiveChildren) {
                field.isActive = true;
                oneIsActive = true;
            } else if (!field.hasValue) {
                // Ensure that fields without values are not active when no child is active.
                field.isActive = false;
            } else if (field.isActive) {
                // Has no active children but has a value
                oneIsActive = true;
            }
        }
    });

    return oneIsActive;
};
