import { useMap } from 'react-leaflet';
import * as esri from 'esri-leaflet';
import * as L from 'leaflet';
import { useEffect, useState } from 'react';
import { layersEnum, MapState } from '../redux/store/map/types';
import { useDispatch } from 'react-redux';
import { setMapState } from '../redux/store/map/action';
import { validateOnVpn } from '../utils/map/validateOnVpn';

export const url = [
    'https://gismapserver.statoil.net/arcgis/rest/services/Boundaries/WindfarmBoundaries_Offshore_4COffshore/MapServer',
    'https://ws629.statoil.net:6443/arcgis/rest/services/NES_GlobalWind/Technical_Potential_pro/MapServer'
];
const windfarm = esri.dynamicMapLayer({ url: url[0] });
const wavesMaxHs = esri.dynamicMapLayer({ url: url[1], layers: [26] });
const wavesMeanHs = esri.dynamicMapLayer({ url: url[1], layers: [27] });
const bathymetry = esri.dynamicMapLayer({ url: url[1], layers: [28] });
const avgWind100m = esri.dynamicMapLayer({ url: url[1], layers: [21, 20] });

export const MultiLayerWithLabels = (): null => {
    const promises = validateOnVpn(url);
    const map = useMap();
    const dispatch = useDispatch();
    const [onVpn, setOnVpn] = useState<boolean>(false);
    const [activeLayers, setActiveLayers] = useState<MapState>([
        { name: 'windfarm', checked: false, index: layersEnum.windfarm },
        { name: 'wavesMaxHs', checked: false, index: layersEnum.wavesMaxHs },
        { name: 'wavesMeanHs', checked: false, index: layersEnum.wavesMeanHs },
        { name: 'bathymetry', checked: false, index: layersEnum.bathymetry },
        { name: 'avgWind100m', checked: false, index: layersEnum.avgWind100m }
    ]);
    Promise.all(promises)
        .then(() => {
            setOnVpn(true);
        })
        .catch(() => {
            setOnVpn(false);
        });
    useEffect(() => {
        if (onVpn) {
            const overlay = {
                ' Average Wind at 100m height ': avgWind100m,
                ' Waves - Max Hs ': wavesMaxHs,
                ' Waves - Mean Hs ': wavesMeanHs,
                ' Depth Contours, Global ': bathymetry,
                ' Wind Farm Boundaries ': windfarm
            };
            L.control.layers({}, overlay, { position: 'topleft' }).addTo(map);

            map.on('overlayadd', function (e) {
                const modifiedLayers = activeLayers;
                modifiedLayers[e.layer._leaflet_id - 1].checked = true;
                setActiveLayers(modifiedLayers);
                dispatch(setMapState(activeLayers));
            });
            map.on('overlayremove', function (e) {
                const modifiedLayers = activeLayers;
                modifiedLayers[e.layer._leaflet_id - 1].checked = false;
                setActiveLayers(modifiedLayers);
                dispatch(setMapState(activeLayers));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLayers, onVpn]);
    return null;
};
