export function spaceForCamelCases(text: string): string {
    return text
        .replace(/^./, function (str) {
            return str.toLowerCase();
        }) // lowercase the first character
        .replace(/([a-z]{2,})([A-Z])/g, '$1 $2') // Add space before any capital letter with 2 or more lowercase letters before it.
        .replace(/^./, function (str) {
            return str.toUpperCase();
        }); // uppercase the first character
}

export function emailToUsername(email: string): string {
    const before_at = email.replace(/@.*$/g, '');
    return before_at.toLowerCase();
}
