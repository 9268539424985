import {
    CalculationResult,
    Case,
    ErrorResponse,
    ErrorResponseFromJSON,
    Location as CaseLocation,
    OverrideResultStructure
} from '@lcoe/lcoe-client';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { JacketWarningDialog } from 'components/warnings/JacketWarningDialog';
import * as React from 'react';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { emptyCase } from '../Api/model/case';
import { Problems } from '../Api/Problems';
import { ApiContext } from '../components/ApiContext';
import { BeforeUnload } from '../components/BeforeUnload';
import LoadingDialog from '../components/LoadingDialog';
import { NavBar } from '../components/NavBar';
import { getNewCopy, getVersion } from '../components/utils/helpers';
import { FloatingWarningDialog } from '../components/warnings/FloatingWarningDialog';
import { convertEpaStringToYearQuarter, getLatestEpaString, updateSupportedCurrencyRates } from '../Settings/EpaUtils';
import { CalcStateView } from './CalcStateView';
import { reduceResult } from './CaseEditResultReducer';
import { CalcStates } from './edit/CalcStates';
import { CaseEditForm } from './edit/CaseEditForm';
import { ResultView } from './results/ResultView';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../redux/store';

enum WarningEnum {
    Jacket = 0,
    Floating
}

export const CaseEdit = (): JSX.Element => {
    const navigate = useNavigate();
    const params = useParams() as {
        caseId: string;
    };
    const caseId = params.caseId;
    const api = useContext(ApiContext);
    const version = getVersion();
    const newCopy = getNewCopy();

    const [resultState, resultDispatch] = useReducer(reduceResult, {
        calcState: CalcStates.NOT_STARTED,
        caze: undefined as unknown as Case
    });
    const setCaze = useCallback((caze: Case) => resultDispatch({ type: 'setCase', caze }), [resultDispatch]);

    const [changed, setChanged] = useState(false);
    const [saving, setSaving] = useState(false);

    const [options, setOptions] = useState({} as any);
    const [warnings, setWarnings] = useState<Problems | undefined>({} as Problems);
    const [visibleScrimFloating, setVisibleScrimFloating] = useState<boolean>(false);
    const [visibleScrimJacket, setVisibleScrimJacket] = useState<boolean>(false);
    const [isWarned, setIsWarned] = useState<boolean[]>([false, false]);
    const [averageDepth, setAverageDepth] = useState<number>(10000);
    const [caseName, setCaseName] = useState<string>('');

    // Calculate
    useEffect(() => {
        const caze = resultState.calculatingCase;
        if (!caze) {
            return;
        }
        api.calculate(caze)
            .then(({ result }) => {
                resultDispatch({ type: 'success', result: result as CalculationResult, caze });
            })
            .catch((err: any) => {
                if (err.json) {
                    err.json()
                        .then(ErrorResponseFromJSON)
                        .then((errorResponse: ErrorResponse) => {
                            resultDispatch({ type: 'error', error: errorResponse, caze });
                        });
                } else if (err instanceof TypeError) {
                    resultDispatch({
                        type: 'error',
                        caze,
                        error: {
                            title: err.name,
                            detail: err.stack
                        }
                    });
                } else {
                    resultDispatch({
                        type: 'error',
                        caze,
                        error: {
                            title: 'Calculation failed',
                            detail: 'This seems to be caused by connection issues. See the error popup for details.'
                        }
                    });
                }
            });
    }, [api, resultState.calculatingCase]);

    const updateCase = useCallback(
        (modification: Partial<Case>) => {
            setChanged(true);
            resultDispatch({ type: 'updateCase', modification });
        },
        [resultDispatch]
    );

    // Change case
    const onClickSave = useCallback(() => {
        if (!resultState.caze || saving) {
            return;
        }
        setSaving(true);
        let promise: Promise<Case>;
        if (caseId) {
            // Edit
            promise = api.updateCase(caseId, resultState.caze as Case);
        } else {
            // New
            promise = api.saveCase(resultState.caze as Case);
        }
        promise
            .then((savedCase) => {
                setChanged(false);
                setSaving(false);
                navigate(`/cases/${savedCase.id}`);
            })
            .catch(() => {
                setSaving(false);
            });
    }, [saving, resultState.caze, api, caseId, history, resultState.result]);

    const templateCase = useSelector((state: ApplicationState) => state.caseCopy);

    // Load case
    useEffect(() => {
        const load = async () => {
            setOptions(await api.getAllOptions());

            // Used by copy case
            if (newCopy && templateCase && !changed) {
                setCaze({
                    ...templateCase,
                    id: undefined,
                    name: `Copy of ${templateCase.name}`,
                    sharedWith: [],
                    createdBy: undefined,
                    updatedBy: undefined
                });
                setChanged(true);
                return;
            }

            let epaString;
            if (!caseId) {
                epaString = await getLatestEpaString(api.epaApi);
                setCaze(emptyCase(epaString) as Case);
            } else {
                const caze = await api.getCase({ id: caseId, version });
                epaString = caze.epaAssumptions;
                setCaze(caze);
                setChanged(false);

                const [year, quarter] = convertEpaStringToYearQuarter(epaString);
                const epa = await api.epaApi.getEpaAssumptions({ year, quarter });
                updateSupportedCurrencyRates(epa);
            }
        };
        // noinspection JSIgnoredPromiseFromCall
        load();
    }, [caseId, api, location, version, setCaze]);

    // Location outdated and WTG outdated warning
    useEffect(() => {
        let locationVersionWarning = {} as Problems;
        let WTGVersionWarning = {} as Problems;
        let collectionGridWarning = {} as Problems;

        const currentDisplayedLocation = options?.locationOptions?.filter(
            (loc: CaseLocation) => loc?.name === resultState?.caze?.location?.name
        )[0];
        const currentDisplayedWTG = options?.wtgOptions?.filter(
            (wtg: CaseLocation) => wtg?.name === resultState?.caze?.wtg?.name
        )[0];

        if (resultState?.caze?.location?.version) {
            if (resultState?.caze?.location?.version < currentDisplayedLocation?.version) {
                locationVersionWarning = {
                    location: 'An updated version of this location exists: Re-select to use latest.'
                } as Problems;
            }
        }

        if (resultState?.caze?.wtg?.version) {
            if (resultState?.caze?.wtg?.version < currentDisplayedWTG?.version) {
                WTGVersionWarning = {
                    wtg: 'An updated version of this WTG exists: Re-select to use latest.'
                } as Problems;
            }
        }
        if (resultState.caze?.collectionGrid?.name) {
            if (resultState.caze?.collectionGrid?.name === '33 kV AC - COLLECTION GRID') {
                collectionGridWarning = {
                    collectionGrid: 'The selected voltage level has been marked outdated by a cable administrator'
                } as Problems;
            }
        }

        setWarnings({
            ...WTGVersionWarning,
            ...locationVersionWarning,
            ...resultState.warnings,
            ...collectionGridWarning
        });
    }, [
        options?.locationOptions,
        resultState?.caze?.location,
        options?.wtgOptions,
        resultState?.caze?.wtg,
        resultState?.warnings,
        resultState?.caze?.collectionGrid?.name
    ]);
    //Logic for warning popups
    useEffect(() => {
        if (
            resultState.caze?.location?.averageDepth < 80 &&
            resultState.caze?.foundation?.id == 'Floating' &&
            !isWarned[WarningEnum.Floating]
        ) {
            setAverageDepth(resultState.caze.location.averageDepth);
            setCaseName(resultState.caze.name);
            setVisibleScrimFloating(true);
        }
        if (resultState.caze?.foundation?.id == 'Jacket' && !isWarned[WarningEnum.Jacket]) {
            setAverageDepth(resultState.caze.location.averageDepth);
            setCaseName(resultState.caze.name);
            setVisibleScrimJacket(true);
        }
    }, [resultState]);

    const handleToggleOverride = (toggleOverride: boolean) => {
        resultDispatch({
            type: 'success',
            result: { ...resultState.result, overrideResultSwitch: toggleOverride },
            caze: {
                ...resultState.caze,
                result: {
                    ...resultState.caze?.result,
                    overrideResultSwitch: toggleOverride
                }
            }
        });
    };

    const handleOverrideChange = (newResult: OverrideResultStructure) => {
        resultDispatch({
            type: 'success',
            result: { ...resultState.result, overrideHierarchy: newResult } as CalculationResult,
            caze: {
                ...resultState.caze,
                result: {
                    ...resultState.caze?.result,
                    overrideHierarchy: newResult
                } as CalculationResult
            }
        });
    };

    const handleClose = () => {
        if (visibleScrimFloating === true) {
            setVisibleScrimFloating(false);
            const temporary = isWarned;
            temporary[WarningEnum.Floating] = true;
            setIsWarned(temporary);
        } else {
            setVisibleScrimJacket(false);
            const temporary = isWarned;
            temporary[WarningEnum.Jacket] = true;
            setIsWarned(temporary);
        }
    };

    if (!resultState.caze) {
        return <LoadingDialog />;
    }

    return (
        <>
            <NavBar />
            <Grid container spacing={0}>
                <Grid item sm={12} md={6}>
                    <Card style={{ marginLeft: 4, marginTop: 8, marginRight: 4, marginBottom: 8 }}>
                        <CardContent>
                            <CaseEditForm
                                caseChange={updateCase}
                                caze={resultState.caze}
                                problems={resultState.problems}
                                warnings={warnings}
                                {...options}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <FloatingWarningDialog
                    visibleScrim={visibleScrimFloating}
                    handleClose={handleClose}
                    depth={averageDepth}
                    name={caseName}
                />
                <JacketWarningDialog visibleScrim={visibleScrimJacket} handleClose={handleClose} />
                <Grid item sm={12} md={6}>
                    {resultState.result && (
                        <ResultView
                            caze={resultState.caze}
                            onClickSave={onClickSave}
                            saving={saving}
                            result={resultState.result}
                            wtgCount={resultState.caze.wtgCount as number}
                            wtgCapacity={resultState.caze.wtg.capacity}
                            onToggleOverride={handleToggleOverride}
                            onOverrideChange={handleOverrideChange}
                            discountRate={resultState.caze.discountRatePercent}
                            parkLife={resultState.caze.parkLifeYears}
                        />
                    )}
                    <CalcStateView errorResponse={resultState.errorResponse} calcState={resultState.calcState} />
                </Grid>
                <BeforeUnload when={changed && !saving} />
            </Grid>
        </>
    );
};
