import { Button, Icon } from '@equinor/eds-core-react';
import { CaseVersion, CaseVersions } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import { DialogWarning } from 'components/utils/DialogWarning';
import React, { useContext, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { addCaseCompare, removeCaseCompare } from 'redux/store/caseCompare/actions';
import { CaseRef } from 'redux/store/caseCompare/types';
import styles from './SelectVersionDialog.module.css';

interface IProps {
    caseRef: CaseRef;
    className?: string;
}

export const SelectVersionDialog = (props: IProps): JSX.Element => {
    const api = useContext(ApiContext);
    const [visibleScrim, setVisibleScrim] = useState<boolean>(false);
    const [allVersions, setAllVersions] = useState<CaseVersions>([] as unknown as CaseVersions);
    const [selectedItems, setSeletedItems] = useState<boolean[]>([]);
    const [hasCheckedStore, setHasCheckedStore] = useState<boolean>(false);
    const store = useStore();
    useEffect(() => {
        api.getCaseVersions(props.caseRef.id).then((response: CaseVersions) => {
            setAllVersions(response);
        });
    }, [store]);
    useEffect(() => {
        if (allVersions.versions) {
            const tempArray: boolean[] = Array(allVersions.versions.length);
            tempArray.fill(false);
            const compareState = store.getState().caseCompare;
            allVersions.versions.map((version) => {
                compareState.some((stateElement: CaseRef) => {
                    if (stateElement.id === props.caseRef.id && stateElement.version === version.version) {
                        tempArray[allVersions.versions.length - (stateElement.version as number)] = true;
                    }
                });
                setSeletedItems(tempArray);
            });
        }
    }, [allVersions, hasCheckedStore]);

    function handleClose() {
        setVisibleScrim(false);
        setHasCheckedStore(false);
    }
    interface SelectCaseRowProps {
        caseVersion: CaseVersion;
        isSelected: boolean;
        caseId: string;
        id: number;
    }
    const SelectCaseRow = (props: SelectCaseRowProps) => {
        return (
            <div className={styles.row}>
                <div className={styles.cell}>Version: {props.caseVersion.version}</div>
                <div
                    className={styles.button}
                    onClick={() => {
                        if (!selectedItems[props.id]) {
                            store.dispatch(addCaseCompare({ id: props.caseId, version: props.caseVersion.version }));
                        } else {
                            store.dispatch(removeCaseCompare({ id: props.caseId, version: props.caseVersion.version }));
                        }
                        const temp = selectedItems;
                        temp[props.id] = selectedItems[props.id] ? false : true;
                        setSeletedItems(temp);
                    }}
                >
                    {props.isSelected ? (
                        <Icon name="shopping_card" color={'#eb0037'} size={18} />
                    ) : (
                        <Icon name="shopping_cart_add" size={18} />
                    )}
                </div>
            </div>
        );
    };

    interface VersionListProps {
        caseVersions: CaseVersions;
        selectedItems: boolean[];
        caseId: string;
    }
    const VersionList = (props: VersionListProps) => {
        return (
            <div>
                {props.caseVersions.versions.map((version, index) => {
                    return (
                        <SelectCaseRow
                            caseVersion={version}
                            isSelected={selectedItems[index]}
                            caseId={props.caseId}
                            key={index}
                            id={index}
                        ></SelectCaseRow>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            {props.caseRef && allVersions.versions && (
                <div>
                    <Button
                        onClick={() => {
                            setVisibleScrim(true);
                            setHasCheckedStore(true);
                        }}
                        variant="ghost"
                    >
                        Select Versions
                    </Button>
                    <DialogWarning
                        isDismissable={true}
                        visibleScrim={visibleScrim}
                        handleClose={handleClose}
                        Content={
                            <VersionList
                                caseId={props.caseRef.id}
                                caseVersions={allVersions}
                                selectedItems={selectedItems}
                            />
                        }
                    />
                </div>
            )}
        </>
    );
};
