/* eslint-disable */
import * as React from 'react';

function wrap(text: string) {
    if (!text.startsWith('_')) {
        return text;
    }
    const t = text.substr(1);
    return <sub key={t}>{t}</sub>;
}

export function subscript(text: string) {
    const parts = text.match(/(_[^ ]+|[^_]+)/g);
    if (!parts) {
        return text;
    }

    return <React.Fragment>{parts.map((p) => wrap(p))}</React.Fragment>;
}
