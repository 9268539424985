/* eslint-disable */
import {
    FetchParams,
    Middleware,
    RequestContext,
    ResponseContext,
    ErrorResponse,
    ErrorResponseFromJSON
} from '@lcoe/lcoe-client';

export class BearerTokenMiddleware implements Middleware {
    constructor(private getToken: () => string) {}

    async pre(context: RequestContext): Promise<FetchParams | void> {
        const token = this.getToken();
        if (token) {
            const headers: any = context.init.headers || {};
            headers.Authorization = 'Bearer ' + token;
            context.init.headers = headers;
        }
        return context;
    }
}

export class ErrorPropagationMiddleware implements Middleware {
    constructor(private callback: ErrorCallback) {}

    async post(context: ResponseContext): Promise<Response | void> {
        const response = context.response.clone();
        if (!response.ok) {
            const json = await response.json();
            const errorResponse = ErrorResponseFromJSON(json);
            this.callback({ errorResponse, response: response, request: context.init });
        }
        return context.response;
    }
}

export type ErrorCallback = (error: ErrorCallbackParameters) => void;

export interface ErrorCallbackParameters {
    response?: Response;
    request?: RequestInit;
    errorResponse: ErrorResponse;
    details?: any;
}
