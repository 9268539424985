import { Case, ValueWithUnit } from '@lcoe/lcoe-client';
import { asUnitObj, UnitConversion } from '../uom/UnitConversion';
import styles from './CaseCompareListPage.module.css';
import * as React from 'react';
import { Column } from '../Case/CaseListPage';

export const getTableHeader = (): Column[] => {
    /* eslint-disable */
    const header_columns = [
        { field: 'id', hide: true },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'version', headerName: 'Version', width: 100, renderCell: (params: any) => <a href={`/cases/${params.id}`}>{params.value}</a> },
        { field: 'country', headerName: 'Country', width: 150 },
        { field: 'updatedBy', headerName: 'Modified By', width: 150 },
        { field: 'updatedTime', headerName: 'Modified Time', width: 150 },
        { field: 'parkLifeYears', headerName: 'Park life', width: 150 },
        { field: 'wtgCount', headerName: 'WTG #', width: 150 },
        { field: 'wtgMW', headerName: 'WTG (MW)', width: 150 },
        { field: 'instcap', headerName: 'Inst. Capacity', width: 150 },
        { field: 'onmStrategy', headerName: 'O&M Strategy', width: 150 },
        { field: 'foundation', headerName: 'Foundation', width: 150 },
        { field: 'fid', headerName: 'FID', width: 150 },
        { field: 'spacing', headerName: 'Spacing', width: 150 },

        { field: 'loc_soil', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'Soil type', width: 150, align: 'right' },
        { field: 'loc_averageDepth', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'Average Depth', width: 150, align: 'right' },
        { field: 'loc_hs50', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'HS50', width: 150, align: 'right' },
        { field: 'loc_tp50', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'TP50', width: 150, align: 'right' },
        { field: 'loc_distanceToLandfall', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'Dist Landfall', width: 150, align: 'right' },
        { field: 'loc_distanceToConverterPort', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'Dist Converter Port', width: 150, align: 'right' },
        { field: 'loc_distanceToOandMPort', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'Dist O&M Port', width: 150, align: 'right' },
        { field: 'loc_distanceToTurbinePort', headerClassName: styles.loc_main, cellClassName: styles.loc_main, headerName: 'Dist Turbine Port', width: 150, align: 'right' },

        { field: 'capex_total', headerClassName: styles.capex_main, cellClassName: styles.capex_main, headerName: 'Capex total', width: 150, align: 'right' },
        { field: 'capex_predg3', headerClassName: styles.capex_main, cellClassName: styles.capex_level1, headerName: 'Pre-DG3', width: 150, align: 'right' },
        { field: 'capex_capex', headerClassName: styles.capex_main, cellClassName: styles.capex_level1, headerName: 'Capex', width: 150, align: 'right' },
        { field: 'capex_capex_wtg', headerClassName: styles.capex_main, cellClassName: styles.capex_level2, headerName: 'WTG', width: 150, align: 'right' },
        { field: 'capex_capex_sub', headerClassName: styles.capex_main, cellClassName: styles.capex_level2, headerName: 'Substructure', width: 150, align: 'right' },
        { field: 'capex_capex_infield', headerClassName: styles.capex_main, cellClassName: styles.capex_level2, headerName: 'Infield Infrastructure', width: 150, align: 'right' },
        { field: 'capex_capex_export', headerClassName: styles.capex_main, cellClassName: styles.capex_level2, headerName: 'Export Infrastructure', width: 150, align: 'right' },
        { field: 'capex_capex_marine', headerClassName: styles.capex_main, cellClassName: styles.capex_level2, headerName: 'Marine Operations', width: 150, align: 'right' },
        { field: 'capex_capex_logistics', headerClassName: styles.capex_main, cellClassName: styles.capex_level2, headerName: 'Logistics', width: 150, align: 'right' },
        { field: 'capex_capex_company', headerClassName: styles.capex_main, cellClassName: styles.capex_level2, headerName: 'Company Cost DG3-DG4', width: 150, align: 'right' },
        { field: 'capex_decom', headerClassName: styles.capex_main, cellClassName: styles.capex_level1, headerName: 'Decomissioning', width: 150, align: 'right' },

        { field: 'opex_total', headerClassName: styles.opex_main, cellClassName: styles.opex_main, headerName: 'Opex total / year', width: 150, align: 'right' },
        { field: 'opex_om', headerClassName: styles.opex_main, cellClassName: styles.opex_level1, headerName: 'O&M', width: 150, align: 'right' },
        { field: 'opex_tarifs', headerClassName: styles.opex_main, cellClassName: styles.opex_level1, headerName: 'Tarifs', width: 150, align: 'right' },
        { field: 'opex_other', headerClassName: styles.opex_main, cellClassName: styles.opex_level1, headerName: 'Other', width: 150, align: 'right' },

        { field: 'prod_gross', headerClassName: styles.prod_main, cellClassName: styles.prod_main, headerName: 'Gross Annual Production', width: 150, align: 'right' },
        { field: 'prod_net', headerClassName: styles.prod_main, cellClassName: styles.prod_main, headerName: 'Net Annual Production', width: 150, align: 'right' },
        { field: 'prod_electricalLosses', headerClassName: styles.prod_main, cellClassName: styles.prod_main, headerName: 'Electrical losses', width: 150, align: 'right' },
        { field: 'prod_otherLosses', headerClassName: styles.prod_main, cellClassName: styles.prod_main, headerName: 'Other losses', width: 150, align: 'right' },
        { field: 'prod_wakeLoss', headerClassName: styles.prod_main, cellClassName: styles.prod_main, headerName: 'Wake loss', width: 150, align: 'right' },
        { field: 'prod_totalLoss', headerClassName: styles.prod_main, cellClassName: styles.prod_main, headerName: 'Total loss', width: 150, align: 'right' },
        { field: 'prod_techAvailability', headerClassName: styles.prod_main, cellClassName: styles.prod_main, headerName: 'Technical availability', width: 150, align: 'right' },

        { field: 'coe_total', headerClassName: styles.coe_main, cellClassName: styles.coe_main, headerName: 'LCoE total', width: 150, align: 'right' },
        { field: 'coe_capex', headerClassName: styles.coe_main, cellClassName: styles.coe_level1, headerName: 'Capex total', width: 150, align: 'right' },
        { field: 'coe_capex_predg3', headerClassName: styles.coe_main, cellClassName: styles.coe_level2, headerName: 'PreDG3', width: 150, align: 'right' },
        { field: 'coe_capex_capex', headerClassName: styles.coe_main, cellClassName: styles.coe_level2, headerName: 'Capex', width: 150, align: 'right' },
        { field: 'coe_capex_capex_wtg', headerClassName: styles.coe_main, cellClassName: styles.coe_level3, headerName: 'WTG', width: 150, align: 'right' },
        { field: 'coe_capex_capex_sub', headerClassName: styles.coe_main, cellClassName: styles.coe_level3, headerName: 'Substructure', width: 150, align: 'right' },
        { field: 'coe_capex_capex_infield', headerClassName: styles.coe_main, cellClassName: styles.coe_level3, headerName: 'Infield Infrastructure', width: 150, align: 'right' },
        { field: 'coe_capex_capex_export', headerClassName: styles.coe_main, cellClassName: styles.coe_level3, headerName: 'Export Infrastructure', width: 150, align: 'right' },
        { field: 'coe_capex_capex_marine', headerClassName: styles.coe_main, cellClassName: styles.coe_level3, headerName: 'Marine Operations', width: 150, align: 'right' },
        { field: 'coe_capex_capex_logistics', headerClassName: styles.coe_main, cellClassName: styles.coe_level3, headerName: 'Logistics', width: 150, align: 'right' },
        { field: 'coe_capex_capex_company', headerClassName: styles.coe_main, cellClassName: styles.coe_level3, headerName: 'Company Cost DG3-DG4', width: 150, align: 'right' },
        { field: 'coe_capex_decom', headerClassName: styles.coe_main, cellClassName: styles.coe_level2, headerName: 'Decommissioning', width: 150, align: 'right' },
        { field: 'coe_opex', headerClassName: styles.coe_main, cellClassName: styles.coe_level1, headerName: 'Opex COE', width: 150, align: 'right' },
        { field: 'coe_opex_om', headerClassName: styles.coe_main, cellClassName: styles.coe_level2, headerName: 'O&M', width: 150, align: 'right' },
        { field: 'coe_opex_tarifs', headerClassName: styles.coe_main, cellClassName: styles.coe_level2, headerName: 'Tarifs', width: 150, align: 'right' },
        { field: 'coe_opex_other', headerClassName: styles.coe_main, cellClassName: styles.coe_level2, headerName: 'Other', width: 150, align: 'right' },

        { field: 'bop_export_offshore', headerClassName: styles.bop_main, cellClassName: styles.bop_main, headerName: 'Export Cables Offshore Cross Section', width: 150, align: 'right' },
        { field: 'bop_export_current', headerClassName: styles.bop_main, cellClassName: styles.bop_main, headerName: 'Export Cables Offshore Current Rating', width: 150, align: 'right' },
        { field: 'jacket_secondary_steel_quantity', headerClassName: styles.bop_main, cellClassName: styles.bop_main, headerName: 'Jacket Secondary Steel Quantity', width: 150, align: 'right' },

    ];
    /* eslint-enable */

    return header_columns;
};

export const getTableData = (cases: Case[], uc: UnitConversion): string => {
    const show = (value: ValueWithUnit) => {
        return uc.convertUnitToString(asUnitObj(value));
    };

    const showTime = (date: Date) => {
        return date.toISOString().split('T')[0];
    };

    const showPercent = (value: number) => {
        return (value * 100).toFixed(2) + ' %';
    };
    // eslint-disable-next-line
    const datarows = [] as any;

    cases.forEach((element) => {
        /* eslint-disable */
        if (element.result) {
            const result = element.result;
            const isManualOverride = result?.overrideResultSwitch;
            const capex = !isManualOverride ? result?.hierarchy?.capex : result?.overrideHierarchy?.capex;
            const opex = !isManualOverride ? result?.hierarchy?.opex : result?.overrideHierarchy?.opex;
            const prod = !isManualOverride ? result?.hierarchy?.production : result?.overrideHierarchy?.production;

            const bop = result?.hierarchy?.bop;
            const substructure = capex?.children && capex?.children[1] && capex?.children[1].children && capex?.children[1]?.children[1];
            const substructure_type = substructure && substructure?.children && substructure?.children[0];
            const jacket_secondary_steel = substructure_type?.name == 'Jacket' ? substructure_type && substructure_type.children && substructure_type.children[0] : '';
            const jacket_secondary_steel_quantity = jacket_secondary_steel && jacket_secondary_steel.quantity && jacket_secondary_steel.quantity.toFixed(0);

            const totalCapexCoeManual = isManualOverride && capex?.coe && opex?.coe && capex?.coe.value + opex?.coe.value;

            datarows.push({
                id: ((element.id as string) + '?version=' + element.version) as string,
                name: element.name,
                version: element.version,
                country: element.location.country,
                updatedTime: element.updatedTime ? showTime(element.updatedTime) : element.createdTime && showTime(element.createdTime),
                updatedBy: element.updatedBy ? element.updatedBy : element.createdBy,
                parkLifeYears: element.parkLifeYears,
                wtgCount: element.wtgCount,
                wtgMW: element.wtg.capacity,
                instcap: element.wtg.capacity * element.wtgCount,
                onmStrategy: element.onmStrategy.name,
                foundation: element.foundation.name,
                fid: element.fid,
                spacing: element.wtgSpacingNd,

                loc_soil: element.location.soilType,
                loc_averageDepth: element.location.averageDepth,
                loc_hs50: element.location.hs50.toFixed(2),
                loc_tp50: element.location.tp50.toFixed(2),
                loc_distanceToLandfall: element.location.distanceToLandfall,
                loc_distanceToConverterPort: element.location.distanceToConverterPort,
                loc_distanceToOandMPort: element.location.distanceToOandMPort,
                loc_distanceToTurbinePort: element.location.distanceToTurbinePort,

                capex_total: capex?.cost && (isManualOverride ? '(manual) ': '') + show(capex?.cost),
                capex_predg3: capex?.children && capex?.children[0] && (isManualOverride ? '(manual) ': '') + show(capex?.children[0].cost),
                capex_capex: capex?.children && capex?.children[1] && (isManualOverride ? '(manual) ': '') + show(capex?.children[1].cost),
                capex_capex_wtg: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[0].cost),
                capex_capex_sub: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[1].cost),
                capex_capex_infield: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[2].cost),
                capex_capex_export: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[3].cost),
                capex_capex_marine: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[4].cost),
                capex_capex_logistics: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[5].cost),
                capex_capex_company: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[6].cost),
                capex_decom: capex?.children && capex?.children[2] && capex?.children[2].name == 'Decommissioning' && (isManualOverride ? '(manual) ': '') + show(capex?.children[2].cost),

                opex_total: opex?.cost && (isManualOverride ? '(manual) ': '') + show(opex?.cost),
                opex_om: opex?.children && opex?.children[0] && show(opex?.children[0].cost),
                opex_tarifs: opex?.children && opex?.children[1] && show(opex?.children[1].cost),
                opex_other: opex?.children && opex?.children[2] && show(opex?.children[2].cost),

                prod_gross: isManualOverride ? '' : prod?.grossAnnualProduction && show(prod?.grossAnnualProduction),
                prod_net: prod?.netAnnualProduction && (isManualOverride ? '(manual) ': '') + show(prod?.netAnnualProduction),
                prod_electricalLosses: isManualOverride ? '' : prod?.electricalLossesRatio && showPercent(prod?.electricalLossesRatio),
                prod_otherLosses: isManualOverride ? '' : prod?.otherLossesRatio && showPercent(prod?.otherLossesRatio),
                prod_wakeLoss: isManualOverride ? '' : prod?.wakeLossRatio && showPercent(prod?.wakeLossRatio),
                prod_totalLoss: isManualOverride ? '' : prod?.totalLossesRatio && showPercent(prod?.totalLossesRatio),
                prod_techAvailability: isManualOverride ? '' : prod?.technicalAvailabilityRatio && showPercent(prod?.technicalAvailabilityRatio),

                coe_total: isManualOverride ? totalCapexCoeManual && (isManualOverride ? '(manual) ': '') + show({value:totalCapexCoeManual, unit:'kEUR/GWh'}) : result?.hierarchy && show(result?.hierarchy?.coe),
                coe_capex: capex?.coe && (isManualOverride ? '(manual) ': '') + show(capex?.coe),
                coe_capex_predg3: capex?.children && capex?.children[0] && (isManualOverride ? '(manual) ': '') + show(capex?.children[0].coe),
                coe_capex_capex: capex?.children && capex?.children[1] && (isManualOverride ? '(manual) ': '') + show(capex?.children[1].coe),
                coe_capex_capex_wtg: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[0].coe),
                coe_capex_capex_sub: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[1].coe),
                coe_capex_capex_infield: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[2].coe),
                coe_capex_capex_export: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[3].coe),
                coe_capex_capex_marine: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[4].coe),
                coe_capex_capex_logistics: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[5].coe),
                coe_capex_capex_company: isManualOverride ? '' : capex?.children && capex?.children[1] && capex?.children[1].children && show(capex?.children[1].children[6].coe),
                coe_capex_decom: capex?.children && capex?.children[2] && capex?.children[2].name == 'Decommissioning' && (isManualOverride ? '(manual) ': '') + show(capex?.children[2].coe),
                coe_opex: opex?.coe && (isManualOverride ? '(manual) ': '') + show(opex?.coe),
                coe_opex_om:  opex?.children && opex?.children[0] && show(opex?.children[0].coe),
                coe_opex_tarifs: opex?.children && opex?.children[1] && show(opex?.children[1].coe),
                coe_opex_other: opex?.children && opex?.children[2] && show(opex?.children[2].coe),

                bop_export_offshore: bop?.exportCables.offshoreCable.crossSection.value + ' ' + bop?.exportCables.offshoreCable.crossSection.unit,
                bop_export_current: bop?.exportCables.offshoreCable.currentRating.value?.toFixed(0) + ' ' + bop?.exportCables.offshoreCable.currentRating.unit,
                jacket_secondary_steel_quantity: jacket_secondary_steel_quantity,
            });
        }
        /* eslint-enable */
    });

    return datarows;
};
