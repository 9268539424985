import { Turbine } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import LoadingDialog from 'components/LoadingDialog';
import { TurbineDetails } from 'components/turbine/TurbineDetails';
import { TurbineWithId } from 'interface/ITurbine';
import { default as React, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './TurbineDetailPage.module.css';
import { NavBar } from '../../components/NavBar';

export const TurbineDetailPage = (): JSX.Element => {
    const params = useParams() as {
        turbineId: string;
    };
    const turbineId = params.turbineId;
    const api = useContext(ApiContext);
    const navigate = useNavigate();

    const [turbine, setTurbine] = useState<Turbine>(undefined as unknown as Turbine);

    useEffect(() => {
        api.getTurbine(turbineId).then((turbine) => setTurbine(turbine));
    }, [turbineId, api]);

    const deleteTurbine = useCallback(() => {
        api.deleteTurbine(turbineId).then(() => navigate('/turbines'));
    }, [turbineId, navigate, api]);

    if (!turbine) {
        return <LoadingDialog />;
    } else {
        return (
            <>
                <NavBar />
                <div className={styles.TurbineDetailPage}>
                    <TurbineDetails deleteTurbine={deleteTurbine} downloadedTurbine={turbine as TurbineWithId} />
                </div>
            </>
        );
    }
};
