import { ApplicationState } from 'redux/store';
import {
    LoginState,
    Role,
    RoleCableAdmin,
    RoleCostEstimator,
    RoleFoundationAdmin,
    RoleLocationAdmin,
    RoleWtgAdmin,
    UserState
} from 'redux/store/user/types';

export const isUserTurbineAdmin = (user: UserState): boolean => {
    return userHasRole(user, RoleWtgAdmin);
};

export const isUserCableAdmin = (user: UserState): boolean => {
    return userHasRole(user, RoleCableAdmin);
};

export const isUserFoundationAdmin = (user: UserState): boolean => {
    return userHasRole(user, RoleFoundationAdmin);
};

export const isUserLocationAdmin = (state: ApplicationState): boolean => {
    return userHasRole(state.user, RoleLocationAdmin);
};

export const isUserCostEstimator = (user: UserState): boolean => {
    return userHasRole(user, RoleCostEstimator);
};

const userHasRole = (user: UserState, role: Role): boolean => {
    return (
        user.loginState === LoginState.SUCCESS &&
        user.roles &&
        user.roles.includes &&
        typeof user.roles.includes === 'function' &&
        user.roles.includes(role)
    );
};
