import React, { ChangeEvent, useCallback } from 'react';
import { FormSection } from '../components/forms/FormSection';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { extractValue } from '../components/utils/helpers';
import { SimpleChipList } from '../components/forms/SimpleChipList';
import * as PropTypes from 'prop-types';
import { SimpleCommentsField } from '../components/forms/SimpleCommentsField';
import { SoilTypeEnum } from '@lcoe/lcoe-client';
import { locationValidationModel, SwaggerSchema } from '../Api/model/SwaggerSpec';
import { SchemaField } from '../components/forms/SchemaField';
import { SimpleTagsField } from '../components/SimpleTagsField';
import { Location, Position } from '@lcoe/lcoe-client/src/models';
import { Problems } from '../Api/Problems';

const soilTypeOptions = Object.keys(SoilTypeEnum).map((key: string) => SoilTypeEnum[key as keyof typeof SoilTypeEnum]);

interface IProps {
    location: Location;
    locationChange: (diff: Partial<Location>) => void;
    problems: Problems;
    localEdit?: boolean;
}

export const LocationEditForm = ({
    location,
    locationChange,
    problems = {},
    localEdit = false
}: IProps): JSX.Element => {
    const dateChange = useCallback(
        (name, value) => {
            locationChange({ [name]: value });
        },
        [locationChange]
    );
    const handleChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            const name = evt.target.name;
            const value = extractValue(evt.target);
            locationChange({ [name]: value });
        },
        [locationChange]
    );

    const positionChange = useCallback(
        (diff) => {
            const position = { ...location.position, ...diff };
            locationChange({ position });
        },
        [locationChange, location.position]
    );

    const schemaProps = {
        schema: locationValidationModel.schema,
        object: location,
        onChange: locationChange,
        problems
    };

    const positionSchemaProps = {
        schema: locationValidationModel?.schema?.properties?.position,
        object: location.position,
        onChange: positionChange,
        problems: problems && problems.position
    };

    interface positionSchemaPropsInterface {
        schema: SwaggerSchema;
        object: Position;
        onChange: (diff: Partial<Location>) => void;
        problems: Problems;
    }
    const weatherFileOptions = location.files === undefined ? undefined : [undefined, ...location.files];
    return (
        <form autoComplete="off">
            <FormSection legend="Location">
                <SchemaField {...schemaProps} name="name" disabled={localEdit} />
                <SchemaField {...schemaProps} name="wfid" disabled={localEdit} />
                <SchemaField {...schemaProps} name="soilType" options={soilTypeOptions} />
                <SchemaField {...schemaProps} name="country" />
                <SchemaField {...schemaProps} name="averageDepth" />
                <SchemaField {...schemaProps} name="concessionArea" />
                <SchemaField {...schemaProps} name="concessionCapacity" />
                <SchemaField
                    {...schemaProps}
                    name="defaultWeatherFile"
                    options={weatherFileOptions}
                    disabled={localEdit || !location.files || location.files.length === 0}
                />
                <SchemaField {...schemaProps} name="hs50" />
                <SchemaField {...schemaProps} name="tp50" />
                <SchemaField {...schemaProps} name="airDensityMean" />
            </FormSection>

            <FormSection legend="Position">
                <SchemaField
                    name="lat"
                    {...(positionSchemaProps as positionSchemaPropsInterface)}
                    disabled={localEdit}
                />
                <SchemaField
                    name="lon"
                    {...(positionSchemaProps as positionSchemaPropsInterface)}
                    disabled={localEdit}
                />
            </FormSection>
            <FormSection legend="Distances">
                <SchemaField {...schemaProps} name="distanceToLandfall" />
                <SchemaField {...schemaProps} name="distanceToGridConnectionFromLandfall" />
                {/* <SchemaField {...schemaProps} name="distanceToSubstationPort" />
                <SchemaField {...schemaProps} name="distanceToConverterPort" /> */}
                <SchemaField {...schemaProps} name="distanceToCablePort" />
                <SchemaField {...schemaProps} name="distanceToOandMPort" />
                <SchemaField {...schemaProps} name="distanceToSubstructurePort" />
                <SchemaField {...schemaProps} name="distanceToTurbinePort" />
                <SchemaField {...schemaProps} name="distanceToConverterStationOffshore" />
            </FormSection>

            <FormSection legend="More numbers">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <React.Fragment>
                        <DateField
                            label="Start installation season"
                            name="startInstallationSeason"
                            value={location.startInstallationSeason}
                            problem={problems.startInstallationSeason}
                            onChange={useCallback((v) => dateChange('startInstallationSeason', v), [dateChange])}
                        />
                        <DateField
                            label="End installation season"
                            name="endInstallationSeason"
                            value={location.endInstallationSeason}
                            problem={problems.endInstallationSeason}
                            onChange={useCallback((v) => dateChange('endInstallationSeason', v), [dateChange])}
                        />
                    </React.Fragment>
                </MuiPickersUtilsProvider>

                <SchemaField {...schemaProps} name="gridCostMW" />
                <SchemaField {...schemaProps} name="gridCostMWh" />
                <SchemaField {...schemaProps} name="feeOffshoreLandleaseMWh" />
            </FormSection>

            <FormSection legend="Metadata">
                <SimpleTagsField onChange={locationChange} value={location.tags as string[]} />
                <SimpleCommentsField name="comments" onChange={handleChange} value={location.comments} />
            </FormSection>
            {!localEdit && (
                <FormSection legend="Access control">
                    <SimpleChipList
                        label="Shared with"
                        helperText="Username or 'all'"
                        selectedItems={location.sharedWith || []}
                        onSelectedItemsChange={(items: string[]) => locationChange({ sharedWith: items })}
                    />
                </FormSection>
            )}
        </form>
    );
};

LocationEditForm.propTypes = {
    location: PropTypes.object.isRequired,
    locationChange: PropTypes.func.isRequired,
    localEdit: PropTypes.bool,
    problems: PropTypes.object
};

interface DateFieldInterface {
    name: string;
    onChange: (v: Date | null) => void;
    value: Date;
    label: string;
    sm?: boolean | 1 | 'auto' | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
    problem: Problems | string;
    helperText?: string;
}

const DateField = ({ label, name, value, onChange, sm, helperText, problem }: DateFieldInterface): JSX.Element => {
    return (
        <Grid item xs={12} sm={sm}>
            <DatePicker
                name={name}
                onChange={onChange}
                value={value}
                minDate="2000-01-01"
                maxDate="2000-12-31"
                fullWidth
                label={label}
                required
                helperText={helperText || problem}
                error={!!problem}
                autoOk
            />
        </Grid>
    );
};
