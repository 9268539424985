import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, Typography } from '@equinor/eds-core-react';
import styles from './WeatherFileDownload.module.css';
import { WeatherSources } from '@lcoe/lcoe-client';
import { ApiContext } from '../components/ApiContext';

interface IProps {
    visibleDialog: boolean;
    validSystems?: WeatherSources;
    setVisibleFetchDialog: (status: boolean) => void;
    fetchWeather: (atmosSystem: string, atmosSystemId: number) => void;
    locationId: string;
}

export const WeatherFileDownload = (props: IProps): JSX.Element => {
    const [validSystems, setValidSystems] = useState({} as WeatherSources);

    const api = useContext(ApiContext);

    useEffect(() => {
        api.fetchValidSystems(props.locationId).then((response: WeatherSources) => {
            setValidSystems(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    return (
        validSystems && (
            <WeatherFileDownloadDialog
                visibleDialog={props.visibleDialog}
                validSystems={validSystems}
                setVisibleFetchDialog={props.setVisibleFetchDialog}
                fetchWeather={props.fetchWeather}
                locationId={props.locationId}
            />
        )
    );
};

const WeatherFileDownloadDialog = (props: IProps): JSX.Element => {
    const fetchWeather = (atmosSystem: string, atmosSystemId: number) => {
        props.fetchWeather(atmosSystem, atmosSystemId);
        props.setVisibleFetchDialog(false);
    };

    return (
        <Dialog open={props.visibleDialog} style={{ width: 400, marginTop: 50 }} isDismissable>
            <Dialog.Header>
                <Dialog.Title>
                    <Typography variant={'h3'}>Get weather data</Typography>
                </Dialog.Title>
            </Dialog.Header>
            <Dialog.CustomContent scrollable style={{ height: 300 }}>
                <Typography>Select from the following sources available at the current location.</Typography>
                <div className={styles.div_table}>
                    {!props.validSystems?.weatherSources && (
                        <>
                            <br />
                            <CircularProgress size={48} color="primary" value={0} />
                        </>
                    )}
                    {props.validSystems?.weatherSources &&
                        props.validSystems.weatherSources.map((val, index) => (
                            <div key={index} className={styles.div_button}>
                                <Button
                                    onClick={() => fetchWeather(val.sourceName, val.sourceID)}
                                    variant="outlined"
                                    className={styles.button}
                                >
                                    {val.sourceName == 'SK_continous'
                                        ? 'Korea (SK_continous)'
                                        : val.sourceName == 'SK_Typhoon_merge'
                                        ? 'Korea (SK_Typhoon_merge)'
                                        : val.sourceName}
                                </Button>
                            </div>
                        ))}
                </div>
                <Typography>
                    <b>NB!</b> Some datasets are access restricted and access needs to be requested from the Atmos team.
                </Typography>
            </Dialog.CustomContent>
            <Dialog.Actions>
                <Button onClick={() => props.setVisibleFetchDialog(false)} color="primary">
                    Cancel
                </Button>
            </Dialog.Actions>
        </Dialog>
    );
};
