import { CaseWithResult } from './CaseComparisonPage';
import { Field } from './fields';
import { getFieldsFromHierarchy } from './fieldsFromHierarchy';

const getUnionOfFieldsArr = (fieldsArr1: Field[], fieldsArr2: Field[]): Field[] => {
    fieldsArr1.forEach((field) => {
        const correspondingField = fieldsArr2.find((fieldTest) => fieldTest.title === field.title);
        if (correspondingField === undefined) return;

        // This field in arr1 has a similar field in arr2. Paths should be the same, but the children might not be.
        if (correspondingField.children === undefined) return;
        if (field.children === undefined) {
            field.children = correspondingField.children;
        } else {
            field.children = getUnionOfFieldsArr(field.children, correspondingField.children);
        }
    });

    return fieldsArr1.concat(
        fieldsArr2.filter(
            (fieldInArr2) => !fieldsArr1.map((fieldInArr1) => fieldInArr1.title).includes(fieldInArr2.title)
        )
    );
};
export const getUnionOfFieldsArrArr = (fieldsArrArr: Field[][]): Field[] => {
    if (fieldsArrArr.length === 0) return [];
    return fieldsArrArr.slice(1).reduce((curr, fieldArr) => {
        return getUnionOfFieldsArr(curr, fieldArr);
    }, fieldsArrArr[0]);
};
export const getFieldsFromCaseWithResult = (caze: CaseWithResult): Field[] => {
    const fieldArray = [] as Field[];
    for (const [key, value] of Object.entries(caze)) {
        const fieldsToPush = getFieldsFromHierarchy(value, [key], []);
        if (fieldsToPush !== undefined && fieldsToPush[0] !== undefined) {
            const fieldToPush = fieldsToPush[0];
            fieldArray.push(fieldToPush);
        }
    }
    activateByName(fieldArray, 'location.name');
    activateByName(fieldArray, 'wtg.name');
    activateByName(fieldArray, 'wtgCount');
    activateByName(fieldArray, 'result.hierarchy.Total CAPEX.cost');
    activateByName(fieldArray, 'result.hierarchy.Total OPEX.cost');
    activateByName(fieldArray, 'result.hierarchy.decommissioning.total');
    activateByName(fieldArray, 'result.hierarchy.production.netAnnualProduction');
    activateByName(fieldArray, 'result.hierarchy.production.grossAnnualProduction');
    // activateByName(fieldArray, 'result.hierarchy.production.wakeLossRatio');
    // activateByName(fieldArray, 'result.hierarchy.production.electricalLossesRatio');
    // activateByName(fieldArray, 'result.hierarchy.production.technicalAvailabilityRatio');
    // activateByName(fieldArray, 'result.hierarchy.production.otherLossesRatio');
    // activateByName(fieldArray, 'result.hierarchy.production.totalLossesRatio');

    return fieldArray;
};

const activateByName = (fieldArray: Field[], fieldTitle: string) => {
    const title = fieldTitle.split('.')[0];
    fieldArray.forEach((field: Field) => {
        if (field.title === title) {
            if (field.children !== undefined) {
                activateByName(field.children, fieldTitle.replace(`${title}.`, ''));
            } else {
                field.isActive = true;
            }
        }
    });
};
