import { Case } from '@lcoe/lcoe-client';
import React, { useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import LoadingDialog from '../components/LoadingDialog';
import { UnitConversion } from '../uom/UnitConversion';
import styles from './ComparisonChart.module.css';
import {
    convertToPerMW,
    extractCoe,
    extractCost,
    extractDetailedCost,
    extractDetailedCostWaterfall,
    extractSublevelCost,
    multiplyOpex
} from './ComparisonChartData';

type plotDataType = string | number;

interface IProps {
    comparisonLevel: number;
    uc: UnitConversion;
    cases: Case[];
    case1?: string;
    case2?: string;
    isPerMW?: boolean;
}

export const ComparisonChart = (props: IProps): JSX.Element => {
    const [plotTitle, setPlotTitle] = useState<string>('');
    const [yAxisUnit, setYAxisUnit] = useState<string>('M' + props.uc.unitSystem.money);
    const [plot, setPlot] = useState<plotDataType[][]>([['Temp', 'Temp']]);
    const [waterFall, setWaterFall] = useState<boolean>(false);
    useEffect(() => {
        if (props.isPerMW) {
            setYAxisUnit('M' + props.uc.unitSystem.money + '/MW');
            if (props.comparisonLevel === 1) {
                const results = multiplyOpex(props.cases, props.uc);
                setPlot(convertToPerMW(extractCost(results, props.cases, props.uc), props.cases));
                setPlotTitle('Capex and Opex comparison');
            } else if (props.comparisonLevel === 3) {
                setPlot(convertToPerMW(extractSublevelCost(props.cases, 'Capex', props.uc), props.cases));
                setPlotTitle('Capex breakdown');
            } else if (props.comparisonLevel === 4) {
                setPlot(convertToPerMW(extractSublevelCost(props.cases, 'Tariffs', props.uc), props.cases));
                setPlotTitle('Opex Tariff breakdown');
            } else if (props.comparisonLevel === 5) {
                setPlot(convertToPerMW(extractSublevelCost(props.cases, 'Other', props.uc), props.cases));
                setPlotTitle('Opex Other breakdown');
            } else if (props.comparisonLevel === 6) {
                setPlot(
                    convertToPerMW(
                        extractDetailedCostWaterfall(props.cases, props.uc, props.case1, props.case2),
                        props.cases
                    )
                );
                setPlotTitle('Waterfall cost');
                setWaterFall(true);
            } else {
                setPlot(convertToPerMW(extractDetailedCost(props.cases, props.uc), props.cases));
                setPlotTitle('Capex and Opex detailed comparison');
            }
        } else {
            setYAxisUnit('M' + props.uc.unitSystem.money);
            if (props.comparisonLevel === 1) {
                const results = multiplyOpex(props.cases, props.uc);
                setPlot(extractCost(results, props.cases, props.uc));
                setPlotTitle('Capex and Opex comparison');
            } else if (props.comparisonLevel === 3) {
                setPlot(extractSublevelCost(props.cases, 'Capex', props.uc));
                setPlotTitle('Capex breakdown');
            } else if (props.comparisonLevel === 4) {
                setPlot(extractSublevelCost(props.cases, 'Tariffs', props.uc));
                setPlotTitle('Opex Tariff breakdown');
            } else if (props.comparisonLevel === 5) {
                setPlot(extractSublevelCost(props.cases, 'Other', props.uc));
                setPlotTitle('Opex Other breakdown');
            } else if (props.comparisonLevel === 6) {
                setPlot(extractDetailedCostWaterfall(props.cases, props.uc, props.case1, props.case2));
                setPlotTitle('Waterfall cost');
                setWaterFall(true);
            } else if (props.comparisonLevel === 7) {
                setPlot(extractCoe(props.cases, props.uc));
                setPlotTitle('LCoE');
                setYAxisUnit(props.uc.unitSystem.energyCost);
            } else {
                setPlot(extractDetailedCost(props.cases, props.uc));
                setPlotTitle('Capex and Opex detailed comparison');
            }
        }
    }, [props]);
    return (
        <>
            {plot.length > 1 && waterFall && (
                <Chart
                    className={styles.chart}
                    chartType="CandlestickChart"
                    loader={<div>Loading Chart</div>}
                    rows={plot}
                    columns={[
                        { type: 'string', label: 'Cost item' },
                        { type: 'number', label: 'Low' },
                        {
                            type: 'number',
                            label: 'Low'
                        },
                        { type: 'number', label: 'High' },
                        { type: 'number', label: 'High' },
                        //because use of enum does not work in the library, should have been:
                        // export declare type GoogleDataTableColumnRoleType = "annotation" | "annotationText" | "certainty" | "emphasis" | "interval" | "scope" | "style" | "tooltip" | "domain";
                        // eslint-disable-next-line
                        //@ts-ignore:
                        { role: 'tooltip', type: 'string' }
                    ]}
                    options={{
                        title: plotTitle,
                        chartArea: { left: 100, bottom: 80, top: 100, right: 10, width: '100%', height: '100%' },
                        isStacked: false,
                        hAxis: {
                            title: 'Cost Element',
                            minValue: 0
                        },
                        candlestick: {
                            fallingColor: { strokeWidth: 0, fill: '#a52714' },
                            risingColor: { strokeWidth: 0, fill: '#0f9d58' }
                        },
                        legend: 'none',
                        vAxis: {
                            title: 'M' + props.uc.unitSystem.money
                        }
                    }}
                />
            )}
            {plot.length > 1 && !waterFall && (
                <Chart
                    className={styles.chart}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={plot}
                    options={{
                        title: plotTitle,
                        chartArea: { left: 100, bottom: 80, top: 100, right: 300, width: '100%', height: '100%' },
                        isStacked: false,
                        hAxis: {
                            title: 'Cost Element',
                            minValue: 0
                        },
                        vAxis: {
                            title: yAxisUnit
                        }
                    }}
                    legendToggle
                />
            )}
            {plot.length <= 1 && <LoadingDialog />}
        </>
    );
};
