import { layersEnum, MapState, SET_MAP_STATE, SetMapStateAction } from './types';

export const defaultInitialState = [
    { name: 'windfarm', checked: false, index: layersEnum.windfarm },
    { name: 'wavesMaxHs', checked: false, index: layersEnum.wavesMaxHs },
    { name: 'wavesMeanHs', checked: false, index: layersEnum.wavesMeanHs },
    { name: 'bathymetry', checked: false, index: layersEnum.bathymetry },
    { name: 'avgWind100m', checked: false, index: layersEnum.avgWind100m }
];

export function mapReducer(mapState: MapState = defaultInitialState, action: SetMapStateAction): MapState {
    switch (action.type) {
        case SET_MAP_STATE:
            return { ...(mapState = action.mapState) };
        default:
            return { ...mapState };
    }
}
