import Grid from '@material-ui/core/Grid';
import React, { ChangeEvent, useContext, useState } from 'react';
import { Button } from '@material-ui/core';
import { WeatherFileMap } from './WeatherFileMap';
import { Location, Position } from '@lcoe/lcoe-client';
import { LeafletMouseEvent } from 'leaflet';
import { ApiContext } from '../components/ApiContext';
import { round } from 'mathjs';
import { LocationWizardLayout } from './LocationWizardLayout';
import { TextField, Typography } from '@equinor/eds-core-react';

interface LocationWeatherMetaLoaderProps {
    setStep: (step: number) => void;
    locationChange: (diff: Partial<Location>) => void;
}

export const LocationWeatherMetaLoader = ({ setStep, locationChange }: LocationWeatherMetaLoaderProps): JSX.Element => {
    const api = useContext(ApiContext);

    const [locationPosition, setLocationPosition] = useState<Position>({ lat: 56.0, lon: 3.0 });
    const [weatherGridPosition, setWeatherGridPosition] = useState<Position>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onMapClick = (e: LeafletMouseEvent) => {
        setLocationPosition({ lat: round(e.latlng.lat, 3), lon: round(e.latlng.lng, 3) });
    };

    const showWeatherMarker = () => {
        setIsLoading(true);

        api.getClosestWeatherGridPosition(locationPosition)
            .then((gridPos) => {
                setWeatherGridPosition(gridPos);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onLocationPositionChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'lon') {
            let newLon = parseFloat(e.target.value);
            if (Number.isNaN(newLon)) {
                newLon = 0;
            }
            setLocationPosition({ ...locationPosition, lon: newLon });
        } else if (e.target.name == 'lat') {
            let newLat = parseFloat(e.target.value);
            if (Number.isNaN(newLat)) {
                newLat = 0;
            }
            setLocationPosition({ ...locationPosition, lat: newLat });
        }
    };

    const confirmPosition = () => {
        setIsLoading(true);
        api.getClosestWeatherMeta(locationPosition)
            .then((meta) => {
                locationChange({
                    position: locationPosition,
                    hs50: round(meta.hs50 as number, 3),
                    tp50: round(meta.tp50 as number, 3),
                    airDensityMean: round(meta.airDensityMean as number, 3)
                });
            })
            .catch(() => {
                locationChange({
                    position: locationPosition
                });
            })
            .finally(() => {
                setIsLoading(false);
                setStep(2);
            });
    };
    const content = () => {
        return (
            <>
                <Typography>
                    Please choose a location to autofill weather metadata from Era5 weather file, or skip to fill out
                    everything manually. You can use the map and right click to select a location. Click
                    &quot;Show/Update weather file marker&quot; to automatically calculate the distance to the closest
                    Era5 grid position. Click on markers to see details.
                </Typography>
                <br />
                <Typography>
                    NB: After saving location first time, a full weather file still needs to be uploaded or fetched from
                    weather file source. This window will only fetch the metadata to help complete location form.
                    Nothing is stored until location form is completed and &quot;Create&quot; button is clicked.
                </Typography>
                <Grid container justifyContent="center" spacing={2} style={{ paddingTop: 20 }}>
                    <Grid item xs={4}>
                        <TextField
                            label="Latitude"
                            type="number"
                            id="lat_field"
                            name="lat"
                            placeholder="Enter number "
                            meta=""
                            helperText="Latitude in range[-90,90]"
                            onChange={onLocationPositionChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Longitude"
                            type="number"
                            name="lon"
                            id="textfield-number"
                            placeholder="Enter number"
                            meta=""
                            helperText="Longitude in range[-180,180]"
                            onChange={onLocationPositionChange}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };

    const buttons = () => {
        return (
            <>
                <Button onClick={() => setStep(3)} variant="contained" color="primary">
                    Skip
                </Button>
                <Button onClick={() => showWeatherMarker()} variant="outlined">
                    {!weatherGridPosition && 'Show weather file marker'}
                    {weatherGridPosition && 'Update weather file marker'}
                </Button>
                <Button onClick={() => confirmPosition()} variant="outlined" disabled={!weatherGridPosition}>
                    Autofill from current weather pos.
                </Button>
            </>
        );
    };

    const displayMap = () => {
        return (
            <WeatherFileMap
                style={{ height: window.innerHeight / 2, width: '1000px' }}
                location={locationPosition}
                weatherFile={weatherGridPosition}
                onClick={onMapClick}
            />
        );
    };

    return (
        <LocationWizardLayout
            isLoading={isLoading}
            title={'Create new location'}
            content={content()}
            buttons={buttons()}
            displayMap={displayMap()}
        />
    );
};
