import React, { useCallback, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grow from '@material-ui/core/Grow';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { writeToClipboard } from '../components/utils/helpers';
import { connect } from 'react-redux';
import { setVisibilityAction } from '../redux/store/log/actions';
import { LogLevelEnum } from './AppLogLevelEnum';
import { Button, Icon } from '@equinor/eds-core-react';
import { chevron_left, chevron_right } from '@equinor/eds-icons';
import styles from './AppLogView.module.css';

const AppLogViewComponent = ({ logItems, visible, setVisible }: any) => {
    Icon.add({ chevron_left, chevron_right });

    const toggleVisible = useCallback(() => setVisible(!visible), [visible, setVisible]);

    return (
        <div className={styles.logwindow}>
            <div style={{ paddingTop: 50 }}>
                <Button variant={'ghost_icon'} onClick={toggleVisible}>
                    {visible ? <Icon name={'chevron_right'} /> : <Icon name={'chevron_left'} />}
                </Button>
            </div>
            <Grow in={visible} mountOnEnter unmountOnExit>
                <List dense className={styles.logcontent}>
                    {logItems.map((logStatement: any, index: any) => (
                        <LogLine key={index} logStatement={logStatement} />
                    ))}
                </List>
            </Grow>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        logItems: state.log.logs,
        visible: state.log.visible
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setVisible: (visible: any) => dispatch(setVisibilityAction(visible))
    };
};

export const AppLogView = connect(mapStateToProps, mapDispatchToProps)(AppLogViewComponent);

export const LogLine = ({ logStatement }: any): JSX.Element => {
    const [expanded, setExpanded] = useState(false);

    function copyToClipboard() {
        const body = logStatement.request && logStatement.request.body && JSON.parse(logStatement.request.body);

        writeToClipboard(
            JSON.stringify({
                ...logStatement,
                request: { ...logStatement.request, body: body }
            })
        );
        setExpanded(false);
    }

    return (
        <ListItem button onClick={() => setExpanded(!expanded)}>
            <ListItemIcon>
                {logStatement.status === LogLevelEnum.Error && <Icon name={'error_outlined'} />}
                {logStatement.status === LogLevelEnum.Warning && <Icon name={'warning_outlined'} color="#fab30c" />}
                {logStatement.status === LogLevelEnum.Info && <Icon name={'info_circle'} />}
            </ListItemIcon>
            <ListItemText primary={logStatement.title} secondary={logStatement.detail} />
            {expanded && (
                <Dialog open={expanded} onClose={() => setExpanded(false)}>
                    <DialogTitle>Error details</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {logStatement.title}
                            <br />
                            {logStatement.detail}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={copyToClipboard}>Copy all details to clipboard</Button>
                    </DialogActions>
                </Dialog>
            )}
        </ListItem>
    );
};
