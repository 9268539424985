/* eslint-disable */
export class CalcState {
    description: any;
    constructor(description: any) {
        this.description = description;
    }
} 

export const CalcStates = {
    NOT_STARTED: new CalcState('Not started'),
    STARTED: new CalcState('Started'),
    FAILED: new CalcState('Failed'),
    SUCCESS: new CalcState('Success')
};
