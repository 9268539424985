import { SET_CASE_COPY, SetCaseCopyAction } from './types';
import { Case } from '@lcoe/lcoe-client';

export function caseCopyReducer(caze: Case | null = null, action: SetCaseCopyAction) {
    // noinspection JSRedundantSwitchStatement
    switch (action.type) {
        case SET_CASE_COPY:
            return action.caze;
        default:
            return caze;
    }
}
