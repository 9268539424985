import { Position } from '@lcoe/lcoe-client';
import { LatLng } from 'leaflet';
import React, { useEffect, useMemo } from 'react';
import { Marker, Polyline, Popup, useMapEvents } from 'react-leaflet';
import { circleIcon } from '../Map/TooltipLayer';
import { Distance } from './LocationDistanceDrawer';
import { PopupMarker } from './WeatherFileMap';

interface DistanceMapLayerProps {
    locationPosition: Position;
    markers: PopupMarker[];
    newMarker: LatLng | undefined;
    setNewMarker: (PopupMarker: LatLng) => void;
    distances: Partial<Distance>[];
    combineDistance: (dist: number, fromPosition: LatLng, toPosition: LatLng) => void;
}

export const LocationDistanceLayer = ({
    locationPosition,
    markers,
    newMarker,
    setNewMarker,
    distances,
    combineDistance
}: DistanceMapLayerProps): JSX.Element => {
    const map = useMapEvents({
        contextmenu(e) {
            setNewMarker(e.latlng);
        }
    });
    const locationMarker = useMemo(() => {
        map.setView({ lat: locationPosition.lat, lng: locationPosition.lon }, 6);
        return { lat: locationPosition.lat, lng: locationPosition.lon };
    }, [locationPosition, map]);

    useEffect(() => {
        if (newMarker) {
            const dist = map.distance(locationMarker, newMarker) / 1000;
            combineDistance(dist, locationMarker as LatLng, newMarker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationMarker, map, newMarker]); //Fix rerender for combineDistance

    let dist = 0;
    if (newMarker) {
        dist = map.distance(locationMarker, newMarker) / 1000;
    }

    return (
        <>
            {markers.map((mark, index) => (
                <Marker key={index} position={mark.latLng} icon={circleIcon}>
                    <Popup>{mark.popupText}</Popup>
                </Marker>
            ))}

            {distances.map((dist, index) => (
                <Polyline key={index} positions={[dist.toPosition as LatLng, dist.fromPosition as LatLng]} color="red">
                    <Popup>{`${dist.distance}km`}</Popup>
                </Polyline>
            ))}

            <Marker position={locationMarker} icon={circleIcon} />

            {newMarker && dist && (
                <>
                    <Marker position={newMarker} icon={circleIcon} />
                    <Polyline positions={[locationMarker, newMarker]} color="red">
                        <Popup>{`Distance: ${dist}km`}</Popup>
                    </Polyline>
                </>
            )}
        </>
    );
};
