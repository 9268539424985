import XLSX from 'xlsx';
import { IndexTable, WtgIndexRequest } from '@lcoe/lcoe-client';

export function parseWtgIndexFromExcel(data: string): WtgIndexRequest {
    const d = XLSX.read(data);
    const indexRows: string[] = XLSX.utils.sheet_to_json(d.Sheets.WTGIndex, { header: 0 });
    return {
        year: new Date().getFullYear(),
        quarter: Math.floor((new Date().getMonth() + 3) / 3),
        tables: getTablesFromIndexRows(indexRows)
    } as WtgIndexRequest;
}

const getTablesFromIndexRows = (indexRows: any[]): IndexTable[] => {
    const cotTable = { id: 'Compete on technology', rows: [] } as IndexTable;
    const copTable = { id: 'Compete on price', rows: [] } as IndexTable;
    indexRows.forEach((row) => {
        for (const columnName in row) {
            if (row.hasOwnProperty(columnName) && columnName.match(/tech/g)) {
                cotTable.rows.push({ year: row['FID'], indexPrice: row[columnName] });
            } else if (row.hasOwnProperty(columnName) && columnName.match(/price/g)) {
                copTable.rows.push({ year: row['FID'], indexPrice: row[columnName] });
            }
        }
    });
    return [cotTable, copTable];
};
