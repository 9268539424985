import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SwaggerSchema, turbineValidationModel } from 'Api/model/SwaggerSpec';
import { FormSection } from 'components/forms/FormSection';
import { SchemaField } from 'components/forms/SchemaField';
import { SimpleChipList } from 'components/forms/SimpleChipList';
import { SimpleCommentsField } from 'components/forms/SimpleCommentsField';
import { SimpleTagsField } from 'components/SimpleTagsField';
import { extractValue } from 'components/utils/helpers';
import React, { useCallback, useEffect } from 'react';
import { calculatePercentOfMaxCapacity } from 'utils/turbine/turbineUtils';
import { CalcDudgeonAdornment } from './CalcDudgeonAdornment';
import { Turbine } from '@lcoe/lcoe-client/src/models';
import { Problems } from '../../Api/Problems';
import { PartialTurbineWithId } from '../../interface/ITurbine';

interface IProps {
    turbine: PartialTurbineWithId | Partial<Turbine>;
    turbineChange: (diff: Partial<Turbine>) => void;
    problems: Problems;
    localEdit?: boolean;
}

export const TurbineEditForm = ({ turbine, turbineChange, problems = {}, localEdit = false }: IProps): JSX.Element => {
    const handleChange = useCallback(
        (evt) => {
            const name = evt.target.name;
            const value = extractValue(evt.target);
            turbineChange({ [name]: value });
        },
        [turbineChange]
    );
    const dateChange = useCallback(
        (value) => {
            turbineChange({ dateAvailable: value });
        },
        [turbineChange]
    );

    useEffect(() => {
        turbineChange({
            grossProdPercent: calculatePercentOfMaxCapacity(turbine.capacity as number, turbine.grossProdDud as number)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [turbine.grossProdDud, turbine.capacity]);

    const schemaProps = {
        schema: turbineValidationModel.schema,
        object: turbine,
        onChange: turbineChange,
        problems
    };

    interface schemaPropsInterface {
        schema: SwaggerSchema;
        object: Turbine;
        onChange: typeof turbineChange;
        problems: typeof problems;
    }

    return (
        <>
            <form autoComplete="off">
                <FormSection legend="Turbine">
                    <SchemaField {...schemaProps} name="name" disabled={localEdit} />
                    <SchemaField {...schemaProps} name="capacity" disabled={localEdit} />
                    <SchemaField
                        {...schemaProps}
                        name="grossProdDud"
                        disabled={localEdit}
                        endAdornmentComponentRender={(schemaProps: schemaPropsInterface) => (
                            <CalcDudgeonAdornment {...schemaProps} />
                        )}
                    />
                    <SchemaField {...schemaProps} name="grossProdPercent" disabled={true} />
                    <SchemaField {...schemaProps} name="rotorDiameter" disabled={localEdit} />
                    <SchemaField {...schemaProps} name="airGap" />
                    <SchemaField {...schemaProps} name="topHeadMass" disabled={localEdit} />
                    <SchemaField {...schemaProps} name="towerMass" disabled={localEdit} />
                    <SchemaField {...schemaProps} name="airDensityNominal" disabled={localEdit} />

                    <div style={{ padding: 5 }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                name="dateAvailable"
                                onChange={dateChange}
                                value={turbine.dateAvailable}
                                minDate="2000-01-01"
                                fullWidth
                                openTo="year"
                                format="yyyy-MM-dd"
                                label="Date available"
                                required
                                helperText={problems.dateAvailable}
                                error={!!problems.dateAvailable}
                                autoOk
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </FormSection>

                <FormSection legend="Metadata">
                    <SimpleTagsField onChange={turbineChange} value={turbine.tags as string[]} />
                    <SimpleCommentsField name="comments" onChange={handleChange} value={turbine.comments} />
                </FormSection>

                {!localEdit && (
                    <FormSection legend="Access control">
                        <SimpleChipList
                            label="Shared with"
                            helperText="Username or 'all'"
                            selectedItems={turbine.sharedWith || []}
                            onSelectedItemsChange={(items: string[]) => turbineChange({ sharedWith: items })}
                        />
                    </FormSection>
                )}
            </form>
        </>
    );
};
