import React from 'react';
import { WeatherFileStatus } from '@lcoe/lcoe-client';
import { Icon } from '@equinor/eds-core-react';

interface WeatherFileIconProps {
    status?: WeatherFileStatus;
}

const WeatherFileIcon = ({ status }: WeatherFileIconProps): JSX.Element => {
    switch (status) {
        case undefined:
        case WeatherFileStatus.NotFound:
            return <Icon name={'close'} />;
        case WeatherFileStatus.Error:
            return <Icon name={'error_outlined'} />;
        case WeatherFileStatus.ServiceUnavailable:
            return <Icon name={'build_wrench'} />;
        case WeatherFileStatus.Queued:
            return <Icon name={'pause'} />;
        case WeatherFileStatus.Processing:
            return <Icon name={'autorenew'} />;
        case WeatherFileStatus.Ready:
            return <Icon name={'done'} />;
    }
};

export default WeatherFileIcon;
