import * as React from 'react';
import styles from './PageHeader.module.css';
import CSS from 'csstype';
import cx from 'classnames';
import { Typography } from '@equinor/eds-core-react';

interface IProps {
    style?: CSS.Properties;
    className?: string;
    title: string;
    right_element?: JSX.Element;
}

export const PageHeader = (props: IProps): JSX.Element => {
    return (
        <div className={cx(styles.PageHeader, props.className)} style={props.style}>
            <div className={styles.header}>
                <Typography variant="h2">{props.title}</Typography>
                <div>{props.right_element}</div>
            </div>
        </div>
    );
};
