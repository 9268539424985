import React from 'react';
import { VersionMeta, WtgIndexRequest } from '@lcoe/lcoe-client';
import { Table } from '@equinor/eds-core-react';

interface IProps {
    definitions: { title: string }[];
    index: WtgIndexRequest;
    versionMeta: VersionMeta;
}

export const WtgListTable = (props: IProps): JSX.Element => {
    const estimationSeasonFromIndex = (index: WtgIndexRequest) => {
        return index.year + 'Q' + index.quarter;
    };

    return (
        <Table style={{ width: '100%' }}>
            <Table.Head>
                <Table.Row>
                    {props.definitions.map((column) => (
                        <Table.Cell className="turbineListHeader" key={column.title}>
                            {column.title}
                        </Table.Cell>
                    ))}
                </Table.Row>
            </Table.Head>
            <Table.Body>
                {props.versionMeta && (
                    <Table.Row>
                        <Table.Cell key="Estimation season">{estimationSeasonFromIndex(props.index)}</Table.Cell>
                        <Table.Cell title="Uploaded time">{props.versionMeta.createdBy}</Table.Cell>
                        <Table.Cell>{props.versionMeta.createdTime.toDateString()}</Table.Cell>
                        <Table.Cell>{props.versionMeta.version}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
};
