import { formatNumber } from '../../numbers';

export function formatTableValue(value: unknown): string {
    if (value === undefined || value === null) {
        return '-';
    } else if (value instanceof Date) {
        return value.toISOString();
    } else if (typeof value == 'number') {
        return formatNumber(value);
    } else if (value instanceof Object && value.hasOwnProperty('lat') && value.hasOwnProperty('lon')) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return `lat,:lon ${(value as any)?.lat}, ${(value as any)?.lon}`;
    }
    return `${value}`;
}
