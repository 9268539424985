import React from 'react';
import { WtgIndex, WtgIndexRequest } from '@lcoe/lcoe-client';
import { Chart } from 'react-google-charts';

interface iProps {
    index: WtgIndex | WtgIndexRequest;
}

export const WtgIndexChart = (props: iProps): JSX.Element => {
    const chartData = [] as (string | number)[][];
    chartData.push(['x', 'Compete on technology', 'Compete on price']);

    const tech = props.index?.tables[0].rows;
    const price = props.index?.tables[1].rows;

    for (let i = 0; i < tech?.length; i++) {
        if (tech) {
            if (price) {
                chartData.push([tech[i].year.toString(), tech[i].indexPrice, price[i].indexPrice]);
            }
        }
    }

    return (
        <Chart
            height={'800px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
                hAxis: {
                    title: 'Year'
                },
                vAxis: {
                    title: 'Eur / GWh/y'
                },
                series: {
                    1: { curveType: 'function' }
                },
                chartArea: { left: 100, bottom: 160, top: 60, right: 150, width: '100%', height: '100%' }
            }}
        />
    );
};
