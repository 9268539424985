/* eslint-disable */
import { ADD_LOG_LINE, LogAction, LogState, SET_VISIBILITY } from './types';

export function logReducer(state: LogState = { logs: [], visible: false }, action: LogAction) {
    switch (action.type) {
        case ADD_LOG_LINE:
            return { logs: [...state.logs, action.log], visible: true };
        case SET_VISIBILITY:
            return { ...state, visible: action.visible };
        default:
            return state;
    }
}
