import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect } from 'react';
import { Location } from '@lcoe/lcoe-client';
import './leaflet-map.css';
import { TooltipLayer } from './TooltipLayer';
import { BaseLeafletMap } from './BaseLeafletMap';
import { useMap } from 'react-leaflet';
import styles from './CaseLocationMap.module.css';
import { Labels } from './Labels';
import { maxHsLabelSpecsColors, maxHsLabelSpecsValues } from './MaxHsLabelSpecs';
import { meanHsLabelSpecsColors, meanHsLabelSpecsValues } from './meanHsLabelSpecs';
import { useSelector } from 'react-redux';
import { layersEnum } from '../redux/store/map/types';
import { defaultView } from '../utils/map/defaultView';
import { ApplicationState } from '../redux/store';

interface CaseLocationMapProps {
    className?: string;
    locations: Location[];
    onGotoLocation: (id: string) => void;
}

export const CaseLocationMap = ({ locations, onGotoLocation }: CaseLocationMapProps): JSX.Element => {
    const mapState = useSelector((state: ApplicationState) => state.map);
    const [displayMaxHsLabels, setDisplayMaxHsLabels] = useState<boolean>(false);
    useEffect(() => {
        if (mapState[layersEnum.wavesMaxHs].checked) {
            setDisplayMaxHsLabels(true);
        }

        if (mapState[layersEnum.wavesMeanHs].checked || !mapState[layersEnum.wavesMaxHs].checked) {
            setDisplayMaxHsLabels(false);
        }
    }, [mapState]);
    return (
        <div className={styles.container}>
            <BaseLeafletMap style={{ height: window.innerHeight / 2 }} defaultView={defaultView}>
                <CaseLocationLayer locations={locations} onGotoLocation={onGotoLocation} />
            </BaseLeafletMap>
            <div className={styles.labels}>
                {displayMaxHsLabels && <Labels colors={maxHsLabelSpecsColors} values={maxHsLabelSpecsValues} />}
                {mapState[layersEnum.wavesMeanHs].checked && (
                    <Labels colors={meanHsLabelSpecsColors} values={meanHsLabelSpecsValues} />
                )}
            </div>
            <div style={{ textAlign: 'center', backgroundColor: '#ffffff' }}>
                Equinor internal network or VPN required for supporting additional map layers such as Wind Farm
                Boundaries.
            </div>
        </div>
    );
};

const CaseLocationLayer = ({ locations, onGotoLocation }: CaseLocationMapProps) => {
    const map = useMap();

    useEffect(() => {
        const locationLayer = new TooltipLayer().addTo(map);
        locations.map((location) => {
            const link = document.createElement('a');
            link.href = `/locations/${location.id}`;
            link.innerHTML = location.name;
            link.onclick = (event) => {
                event.preventDefault();
                onGotoLocation(location.id as string);
            };
            locationLayer.addTooltip(link, {
                lat: location.position.lat,
                lng: location.position.lon
            });
        });

        const bounds = locationLayer.getBounds();
        if (bounds.isValid()) {
            map.fitBounds(bounds).setZoom(2);
        }
    }, [locations, map, onGotoLocation]);

    return null;
};
