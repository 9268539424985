import { TurbineProfile } from '@lcoe/lcoe-client';
import * as React from 'react';
import { useState } from 'react';
import { TurbineProfileChart } from 'Turbine/TurbineProfileChart';
import { Button, EdsProvider, Icon, Table, Typography } from '@equinor/eds-core-react';
import styles from './TurbineProfileView.module.css';

interface IProps {
    profile?: TurbineProfile;
}

export const TurbineProfileView = (props: IProps): JSX.Element => {
    const [toggleView, setToggleView] = useState(false);
    return (
        <>
            <div className={styles.switch_row}>
                <div>
                    <Button variant="ghost_icon" onClick={() => setToggleView(!toggleView)}>
                        {toggleView ? (
                            <Icon size={48} name="switch_on" color={'#ee2b59'}></Icon>
                        ) : (
                            <Icon size={48} name="switch_off"></Icon>
                        )}
                    </Button>
                </div>
                <div style={{ paddingTop: 15 }}>
                    <Typography variant={'body_short_bold'}> &nbsp; Raw data</Typography>
                </div>
            </div>

            {!toggleView && <TurbineProfileChart profile={props.profile as TurbineProfile} />}
            {toggleView && <RawProfileView profile={props.profile} />}
        </>
    );
};

const RawProfileView = (props: IProps): JSX.Element => {
    return (
        <EdsProvider density={'compact'}>
            <Table style={{ width: '100%' }}>
                <Table.Head>
                    <Table.Row>
                        <Table.Cell>Wind speed</Table.Cell>
                        <Table.Cell>Power output</Table.Cell>
                        <Table.Cell>Thrust coefficient</Table.Cell>
                    </Table.Row>
                </Table.Head>
                <Table.Body>
                    {props.profile &&
                        props.profile.performanceTable.map((performanceRow) => {
                            return (
                                <Table.Row key={performanceRow.windSpeedMps}>
                                    <Table.Cell>{performanceRow.windSpeedMps} m/s</Table.Cell>
                                    <Table.Cell>{performanceRow.powerOutputWatt / 1000} kW</Table.Cell>
                                    <Table.Cell>{performanceRow.thrustCoefficient}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                </Table.Body>
            </Table>
            <br />
            {props.profile && props.profile.comments}
        </EdsProvider>
    );
};
