/* eslint-disable */
import * as Redux from 'redux';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import {ApiContext} from '../components/ApiContext';
import {CalculationResult, Case, EpaAssumptions} from '@lcoe/lcoe-client';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {ColumnSelectCard} from './ColumnSelectCard';
import {
    activateAllFields,
    activateFieldsForLevel,
    Field,
    mapActiveFields,
    setActiveParentsForActiveFields
} from './fields';
import {connect} from 'react-redux';
import {ApplicationState} from '../redux/store';
import {clearCaseCompare, removeCaseCompare} from '../redux/store/caseCompare/actions';
import {CaseRef} from '../redux/store/caseCompare/types';
import {getFieldsFromCaseWithResult, getUnionOfFieldsArrArr} from './fieldsFromCase';
import useLocalStorage from '../hooks/useLocalStorage';
import {Grid} from '@material-ui/core';
import {CasesCompareCard} from './CaseCompareCard';
import {convertEpaStringToYearQuarter} from '../Settings/EpaUtils';
import {NavBar} from "../components/NavBar";
import styles from './CaseComparisonPage.module.css'

export function caseRefToString(caseRef: CaseRef): string {
    if (caseRef.version) {
        return caseRef.id + ':' + caseRef.version;
    }
    return caseRef.id;
}

export interface CaseWithResult extends Case {
    result: CalculationResult;
    epa: EpaAssumptions;
    id: string;
}

export interface CaseMap {
    [key: string]: CaseWithResult | string;
}

interface Props {
    cases: CaseRef[];
    clear: () => void;
    removeCase: (caseRef: CaseRef) => void;
}

const CaseComparisonPageComponent = ({cases, clear, removeCase}: Props) => {
    const api = useContext(ApiContext);

    const [loadedCases, setLoadedCases] = useState({} as CaseMap);

    const [fields, setFields] = useLocalStorage('fieldSelection', []);

    const getFieldsFromCases = (): Field[] => {
        const casesFieldsArrArr = [] as Field[][];

        cases.forEach((caseRef) => {
            const caze = loadedCases[caseRefToString(caseRef)];
            if (!caze) return;
            const fields = getFieldsFromCaseWithResult(caze as CaseWithResult);
            if (!fields) return;

            casesFieldsArrArr.push(fields);
        });

        return getUnionOfFieldsArrArr(casesFieldsArrArr);
    };

    const resetFields = () => {
        const newFieldsArr = getFieldsFromCases();
        setFields(newFieldsArr);
    };

    useEffect(() => {
        const newFieldsArr = getFieldsFromCases();
        mapActiveFields(fields, newFieldsArr);
        if (Object.keys(loadedCases).length === cases.length) {
            setFields(newFieldsArr);
        }
    }, [cases, loadedCases]);

    useEffect(() => {
        let r: CaseMap = {};
        cases.forEach((caseRef: CaseRef) => {
            api.getCase(caseRef)
                .then((caze: Case) => {
                    const [year, quarter] = convertEpaStringToYearQuarter(caze.epaAssumptions as string);
                    api.epaApi.getEpaAssumptions({year, quarter}).then((epa) => {
                        r = {...r, [caseRefToString(caseRef)]: {epa, ...caze} as CaseWithResult};
                        setLoadedCases(r);
                    });
                })
                .catch((err) => {
                    if (!err.json) {
                        r = {
                            ...r,
                            [caseRefToString(caseRef)]: 'Could not connect to backend. Try reloading the page.'
                        };
                        setLoadedCases(r);
                        return;
                    }
                    err.json()
                        .then((json: any) => 'Error when loading: ' + json.detail)
                        .catch(() => 'Error when loading')
                        .then((msg: string) => {
                            r = {...r, [caseRefToString(caseRef)]: msg};
                            setLoadedCases(r);
                        });
                });
        });
    }, [cases, api]);

    const toggleField = (field: Field) => {
        field.isActive = !field.isActive;
        setFields([...fields]);
    };

    const selectAllFields = () => {
        activateAllFields(fields);
        setFields([...fields]);
    };

    const resetAllFields = () => {
        resetFields();
    };

    const selectLevelFields = (levelName: string) => {
        activateFieldsForLevel(fields, levelName);
        setFields([...fields]);
    };

    if (cases.length === 0) {
        return (
            <Card className={styles.frontpage_card}>
                <CardHeader title="Compare cases"/>
                <CardContent>
                    <Typography>No cases selected &mdash; nothing to compare. </Typography>
                    <Typography>Select some cases and come back later.</Typography>
                </CardContent>
            </Card>
        );
    }

    setActiveParentsForActiveFields(fields);

    // Column select card sizes right now are at least xs={12} sm={8} md={5} lg={4} xl={3}
    return (
        <>
            <NavBar/>
            <Grid container>
                <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
                    <ColumnSelectCard
                        fields={fields}
                        toggleField={toggleField}
                        selectAll={selectAllFields}
                        resetSelection={resetAllFields}
                        selectLevel={selectLevelFields}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
                    <CasesCompareCard
                        clear={clear}
                        cases={cases}
                        removeCase={removeCase}
                        fields={fields}
                        loadedCases={loadedCases}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        cases: state.caseCompare
    };
};
const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
    return {
        clear: () => dispatch(clearCaseCompare()),
        removeCase: (caseRef: CaseRef) => dispatch(removeCaseCompare(caseRef))
    };
};

export const CaseComparisonPage = connect(mapStateToProps, mapDispatchToProps)(CaseComparisonPageComponent);
