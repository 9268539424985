import { FileUpload, Turbine } from '@lcoe/lcoe-client';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'redux/store';
import { isUserTurbineAdmin } from 'utils/permission/permissionUtil';
import { parseTurbineFromExcel } from 'utils/turbine/ExcelProfileParser';
import { turbineValidationModel } from '../../Api/model/SwaggerSpec';
import { Problems, problemsExist } from '../../Api/Problems';
import { PartialTurbineWithId, TurbineWithId } from '../../interface/ITurbine';
import { parseTurbineFromXml } from '../../utils/turbine/XMLProfileParser';
import { ApiContext } from '../ApiContext';
import { BeforeUnload } from '../BeforeUnload';
import ConfirmationDialog from '../ConfirmationDialog';
import LoadingDialog from '../LoadingDialog';
import { UploadButton } from '../UploadButton';
import { TurbineEditForm } from './TurbineEditForm';
import { TurbineProfileView } from './TurbineProfileView';
import styles from './TurbineDetails.module.css';
import { Button, Typography } from '@equinor/eds-core-react';

interface IProps {
    downloadedTurbine: TurbineWithId;
    deleteTurbine: () => void;
}

export const TurbineDetails = (props: IProps): JSX.Element => {
    const user = useSelector((state: ApplicationState) => state.user);
    const isTurbineAdmin = isUserTurbineAdmin(user);

    const [turbine, setTurbine] = useState<PartialTurbineWithId>(props.downloadedTurbine);
    const [problems, setProblems] = useState<Problems>({});
    const [saving, setSaving] = useState(false);
    const [modified, setModified] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const api = useContext(ApiContext);

    function validate() {
        if (turbine) {
            setProblems(turbineValidationModel.validate(turbine as Turbine));
        }
    }

    useEffect(validate, [turbine]);

    function turbineChange(diff: Partial<Turbine>) {
        if (!diff.grossProdPercent) {
            setModified(true);
        }
        setTurbine({ ...turbine, ...diff });
    }

    function save() {
        setSaving(true);
        api.updateTurbine(turbine.id, turbine as Turbine)
            .then((updatedTurbine) => {
                setTurbine(updatedTurbine as TurbineWithId);
                setModified(false);
            })
            .finally(() => {
                setSaving(false);
            });
    }

    function loadProfile(file: FileUpload) {
        if (file.filename.match(/\.xml$/)) {
            const parsedTurbine = parseTurbineFromXml(file.content);
            turbineChange({ profile: parsedTurbine.profile });
            return;
        }
        const profile = parseTurbineFromExcel(file.content);
        turbineChange({ profile: profile.profile });
    }

    const askToDeleteTurbine = useCallback(() => setDeleting(true), []);

    const confirmDelete = useCallback(() => {
        props.deleteTurbine();
    }, [props.deleteTurbine]);

    const cancelDelete = useCallback(() => setDeleting(false), []);

    const createdThisTurbine = 'username' in user && user.username === turbine.createdBy;
    const canEdit = isTurbineAdmin || createdThisTurbine;

    const hasProblems = problemsExist(problems);
    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
                <div style={{ width: '50%' }}>
                    <div className={styles.content_box}>
                        <div className={styles.card_hider_wrapper}>
                            {!isTurbineAdmin && <div className={styles.card_hider} />}
                            <TurbineEditForm turbine={turbine} turbineChange={turbineChange} problems={problems} />
                        </div>
                        {isTurbineAdmin && (
                            <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                                <Button color="secondary" className={styles.button_right} onClick={askToDeleteTurbine}>
                                    Delete
                                </Button>{' '}
                                &nbsp;
                                <Button onClick={save} disabled={hasProblems} variant="contained" color="primary">
                                    Update
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ width: '50%' }}>
                    <div className={styles.content_box}>
                        <Typography variant="h3" className={styles.legend}>
                            Turbine profile
                        </Typography>
                        {canEdit && <TurbineProfileView profile={turbine.profile} />}
                        {!canEdit && (
                            <Typography style={{ paddingTop: 20 }}>
                                The turbine profile is only available for users with wtg_admin privileges. Apply for
                                access in AccessIT if needed.
                            </Typography>
                        )}
                        {isTurbineAdmin && (
                            <UploadButton
                                id="turbine-import-button"
                                onUpload={loadProfile}
                                accept={'.xml,.xls,.xlsx'}
                                variant="outlined"
                            >
                                Import profile from Excel or XML
                            </UploadButton>
                        )}

                        {canEdit && !isTurbineAdmin && (
                            <Typography style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 5 }}>
                                You can see the turbine profile because you created it. The turbine profile can only be
                                changed by users with wtg_admin privileges. Apply for access in AccessIT if needed.
                            </Typography>
                        )}
                    </div>
                </div>
            </div>

            <LoadingDialog open={saving} />
            <BeforeUnload when={modified && !saving} />
            {deleting && (
                <ConfirmationDialog
                    title="Delete turbine"
                    open={deleting}
                    onConfirm={confirmDelete}
                    onCancel={cancelDelete}
                    confirmText="Delete"
                    text={'Are you sure you want to delete this turbine?'}
                />
            )}
        </React.Fragment>
    );
};
