import React from 'react';
import { CircularProgress, Scrim } from '@equinor/eds-core-react';

interface IProps {
    open?: boolean;
}

export default function LoadingDialog({ open = true }: IProps): JSX.Element {
    return (
        <Scrim open={open}>
            <CircularProgress size={48} color="primary" value={0} />
        </Scrim>
    );
}
