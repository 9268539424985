export const exportGridOptions: Array<ExportGrid> = [
    // {name: '33 kV AC - EXPORT', voltage: 33000},
    // {name: '66 kV AC - EXPORT', voltage: 66000},
    { name: '132 kV AC - EXPORT', voltage: 132000 },
    { name: '220 kV AC - EXPORT', voltage: 220000 },
    { name: '300 kV DC - EXPORT', voltage: 300000 }
];

export const collectionGridOptions: Array<CollectionGrid> = [
    { name: '33 kV AC - COLLECTION GRID', voltage: 33000 },
    { name: '66 kV AC - COLLECTION GRID', voltage: 66000 }
];

export enum CurrentType {
    DC = 'DC',
    AC = 'AC'
}

export interface ExportGrid {
    name: string;
    voltage: number;
}

export interface CollectionGrid {
    name: string;
    voltage: number;
}
