import { ExperienceMonopiles, ExperienceMonopile } from '@lcoe/lcoe-client';
import * as React from 'react';
import CSS from 'csstype';
import { ApiContext } from 'components/ApiContext';
import { TableEditor } from '../components/tableeditor/TableEditor';
import { useContext, useState } from 'react';
import { isUserFoundationAdmin } from 'utils/permission/permissionUtil';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../redux/store';
import { DialogOnSave } from '../components/tableeditor/DialogOnSave';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    monopiles: ExperienceMonopiles;
}

export const MonopileExperienceEdit = (props: IProps): JSX.Element => {
    const user = useSelector((state: ApplicationState) => state.user);
    const [savedData, setSavedData] = useState(false);
    const isFoundationAdmin = isUserFoundationAdmin(user);
    const api = useContext(ApiContext);

    const columns = [
        { name: 'Wind project', type: 'text', select_options: '' },
        { name: 'Source', type: 'text', select_options: '' },
        { name: 'Year', type: 'number', select_options: '' },
        { name: 'Water depth', type: 'number', select_options: '' },
        { name: 'Wind speed 50yrs', type: 'number', select_options: '' },
        { name: 'HS50', type: 'number', select_options: '' },
        { name: 'Wind Cyclone 500yrs', type: 'number', select_options: '' },
        { name: 'HS500', type: 'number', select_options: '' },
        { name: 'Soil type', type: 'text', select_options: '' },
        { name: 'Soil characteristics', type: 'text', select_options: '' },
        { name: 'WTG rotor diameter', type: 'number', select_options: '' },
        { name: 'WTG rating', type: 'number', select_options: '' },
        { name: 'MP diameter', type: 'number', select_options: '' },
        { name: 'MP length', type: 'number', select_options: '' },
        { name: 'MP weight', type: 'number', select_options: '' },
        { name: 'MP penetration', type: 'number', select_options: '' },
        { name: 'TP diameter', type: 'number', select_options: '' },
        { name: 'TP length', type: 'number', select_options: '' },
        { name: 'TP main steel weight', type: 'number', select_options: '' },
        { name: 'TP secondary steel weight', type: 'number', select_options: '' },
        { name: 'Updated by', type: 'string', select_options: '' }
    ];

    const dataRows = [] as string[][];

    props.monopiles.monopiles.map((el: ExperienceMonopile) =>
        dataRows.push([
            el.windProject === undefined ? '' : String(el.windProject),
            el.sourceReference === undefined ? '' : String(el.sourceReference),
            el.year === undefined ? '' : String(el.year),
            el.waterDepth === undefined ? '' : String(el.waterDepth),
            el.windSpeed50y === undefined ? '' : String(el.windSpeed50y),
            el.hs50 === undefined ? '' : String(el.hs50),
            el.windCyclone500y === undefined ? '' : String(el.windCyclone500y),
            el.hs500 === undefined ? '' : String(el.hs500),
            el.soilType === undefined ? '' : String(el.soilType),
            el.soilCharacteristics === undefined ? '' : String(el.soilCharacteristics),
            el.wtgRotorDiameter === undefined ? '' : String(el.wtgRotorDiameter),
            el.wtgRating === undefined ? '' : String(el.wtgRating),
            el.mpDiameter === undefined ? '' : String(el.mpDiameter),
            el.mpLength === undefined ? '' : String(el.mpLength),
            el.mpWeight === undefined ? '' : String(el.mpWeight),
            el.mpPenetration === undefined ? '' : String(el.mpPenetration),
            el.tpDiameter === undefined ? '' : String(el.tpDiameter),
            el.tpLength === undefined ? '' : String(el.tpLength),
            el.tpPrimarySteelWeight === undefined ? '' : String(el.tpPrimarySteelWeight),
            el.tpSecondarySteelWeight === undefined ? '' : String(el.tpSecondarySteelWeight),
            el.uploadedBy === undefined ? '' : String(el.uploadedBy)
        ])
    );

    const saveDataset = (dataset: string[]) => {
        const saveMonopiles = {} as ExperienceMonopiles;
        const monopiles = [] as ExperienceMonopile[];

        dataset.map((el: string) => {
            monopiles.push({
                windProject: el[0],
                sourceReference: el[1],
                year: Number(el[2]),
                waterDepth: Number(el[3]),
                windSpeed50y: Number(el[4]),
                hs50: Number(el[5]),
                windCyclone500y: Number(el[6]),
                hs500: Number(el[7]),
                soilType: el[8],
                soilCharacteristics: el[9],
                wtgRotorDiameter: Number(el[10]),
                wtgRating: Number(el[11]),
                mpDiameter: Number(el[12]),
                mpLength: Number(el[13]),
                mpWeight: Number(el[14]),
                mpPenetration: Number(el[15]),
                tpDiameter: Number(el[16]),
                tpLength: Number(el[17]),
                tpPrimarySteelWeight: Number(el[18]),
                tpSecondarySteelWeight: Number(el[19]),
                uploadedBy: el[20]
            } as ExperienceMonopile);
        });

        saveMonopiles.monopiles = monopiles;
        const promise = api.saveExperienceMonopiles(saveMonopiles as ExperienceMonopiles);
        promise.then((savedCase) => {
            if (savedCase.monopiles.length > 0) {
                setSavedData(true);
            }
        });
    };

    return (
        <>
            <DialogOnSave
                dialog_text={'Foundation data has been saved.'}
                savedOpen={savedData}
                setSaved={setSavedData}
            ></DialogOnSave>
            <TableEditor
                title={'Experience data - Monopiles'}
                saveDataset={saveDataset}
                columns={columns}
                datarows={dataRows}
                isEditor={isFoundationAdmin}
            ></TableEditor>
        </>
    );
};
