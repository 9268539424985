import { default as React } from 'react';
import { Button, Dialog, Input } from '@equinor/eds-core-react';

interface IProps {
    baseCurrency: string;
    open: boolean;
    onClose: () => void;
    dialogValue: number;
    dialogName: string;
    onDialogChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onDialogSubmit: () => void;
}

const OverrideDialog = (props: IProps): JSX.Element => {
    const dialog_title =
        props.dialogName === 'Net Annual Production'
            ? 'Enter new value for ' + props.dialogName + ' in GWh/year'
            : props.dialogName.indexOf('percentage') >= 0
            ? props.dialogName
            : 'Enter new value for ' + props.dialogName + ' in M' + props.baseCurrency;

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <Dialog.CustomContent>
                {dialog_title}
                <br />
                <Input id="newValue" defaultValue={props.dialogValue} onChange={props.onDialogChange} />
            </Dialog.CustomContent>
            <Dialog.Actions>
                <Button onClick={props.onClose}>Cancel</Button> &nbsp;
                <Button onClick={props.onDialogSubmit}>Submit</Button>
            </Dialog.Actions>
        </Dialog>
    );
};

export default OverrideDialog;
