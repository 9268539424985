import { Button, Dialog } from '@equinor/eds-core-react';
import * as React from 'react';

export interface IProps {
    dialog_text: string;
    savedOpen: boolean;
    setSaved: (status: boolean) => void;
}

export const DialogOnSave = (props: IProps): JSX.Element => {
    return (
        <Dialog open={props.savedOpen} isDismissable={false}>
            <Dialog.CustomContent>
                <div style={{ textAlign: 'center' }}>
                    {props.dialog_text}
                    <br />
                    <br />
                    <Button onClick={() => props.setSaved(false)}>OK</Button>
                </div>
            </Dialog.CustomContent>
        </Dialog>
    );
};
