import { default as React } from 'react';
import { UnitConversion } from '../../uom/UnitConversion';
import { Opex, Production } from '@lcoe/lcoe-client';
import { round } from 'mathjs';
import styles from './ResultDetailOverrideStructure.module.css';
import { ResultEntry } from './ResultUtils';

interface IProps {
    unitConversion: UnitConversion;
    capexOverride: ResultEntry[];
    opexOverride: Opex;
    productionOverride: Production;
    onDialogOpen: (id: string, type: string, currentValue: number, varName: string) => void;
}

export const ResultDetailOverrideStructure = (props: IProps): JSX.Element => {
    const isEdit = window.location.href.includes('edit');
    const opexCost = round(props.opexOverride.cost.value / 1000, 3);
    const production = round(props.productionOverride.netAnnualProduction.value, 3);

    return (
        <>
            <table className={styles.table}>
                <tbody>
                    <tr className={styles.row_header}>
                        <td />
                        <td className={styles.table_header_column}>Cost excl. contingency</td>
                        <td className={styles.table_header_column}>Contingency %</td>
                        <td className={styles.table_header_column}>Contingency</td>
                        <td className={styles.table_header_column}>Cost</td>
                        <td className={styles.table_header_column}>COE</td>
                    </tr>

                    {props.capexOverride.map((dataRow, index) => {
                        const isCapexElement = isEdit && dataRow.name != 'Total CAPEX';

                        const entryClass =
                            isEdit && dataRow.isActive
                                ? styles.isEditActive
                                : isEdit
                                ? styles.isEditNotActive
                                : !isEdit && dataRow.isActive
                                ? styles.isNotEditActive
                                : styles.isNotEditNotActive;

                        return (
                            <tr
                                className={styles.row}
                                key={index}
                                style={{
                                    backgroundColor: dataRow.isActive ? '#ffffff' : '#ffeeee'
                                }}
                            >
                                <td
                                    style={{
                                        textAlign: 'left',
                                        paddingLeft: dataRow.level * 10 - 10,
                                        fontWeight: dataRow.isParent || dataRow.level <= 2 ? 'bold' : 'normal'
                                    }}
                                >
                                    {dataRow.name}
                                </td>

                                <td
                                    className={isCapexElement ? entryClass : ''}
                                    onClick={() =>
                                        isCapexElement
                                            ? props.onDialogOpen(
                                                  dataRow.id,
                                                  'capexBase',
                                                  props.unitConversion.fromMEURToCurrencyValue(dataRow.capexCost),
                                                  dataRow.name
                                              )
                                            : null
                                    }
                                >
                                    {props.unitConversion.fromMEURToCurrency(dataRow.capexCost)}
                                </td>
                                <td
                                    className={isCapexElement ? entryClass : ''}
                                    onClick={() =>
                                        isCapexElement
                                            ? props.onDialogOpen(
                                                  dataRow.id,
                                                  'contingencyPercent',
                                                  +((dataRow.contingency / dataRow.capexCost) * 100).toFixed(2),
                                                  dataRow.name + ' contingency in percentage'
                                              )
                                            : null
                                    }
                                >
                                    {((dataRow.contingency / dataRow.capexCost) * 100).toFixed(2)} %
                                </td>
                                <td
                                    className={isCapexElement ? entryClass : ''}
                                    onClick={() =>
                                        isCapexElement
                                            ? props.onDialogOpen(
                                                  dataRow.id,
                                                  'contingency',
                                                  props.unitConversion.fromMEURToCurrencyValue(dataRow.contingency),
                                                  dataRow.name
                                              )
                                            : null
                                    }
                                >
                                    {props.unitConversion.fromMEURToCurrency(dataRow.contingency)}
                                </td>

                                <td>
                                    {props.unitConversion.fromMEURToCurrency(dataRow.capexCost + dataRow.contingency)}
                                </td>

                                <td>{props.unitConversion.fromMEURToCurrency(dataRow.COE)}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={6}> &nbsp; </td>
                    </tr>
                    <tr className={styles.row_header}>
                        <td />
                        <td>Cost</td>
                        <td colSpan={3} />
                        <td>COE</td>
                    </tr>
                    <tr className={styles.row}>
                        <td
                            style={{
                                textAlign: 'left',
                                fontWeight: 'bold'
                            }}
                        >
                            {props.opexOverride.name} / year
                        </td>
                        <td
                            className={isEdit ? styles.isEditActive : styles.isNotEditActive}
                            onClick={() => (isEdit ? props.onDialogOpen('opex', '', opexCost, 'Total Opex') : null)}
                        >
                            {props.unitConversion.fromMEURToCurrency(opexCost)}
                        </td>
                        <td colSpan={3} />
                        <td>{props.unitConversion.fromMEURToCurrency(props.opexOverride.coe.value)}</td>
                    </tr>
                    <tr>
                        <td colSpan={6}> &nbsp; </td>
                    </tr>
                    <tr className={styles.row_header}>
                        <td />
                        <td>Energy (GWh/year)</td>
                        <td colSpan={4} />
                    </tr>
                    <tr className={styles.row}>
                        <td
                            style={{
                                textAlign: 'left',
                                fontWeight: 'bold'
                            }}
                        >
                            Net annual production
                        </td>
                        <td
                            className={isEdit ? styles.isEditActive : styles.isNotEditActive}
                            onClick={() =>
                                isEdit
                                    ? props.onDialogOpen('production', '', production, 'Net Annual Production')
                                    : null
                            }
                        >
                            {production} GWh
                        </td>
                        <td colSpan={4} />
                    </tr>
                </tbody>
            </table>
            <br />
        </>
    );
};
