/* eslint-disable */
import { LogLevelEnum } from '../../../LogView/AppLogLevelEnum';

export const ADD_LOG_LINE = 'ADD_LOG_LINE';
export const SET_VISIBILITY = 'SET_VISIBILITY';

export interface LogState {
    logs: LogLine[];
    visible: boolean;
}

export interface AddLogLineAction {
    type: typeof ADD_LOG_LINE;
    log: LogLine;
}

export interface SetVisibilityAction {
    type: typeof SET_VISIBILITY;
    visible: boolean;
}

export type LogAction = AddLogLineAction | SetVisibilityAction;

export interface LogLine {
    status: LogLevelEnum;
    time: Date;
    title: string;
    detail: string;
    details: any;
    response: any;
    request: any;
}
