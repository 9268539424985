import { Typography } from '@equinor/eds-core-react';
import { DialogWarning } from 'components/utils/DialogWarning';
import React from 'react';
import styles from './FloatingWarningDialog.module.css';
export interface ContentProps {
    depth: number;
    name: string;
}

export const FloatingWarningContent = (props: ContentProps): JSX.Element => {
    return (
        <div className={styles.textBox}>
            <Typography className={styles.header} variant="h2">
                Substructure selection
            </Typography>
            <Typography className={styles.mainText} variant="h4">
                {props.name} has an average depth of {props.depth} m. The floating substructure is not recommended for
                water depths less than 80 m due to complex mooring.
            </Typography>
        </div>
    );
};

export interface IProps {
    depth: number;
    name: string;
    handleClose: () => void;
    visibleScrim: boolean;
}
export const FloatingWarningDialog = (props: IProps): JSX.Element => {
    return (
        <DialogWarning
            visibleScrim={props.visibleScrim}
            handleClose={props.handleClose}
            isDismissable={true}
            Content={<FloatingWarningContent depth={props.depth} name={props.name} />}
        />
    );
};
