import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopBar, Typography, Tabs } from '@equinor/eds-core-react';
import styles from './NavBarMasterData.module.css';
import { Icon } from '@equinor/eds-core-react';

export const NavBarMasterData = (): JSX.Element => {
    const navigate = useNavigate();

    const getInitialTab = () => {
        const currentHref = window.location.pathname;
        if (currentHref.indexOf('monopile') > 0) return 1;
        if (currentHref.indexOf('electrical') > 0) return 2;
        if (currentHref.indexOf('cables') > 0) return 3;
        if (currentHref.indexOf('wtgindex') > 0) return 4;
        else return 5;
    };

    const [activeTab, setActiveTab] = useState(getInitialTab());

    const handleTabChange = (index: number) => {
        setActiveTab(index);
        if (index == 0) navigate('/');
        else if (index == 1) navigate('/foundations/experience-monopile');
        else if (index == 2) navigate('/config/electrical');
        else if (index == 3) navigate('/config/cables');
        else if (index == 4) navigate('/wtgindex');
    };

    return (
        <TopBar style={{ zIndex: 5000, backgroundColor: '#ffffff', borderBottom: '1px solid lightgrey' }}>
            <TopBar.Header>
                <div className={styles.NavBar} onClick={() => (location.href = '/')}>
                    <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.33277 10.2045L8.33313 23.0959C8.33277 23.478 8.52438 23.831 8.85534 24.0216L20.0259 30.457C20.4997 30.73 21.1041 30.388 21.1044 29.8409V16.9499C21.1048 16.5677 20.9011 16.2147 20.5698 16.0238L9.39959 9.58875C8.92537 9.31573 8.33348 9.65771 8.33277 10.2045ZM42.7387 0.138079L26.6568 9.40318C26.1804 9.67762 25.8868 10.1856 25.8875 10.7356V29.2953C25.8882 30.083 26.7581 30.575 27.4403 30.1819L43.5226 20.9175C43.999 20.6427 44.2745 20.1347 44.2737 19.5847L44.2741 1.02502C44.2734 0.23726 43.4213 -0.254736 42.7387 0.138079ZM20.6778 39.6119L16.2086 42.1871C16.0764 42.2635 15.9946 42.4046 15.9946 42.5575L15.9943 47.7149C15.9943 47.9342 16.2367 48.0708 16.4262 47.9616L20.8954 45.3868C21.028 45.3107 21.1048 45.1693 21.1044 45.0168V39.8586C21.1044 39.64 20.8673 39.5031 20.6778 39.6119ZM18.0732 34.853L11.38 30.9799C11.1817 30.8655 10.9371 30.8655 10.7387 30.9799L4.04558 34.853C3.76154 35.0172 3.76154 35.4271 4.04558 35.5917L10.7387 39.464C10.9371 39.5792 11.1817 39.5792 11.38 39.464L18.0732 35.5917C18.3572 35.4271 18.3572 35.0172 18.0732 34.853ZM28.8484 35.5512L31.8224 37.2717C31.9984 37.3737 32.2156 37.3737 32.3919 37.2717L35.3655 35.5512C35.6179 35.4047 35.6179 35.0403 35.3655 34.8942L32.3919 33.1736C32.2156 33.0716 31.9984 33.0716 31.8224 33.1736L28.8484 34.8942C28.596 35.0403 28.596 35.4047 28.8484 35.5512ZM26.4563 39.6922L29.4332 41.4071C29.6095 41.5088 29.7183 41.6972 29.7183 41.9009L29.7147 45.3363C29.7143 45.6278 29.3987 45.8102 29.1459 45.6648L26.1691 43.9499C25.9927 43.8483 25.8879 43.6591 25.8879 43.4562L25.8875 40.0207C25.8875 39.7296 26.2035 39.5465 26.4563 39.6922Z"
                            fill="#000000"
                        />
                    </svg>
                    <span>
                        <Typography className={styles.LogoText} variant="h2">
                            LCOE Master Data
                        </Typography>
                    </span>
                </div>
            </TopBar.Header>
            <TopBar.Actions>
                <Tabs activeTab={activeTab} onChange={handleTabChange} variant={'fullWidth'}>
                    <Tabs.List>
                        <Tabs.Tab className={styles.MenuItem}>
                            <Icon size={18} name={'world'} /> &nbsp; LCoE home
                        </Tabs.Tab>
                        <Tabs.Tab className={styles.MenuItem}>
                            <Icon size={18} name={'settings'} /> &nbsp; Foundation
                        </Tabs.Tab>
                        <Tabs.Tab className={styles.MenuItem}>
                            <Icon size={18} name={'settings'} /> &nbsp; Electrical config
                        </Tabs.Tab>
                        <Tabs.Tab className={styles.MenuItem}>
                            <Icon size={18} name={'cable'} /> &nbsp; Electrical cables
                        </Tabs.Tab>
                        <Tabs.Tab className={styles.MenuItem}>
                            <Icon size={18} name={'turbine'} /> &nbsp; WTG Index
                        </Tabs.Tab>
                    </Tabs.List>
                </Tabs>
            </TopBar.Actions>
        </TopBar>
    );
};
