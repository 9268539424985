import React, { useCallback, useContext, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import { LogLevel } from '@lcoe/lcoe-client';
import { UnitConversionContext } from '../uom/UnitConversionContext';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon } from '@equinor/eds-core-react';
import styles from './LogTreeView.module.css';

function max(...logs: any) {
    if (logs.includes(LogLevel.Error)) {
        return LogLevel.Error;
    }
    if (logs.includes(LogLevel.Warning)) {
        return LogLevel.Warning;
    }
    return LogLevel.Info;
}

function maxLevel(log: any) {
    const childLevels = (log.children || []).map((child: any) => maxLevel(child));
    return max(log.level, ...childLevels);
}

function getIcon(logLevel: any) {
    switch (logLevel) {
        case LogLevel.Error:
            return <Icon name={'error_outlined'} className={styles.errorIcon} />;
        case LogLevel.Warning:
            return <Icon name={'warning_outlined'} className={styles.warningIcon} />;
        default:
            return <Icon name={'trip_origin'} opacity={0} />;
    }
}

export function hasWarnings(log: any) {
    const maxLevelHereOrBelow = maxLevel(log);
    return maxLevelHereOrBelow === LogLevel.Error || maxLevelHereOrBelow === LogLevel.Warning;
}

export function LogTreeView({ log }: any) {
    const [open, setOpen] = useState(false);
    const unitConversion = useContext(UnitConversionContext);
    const toggleOpen = useCallback(() => setOpen(!open), [open]);
    const { desc, url, name, children, value } = log;

    const maxLevelHereOrBelow = maxLevel(log);

    const icon = getIcon(maxLevelHereOrBelow);

    const expandable = children && children.length > 0;

    const withTooltip = (component: any) => {
        if (!desc) {
            return component; // No tooltip
        }
        return <Tooltip title={desc}>{component}</Tooltip>;
    };

    const mainText =
        (url && (
            <Link href={url} target="_blank">
                {name}
            </Link>
        )) ||
        name;
    const normalizedValue = unitConversion.convertValueToString(value);
    if (!expandable) {
        return withTooltip(
            <ListItem style={{ position: 'relative', left: '4px' }}>
                <Icon name={'trip_origin'} opacity={0} /> {/* Hack to make sure we have equal spacing */}
                {icon}
                <ListItemText primary={mainText} secondary={normalizedValue} title={value} />
            </ListItem>
        );
    }

    return (
        <React.Fragment>
            <List dense disablePadding>
                {withTooltip(
                    <ListItem button onClick={toggleOpen}>
                        {icon}
                        {open ? <Icon name={'chevron_up'} /> : <Icon name={'chevron_up'} />}
                        <ListItemText primary={mainText} secondary={normalizedValue} title={value} />
                    </ListItem>
                )}
                {children && (
                    <Collapse in={open} className={styles.case_detail_indent}>
                        {children.map((child: any) => (
                            <LogTreeView log={child} key={child.id} />
                        ))}
                    </Collapse>
                )}
            </List>
        </React.Fragment>
    );
}
