import { Icon, Popover, Typography } from '@equinor/eds-core-react';
import cx from 'classnames';
import React, { useRef, useState } from 'react';
import styles from './PopoverInfo.module.css';
import CSS from 'csstype';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    title?: string;
    icon_text?: string;
    body_element?: JSX.Element;
}

export const PopoverInfo = (props: IProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    return (
        <div className={cx(styles.AdvancedQueryInfo, props.className)} style={props.style}>
            <div className={styles.advanced} onClick={() => setIsOpen(!isOpen)}>
                <div ref={anchorRef} className={styles.advancedtext}>
                    <Icon onClick={() => setIsOpen(!isOpen)} name={'info_circle'} title={'How to import'}></Icon>
                    {props.icon_text && <Typography>{props.icon_text}</Typography>}
                </div>
            </div>
            <Popover
                placement="bottom-end"
                id="click-popover"
                aria-expanded={isOpen}
                anchorEl={anchorRef.current}
                onClose={() => setIsOpen(!isOpen)}
                open={isOpen}
            >
                <Popover.Title>
                    <Typography variant="h5" className={styles.title}>
                        {props.title ? props.title : <span>&nbsp;</span>}
                    </Typography>
                </Popover.Title>
                <Popover.Content>
                    <Typography variant="body_long" className={styles.tips}>
                        {props.body_element && props.body_element}
                    </Typography>
                </Popover.Content>
            </Popover>
        </div>
    );
};
