/* eslint-disable */
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import styles from 'components/forms/SimpleCheckbox.module.css';
import * as PropTypes from 'prop-types';
import React from 'react';

export const SimpleCheckbox = ({ name, label, checked, onChange, wide, tiny, helperText }: any) => {
    return (
        <Grid item xs={tiny ? 6 : 12} sm={tiny ? 4 : wide ? 12 : 6}>
            <div className={styles.CheckBox}>
            <FormControlLabel
                label={
                    <React.Fragment>
                        {label}
                        {helperText && <FormHelperText>{helperText}</FormHelperText>}
                    </React.Fragment>
                }
                control={<Checkbox checked={checked || false} onChange={onChange} name={name} />}
            />
            </div>
        </Grid>
    );
};

SimpleCheckbox.propTypes = {
    name: PropTypes.any,
    label: PropTypes.any,
    checked: PropTypes.any,
    onChange: PropTypes.any,
    wide: PropTypes.any,
    tiny: PropTypes.any,
    helperText: PropTypes.any
};
