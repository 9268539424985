import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../components/ApiContext';
import { Case, CaseReference } from '@lcoe/lcoe-client';
import { clearCaseCompare } from '../redux/store/caseCompare/actions';
import { ApplicationState } from '../redux/store';
import { connect } from 'react-redux';
import CSS from 'csstype';
import cx from 'classnames';
import styles from './CaseCompareListPage.module.css';
import { PageHeader } from '../elements/visual/PageHeader';
import { ListTable } from '../elements/visual/ListTable';
import { UnitConversion } from '../uom/UnitConversion';
import { Button } from '@equinor/eds-core-react';
import * as Redux from 'redux';
import { getTableData, getTableHeader } from './CaseCompareTableGenerator';
import { ChartDialog } from './ChartDialog';
import { setCurrentEPA } from '../Settings/EpaUtils';
import { NavBar } from '../components/NavBar';

const mapStateToCaseCompareListProps = (state: ApplicationState) => {
    return {
        compareCases: state.caseCompare
    };
};

interface ICaseCompareProps {
    compareCases: CaseReference[];
}

export const CaseCompareListPageComponent = (props: ICaseCompareProps): JSX.Element => {
    const api = useContext(ApiContext);
    const [cases, setCases] = useState([] as Case[]);

    useEffect(() => {
        api.getCompareCases(props.compareCases).then((response) => {
            setCases(response.cases);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api]);

    return <CaseCompareList cases={cases}></CaseCompareList>;
};

export const CaseCompareListPage = connect(mapStateToCaseCompareListProps)(CaseCompareListPageComponent);

const mapDispatchToProps = (dispatch: Redux.Dispatch) => {
    return {
        clear: () => dispatch(clearCaseCompare())
    };
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        unitConversion: state.unitConversion
    };
};

interface IProps {
    style?: CSS.Properties;
    className?: string;
    cases: Case[];
    unitConversion: UnitConversion;
    clear: () => void;
}

const CaseCompareListComponent = (props: IProps): JSX.Element => {
    setCurrentEPA();

    const header_columns = getTableHeader();
    const datarows = getTableData(props.cases, props.unitConversion) as unknown as Case[];

    const [visibleDialog, setVisibleDialog] = useState(false);
    const handleDialogClose = () => {
        setVisibleDialog(!visibleDialog);
    };

    const clearCompareCases = () => {
        props.clear();
        location.href = '/cases';
    };

    const element = (
        <div className={styles.AlignButtons}>
            <div className={styles.padding}>
                {datarows.length > 0 && <Button href="/cases/comparefull">Drilldown for excel export</Button>}
                &nbsp; <Button onClick={() => clearCompareCases()}>Clear all cases</Button> &nbsp;
                {datarows.length > 0 && <Button onClick={() => setVisibleDialog(true)}>Charts</Button>}
            </div>
        </div>
    );

    return (
        <>
            <NavBar />
            <div className={cx(styles.CaseCompareListPage, props.className)} style={props.style}>
                <PageHeader title={'Compare cases'} right_element={element}></PageHeader>
                {datarows.length > 0 && <ListTable datarows={datarows} columns={header_columns}></ListTable>}
                {datarows.length == 0 && (
                    <div className={styles.no_cases}>
                        No cases selected &mdash; nothing to compare. Select some cases and come back later.
                    </div>
                )}

                <ChartDialog
                    handleDialogClose={handleDialogClose}
                    uc={props.unitConversion}
                    cases={props.cases}
                    open={visibleDialog}
                />
            </div>
        </>
    );
};

const CaseCompareList = connect(mapStateToProps, mapDispatchToProps)(CaseCompareListComponent);
