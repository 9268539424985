export function extractValue(target: any) {
    switch (target.type) {
        case 'checkbox':
            return target.checked;
        case 'number':
            if (target.value.trim() === '') {
                return undefined;
            }
            const v = Number(target.value);
            if (isNaN(v)) {
                return undefined;
            }
            return v;
        default:
            return target.value;
    }
}

export async function writeToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return null;
    }
}

export function getVersion(): number | undefined {
    const urlParams = new URLSearchParams(window.location.search);
    const v = urlParams.get('version');

    if (!v || typeof v !== 'string') {
        return undefined;
    }

    return parseInt(v);
}

export function getNewCopy(): boolean {
    const urlParams = new URLSearchParams(window.location.search);
    const v = urlParams.get('copy');

    if (!v) {
        return false;
    }

    return v == 'true' ? true : false;
}

export function isObjectSubset(subsetObj: { [prop: string]: any }, supersetObj: { [prop: string]: any }): boolean {
    if (subsetObj === undefined || supersetObj === undefined) {
        return subsetObj === undefined && supersetObj === undefined;
    }

    const subsetObjProps = Object.getOwnPropertyNames(subsetObj);

    for (const subsetProp of subsetObjProps) {
        if (subsetObj[subsetProp] !== supersetObj[subsetProp]) {
            return false;
        }
    }

    return true;
}
