import { useEffect } from 'react';
import * as esri from 'esri-leaflet';
import { useMap } from 'react-leaflet';

export const OceanLayer = (): null => {
    const map = useMap();

    useEffect(() => {
        esri.basemapLayer('Oceans').addTo(map);
        esri.basemapLayer('OceansLabels').addTo(map);
    }, [map]);

    return null;
};
