import { Location } from '@lcoe/lcoe-client';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isUserLocationAdmin } from 'utils/permission/permissionUtil';
import { locationValidationModel } from '../Api/model/SwaggerSpec';
import { Problems, problemsExist } from '../Api/Problems';
import { ApplicationState } from '../redux/store';
import { UserState } from '../redux/store/user/types';
import { LocationEditForm } from './LocationEditForm';
import styles from './LocationEditCard.module.css';

interface LocationEditCardProps {
    isLocationAdmin: boolean;
    user: UserState;
    location: Location;
    locationChange: (diff: Partial<Location>) => void;
    save: () => void;
    deleteLocation: () => void;
}

const LocationEditCardComponent = ({
    isLocationAdmin,
    user,
    location,
    locationChange,
    save,
    deleteLocation
}: LocationEditCardProps): JSX.Element => {
    const [problems, setProblems] = useState({} as Problems);

    function validate() {
        if (location) {
            const new_problems = locationValidationModel.validate(location);
            if (new_problems !== undefined) {
                setProblems(new_problems);
            }
        }
    }

    useEffect(validate, [location]);

    const hasProblems = problemsExist(problems);
    const createdThisLocation = 'username' in user && user.username === location.createdBy;
    const canEdit = isLocationAdmin || createdThisLocation;

    return (
        <Card className={styles.card_margin}>
            <CardContent>
                <div className={styles.card_hider_wrapper}>
                    {!canEdit && <div className={styles.card_hider} />}
                    <LocationEditForm location={location} locationChange={locationChange} problems={problems} />
                </div>
            </CardContent>
            {canEdit && (
                <CardActions style={{ paddingRight: 18 }}>
                    <Button className={styles.button_right} color="secondary" onClick={deleteLocation}>
                        Delete
                    </Button>
                    <Button onClick={save} disabled={hasProblems} variant="contained" color="primary">
                        Update
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        isLocationAdmin: isUserLocationAdmin(state),
        user: state.user
    };
};

const LocationEditCard = connect(mapStateToProps)(LocationEditCardComponent);
export default LocationEditCard;
