import { Capex, Opex, ValueWithUnit } from '@lcoe/lcoe-client';
import { Unit } from 'mathjs';
import { add, asUnitObj, unaryMinus } from '../../uom/UnitConversion';
import { CoeNode } from './ResultUtils';

export class CoeCapexNode implements CoeNode<Capex> {
    constructor(private item: Capex, private parent?: CoeCapexNode) {}

    get name(): string {
        return this.item.name;
    }

    get totalCost(): Unit {
        if (this.parent) {
            return this.parent.totalCost;
        }
        return this.cost;
    }

    get children(): CoeCapexNode[] {
        if (!this.item.children) {
            return [];
        }
        return this.item.children.map((child) => new CoeCapexNode(child, this));
    }

    get cost(): Unit {
        return asUnitObj(this.item.cost);
    }

    get percentCost(): number {
        return this.cost.divide(this.totalCost) as unknown as number;
    }

    get learningEffect(): Unit {
        return unaryMinus(asUnitObj(this.item.learningEffect)); // Returned learning effect is negative. Use positive
    }

    get learningEffectPercent(): number {
        const costPlusLearning = add(this.cost, this.learningEffect);
        if (costPlusLearning.toJSON().value === 0) return 0;
        return this.learningEffect.divide(costPlusLearning) as unknown as number;
    }

    get coe(): Unit {
        return asUnitObj(this.item.coe);
    }

    get capexBase(): ValueWithUnit | undefined {
        return this.item.capexBase;
    }

    get quantity(): ValueWithUnit {
        return { value: this.item.quantity as number, unit: this.item.unit as string };
    }

    get contingency(): ValueWithUnit | undefined {
        return this.item.contingency;
    }

    get overrideNodeIsActive(): boolean | undefined {
        return this.item.overrideNodeIsActive;
    }
}

export class CoeOpexNode implements CoeNode<Opex> {
    constructor(private item: Opex, private parent?: CoeOpexNode) {}

    get name(): string {
        return this.item.name;
    }

    get totalCost(): Unit {
        if (this.parent) {
            return this.parent.totalCost;
        }
        return this.cost;
    }

    get children(): CoeOpexNode[] {
        if (!this.item.children) {
            return [];
        }
        return this.item.children.map((child) => new CoeOpexNode(child, this));
    }

    // This is an annual cost
    get cost(): Unit {
        return asUnitObj(this.item.cost);
    }

    get percentCost(): number {
        return this.cost.divide(this.totalCost) as unknown as number;
    }

    get learningEffect(): Unit {
        return unaryMinus(asUnitObj(this.item.learningEffect)); // Returned learning effect is negative. Use positive
    }

    get learningEffectPercent(): number {
        const costPlusLearning = add(this.cost, this.learningEffect);
        if (costPlusLearning.toJSON().value === 0) return 0;
        return this.learningEffect.divide(costPlusLearning) as unknown as number;
    }

    get coe(): Unit {
        return asUnitObj(this.item.coe);
    }
}
