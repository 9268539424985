import { Cable, Cables } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import CSS from 'csstype';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { isUserCableAdmin } from 'utils/permission/permissionUtil';
import { TableEditor } from '../components/tableeditor/TableEditor';
import { ApplicationState } from '../redux/store';
import { DialogOnSave } from '../components/tableeditor/DialogOnSave';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    cables: Cables;
}

export const CableList = (props: IProps): JSX.Element => {
    const user = useSelector((state: ApplicationState) => state.user);
    const [savedData, setSavedData] = useState(false);
    const isCableAdmin = isUserCableAdmin(user);
    const api = useContext(ApiContext);

    const columns = [
        { name: 'Outdated', type: 'select_single', select_options: 'Outdated,' },
        { name: 'Cross section (mm^2)', type: 'number', select_options: '' },
        { name: 'Cost (kEUR/km)', type: 'number', select_options: '' },
        { name: 'Charging current 50 (A)', type: 'number', select_options: '' },
        { name: 'Charging current 60 (A)', type: 'number', select_options: '' },
        { name: 'Current rating (A)', type: 'number', select_options: '' },
        { name: 'Resistance 50 (ohm/km)', type: 'number', select_options: '' },
        { name: 'Resistance 60 (ohm/km)', type: 'number', select_options: '' },
        { name: 'Weight (tonne/km)', type: 'number', select_options: '' },
        { name: 'Conductor material', type: 'select_single', select_options: 'Copper,Aluminium' },
        {
            name: 'Category',
            type: 'select_single',
            select_options:
                'AC collection grid,AC export onshore,AC export offshore,DC export onshore,DC export offshore'
        },
        { name: 'Voltage', type: 'select_single', select_options: '33,66,132,220,300' }
    ];

    const dataRows = [] as Array<string[]>;

    props.cables.cables.map((el: Cable) =>
        dataRows.push([
            el.outdated === false ? '' : 'Outdated',
            el.crossSection.value === undefined ? '' : String(el.crossSection.value),
            el.cost.value === undefined ? '' : String(el.cost.value),
            el.chargingCurrent50.value === undefined ? '' : String(el.chargingCurrent50.value),
            el.chargingCurrent50.value === undefined ? '' : String(el.chargingCurrent60.value),
            el.currentRating.value === undefined ? '' : String(el.currentRating.value),
            el.resistance50.value === undefined ? '' : String(el.resistance50.value),
            el.resistance60.value === undefined ? '' : String(el.resistance60.value),
            el.weight.value === undefined ? '' : String(el.weight.value),
            el.conductorMaterial && el.conductorMaterial === undefined
                ? ''
                : el.conductorMaterial
                ? el.conductorMaterial
                : 'Unknown',
            el.category === undefined ? '' : String(el.category),
            el.voltage === undefined ? '' : String(el.voltage)
        ])
    );
    const saveDataset = (dataset: string[]) => {
        const saveCables = {} as Cables;
        const cables = [] as Cable[];
        dataset.map((el: string) => {
            cables.push({
                length: { value: 0, unit: 'km' },
                outdated: el[0] === 'Outdated' ? true : false,
                crossSection: el[1] === '' ? { unit: 'mm^2' } : { value: Number(el[1]), unit: 'mm^2' },
                cost: el[2] === '' ? { unit: 'kEUR/km' } : { value: Number(el[2]), unit: 'kEUR/km' },
                chargingCurrent50: el[3] === '' ? { unit: 'A' } : { value: Number(el[3]), unit: 'A' },
                chargingCurrent60: el[4] === '' ? { unit: 'A' } : { value: Number(el[4]), unit: 'A' },
                currentRating: el[5] === '' ? { unit: 'A' } : { value: Number(el[5]), unit: 'A' },
                resistance50: el[6] === '' ? { unit: 'ohm/km' } : { value: Number(el[6]), unit: 'ohm/km' },
                resistance60: el[7] === '' ? { unit: 'ohm/km' } : { value: Number(el[7]), unit: 'ohm/km' },
                weight: el[8] === '' ? { unit: 'tonne/km' } : { value: Number(el[8]), unit: 'tonne/km' },
                conductorMaterial: el[9],
                category: el[10],
                voltage: Number(el[11])
            } as Cable);
        });

        saveCables.cables = cables;
        saveCables.cableConfigVersion = 1;

        const promise = api.saveCables(saveCables as Cables);

        promise.then((savedCase) => {
            if (savedCase.cables.length > 0) {
                setSavedData(true);
            }
        });
    };

    return (
        <>
            <DialogOnSave
                dialog_text={'Cable data has been saved.'}
                savedOpen={savedData}
                setSaved={setSavedData}
            ></DialogOnSave>
            <TableEditor
                title={'Cables'}
                saveDataset={saveDataset}
                columns={columns}
                datarows={dataRows}
                isEditor={isCableAdmin}
            ></TableEditor>
        </>
    );
};
