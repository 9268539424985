import { Location } from '@lcoe/lcoe-client';
import Grid from '@material-ui/core/Grid';
import { ApiContext } from 'components/ApiContext';
import { BeforeUnload } from 'components/BeforeUnload';
import LoadingDialog from 'components/LoadingDialog';
import { LocationWithId } from 'interface/ILocations';
import LocationEditCard from 'Location/LocationEditCard';
import WeatherFilesCard from 'Location/WeatherFilesCard';
import React, { useContext, useEffect, useState } from 'react';

export interface LocationDetailsForProps {
    locationWithId: LocationWithId;
    deleteLocation: () => void;
}

export const LocationDetailsFor = ({ locationWithId, deleteLocation }: LocationDetailsForProps): JSX.Element => {
    const [location, setLocation] = useState<LocationWithId>(locationWithId);
    const [saving, setSaving] = useState(false);
    const [modified, setModified] = useState(false);

    useEffect(() => {
        setLocation(locationWithId);
    }, [locationWithId]);

    const api = useContext(ApiContext);

    function locationChange(diff: Partial<Location>) {
        const updatedLocation: LocationWithId = { ...location, ...diff } as LocationWithId;
        setModified(true);
        setLocation(updatedLocation);
    }

    function save() {
        setSaving(true);
        api.updateLocation(location.id, location)
            .then((updatedLocation: Location) => {
                setModified(false);
                setLocation(updatedLocation as LocationWithId);
            })
            .finally(() => setSaving(false));
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <LocationEditCard
                        location={location}
                        locationChange={locationChange}
                        save={save}
                        deleteLocation={deleteLocation}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <WeatherFilesCard location={location} />
                </Grid>
            </Grid>
            <LoadingDialog open={saving} />
            <BeforeUnload when={modified && !saving} />
        </>
    );
};
