/* eslint-disable */
import React, { useState } from 'react';
import { SchemaField } from '../../components/forms/SchemaField';
import { SwaggerSchema } from '../../Api/model/SwaggerSpec';
import { Problems } from '../../Api/Problems';
import { Grid, IconButton } from '@material-ui/core';
import { FileResponseWithMetadata, WeatherFileMeta } from '../../Location/WeatherFileMeta';
import { useWeatherFile } from './hooks/useWeatherFile';
import { Icon } from '@equinor/eds-core-react';

interface WeatherFileFieldProps {
    name: string;
    options: any[];
    disabled: boolean;
    schema: SwaggerSchema;
    object: any;
    onChange: (diff: { [key: string]: any }) => void;
    problems: Problems;
    warnings: Problems;
}

export const WeatherFileField = ({
    name,
    options,
    disabled,
    schema,
    object,
    onChange,
    problems,
    warnings
}: WeatherFileFieldProps) => {
    const { weatherFile } = useWeatherFile(object);
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <Grid item sm={6}>
            <Grid container alignItems="center">
                <SchemaField
                    name={name}
                    options={options}
                    disabled={disabled}
                    schema={schema}
                    object={object}
                    onChange={onChange}
                    problems={problems}
                    warnings={warnings}
                    sm={11}
                />
                <Grid item sm={1}>
                    <IconButton
                        aria-label={'viewWeatherFileButton'}
                        size={'medium'}
                        onClick={handleOpen}
                        disabled={!weatherFile}
                    >
                        <Icon name={'visibility'} />
                    </IconButton>
                </Grid>
                {openDialog && (
                    <WeatherFileMeta
                        location={object.location}
                        weatherFile={weatherFile as FileResponseWithMetadata}
                        onDelete={() => {}}
                        visibleDialog={true}
                        setVisibleDialog={handleClose}
                        showDelete={false}
                    />
                )}
            </Grid>
        </Grid>
    );
};
