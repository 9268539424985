import { DialogWarning } from 'components/utils/DialogWarning';
import React from 'react';

interface IProps {
    visibleScrim: boolean;
    handleClose: () => void;
}

export const JacketWarningDialog = (props: IProps): JSX.Element => {
    return (
        <DialogWarning
            visibleScrim={props.visibleScrim}
            handleClose={props.handleClose}
            isDismissable={true}
            Content={<JacketWarningContent />}
        />
    );
};

const JacketWarningContent = (): JSX.Element => {
    return (
        <div>
            <h2> Disclaimer</h2>
            <div>
                The jacket model in the LCoE-tool underestimates the substructure weight for water depths greater than
                65 m.
            </div>
            <div>It also underestimates the substructure weight for areas prone to earthquakes and typhoons.</div>
        </div>
    );
};
