/* eslint-disable */
import {
    ADD_CASE_COMPARE,
    CaseCompareAction,
    CaseRef,
    CLEAR_CASE_COMPARE,
    REMOVE_CASE_COMPARE,
    TOGGLE_CASE_COMPARE
} from './types';
import { ApplicationState } from '../index';

const LOCAL_STORE_KEY = 'caseCompare';

function loadLocalStore(): CaseRef[] {
    const v = window.localStorage.getItem(LOCAL_STORE_KEY);
    if (!v) {
        return [];
    }
    try {
        return JSON.parse(v);
    } catch (_) {
        return [];
    }
}
function saveLocalStore(cases: CaseRef[]) {
    window.localStorage.setItem(LOCAL_STORE_KEY, JSON.stringify(cases));
}

function equals(caseRef1: CaseRef, caseRef2: CaseRef) {
    return caseRef1.id === caseRef2.id && caseRef1.version === caseRef2.version;
}

function contains(caseCompareList: CaseRef[], caseRef: CaseRef) {
    return !!caseCompareList.find((c) => equals(c, caseRef));
}

export const caseCompareSelected = (state: ApplicationState, cazeRef: CaseRef) => {
    return contains(state.caseCompare, cazeRef);
};

function clean(caseRef: CaseRef): CaseRef {
    return { version: caseRef.version, id: caseRef.id };
}

export function caseCompareReducer(caseCompareList: CaseRef[] = loadLocalStore(), action: CaseCompareAction) {
    const add = (caseRef: CaseRef) => [...caseCompareList, clean(caseRef)];
    const remove = (caseRef: CaseRef) => caseCompareList.filter((c) => !equals(c, caseRef));
    const persist = (cases: CaseRef[]) => {
        saveLocalStore(cases);
        return cases;
    };
    switch (action.type) {
        case ADD_CASE_COMPARE:
            if (contains(caseCompareList, action.caseRef)) {
                return caseCompareList;
            }
            return persist(add(action.caseRef));
        case REMOVE_CASE_COMPARE:
            return persist(remove(action.caseRef));
        case CLEAR_CASE_COMPARE:
            return persist([]);
        case TOGGLE_CASE_COMPARE:
            if (contains(caseCompareList, action.caseRef)) {
                return persist(remove(action.caseRef));
            }
            return persist(add(action.caseRef));
        default:
            return caseCompareList;
    }
}
