/* eslint-disable */
import XLSX from 'xlsx';
import { Turbine, TurbinePerformanceTableRow } from '@lcoe/lcoe-client';

function excelDateToDate(excelDate: number): Date {
    return new Date(Math.round((excelDate - 25569) * 86400 * 1000));
}

export function parseTurbineFromExcel(data: any): Partial<Turbine> {
    const d = XLSX.read(data);
    const powerCurve: any[] = XLSX.utils.sheet_to_json(d.Sheets.PowerCurve);
    const propertiesRows: any[][] = XLSX.utils.sheet_to_json(d.Sheets.Properties, { header: 1 });

    const properties: { [key: string]: any } = propertiesRows.reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {});

    const performanceTable: TurbinePerformanceTableRow[] = powerCurve.map((row) => ({
        thrustCoefficient: row.Ct,
        powerOutputWatt: row['Power output (kW)'] * 1000,
        windSpeedMps: row['Wind speed (m/s)']
    }));

    return {
        name: properties['WTG Description'],
        airGap: properties['Air Gap'],
        towerMass: properties['Tower Mass'],
        topHeadMass: properties['Top Head Mass'],
        capacity: properties['Rated Capacity'],
        rotorDiameter: properties['Rotor Diameter'],
        dateAvailable: excelDateToDate(properties['Date Available']),
        profile: {
            performanceTable,
            comments: 'Imported from XLSX file'
        }
    };
}
