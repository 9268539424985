import { combineReducers, Reducer } from 'redux';
import { UnitConversion } from '../../uom/UnitConversion';
import { unitConversionReducer } from './unitSystem/reducer';
import { caseCompareReducer } from './caseCompare/reducer';
import { CaseRef } from './caseCompare/types';
import { caseCopyReducer } from './caseCopy/reducer';
import { logReducer } from './log/reducer';
import { LogState } from './log/types';
import { userReducer } from './user/reducer';
import { UserState } from './user/types';
import { mapReducer } from './map/reducer';
import { MapState } from './map/types';
import { Case } from '@lcoe/lcoe-client';

export const appReducers: Reducer<ApplicationState> = combineReducers({
    unitConversion: unitConversionReducer,
    caseCompare: caseCompareReducer,
    caseCopy: caseCopyReducer,
    log: logReducer,
    user: userReducer,
    map: mapReducer
});

export interface ApplicationState {
    unitConversion: UnitConversion;
    caseCompare: CaseRef[];
    caseCopy: Case | null;
    log: LogState;
    user: UserState;
    map: MapState;
}
