import { FileUpload } from '@lcoe/lcoe-client';
import React from 'react';
import { UploadButton } from '../UploadButton';
import { PageHeader } from '../../elements/visual/PageHeader';
import styles from './LoadTurbineProfile.module.css';
import { Typography } from '@equinor/eds-core-react';

interface IProps {
    onLoadProfile: (file: FileUpload) => void;
}

export const LoadTurbineProfile = (props: IProps): JSX.Element => {
    return (
        <div className={styles.LoadTurbineProfile}>
            <PageHeader title={'New turbine profile'} />

            <div className={styles.content}>
                <div className={styles.upload_box}>
                    <Typography variant={'h4'}>Step 1 - Upload profile</Typography>
                    <br />
                    <Typography variant={'body_short'}>
                        To create a new turbine, please upload the turbine profile. If you need an example you can{' '}
                        <a href={'/files/turbine_profile_template.xlsx'}>download a profile template</a>.
                    </Typography>
                    <br />
                    <UploadButton
                        id="turbine-import-button"
                        onUpload={props.onLoadProfile}
                        accept={'.xml,.xls,.xlsx'}
                        variant="outlined"
                    >
                        Import from Excel or XML
                    </UploadButton>
                </div>
            </div>
        </div>
    );
};
