/* eslint-disable */
import { CompensationType } from '@lcoe/lcoe-client';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { TurbineEditForm } from 'components/turbine/TurbineEditForm';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { CurrentType } from '../../Api/grid-options';
import { caseValidationModel } from '../../Api/model/SwaggerSpec';
import { wtgIndexOptions } from '../../Api/wtg-index-options';
import { FormSection } from '../../components/forms/FormSection';
import { SchemaField } from '../../components/forms/SchemaField';
import { SimpleChipList } from '../../components/forms/SimpleChipList';
import { SimpleCommentsField } from '../../components/forms/SimpleCommentsField';
import { SimpleSelect } from '../../components/forms/SimpleSelect';
import { SimpleTagsField } from '../../components/SimpleTagsField';
import { extractValue } from '../../components/utils/helpers';
import { LocationEditForm } from '../../Location/LocationEditForm';
import { WtgCountAdornment } from '../WtgCountAdornment';
import { WtgSpacingAdornment } from '../WtgSpacingAdornment';
import './CaseEditForm.css';
import { CheckboxTable } from './CheckboxTable';
import { OptionSelectField } from './OptionSelectField';
import { WeatherFileField } from './WeatherFileField';

const frequencyOptions = [
    { name: '50 Hz', value: 50 },
    { name: '60 Hz', value: 60 }
];

const compensationOptions = [] as any;
for (const value of Object.values(CompensationType)) {
    compensationOptions.push(value);
}

const renderLocationEditForm = ({ editingObject, objectChange, problems }: any) => (
    <LocationEditForm location={editingObject} locationChange={objectChange} problems={problems} localEdit />
);

const renderWtgEditForm = ({ editingObject, objectChange, problems }: any) => (
    <TurbineEditForm turbine={editingObject} turbineChange={objectChange} problems={problems} localEdit />
);

const UNIQUE_OBJECT = () => ({});

export const CaseEditForm = memo(
    ({
        caseChange,
        locationOptions,
        wtgOptions,
        foundationOptions,
        collectionGridOptions,
        exportGridOptions,
        onmStrategyOptions,
        infrastructureOptions,
        caze,
        problems = {},
        warnings = {}
    }: any) => {
        const handleCaseChange = useCallback(
            (event) => {
                const target = event.target;
                const name = target.name;
                const value = extractValue(target);
                const diff = { [name]: value };

                caseChange(diff);
            },
            [caseChange]
        );

        const handleCheckboxTableChange = useCallback(
            (name, value) => {
                caseChange({ infrastructureSelection: value });
            },
            [caseChange]
        );

        const [infrastructure, setInfrastructure] = useState(null);
        const infrastructureChange = useCallback(
            (evt) => {
                setInfrastructure(evt.target.value);
                caseChange({ infrastructureSelection: evt.target.value.infrastructureSelection });
            },
            [caseChange]
        );

        const schemaProps = {
            schema: caseValidationModel.schema,
            object: caze,
            onChange: caseChange,
            problems,
            warnings,
            tiny: true
        };

        return (
            <form autoComplete="off">
                <FormSection legend="Case details">
                    <SchemaField name="name" sm={6} {...schemaProps} />
                    <Grid item xs={2}>
                        <TextField
                            value={caze.epaAssumptions}
                            label={'EPA Assumptions'}
                            fullWidth={true}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <SchemaField name="fid" sm={4} {...schemaProps} />
                    <SchemaField name="discountRatePercent" sm={4} {...schemaProps} />
                    <SchemaField name="parkLifeYears" sm={4} {...schemaProps} />
                    <SchemaField name="otherLossesPercent" sm={4} {...schemaProps} />
                    <SchemaField name="discountSubsidyRatePercent" sm={4} {...schemaProps} />
                    <SchemaField name="parkSubsidyYears" sm={4} {...schemaProps} />
                    <SchemaField
                        name="learningEffectOptions"
                        sm={4}
                        {...schemaProps}
                        helperTextFromObject={() => 'Yearly cost reduction due to development of the industry'}
                    />
                </FormSection>

                <FormSection legend="Location">
                    <OptionSelectField
                        name="location"
                        options={locationOptions}
                        renderEditForm={renderLocationEditForm}
                        {...schemaProps}
                    />

                    <WeatherFileField
                        name="weatherFile"
                        options={caze.location ? caze.location.files : []}
                        disabled={!caze.location}
                        {...schemaProps}
                    />
                </FormSection>

                <FormSection legend="Wind farm definition">
                    <OptionSelectField
                        name="wtg"
                        options={wtgOptions}
                        renderEditForm={renderWtgEditForm}
                        {...schemaProps}
                        sm={12}
                    />
                    <SchemaField name="wtgIndexProfile" options={wtgIndexOptions} {...schemaProps} />
                    <SchemaField
                        name="wtgCount"
                        dependentOn={useMemo(UNIQUE_OBJECT, [
                            caze.wtg,
                            caze.location,
                            caze.wtgCount,
                            caze.wtgSpacingNd
                        ])}
                        helperTextFromObject={(caze) =>
                            caze.wtgCount && `Installed capacity: ${caze.wtg.capacity * caze.wtgCount} MW`
                        }
                        endAdornmentComponentRender={(props: any) => <WtgCountAdornment {...props} />}
                        {...schemaProps}
                    />
                    <SchemaField
                        name="wtgSpacingNd"
                        dependentOn={useMemo(UNIQUE_OBJECT, [caze.wtg, caze.location, caze.wtgCount])}
                        helperTextFromObject={(caze) => `${Math.round(caze.wtg.rotorDiameter * caze.wtgSpacingNd)} m`}
                        endAdornmentComponentRender={(props: any) => <WtgSpacingAdornment {...props} />}
                        {...schemaProps}
                    />

                    <SchemaField name="foundation" options={foundationOptions} {...schemaProps} />
                </FormSection>

                <FormSection legend="Electrical infrastructure">
                    <SimpleSelect
                        label="Assign from template"
                        sm={3}
                        value={infrastructure}
                        options={infrastructureOptions}
                        onChange={infrastructureChange}
                        required={false}
                    />
                    <Grid item xs={12} sm={9}>
                        <CheckboxTable
                            value={caze.infrastructureSelection}
                            problem={problems.infrastructureSelection}
                            name="infratructureSelection"
                            onChange={handleCheckboxTableChange}
                            dc={caze.exportSolution && caze.exportSolution.name.includes(CurrentType.DC)}
                        />
                    </Grid>

                    <SchemaField name="collectionGrid" options={collectionGridOptions} {...schemaProps} />
                    <SchemaField name="exportSolution" options={exportGridOptions} {...schemaProps} />
                    <SchemaField name="frequency" options={frequencyOptions} {...schemaProps} />
                    <SchemaField name="reactiveCompensation" options={compensationOptions} {...schemaProps} />
                </FormSection>

                <FormSection legend="Operations & maintenance">
                    <SchemaField
                        name="onmStrategy"
                        options={onmStrategyOptions}
                        sm={4}
                        helperTextFromObject={(caze: any) =>
                            `Distance to O&M port in ${caze.location.name}: ${caze.location.distanceToOandMPort}km`
                        }
                        {...schemaProps}
                    />
                    <SchemaField name="landLeaseOffshoreKnok" sm={4} {...schemaProps} />
                    <SchemaField name="gridTariffsKnok" sm={4} {...schemaProps} />
                </FormSection>
                <FormSection legend="Metadata">
                    <SimpleTagsField onChange={caseChange} value={caze.tags} />
                    <SimpleCommentsField name="comments" onChange={handleCaseChange} value={caze.comments} />
                </FormSection>

                <FormSection legend="Access control">
                    <SimpleChipList
                        label="Shared with"
                        helperText="Username or 'all'. (NB! Users also need access to Location and WTG for the case to be shared.)"
                        selectedItems={caze.sharedWith || []}
                        onSelectedItemsChange={(items: any) => {
                            const stripped = [];
                            for (const item of items) {
                                if (item.indexOf('@') >= 0) {
                                    stripped.push(item.split('@')[0]);
                                } else {
                                    stripped.push(item);
                                }
                            }

                            caseChange({ sharedWith: stripped });
                        }}
                    />
                </FormSection>
            </form>
        );
    }
);
