/* eslint-disable */

import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import { compose, createStore } from 'redux';
import { appReducers } from './redux/store';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const isIE11 = !!window.navigator.userAgent.indexOf('MSIE ');
const doBlockIe11 = false;
if (isIE11 && doBlockIe11) {
    console.log('Warning: Blocking IE11');
    document.body.innerHTML =
        "<h1 style='text-align: center; padding-top: 50px'>Internet Explorer is not supported. Please use Google Chrome</h1>";
} else {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(appReducers, composeEnhancers());

    render(
        <Provider store={store}>
            <App />
        </Provider>,
        document.getElementById('root')
    );
}
unregister();
