import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setUserState } from '../redux/store/user/actions';
import { devUser } from '../redux/store/user/reducer';
import {
    LoginState,
    RoleCableAdmin,
    RoleCostEstimator,
    RoleFoundationAdmin,
    RoleLocationAdmin,
    RoleWtgAdmin,
    UserState
} from '../redux/store/user/types';
import { Button, Typography } from '@equinor/eds-core-react';
import styles from './DebugPane.module.css';

export interface DebugPaneProps {
    onUserInfoChange: (user: UserState) => void;
}

const DebugPane = ({ onUserInfoChange }: DebugPaneProps) => {
    return (
        <div className={styles.debug_pane}>
            <div>
                <Typography variant={'h4'}>Debug login state</Typography>
            </div>
            <Button
                onClick={() => {
                    onUserInfoChange({ loginState: LoginState.FAILED, name: 'Manually failed by button' });
                }}
            >
                Fail login
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({
                        loginState: LoginState.TIMED_OUT,
                        name: 'Manually timed out by button'
                    });
                }}
            >
                Time out login
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({
                        loginState: LoginState.BEFORE,
                        name: 'Manually before-login by button'
                    });
                }}
            >
                Before login
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({ ...devUser, roles: ['user'], accessToken: 'user' });
                }}
            >
                Login as user
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({ ...devUser, roles: [RoleWtgAdmin], accessToken: RoleWtgAdmin });
                }}
            >
                Login as WTG admin
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({ ...devUser, roles: [RoleCableAdmin], accessToken: RoleCableAdmin });
                }}
            >
                Login as Electrical admin
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({
                        ...devUser,
                        roles: [RoleFoundationAdmin],
                        accessToken: RoleFoundationAdmin
                    });
                }}
            >
                Login as Foundation admin
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({
                        ...devUser,
                        roles: [RoleLocationAdmin],
                        accessToken: RoleLocationAdmin
                    });
                }}
            >
                Login as location admin
            </Button>
            <Button
                onClick={() => {
                    onUserInfoChange({
                        ...devUser,
                        roles: [RoleCostEstimator],
                        accessToken: RoleCostEstimator
                    });
                }}
            >
                Login as cost estimator
            </Button>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        onUserInfoChange: (userState: UserState) => dispatch(setUserState(userState))
    };
};

export default connect(null, mapDispatchToProps)(DebugPane);
