/* eslint-disable */
import * as mathjs from 'mathjs';
import {Unit} from 'mathjs';
import {ValueWithUnit} from '@lcoe/lcoe-client';

declare module 'mathjs' {
    /**
     * Extends mathjs with properties not in @types/mathjs
     */
    interface Unit {
        simplify(): Unit;
        fixPrefix: boolean;
        automaticSimplification: boolean;
    }
}

function nok(n: number) {
    return `${n} NOK`;
}

// Currencies
export const updateCurrencyUnits = (
    USD_NOK: number,
    GBP_NOK: number,
    EUR_NOK: number,
    SEK_NOK: number,
    DKK_NOK: number
) => {
    mathjs.createUnit(
        {
            USD: {
                definition: nok(USD_NOK),
                prefixes: 'short',
                aliases: ['dollar', 'dollars', 'US Dollar', '$']
            },
            USDCENT: {
                definition: '0.01 USD',
                prefixes: 'none',
                aliases: ['¢', 'cent', 'cents']
            },
            GBP: {
                definition: nok(GBP_NOK),
                prefixes: 'short'
            },
            EUR: {
                definition: nok(EUR_NOK),
                prefixes: 'short',
                aliases: ['Euro', 'Euros']
            },
            SEK: {
                definition: nok(SEK_NOK),
                prefixes: 'short',
                aliases: ['Swedish krone']
            },
            DKK: {
                definition: nok(DKK_NOK),
                prefixes: 'short',
                aliases: ['Danish krone']
            }
        },
        {override: true}
    );
};
const EUR_NOK = 10.03;
const USD_NOK = 8.83;
const SEK_NOK = 0.99;
const DKK_NOK = 1.35;
const GBP_NOK = 11.96;

mathjs.createUnit({
    NOK: {
        prefixes: 'short',
        aliases: ['Norwegian krone']
    }
});

updateCurrencyUnits(USD_NOK, GBP_NOK, EUR_NOK, SEK_NOK, DKK_NOK);

mathjs.createUnit({
    kn: {
        prefixes: 'short',
        aliases: ['knot', 'knots', 'kt']
    }
});

const pow2 = /\^2/g;
const pow3 = /\^3/g;

const energyCostBase = mathjs.unit('NOK/MWh');
const distanceCostBase = mathjs.unit('NOK/km');

const moneyBase = mathjs.unit('NOK');
const energyBase = mathjs.unit('MWh');
const aepBase = mathjs.unit('GWh/year');//TODO: this should be improved. In general, the system is not able to convert between units properly.

const KM = mathjs.unit('km');

const gigaMoney = /G([A-Z]{3})/;
const unitSuffix = /[A-Za-df-z].*/;

export class UnitConversion {
    constructor(public unitSystem: UnitSystem = unitSystems[0]) {
    }

    // Make unit a value relative to its base
    // This removes prefixes and makes the unit the one chosen in settings(in this.unitsystems)
    toBase(aUnit: Unit): Unit {
        if (aUnit.equalBase(energyCostBase)) {
            aUnit = aUnit.to(this.unitSystem.energyCost);
        } else if (aUnit.equalBase(moneyBase)) {
            aUnit = aUnit.to(this.unitSystem.money);
        } else if (aUnit.equalBase(distanceCostBase)) {
            aUnit = aUnit.to(this.unitSystem.money + ' / km');
        } else if (aUnit.equalBase(energyBase)) {
            aUnit = aUnit.to(this.unitSystem.energy);
        } else if (aUnit.equalBase(aepBase)) {
            aUnit = aUnit.to(this.unitSystem.AEPEnergy);
        }
        aUnit.fixPrefix = false;

        return aUnit;
    }

    normalizeToBase(aUnit: Unit): Unit {
        aUnit = this.toBase(aUnit);

        if (aUnit.equalBase(distanceCostBase)) {
            const nominator = aUnit.multiply(KM);
            const normalizedNominator = this.normalizeNominator(nominator);
            aUnit = normalizedNominator.divide(KM);
        } else {
            aUnit = this.normalizeNominator(aUnit);
        }

        return aUnit;
    }

    normalizeNominator(aUnit: Unit): Unit {
        // Simplify to correct unit
        const reducedUnit = mathjs.divide(aUnit, (20 as unknown) as Unit);
        const theUnitWeWant = reducedUnit.toString().match(unitSuffix);
        if (theUnitWeWant) {
            aUnit = aUnit.to(theUnitWeWant[0]);
        }

        return aUnit;
    }

    private convertToString(aUnit: Unit) {
        if (aUnit.hasBase('TIME')) {
            return this.formatUnitAsTime(aUnit);
        }

        aUnit = this.normalizeToBase(aUnit);

        return this.formatValuelessPartOfUnit(aUnit.format({precision: 4}));
    }

    formatValuelessPartOfUnit(unit: string): string {
        return unit
            .replace(pow2, '²')
            .replace(pow3, '³')
            .replace(gigaMoney, (match, rootCurrency) => {
                return 'B' + rootCurrency;
            });
    }

    formatUnitAsTime(aUnit: Unit): string {
        if (aUnit.toNumber(undefined as any) === 0) {
            return '0';
        }
        const hours = aUnit.toNumber('h');
        if (hours > 2) {
            return asUnit(Math.round(hours), 'h')
                .splitUnit(['years', 'months', 'days', 'hours', 'minutes', 'seconds'])
                .filter((el) => el.toNumber(null as any) !== 0)
                .map((el) => el.toString())
                .join(' ');
        }
        const seconds = aUnit.toNumber('s');
        if (seconds > 1) {
            return asUnit(Math.round(seconds), 's')
                .splitUnit(['years', 'months', 'days', 'hours', 'minutes', 'seconds'])
                .filter((el) => el.toNumber(null as any) !== 0)
                .map((el) => el.toString())
                .join(' ');
        }
        return aUnit.toString();
    }

    convertValueToString(value: string): string {
        try {
            return this.convertToString(mathjs.unit(value));
        } catch (e) {
            return value;
        }
    }

    convertUnitToString(unit: Unit) {
        return this.convertToString(unit);
    }

    convertValueAndUnitToString(value: number, unitName: string): string {
        try {
            return this.convertToString(mathjs.unit(value, unitName));
        } catch (e) {
            return `${value} ${unitName}`;
        }
    }

    fromMEURToCoeString(value: number) {
        let unit_obj = asUnitObj({value: value, unit: 'MEUR'})
        const coeBaseValueWithUnit = this.toBase(unit_obj).toJSON();
        let string_unit = this.convertUnitToString(unit_obj).split(" ")[0]

        return string_unit + ' ' + coeBaseValueWithUnit.unit.toString() + ' / MWh'
    }

    fromMEURToCurrency(value: number) {
        let unit_obj = asUnitObj({value: value, unit: 'MEUR'})
        let string_unit = this.convertUnitToString(unit_obj)

        return string_unit
    }

    fromCurrencyValueToMEUR(value: number) {
        let currentCurrency = 'M' + this.unitSystem.money
        let unit_obj = asUnitObj({value: value, unit: currentCurrency})
        let aUnit = this.normalizeToBase(unit_obj);
        let currentValueInCurrency = this.toValueWithUnit(aUnit.to("MEUR")).value

        return currentValueInCurrency
    }

    fromMEURToCurrencyValue(value: number) {
        let unit_obj = asUnitObj({value: value, unit: 'MEUR'})
        let currentCurrency = 'M' + this.unitSystem.money
        let aUnit = this.normalizeToBase(unit_obj);
        let currentValueInCurrency = this.toValueWithUnit(aUnit.to(currentCurrency)).value

        return currentValueInCurrency
    }

    toValueWithUnit(aUnit: Unit): ValueWithUnit {
        const unitJSON = aUnit.toJSON();
        return {
            value: unitJSON.value,
            unit: unitJSON.unit
        };
    }
}

export function asUnitObj(valueWithUnit: ValueWithUnit) {
    return asUnit(valueWithUnit.value, valueWithUnit.unit);
}

export function asUnit(value: number, unit: string): Unit {
    return mathjs.unit(value, unit);
}

export function multiply(unit: Unit, scalar: number): Unit {
    return unit.multiply(mathjs.unit(scalar as any));
}

export function add(a: Unit, b: Unit): Unit {
    return mathjs.add(a, b) as Unit;
}

export function unaryMinus(unit: Unit): Unit {
    return mathjs.unaryMinus(unit) as Unit;
}

const norway = {
    name: 'NOK/MWh',
    money: 'NOK',
    energyCost: 'NOK/MWh',
    energy: 'MWh',
    AEPEnergy: 'GWh/year'
};

const denmark = {
    name: 'DKK/MWh',
    money: 'DKK',
    energyCost: 'DKK/MWh',
    energy: 'MWh',
    AEPEnergy: 'GWh/year'
};

const usa = {
    name: 'USD/MWh',
    money: 'USD',
    energyCost: 'USD/MWh',
    energy: 'MWh',
    AEPEnergy: 'GWh/year'
};

const uk = {
    name: 'GBP/MWh',
    money: 'GBP',
    energyCost: 'GBP/MWh',
    energy: 'MWh',
    AEPEnergy: 'GWh/year'
};

const eur = {
    name: 'EUR/MWh',
    money: 'EUR',
    energyCost: 'EUR/MWh',
    energy: 'MWh',
    AEPEnergy: 'GWh/year'
};

export const unitSystems: Array<UnitSystem> = [norway, denmark, usa, uk, eur];

interface UnitSystem {
    name: string;
    money: string;
    energyCost: string;
    energy: string;
    AEPEnergy: string;
}
