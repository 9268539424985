export const foundationOptions: Array<Foundation> = [
    {
        name: 'Jacket',
        id: 'Jacket',
        minDepth: 15,
        maxDepth: 60,
        depthWarning: 'Only appropriate for water depths between 15m and 60m',
        explanation: 'Bottom fixed. Appropriate for water depths between 15m and 60m'
    },
    {
        name: 'Monopile',
        id: 'Monopile',
        minDepth: 15,
        maxDepth: 60,
        depthWarning: 'Only appropriate for water depths between 15m and 60m',
        explanation: 'Bottom fixed. Appropriate for water depths between 15m and 60m'
    },
    {
        name: 'Floating',
        id: 'Floating',
        minDepth: 2,
        maxDepth: 1,
        depthWarning: 'EXPERIMENTAL: Floating concept is currently only available for testing!',
        explanation: 'EXPERIMENTAL: Floating foundation.'
    }
];

export interface Foundation {
    name: string;
    id: string;
    minDepth?: number;
    maxDepth?: number;
    depthWarning?: string;
    explanation: string;
}
