import * as React from 'react';
import { ErrorResponse } from '@lcoe/lcoe-client';
import { CalcState, CalcStates } from './edit/CalcStates';
import { CircularProgress, Typography } from '@equinor/eds-core-react';
import styles from './CalcStateView.module.css';

interface IProps {
    errorResponse?: ErrorResponse;
    calcState?: CalcState;
}

export const CalcStateView = (props: IProps): JSX.Element | null => {
    if (props.calcState === CalcStates.NOT_STARTED) {
        return (
            <div className={styles.box}>
                <Typography variant="h3">Please complete the form</Typography>
                <br />
                <Typography>The results will appear after all mandatory fields have been filled.</Typography>
            </div>
        );
    }

    if (props.calcState === CalcStates.FAILED) {
        return (
            <div className={styles.box}>
                <Typography variant="h3">Errors were encountered during calculation</Typography>
                <br />
                {props.errorResponse && (
                    <>
                        <Typography variant="h5">{props.errorResponse.title}</Typography>
                        <Typography variant="body_long">{props.errorResponse.detail}</Typography>
                    </>
                )}
            </div>
        );
    }

    if (props.calcState === CalcStates.STARTED) {
        return (
            <div className={styles.box}>
                <Typography variant="h3">Calculating</Typography>
                <br />
                <Typography>Please wait while your case is being calculated...</Typography>
                <CircularProgress size={48} color="primary" value={0} />
            </div>
        );
    }
    return null;
};
