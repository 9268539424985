import { AccountInfo, AuthenticationResult } from '@azure/msal-common';

export const SET_USER_STATE = 'SET_USER_STATE';
export const AUTHENTICATED = 'LOGIN_SUCCESS';
export const SET_ACCOUNT = 'SET_ACCOUNT';

export enum LoginState {
    BEFORE,
    SUCCESS,
    FAILED,
    TIMED_OUT
}

interface LoggedInUserState {
    loginState: LoginState.SUCCESS;
    name: string;
    username: string;
    userId: string;
    idToken: string;
    accessToken: string;
    roles: string[];
}

interface NotLoggedInUserState {
    loginState: LoginState.BEFORE | LoginState.FAILED | LoginState.TIMED_OUT;
    name: string;
}

export type UserState = LoggedInUserState | NotLoggedInUserState;

export interface SetUserStateAction {
    type: typeof SET_USER_STATE;
    userState: UserState;
}

export interface SetAccountAction {
    type: typeof SET_ACCOUNT;
    accountInfo: AccountInfo;
}

export interface AuthenticateAction {
    type: typeof AUTHENTICATED;
    authResult: AuthenticationResult;
}

export const RoleWtgAdmin = 'wtg_admin';
export const RoleLocationAdmin = 'location_admin';
export const RoleAdmin = 'admin';
export const RoleCostEstimator = 'cost_estimator';
export const RoleCableAdmin = 'cable_admin';
export const RoleFoundationAdmin = 'foundation_admin';
export type Role =
    | typeof RoleWtgAdmin
    | typeof RoleLocationAdmin
    | typeof RoleAdmin
    | typeof RoleCostEstimator
    | typeof RoleCableAdmin
    | typeof RoleFoundationAdmin;
