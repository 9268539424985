/* eslint-disable */
import React, { useCallback } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import * as PropTypes from 'prop-types';

export class CheckboxTable extends React.Component<any> {
    static propTypes: { value: PropTypes.Requireable<object>; dc: PropTypes.Requireable<boolean>; onChange: PropTypes.Validator<(...args: any[]) => any>; };
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange({ rowName, itemName, value }: any) {
        const newValue = cloneInfrastructureSelectionWith(this.props.value || {}, rowName, itemName, value);
        this.props.onChange(this.props.name, newValue);
    }

    shouldComponentUpdate(nextProps: any, nextState: any, nextContext: any) {
        return this.props.value !== nextProps.value || this.props.dc !== nextProps.dc;
    }

    render() {
        const value = this.props.value || {};
        return (
            <Table padding="checkbox" className="checkbox-table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell component="th" scope="col" align="center">
                            Capex
                        </TableCell>
                        <TableCell component="th" scope="col" align="center">
                            O&M
                        </TableCell>
                        <TableCell component="th" scope="col" align="center">
                            Electrical losses
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <CheckboxTableRow
                        legend="Inter-array cables"
                        rowName="infieldCables"
                        value={value.infieldCables}
                        onChange={this.handleChange}
                    />
                    <CheckboxTableRow
                        legend="Offshore substation"
                        rowName="substationOffshore"
                        value={value.substationOffshore}
                        onChange={this.handleChange}
                    />
                    <CheckboxTableRow
                        legend="Onshore substation"
                        rowName="substationOnshore"
                        value={value.substationOnshore}
                        onChange={this.handleChange}
                    />
                    <CheckboxTableRow
                        legend="Export cables"
                        rowName="exportCables"
                        value={value.exportCables}
                        onChange={this.handleChange}
                    />

                    {this.props.dc && (
                        <React.Fragment>
                            <CheckboxTableRow
                                legend="Cable to HVDC"
                                rowName="substationToConverterCable"
                                value={value.substationToConverterCable}
                                onChange={this.handleChange}
                            />
                            <CheckboxTableRow
                                legend="HVDC converter"
                                rowName="hvdcConverter"
                                value={value.hvdcConverter}
                                onChange={this.handleChange}
                            />
                        </React.Fragment>
                    )}
                </TableBody>
            </Table>
        );
    }
}
CheckboxTable.propTypes = {
    value: PropTypes.object,
    dc: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

const CheckboxTableRow = ({ value, rowName, legend, onChange, disabled }: any) => {
    const handleChange = useCallback((evt, value) => onChange({ rowName, itemName: evt.target.name, value }), [
        onChange,
        rowName
    ]);
    value = value || {};
    return (
        <TableRow>
            <TableCell component="th" scope="row">
                {legend}
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={value.capex || false}
                    className="wide-checkbox"
                    name="capex"
                    onChange={handleChange}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={value.onm || false}
                    className="wide-checkbox"
                    name="onm"
                    onChange={handleChange}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell>
                <Checkbox
                    checked={value.energyLoss || false}
                    className="wide-checkbox"
                    name="energyLoss"
                    onChange={handleChange}
                    disabled={disabled}
                />
            </TableCell>
        </TableRow>
    );
};

function cloneInfrastructureSelectionWith(old: any, rowName: any, itemName: any, value: any) {
    return { ...old, [rowName]: cloneSubselectionWith(old[rowName] || {}, itemName, value) };
}

function cloneSubselectionWith(old: any, name: any, value: any) {
    return { ...old, [name]: value };
}
