import { Position } from '@lcoe/lcoe-client';
import { BaseLeafletMap } from '../Map/BaseLeafletMap';
import { LatLng, LeafletMouseEvent } from 'leaflet';
import { Marker, Polyline, Popup, useMapEvents } from 'react-leaflet';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { circleIcon } from '../Map/TooltipLayer';
import React from 'react';
import { useSelector } from 'react-redux';
import { layersEnum } from '../redux/store/map/types';
import styles from './WeatherFileMap.module.css';
import { Labels } from '../Map/Labels';
import { maxHsLabelSpecsColors, maxHsLabelSpecsValues } from '../Map/MaxHsLabelSpecs';
import { meanHsLabelSpecsColors, meanHsLabelSpecsValues } from '../Map/meanHsLabelSpecs';
import { defaultView } from '../utils/map/defaultView';
import { ApplicationState } from '../redux/store';

interface Props {
    className?: string;
    style?: CSSProperties;
    location: Position;
    weatherFile?: Position;
    onClick?: (e: LeafletMouseEvent) => void;
}

export const WeatherFileMap = ({ location, weatherFile, onClick, style }: Props): JSX.Element => {
    const mapState = useSelector((state: ApplicationState) => state.map);
    const [displayMaxHsLabels, setDisplayMaxHsLabels] = useState<boolean>(false);
    useEffect(() => {
        if (mapState[layersEnum.wavesMaxHs].checked) {
            setDisplayMaxHsLabels(true);
        }

        if (mapState[layersEnum.wavesMeanHs].checked || !mapState[layersEnum.wavesMaxHs].checked) {
            setDisplayMaxHsLabels(false);
        }
    }, [mapState]);
    return (
        <div className={styles.container}>
            <BaseLeafletMap style={style as CSSProperties} defaultView={defaultView}>
                <WeatherFileLayer location={location} weatherFile={weatherFile} onClick={onClick} />
            </BaseLeafletMap>
            <div className={styles.labels}>
                {displayMaxHsLabels && <Labels colors={maxHsLabelSpecsColors} values={maxHsLabelSpecsValues} />}
                {mapState[layersEnum.wavesMeanHs].checked && (
                    <Labels colors={meanHsLabelSpecsColors} values={meanHsLabelSpecsValues} />
                )}
            </div>
        </div>
    );
};

const WeatherFileLayer = ({ location, weatherFile, onClick }: Props) => {
    const [distance, setDistance] = useState<number>();
    const [markers, setMarkers] = useState<PopupMarker[]>([]);

    const map = useMapEvents({
        contextmenu(e) {
            if (onClick) onClick(e);
        }
    });
    const updateDistance = useCallback(
        (weatherFile: Position) => {
            const newDistance = Math.round(map.distance(toLatLng(location), toLatLng(weatherFile)) / 1000);
            setDistance(newDistance);

            const midLat = location.lat - (location.lat - weatherFile.lat) / 2;
            const midLon = location.lon - (location.lon - weatherFile.lon) / 2;
            const midPos = { lat: midLat, lng: midLon } as LatLng;
            map.openPopup(`Distance: ${newDistance}km`, midPos);
        },
        [location, map]
    );
    useEffect(() => {
        const newMarkers: PopupMarker[] = [];

        if (weatherFile) {
            updateDistance(weatherFile);
            newMarkers.push({
                latLng: toLatLng(weatherFile),
                popupText: `Weather file lat: ${weatherFile.lat}, lon: ${weatherFile.lon}`
            });
        }

        newMarkers.push({
            latLng: toLatLng(location),
            popupText: `Location lat: ${location.lat}, lon: ${location.lon}`
        });
        setMarkers(newMarkers);
    }, [weatherFile, location, updateDistance]);
    return (
        <>
            {markers.map((mark, index) => (
                <Marker key={index} position={mark.latLng} icon={circleIcon}>
                    <Popup>{mark.popupText}</Popup>
                </Marker>
            ))}
            {distance && weatherFile && (
                <Polyline positions={[toLatLng(location), toLatLng(weatherFile)]} color="#ff3030">
                    <Popup>{`Distance: ${distance}km`}</Popup>
                </Polyline>
            )}
        </>
    );
};

export type PopupMarker = {
    latLng: LatLng;
    popupText: string;
};

const toLatLng = (position: Position) => {
    return new LatLng(position.lat, position.lon);
};
