import { Location } from '@lcoe/lcoe-client';
import { ApiContext } from 'components/ApiContext';
import ConfirmationDialog from 'components/ConfirmationDialog';
import LoadingDialog from 'components/LoadingDialog';
import { LocationDetailsFor } from 'components/locations/LocationDetailsFor';
import { LocationWithId } from 'interface/ILocations';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavBar } from '../../components/NavBar';

export const LocationDetailsPage = (): JSX.Element => {
    const params = useParams() as {
        locationId: string;
    };
    const navigate = useNavigate();
    const [location, setLocation] = useState<undefined | Location>(undefined);
    const [deletingLocation, setDeletingLocation] = useState<boolean>(false);

    const api = useContext(ApiContext);

    const locationId = params.locationId;

    const loadLocation = useCallback(() => {
        api.getLocation(locationId).then((location) => setLocation(location));
    }, [api, locationId]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadLocation, [locationId]);

    const deleteLocation = useCallback(() => setDeletingLocation(true), []);

    const confirmDeleteLocation = useCallback(() => {
        api.deleteLocation(locationId).then(() => navigate('/locations'));
    }, [api, navigate, locationId]);

    const cancelDeleteLocation = useCallback(() => setDeletingLocation(false), []);

    if (!location) {
        return <LoadingDialog />;
    }

    return (
        <>
            <NavBar />
            <LocationDetailsFor locationWithId={location as LocationWithId} deleteLocation={deleteLocation} />
            {deletingLocation && (
                <ConfirmationDialog
                    title="Delete location"
                    open={deletingLocation}
                    onConfirm={confirmDeleteLocation}
                    onCancel={cancelDeleteLocation}
                    confirmText="Delete"
                    text={'Are you sure you want to delete this location?'}
                />
            )}
        </>
    );
};
