import React from 'react';
import { TurbineProfile } from '@lcoe/lcoe-client/dist/models/TurbineProfile';
import { Chart } from 'react-google-charts';

interface IProps {
    profile: TurbineProfile;
}

export const TurbineProfileChart = (props: IProps): JSX.Element => {
    const chartData = [];
    chartData.push(['x', 'Power output', 'Trust coef.']);

    props.profile.performanceTable.forEach((turbineRow) => {
        chartData.push([
            turbineRow.windSpeedMps,
            turbineRow.powerOutputWatt / 1000 / 1000,
            turbineRow.thrustCoefficient
        ]);
    });

    return (
        <Chart
            width="100%"
            chartType="LineChart"
            height={'500px'}
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={{
                hAxis: {
                    title: 'Wind speed [m/s}'
                },
                vAxis: {
                    title: 'Power output [MW]'
                },
                series: {
                    1: { curveType: 'function' }
                },
                chartArea: { left: 100, bottom: 50, top: 20, right: 140, width: '100%', height: '100%' }
            }}
        />
    );
};
