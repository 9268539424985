import { FileResponse, FileType, FileUpload, WeatherFile, WeatherSourceEnum } from '@lcoe/lcoe-client';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { LocationWithId } from 'interface/ILocations';
import React, { useCallback, useContext, useState } from 'react';
import { ApiContext } from '../components/ApiContext';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { UploadButton } from '../components/UploadButton';
import { useInput } from '../hooks/useInput';
import { FileResponseWithMetadata, WeatherFileMeta } from './WeatherFileMeta';
import WeatherFileIcon from './WeatherFileIcon';
import styles from './WeatherFilesCard.module.css';
import { PopoverInfo } from '../elements/visual/PopoverInfo';
import { Button, Typography } from '@equinor/eds-core-react';
import { WeatherFileDownload } from './WeatherFileDownload';

interface WeatherFileWithResponse {
    file: WeatherFile;
    response: FileResponse;
}

interface WeatherFilesCardProps {
    location: LocationWithId;
}

const WeatherFilesCard = ({ location }: WeatherFilesCardProps): JSX.Element => {
    const [weatherFileWithResponse, setWeatherFileWithResponse] = useState(
        undefined as unknown as WeatherFileWithResponse
    );
    const [deletingWeatherFile, setDeletingWeatherFile] = useState(undefined as unknown as FileResponse);
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [visibleFetchDialog, setVisibleFetchDialog] = useState(false);
    const [isFetchingWeatherData, setIsFetchingWeatherData] = useState(false);

    const [files, setFiles] = useInput<undefined | WeatherFile[]>(location.files);

    const api = useContext(ApiContext);

    const loadFiles = useCallback(() => {
        return api.getLocation(location.id).then((location) => {
            setFiles(location.files);
        });
    }, [api, location.id, setFiles]);

    const showWeatherFile = (weatherFile: WeatherFile) => {
        api.downloadWeatherFile(location.id, weatherFile.fileId).then((downloadedWeatherFile) => {
            setWeatherFileWithResponse({
                file: weatherFile,
                response: downloadedWeatherFile
            });
            setVisibleDialog(true);
        });
    };

    const fetchWeather = (atmosSystem: string, atmosSystemId: number) => {
        setIsFetchingWeatherData(true);
        api.fetchWeather(location.id, WeatherSourceEnum.Atmos, atmosSystem, atmosSystemId, location.position).finally(
            () => {
                loadFiles().finally(() => {
                    setIsFetchingWeatherData(false);
                });
            }
        );
    };

    const uploadFile = (payload: FileUpload) => {
        setIsFetchingWeatherData(true);
        api.uploadWeatherFile(
            location.id,
            payload.filename as string,
            payload.content as string,
            payload.type as FileType
        ).finally(() => {
            loadFiles().finally(() => {
                setIsFetchingWeatherData(false);
            });
        });
    };

    function onDeleteWeatherFile(weatherFile: FileResponse) {
        setWeatherFileWithResponse(undefined as unknown as WeatherFileWithResponse);
        setDeletingWeatherFile(weatherFile);
    }

    function confirmDeleteWeatherFile() {
        api.deleteWeatherFile(location.id, deletingWeatherFile.fileId as string).then(() => {
            setDeletingWeatherFile(undefined as unknown as FileResponse);
            loadFiles();
        });
    }

    function cancelDeleteWeatherFile() {
        setDeletingWeatherFile(undefined as unknown as FileResponse);
    }

    const popover_element = (
        <div>
            <div>
                File upload supports two file types. The supported filetypes are Excel Workbook (.xlsx) and Comma
                Separated Values (.csv). The .xlsx file require values for latitude and longitude. An example of an
                .xlsx file can be downloaded from this window. The weather file must contain at a minimum values for
                wind speed at one height (WXX). The application will extra/interpolate to the hub height, so adding more
                values for wind speed is beneficial. To upload a .csv file, values for W10 and W100 must be present.
            </div>
            <a href={'/files/example_weather.xlsx'}>Download Excel Template</a>
        </div>
    );

    return (
        <>
            {visibleFetchDialog && (
                <WeatherFileDownload
                    visibleDialog={visibleFetchDialog}
                    setVisibleFetchDialog={setVisibleFetchDialog}
                    locationId={location.id}
                    fetchWeather={fetchWeather}
                />
            )}
            <WeatherFileMeta
                visibleDialog={visibleDialog}
                setVisibleDialog={setVisibleDialog}
                weatherFile={weatherFileWithResponse?.response as FileResponseWithMetadata}
                location={location}
                onDelete={onDeleteWeatherFile}
                showDelete={true}
            />
            <Card style={{ marginLeft: 4, marginTop: 8, marginRight: 4, marginBottom: 8 }}>
                <CardContent>
                    <div className={styles.banner}>
                        <Typography variant="h4">Weather files</Typography>
                        <div className={styles.ButtonIcon}>
                            <PopoverInfo title={'How to import'} body_element={popover_element} />
                        </div>
                    </div>
                    <List>
                        {files &&
                            files.map((weatherFile) => (
                                <ListItem
                                    key={weatherFile.filename}
                                    button
                                    onClick={() => showWeatherFile(weatherFile)}
                                >
                                    <ListItemIcon>
                                        <WeatherFileIcon status={weatherFile.status} />
                                    </ListItemIcon>
                                    <ListItemText primary={weatherFile.filename} />
                                    <ListItemSecondaryAction>
                                        {location.defaultWeatherFile &&
                                            weatherFile.fileId === location.defaultWeatherFile.fileId && (
                                                <Chip color="primary" label="default" />
                                            )}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                    </List>
                </CardContent>
                <CardActions style={{ paddingLeft: 16 }}>
                    {!isFetchingWeatherData && (
                        <>
                            <Button onClick={() => setVisibleFetchDialog(true)} variant="outlined">
                                Get from Atmos
                            </Button>
                            <UploadButton onUpload={uploadFile} accept={'.csv, .xlsx'} variant="outlined">
                                Upload csv or xlsx file
                            </UploadButton>
                        </>
                    )}
                    {isFetchingWeatherData && (
                        <div className={styles.fetchingData}>
                            <div className={styles.loader} />
                            <span>Please wait a couple of minutes while weather file is processing...</span>
                        </div>
                    )}
                </CardActions>
            </Card>
            {!!deletingWeatherFile && (
                <ConfirmationDialog
                    title="Delete weather file"
                    open
                    onConfirm={confirmDeleteWeatherFile}
                    onCancel={cancelDeleteWeatherFile}
                    confirmText="Delete"
                    text={`Are you sure you want to delete the weather file: ${deletingWeatherFile.name}?`}
                />
            )}
        </>
    );
};

export default WeatherFilesCard;
