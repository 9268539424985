import React from 'react';
import { CalculationResult } from '@lcoe/lcoe-client';
import { LogTreeView, hasWarnings } from '../LogTreeView';
import styles from './CaseLog.module.css';
import { Typography } from '@equinor/eds-core-react';

interface Props {
    result: CalculationResult;
}

const CaseLog = ({ result }: Props) => {
    return (
        <React.Fragment>
            {result && result.logTree && hasWarnings(result.logTree) && (
                <div>
                    <Typography variant={'h3'}>Warnings</Typography>

                    <p className={styles.case_detail_indent}>
                        Some warnings occured during calculation, please review details here:
                    </p>
                    <LogTreeView log={result.logTree} />
                </div>
            )}
        </React.Fragment>
    );
};

export default CaseLog;
