import { LocationPage } from 'page/location/LocationPage';
import { NewTurbinePage } from 'page/turbine/NewTurbinePage';
import { TurbineDetailPage } from 'page/turbine/TurbineDetailPage';
import { TurbineListPage } from 'page/turbine/TurbineListPage';
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CaseEdit } from '../Case/CaseEdit';
import { CaseListPage } from '../Case/CaseListPage';
import { CaseView } from '../Case/CaseView';
import { LocationDetailsPage } from '../page/location/LocationDetailsPage';
import { NewLocationPage } from '../page/location/NewLocationPage';
import { WtgIndexList } from '../WtgIndex/WtgIndexList';
import { Frontpage } from './Frontpage';
import { NotFound } from './NotFound';
import { CaseCompareListPage } from '../Comparison/CaseCompareListPage';
import { CaseComparisonPage } from '../Comparison/CaseComparisonPage';

import { Icon } from '@equinor/eds-core-react';
import {
    add,
    autorenew,
    briefcase,
    build_wrench,
    cable,
    check,
    checkbox,
    checkbox_outline,
    chevron_down,
    chevron_left,
    chevron_right,
    chevron_up,
    close,
    cloud,
    computer,
    copy,
    comment_chat,
    delete_to_trash,
    done,
    download,
    edit,
    error_outlined,
    home,
    info_circle,
    pause,
    refresh,
    reorder,
    save,
    search,
    settings,
    shopping_card,
    shopping_cart_add,
    switch_off,
    switch_on,
    trip_origin,
    turbine,
    vertical_align_bottom,
    visibility,
    warning_outlined,
    world
} from '@equinor/eds-icons';
import { ElectricalConfigPage } from '../page/config/ElectricalConfigPage';
import { MainConfigPage } from '../page/config/MainConfigPage';
import { CableListPage } from '../page/cable/CableListPage';
import { MonopileExperiencePageView } from '../page/foundation/MonopileExperiencePageView';
import { MonopileExperiencePageEdit } from '../page/foundation/MonopileExperiencePageEdit';
import { MonopileExperiencePageDashboard } from '../page/foundation/MonopileExperiencePageDashboard';

export class Content extends Component {
    render(): JSX.Element {
        Icon.add({
            add,
            autorenew,
            briefcase,
            build_wrench,
            cable,
            check,
            checkbox,
            checkbox_outline,
            chevron_down,
            chevron_left,
            chevron_right,
            chevron_up,
            close,
            cloud,
            computer,
            comment_chat,
            copy,
            delete_to_trash,
            done,
            download,
            edit,
            error_outlined,
            info_circle,
            home,
            pause,
            refresh,
            reorder,
            save,
            search,
            settings,
            shopping_card,
            shopping_cart_add,
            switch_on,
            switch_off,
            trip_origin,
            turbine,
            vertical_align_bottom,
            visibility,
            warning_outlined,
            world
        });

        return (
            <Routes>
                <Route path="/" element={<Frontpage />} />

                <Route path="/cases" element={<CaseListPage />} />

                <Route path="/cases/new" element={<CaseEdit />} />

                <Route path="/cases/compare" element={<CaseCompareListPage />} />
                <Route path="/cases/comparefull" element={<CaseComparisonPage />} />

                <Route path="/cases/:caseId" element={<CaseView />} />
                <Route path="/cases/:caseId/edit" element={<CaseEdit />} />

                <Route path="/foundations/experience-monopile" element={<MonopileExperiencePageView />} />
                <Route path="/foundations/experience-monopile-edit" element={<MonopileExperiencePageEdit />} />
                <Route
                    path="/foundations/experience-monopile-dashboard"
                    element={<MonopileExperiencePageDashboard />}
                />
                <Route path="/config" element={<MainConfigPage />} />
                <Route path="/config/cables" element={<CableListPage />} />
                <Route path="/config/electrical" element={<ElectricalConfigPage />} />

                <Route path="/locations" element={<LocationPage />} />
                <Route path="/locations/new" element={<NewLocationPage />} />
                <Route path="/locations/:locationId" element={<LocationDetailsPage />} />

                <Route path="/turbines" element={<TurbineListPage />} />
                <Route path="/turbines/new" element={<NewTurbinePage />} />
                <Route path="/turbines/:turbineId" element={<TurbineDetailPage />} />

                <Route path="/wtgIndex" element={<WtgIndexList />} />

                <Route element={<NotFound />} />
            </Routes>
        );
    }
}
