import { Location } from '@lcoe/lcoe-client/src/models';
import * as React from 'react';
import styles from './LocationList.module.css';
import CSS from 'csstype';
import cx from 'classnames';
import { ListTable } from '../elements/visual/ListTable';
import { PageHeader } from '../elements/visual/PageHeader';
import { Button } from '@equinor/eds-core-react';

const columns = [
    { field: 'id', hide: true },
    {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        // eslint-disable-next-line
        renderCell: (params: any) => <a href={`/locations/${params.id}`}>{params.value}</a>
    },
    { field: 'country', headerName: 'Country', flex: 1 },
    { field: 'latitude', headerName: 'Lat', flex: 0.5 },
    { field: 'longitude', headerName: 'Lon', flex: 0.5 },
    { field: 'createdby', headerName: 'Created by', flex: 0.5 },
    { field: 'updatedTime', headerName: 'Last updated', flex: 0.5 }
];

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    locations: Location[];
}

export interface LocationDataRow {
    createdby: string | undefined;
    id: string | undefined;
    name: string;
    country: string;
    latitude: number;
    longitude: number;
    updatedTime: string | undefined;
}

export const LocationList = (props: IProps): JSX.Element => {
    const datarows = [] as LocationDataRow[];
    props.locations.forEach((element) => {
        datarows.push({
            createdby: element.createdBy,
            id: element.id,
            name: element.name,
            country: element.country,
            latitude: element.position.lat,
            longitude: element.position.lon,
            updatedTime: element.updatedTime?.toISOString().split('T')[0]
        });
    });

    const element = (
        <div>
            <Button href="/locations/new">Create new</Button>
        </div>
    );

    return (
        <div className={cx(styles.LocationList, props.className)} style={props.style}>
            <PageHeader title={'Locations'} right_element={element}></PageHeader>
            {datarows.length > 0 && <ListTable datarows={datarows} columns={columns}></ListTable>}
        </div>
    );
};
