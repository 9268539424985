/* eslint-disable */
import { Case, Turbine, Location } from '@lcoe/lcoe-client';
import { Problem, Problems, problemsExist } from '../Problems';
import schemasJson from '@lcoe/lcoe-client/dist/schemas.json';

type SwaggerSchemas = { [s: string]: SwaggerSchema };
type SwaggerProperties = { [s: string]: SwaggerProperty };

export enum SwaggerTypeEnum {
    Number = 'number',
    Integer = 'integer',
    String = 'string',
    Boolean = 'boolean',
    Object = 'object'
}

export const schemas = schemasJson as SwaggerSchemas;

function validateString(value: string, property: SwaggerProperty) {
    if (!!property.minLength && value.length < property.minLength) {
        return `Minimum length is ${property.minLength}`;
    }
}

function validateNumber(value: number, property: SwaggerProperty) {
    const { minimum, maximum, exclusiveMinimum, exclusiveMaximum } = property;
    if (minimum !== undefined) {
        if (exclusiveMinimum) {
            if (value <= minimum) {
                return `Must be > ${minimum}`;
            }
        } else {
            if (value < minimum) {
                return `Must be ≥ ${minimum}`;
            }
        }
    }
    if (maximum !== undefined) {
        if (exclusiveMaximum) {
            if (value >= maximum) {
                return `Must be < ${maximum}`;
            }
        } else {
            if (value > maximum) {
                return `Must be ≤ ${maximum}`;
            }
        }
    }
}

function validateField(value: any, property: SwaggerProperty, isRequired: boolean): Problem | Problems | undefined {
    if (value === undefined || value === null || value === '') {
        if (isRequired) {
            return 'Required';
        }
        return;
    }
    switch (property.type) {
        case SwaggerTypeEnum.String:
            return validateString(value, property);
        case SwaggerTypeEnum.Number:
            return validateNumber(value, property);
        case SwaggerTypeEnum.Integer:
            return validateNumber(value, property);
        default:
            // Object?
            if (property.properties) {
                return validate(value, property as SwaggerSchema);
            }
    }

    return;
}

function validate(object: any, schema: SwaggerSchema): Problems {
    const problems: Problems = {};
    if (!schema.properties) {
        return problems;
    }
    const required = schema.required || [];
    const keys = Object.keys(schema.properties);
    for (const key of keys) {
        const isRequired = required.includes(key);
        const property = schema.properties[key];
        const problem = validateField(object[key], property, isRequired);
        if (problem !== undefined && problemsExist(problem)) {
            problems[key] = problem;
        }
    }
    return problems;
}

const emptyObject = {};
Object.freeze(emptyObject);

class ValidationModel<T> {
    constructor(public readonly schema: SwaggerSchema) {}

    validate(object: T): Problems {
        const problems = validate(object, this.schema);
        if (Object.keys(problems).length === 0) {
            return emptyObject;
        }
        return problems;
    }
}

export interface SwaggerSchema {
    example?: any;
    properties?: SwaggerProperties;
    required?: Array<string>;
}

interface SwaggerProperty {
    title?: string;
    format?: string;
    description?: string;
    type?: SwaggerTypeEnum;
    minLength?: number;
    maxLength?: number;
    minimum?: number;
    maximum?: number;
    exclusiveMinimum?: boolean;
    exclusiveMaximum?: boolean;

    'x-lcoe-readOnly'?: boolean;
    'x-lcoe-unit'?: string;
    /**
     * If defined, this SwaggerProperty is a SwaggerSchema
     */
    properties?: SwaggerProperties;
}

/**
 * Finds a nested property, using dot notation. "result.coe" will resolve to `root.properties.result.properties.coe`
 *
 * @param root The starting point, e.g. `schemas.case`
 * @param name The node to find, e.g. "result.coe"
 */
export function resolveProperty(root: SwaggerProperty, name: string): SwaggerProperty {
    const names = name.split('.');
    let node = root;
    for (const name of names) {
        if (!node.properties) {
            throw new Error('Error resolving schema');
        }
        node = node.properties[name];
    }
    return node;
}

/**
 * Resolves a nested value, using dot notation.
 */
export function resolveValue(object: any, name: string) {
    const names = name.split('.');
    let node = object;
    for (const name of names) {
        node = node[name];
    }
    return node;
}

export const caseValidationModel = new ValidationModel<Case>(schemas.Case);

export const turbineValidationModel = new ValidationModel<Turbine>(schemas.Turbine);

export const locationValidationModel = new ValidationModel<Location>(schemas.Location);
