import CSS from 'csstype';
import { useState } from 'react';
import { cloneDeep } from 'lodash';
import { Button, Icon, Dialog } from '@equinor/eds-core-react';
import cx from 'classnames';
import styles from './TableEditor.module.css';
import { PageHeader } from '../../elements/visual/PageHeader';
import * as React from 'react';
import { SelectField, ISelectFieldOptions } from '../../elements/input/SelectField';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    title: string;
    datarows: string[][];
    columns: Record<string, unknown>[];
    saveDataset: (dataset: any) => void;
    isEditor: boolean;
}

export const TableEditor = (props: IProps): JSX.Element => {
    const [datarows, setDatarows] = useState<string[][]>(props.datarows);
    const newDatarows = cloneDeep(datarows);

    const addNewRow = () => {
        newDatarows.push(cloneDeep(newDatarows[0]));
        newDatarows[newDatarows.length - 1].map(
            (element, rowIndex) => (newDatarows[newDatarows.length - 1][rowIndex] = '')
        );

        setDatarows(newDatarows);
    };

    const saveData = () => {
        props.saveDataset(newDatarows);
    };

    const element = <div>{props.isEditor && <Button onClick={() => saveData()}>Save</Button>}</div>;

    const changeCellValue = (rowIndex: number, cellIndex: number, newValue: string) => {
        newDatarows[rowIndex][cellIndex] = newValue;
    };

    const columnOptions = [] as string[];
    props.columns.map((col: any) => columnOptions.push(col.select_options));

    const columnTypes = [] as string[];
    props.columns.map((col: any) => columnTypes.push(col.type));

    const columnNames = [] as string[];
    props.columns.map((col: any) => columnNames.push(col.name));

    const selectElement = (rowIndex: number, cellIndex: number, cellValue: string, multiple: boolean) => {
        const options = [] as ISelectFieldOptions[];
        columnOptions[cellIndex].split(',').map((opt: string) => options.push({ value: opt, label: opt }));

        const selectedOptions = [] as ISelectFieldOptions[];
        cellValue.split(',').map((opt: string) => selectedOptions.push({ value: opt, label: opt }));

        const saveSelectMultiple = (value: ISelectFieldOptions[]): void => {
            const result = [] as string[];
            value.map((val) => result.push(val.value));

            changeCellValue(rowIndex, cellIndex, result.join(','));
        };

        const saveSelectSingle = (value: ISelectFieldOptions): void => {
            changeCellValue(rowIndex, cellIndex, value.value);
        };

        return (
            <td
                key={rowIndex + '_' + cellIndex}
                className={props.isEditor ? styles.table_row_select_cell : styles.table_row_cell}
            >
                {props.isEditor && (
                    <SelectField
                        placeholder={''}
                        isMulti={multiple}
                        className={multiple ? styles.fieldMultiple : styles.fieldSingle}
                        selectedOptions={selectedOptions}
                        options={options}
                        onRemovedItem={(value) => {
                            multiple
                                ? saveSelectMultiple(value as ISelectFieldOptions[])
                                : saveSelectSingle(value as ISelectFieldOptions);
                        }}
                        onBlur={(value) => {
                            multiple
                                ? saveSelectMultiple(value as ISelectFieldOptions[])
                                : saveSelectSingle(value as ISelectFieldOptions);
                        }}
                        closeMenuOnSelect={false}
                    />
                )}
                {!props.isEditor && cellValue}
            </td>
        );
    };

    const [visibleNumberCheck, setVisibleNumberCheck] = useState(false);

    const textElement = (rowIndex: number, cellIndex: number, cellValue: string) => {
        return (
            <td
                key={rowIndex + '_' + cellIndex}
                onInputCapture={(e) => {
                    columnTypes[cellIndex] === 'number' &&
                        isNaN(Number(e.currentTarget.innerHTML)) &&
                        setVisibleNumberCheck(!visibleNumberCheck);
                    changeCellValue(rowIndex, cellIndex, e.currentTarget.innerHTML);
                }}
                className={styles.table_row_cell}
                contentEditable={props.isEditor}
                suppressContentEditableWarning={true}
            >
                {cellValue}
            </td>
        );
    };

    const deleteRow = (rowIndex: number) => {
        newDatarows.splice(rowIndex, 1);
        setDatarows(newDatarows);
    };

    return (
        <div className={cx(styles.TableEditor, props.className)} style={props.style}>
            <Dialog open={visibleNumberCheck} isDismissable={false}>
                <Dialog.CustomContent>
                    <div style={{ textAlign: 'center' }}>
                        Value must be a number
                        <br />
                        <br />
                        <Button onClick={() => setVisibleNumberCheck(false)}>OK</Button>
                    </div>
                </Dialog.CustomContent>
            </Dialog>
            <PageHeader title={props.title} right_element={element} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.table_header}>
                            {props.columns.map((column: any) => (
                                <td className={styles.table_header_cell} key={column.name}>
                                    {column.name}
                                </td>
                            ))}
                            {props.isEditor && <td />}
                        </tr>
                    </thead>
                    <tbody>
                        {datarows.map((element, rowIndex) => (
                            <tr key={rowIndex}>
                                {element.map((cellValue, cellIndex) =>
                                    columnTypes[cellIndex] === 'select_multiple'
                                        ? selectElement(rowIndex, cellIndex, cellValue, true)
                                        : columnTypes[cellIndex] === 'select_single'
                                        ? selectElement(rowIndex, cellIndex, cellValue, false)
                                        : textElement(rowIndex, cellIndex, cellValue)
                                )}
                                {props.isEditor && (
                                    <td align={'center'} className={styles.table_row_cell}>
                                        <Icon
                                            color={'red'}
                                            onClick={() => deleteRow(rowIndex)}
                                            size={16}
                                            name={'delete_to_trash'}
                                        />
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {props.isEditor && (
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '37px', paddingTop: '5px' }}>
                    <Button variant="outlined" color="secondary" onClick={() => addNewRow()}>
                        Add row <Icon size={16} name={'add'} />
                    </Button>
                </div>
            )}
        </div>
    );
};
