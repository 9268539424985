/* eslint-disable */
import { UnitConversionContext } from './UnitConversionContext';
import { UnitConversion } from './UnitConversion';
import { connect } from 'react-redux';
import { ApplicationState } from '../redux/store';
import React from 'react';

interface UnitConversionContextProviderProps {
    children: any;
}

interface StateProps {
    unitConversion: UnitConversion;
}

type Props = UnitConversionContextProviderProps & StateProps;

const UnitConversionContextProviderComponent = ({ children, unitConversion }: Props) => {
    return <UnitConversionContext.Provider value={unitConversion}>{children}</UnitConversionContext.Provider>;
};

export function mapUnitConversionStateToProps(state: ApplicationState): StateProps {
    return {
        unitConversion: state.unitConversion
    };
}

export const UnitConversionProvider = connect(mapUnitConversionStateToProps)(UnitConversionContextProviderComponent);
