import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent } from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
interface IProps {
    onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
    value: string | undefined;
    name: string;
}

export const SimpleCommentsField = ({ onChange, value, name }: IProps): JSX.Element => {
    return (
        <Grid item xs={12}>
            <TextField
                label="Comments"
                name={name}
                value={value || ''}
                onChange={onChange}
                fullWidth
                variant="outlined"
                minRows={3}
                maxRows={10}
                multiline
            />
        </Grid>
    );
};

SimpleCommentsField.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string.isRequired
};
