import { Capex, Case, Opex, ValueWithUnit } from '@lcoe/lcoe-client';
import { UnitConversion } from '../uom/UnitConversion';

const showCurrencyValue = (value: number, uc: UnitConversion) => {
    return uc.fromMEURToCurrencyValue(value / 1000);
};

export const extractCoe = (cases: Case[], uc: UnitConversion): Array<Array<string | number>> => {
    const caseNames: string[] = ['Case'];
    const coeArray: Array<string | number> = ['COE'];
    const plotData: Array<Array<string | number>> = [];
    for (let i = 0; i < cases.length; i++) {
        const caseName = cases[i].name;
        caseNames.push(caseName + ' (v' + cases[i].version + ')');
        const coe = cases[i].result?.hierarchy?.coe.value ?? 0;
        coeArray.push(uc.fromMEURToCurrencyValue(coe));
    }
    plotData.push(caseNames);
    plotData.push(coeArray);
    return plotData;
};

export const extractCost = (opexResult: number[], cases: Case[], uc: UnitConversion): Array<Array<string | number>> => {
    const plotArray: Array<Array<string | number>> = [];
    const opexArray: Array<string | number> = ['Opex park lifetime'];
    const capexArray: Array<string | number> = ['Capex'];
    const caseNames: Array<string> = ['Variable'];
    for (let i = 0; i < opexResult.length; i++) {
        opexArray.push(opexResult[i]);
        capexArray.push(showCurrencyValue(cases[i].result?.hierarchy?.capex.cost.value ?? 0, uc));

        caseNames.push(cases[i].name + ' (v' + cases[i].version + ')');
    }
    plotArray.push(caseNames);
    plotArray.push(opexArray);
    plotArray.push(capexArray);
    return plotArray;
};

interface opexAndYear {
    opexYearly: ValueWithUnit;
    operationalYears: number;
}

export const multiplyOpex = (cases: Case[], uc: UnitConversion): number[] => {
    const opexArray: Array<opexAndYear> = [];
    const resultArray: Array<number> = [];
    for (let i = 0; i < cases.length; i++) {
        opexArray.push({
            opexYearly: cases[i].result?.hierarchy?.opex.cost ?? { value: 0, unit: 'none' },
            operationalYears: cases[i].parkLifeYears ?? 0
        });
    }
    for (let i = 0; i < cases.length; i++) {
        resultArray.push(showCurrencyValue(opexArray[i].opexYearly.value * opexArray[i].operationalYears, uc));
    }
    return resultArray;
};

export const extractDetailedCost = (cases: Case[], uc: UnitConversion): Array<Array<number | string>> => {
    const opexArray: Array<Opex[]> = [];
    const capexArray: Array<Capex[]> = [];
    const operationalYears: number[] = [];
    const plotData: Array<Array<number | string>> = [];
    const names: Array<string> = ['Structures'];
    const emptyOpex: Opex[] = [
        {
            name: '',
            coe: { value: 0, unit: '' },
            cost: { value: 0, unit: '' },
            learningEffect: { value: 0, unit: '' }
        }
    ];

    const emptyCapex: Capex[] = [
        {
            name: '',
            coe: { value: 0, unit: '' },
            cost: { value: 0, unit: '' },
            learningEffect: { value: 0, unit: '' }
        }
    ];
    for (let i = 0; i < cases.length; i++) {
        opexArray.push(cases[i].result?.hierarchy?.opex.children ?? emptyOpex);
        capexArray.push(cases[i].result?.hierarchy?.capex.children ?? emptyCapex);
        names.push(cases[i].name + ' (v' + cases[i].version + ')');
        operationalYears.push(cases[i].parkLifeYears);
    }

    for (let j = 0; j < opexArray[0].length; j++) {
        plotData.push(['Opex-' + opexArray[0][j].name]);
        for (let i = 0; i < cases.length; i++) {
            plotData[j].push(showCurrencyValue(opexArray[i][j].cost.value * operationalYears[i], uc));
        }
    }

    for (let j = 0; j < capexArray[0].length; j++) {
        plotData.push([capexArray[0][j].name]);
        for (let i = 0; i < cases.length; i++) {
            plotData[j + opexArray[0].length].push(showCurrencyValue(capexArray[i][j].cost.value, uc));
        }
    }
    plotData.unshift(names);

    return plotData;
};

enum hierarchyCapexEnum {
    PreDG3,
    Capex,
    Decom
}

enum hierarchyOpexEnum {
    OM,
    tariffs,
    other
}

enum capexEnum {
    wtg,
    substructure,
    infieldInfrastructure,
    exportInfrastructure,
    marineOperations,
    logistics,
    companyCost
}

enum opexOtherEnum {
    environmentalMonitoring,
    insurance,
    onshoreBase,
    powerConsumtion
}

enum opexTariffsEnum {
    landLeaseOnshore,
    landLeaseOffshore,
    gridTariffs
}

export const extractCaseNames = (cases: Case[]): string[] => {
    const names: string[] = [];
    for (let i = 0; i < cases.length; i++) {
        names.push(cases[i].name + ' (v' + cases[i].version + ')');
    }
    return names;
};

export const extractSublevelCost = (
    cases: Case[],
    comparisonElement: string,
    uc: UnitConversion
): (string | number)[][] => {
    type plotDataType = string | number;

    const names: string[] = ['Case'];
    const emptyOpex: Opex[] = [
        {
            name: '',
            coe: { value: 0, unit: '' },
            cost: { value: 0, unit: '' },
            learningEffect: { value: 0, unit: '' }
        }
    ];

    const emptyCapex: Capex[] = [
        {
            name: '',
            coe: { value: 0, unit: '' },
            cost: { value: 0, unit: '' },
            learningEffect: { value: 0, unit: '' }
        }
    ];
    for (let i = 0; i < cases.length; i++) {
        names.push(cases[i].name + ' (v' + cases[i].version + ')');
    }
    if (comparisonElement === 'Capex') {
        const costAndNames: plotDataType[][] = [
            ['WTG'],
            ['Substructure'],
            ['Infield Infrastructure'],
            ['Export Infrastructure'],
            ['Marine Operations'],
            ['Logistics'],
            ['Company Cost DG3-DG4']
        ];
        for (let i = 0; i < cases.length; i++) {
            const capex: any[] = cases[i].result?.hierarchy?.capex.children ?? emptyCapex;
            costAndNames[capexEnum.wtg].push(
                showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.wtg].cost.value ?? 0, uc)
            );
            costAndNames[capexEnum.substructure].push(
                showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.substructure].cost.value ?? 0, uc)
            );
            costAndNames[capexEnum.infieldInfrastructure].push(
                showCurrencyValue(
                    capex[hierarchyCapexEnum.Capex].children[capexEnum.infieldInfrastructure].cost.value ?? 0,
                    uc
                )
            );
            costAndNames[capexEnum.exportInfrastructure].push(
                showCurrencyValue(
                    capex[hierarchyCapexEnum.Capex].children[capexEnum.exportInfrastructure].cost.value ?? 0,
                    uc
                )
            );
            costAndNames[capexEnum.marineOperations].push(
                showCurrencyValue(
                    capex[hierarchyCapexEnum.Capex].children[capexEnum.marineOperations].cost.value ?? 0,
                    uc
                )
            );
            costAndNames[capexEnum.logistics].push(
                showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.logistics].cost.value ?? 0, uc)
            );
            costAndNames[capexEnum.companyCost].push(
                showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.companyCost].cost.value ?? 0, uc)
            );
        }
        costAndNames.unshift(names);
        return costAndNames;
    } else if (comparisonElement === 'Other') {
        const costAndNames: plotDataType[][] = [
            ['Environmental Monitoring lifetime'],
            ['Insurance lifetime'],
            ['Onshore Base Support lifetime'],
            ['Power Consumption lifetime']
        ];
        for (let i = 0; i < cases.length; i++) {
            const opex: any[] = cases[i].result?.hierarchy?.opex.children ?? emptyOpex;
            costAndNames[opexOtherEnum.environmentalMonitoring].push(
                showCurrencyValue(
                    opex[hierarchyOpexEnum.other].children[opexOtherEnum.environmentalMonitoring].cost.value *
                        cases[i].parkLifeYears,
                    uc
                )
            );
            costAndNames[opexOtherEnum.insurance].push(
                showCurrencyValue(
                    opex[hierarchyOpexEnum.other].children[opexOtherEnum.insurance].cost.value * cases[i].parkLifeYears,
                    uc
                )
            );
            costAndNames[opexOtherEnum.onshoreBase].push(
                showCurrencyValue(
                    opex[hierarchyOpexEnum.other].children[opexOtherEnum.onshoreBase].cost.value *
                        cases[i].parkLifeYears,
                    uc
                )
            );
            costAndNames[opexOtherEnum.powerConsumtion].push(
                showCurrencyValue(
                    opex[hierarchyOpexEnum.other].children[opexOtherEnum.powerConsumtion].cost.value *
                        cases[i].parkLifeYears,
                    uc
                )
            );
        }
        costAndNames.unshift(names);
        return costAndNames;
    } else if (comparisonElement === 'Tariffs') {
        const costAndNames: plotDataType[][] = [
            ['Land Lease Onshore lifetime'],
            ['Land Lease Offshore lifetime'],
            ['Grid Tariffs lifetime']
        ];
        for (let i = 0; i < cases.length; i++) {
            const opex: any[] = cases[i].result?.hierarchy?.opex.children ?? emptyOpex;

            costAndNames[opexTariffsEnum.landLeaseOnshore].push(
                showCurrencyValue(
                    opex[hierarchyOpexEnum.tariffs].children[opexTariffsEnum.landLeaseOnshore].cost.value *
                        cases[i].parkLifeYears,
                    uc
                )
            );
            costAndNames[opexTariffsEnum.landLeaseOffshore].push(
                showCurrencyValue(
                    opex[hierarchyOpexEnum.tariffs].children[opexTariffsEnum.landLeaseOffshore].cost.value *
                        cases[i].parkLifeYears,
                    uc
                )
            );
            costAndNames[opexTariffsEnum.gridTariffs].push(
                showCurrencyValue(
                    opex[hierarchyOpexEnum.tariffs].children[opexTariffsEnum.gridTariffs].cost.value *
                        cases[i].parkLifeYears,
                    uc
                )
            );
        }
        costAndNames.unshift(names);
        return costAndNames;
    } else {
        return [['Temp', 'Temp', 'Temp']];
    }
};

export const extractDetailedCostWaterfall = (
    cases: Case[],
    uc: UnitConversion,
    case1?: string,
    case2?: string
): (string | number)[][] => {
    const names: Array<string> = [];
    const capexArray: Array<number> = [];
    let case1Num = 0;
    let case2Num = 1;

    const emptyCapex: Capex[] = [
        {
            name: '',
            coe: { value: 0, unit: '' },
            cost: { value: 0, unit: '' },
            learningEffect: { value: 0, unit: '' }
        }
    ];
    for (let i = 0; i < cases.length; i++) {
        names.push(cases[i].name + ' (v' + cases[i].version + ')');
        const capex: any[] = cases[i].result?.hierarchy?.capex.children ?? emptyCapex;
        capexArray.push(showCurrencyValue(capex[hierarchyCapexEnum.Capex].cost.value ?? 0, uc));
    }
    const subNames: string[][] = [
        ['WTG'],
        ['Substructure'],
        ['Infield Infrastructure'],
        ['Export Infrastructure'],
        ['Marine Operations'],
        ['Logistics'],
        ['Company Cost DG3-DG4']
    ];
    const capex: any[] = cases[0].result?.hierarchy?.capex.children ?? emptyCapex;
    const data: number[][] = [
        [showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.wtg].cost.value ?? 0, uc)],
        [showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.substructure].cost.value ?? 0, uc)],
        [
            showCurrencyValue(
                capex[hierarchyCapexEnum.Capex].children[capexEnum.infieldInfrastructure].cost.value ?? 0,
                uc
            )
        ],
        [
            showCurrencyValue(
                capex[hierarchyCapexEnum.Capex].children[capexEnum.exportInfrastructure].cost.value ?? 0,
                uc
            )
        ],
        [showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.marineOperations].cost.value ?? 0, uc)],
        [showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.logistics].cost.value ?? 0, uc)],
        [showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.companyCost].cost.value ?? 0, uc)]
    ];
    for (let i = 1; i < cases.length; i++) {
        const capex: any[] = cases[i].result?.hierarchy?.capex.children ?? emptyCapex;
        data[capexEnum.wtg].push(
            showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.wtg].cost.value ?? 0, uc)
        );
        data[capexEnum.substructure].push(
            showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.substructure].cost.value ?? 0, uc)
        );
        data[capexEnum.infieldInfrastructure].push(
            showCurrencyValue(
                capex[hierarchyCapexEnum.Capex].children[capexEnum.infieldInfrastructure].cost.value ?? 0,
                uc
            )
        );
        data[capexEnum.exportInfrastructure].push(
            showCurrencyValue(
                capex[hierarchyCapexEnum.Capex].children[capexEnum.exportInfrastructure].cost.value ?? 0,
                uc
            )
        );
        data[capexEnum.marineOperations].push(
            showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.marineOperations].cost.value ?? 0, uc)
        );
        data[capexEnum.logistics].push(
            showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.logistics].cost.value ?? 0, uc)
        );
        data[capexEnum.companyCost].push(
            showCurrencyValue(capex[hierarchyCapexEnum.Capex].children[capexEnum.companyCost].cost.value ?? 0, uc)
        );
    }
    for (let i = 0; i < names.length; i++) {
        if (case1 && names[i] === case1) {
            case1Num = i;
        }
        if (case2 && names[i] === case2) {
            case2Num = i;
        }
    }

    const plotData: (string | number)[][] = [
        [
            names[case1Num],
            0,
            0,
            capexArray[case1Num],
            capexArray[case1Num],
            capexArray[case1Num].toPrecision(4).toString()
        ]
    ];
    for (let i = 0; i < subNames.length; i++) {
        plotData.push([
            subNames[i][0],
            plotData[i][3],
            plotData[i][3],
            +plotData[i][3] + (data[i][case2Num] - data[i][case1Num]),
            +plotData[i][3] + (data[i][case2Num] - data[i][case1Num]),
            (data[i][case2Num] - data[i][case1Num]).toPrecision(4).toString()
        ]);
    }
    plotData.push([
        names[case2Num],
        capexArray[case2Num],
        capexArray[case2Num],
        0,
        0,
        capexArray[case2Num].toPrecision(4).toString()
    ]);

    return plotData;
};

export const convertToPerMW = (
    plotArray: Array<Array<string | number>>,
    cases: Case[]
): Array<Array<string | number>> => {
    const convertedPlotArray: Array<Array<string | number>> = [];
    convertedPlotArray.push(plotArray[0]);
    for (let i = 1; i < plotArray.length; i++) {
        convertedPlotArray.push([plotArray[i][0]]);
        for (let j = 0; j < plotArray[0].length - 1; j++) {
            const wtgCount = cases[j].wtgCount ?? 1;
            const wtgCapacity = cases[j].wtg.capacity ?? 1;
            const installedCapacity = wtgCount * wtgCapacity;
            const costPerMW = (plotArray[i][j + 1] as number) / installedCapacity;
            convertedPlotArray[i].push(costPerMW);
        }
    }
    return convertedPlotArray;
};
