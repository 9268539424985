import { Typography } from '@equinor/eds-core-react';
import { Location } from '@lcoe/lcoe-client';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CaseLocationMap } from '../Map/CaseLocationMap';
import { ApiContext } from './ApiContext';
import styles from './Frontpage.module.css';
import { NavBar } from './NavBar';

export const Frontpage = (): JSX.Element => {
    const navigate = useNavigate();
    const api = useContext(ApiContext);
    const [locations, setLocations] = useState([] as Location[]);
    const onGotoLocation = (id: string) => {
        navigate(`/cases/?location.id=${id}`);
    };
    useEffect(() => {
        api.getLocations({ withCasesOnly: true }).then((response) => {
            if (response.locations) {
                setLocations(response.locations);
            }
        });
    }, [api]);
    return (
        <>
            <NavBar />
            <div>
                <div className={styles.topBox}>
                    <Typography variant={'h3'}>Welcome to LCoE</Typography>
                    <Typography variant={'body_long'}>
                        This tool is used for early screening of wind prospects. Do not use for investment decisions.
                        Use the map to browse to cases for a location, or use the menu on top.
                    </Typography>
                </div>
                <div className={styles.topBox}>
                <Typography variant={'h3'}>LCoE to be discontinued</Typography>
                    <Typography variant={'body_long'}>
                    LCoE tool is not being actively developed or maintained. Over time the analysis and resulting numbers have become more inaccurate. In order to prioritise development of decision support tools, LCoE will be discontinued from July 15, 2024.

                    Let us know if your workflow depends on this tool by sending your feedback to eraf@equinor.com before June 30, 2024. Decision to decommission will be review based on the feedback received.

                    </Typography>
                </div>
                {locations.length !== 0 && (
                    <div className={styles.mapBox}>
                        <CaseLocationMap locations={locations} onGotoLocation={onGotoLocation} />
                    </div>
                )}
            </div>
        </>
    );
};
