import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { Case, Location, Turbine } from '@lcoe/lcoe-client';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { SwaggerSchema } from '../../Api/model/SwaggerSpec';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Problems, problemsExist } from '../../Api/Problems';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ListItem from '@material-ui/core/ListItem';
import { Button, Icon } from '@equinor/eds-core-react';

type Option = Turbine | Location;

interface OptionDialogFieldProps<OptionType extends Option> {
    options: OptionType[];
    name: 'location' | 'wtg';
    schema: SwaggerSchema;
    onChange: (diff: { [key: string]: Option }) => void;
    object: Case;
    problems: Problems;
    warnings: Problems;
    renderEditForm: (props: React.ReactNode) => React.ReactNode;
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export function OptionSelectField<OptionType extends Option>({
    name,
    schema,
    problems,
    warnings,
    options,
    onChange,
    object,
    renderEditForm,
    sm = 6
}: OptionDialogFieldProps<OptionType>): JSX.Element {
    const [selecting, setSelecting] = useState(false);
    const inputRef = useRef<HTMLElement>();
    const selectedOption = object[name] as unknown as OptionType;
    const selectedName = selectedOption ? selectedOption.name : '';
    const [editingOption, setEditingOption] = useState(false);

    if (!schema.properties) {
        throw new Error('Schema field references missing properties');
    }
    const schemaProp = schema.properties[name];
    const title = schemaProp.title || name;
    const problem = problems && problems[name];
    const warning = warnings && warnings[name];
    const problemText =
        typeof problem === 'string' ? problem : problemsExist(problem) && "Click the pencil to see what's wrong";

    const openSelect = useCallback(() => setSelecting(true), [setSelecting]);
    const closeSelect = useCallback(() => {
        setSelecting(false);
        setTimeout(() => inputRef.current && inputRef.current.focus(), 20);
    }, [setSelecting, inputRef]);

    const editOption = useCallback(() => setEditingOption(true), []);
    const closeEditOption = useCallback(() => setEditingOption(false), []);

    const onSetOption = useCallback(
        (option: OptionType) => {
            onChange({ [name]: option });
            closeSelect();
            closeEditOption();
        },
        [onChange, closeEditOption, closeSelect, name]
    );

    const InputProps = {
        inputProps: {
            tabIndex: -1,
            onClick: openSelect
        },
        endAdornment: (
            <InputAdornment position="end">
                {selectedOption && <Icon name={'edit'} onClick={editOption} style={{ cursor: 'pointer' }} />}
                <Icon name={'reorder'} onClick={openSelect} style={{ cursor: 'pointer' }} />
            </InputAdornment>
        )
    };

    return (
        <Grid item xs={12} sm={sm}>
            <TextField
                value={selectedName}
                contentEditable={false}
                label={schemaProp.title}
                InputProps={InputProps}
                error={problemsExist(problem) || !!warning}
                helperText={problemText || warning}
                fullWidth
            />
            {selecting && (
                <OptionSelectDialog
                    onClose={closeSelect}
                    options={options}
                    onChange={onSetOption}
                    value={selectedOption}
                    title={title}
                />
            )}
            {editingOption && (
                <OptionEditDialog
                    onChange={onSetOption}
                    onClose={closeEditOption}
                    option={selectedOption}
                    renderEditForm={renderEditForm}
                    problems={typeof problem === 'string' ? {} : (problem as Problems)}
                />
            )}
        </Grid>
    );
}

interface OptionSelectDialogProps<OptionType extends Option> {
    onClose: () => void;
    options: OptionType[];
    onChange: (option: OptionType) => void;
    value?: OptionType;
    title: string;
}

function OptionSelectDialog<OptionType extends Option>({
    onClose,
    onChange,
    options,
    value,
    title
}: OptionSelectDialogProps<OptionType>) {
    const onSelect = useCallback(
        (event) => {
            const id = event.target.closest('[data-id]').getAttribute('data-id');
            const newOption = options.find((opt) => opt.id === id);
            newOption && onChange(newOption);
        },
        [onChange, options]
    );

    const [filter, setFilter] = useState('');

    const onSearchChanged = useCallback(
        (event) => {
            setFilter(event.target.value);
        },
        [setFilter]
    );

    const optionsToShow = options
        .filter((option) => option.name.toLowerCase().includes(filter.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>
                Select {title} &nbsp;
                <Icon name={'close'} onClick={onClose} style={{ cursor: 'pointer' }} />
            </DialogTitle>
            <DialogContent>
                <TextField placeholder="Search..." autoFocus onChange={onSearchChanged} />
                {optionsToShow.map((option) => {
                    return (
                        <ListItem
                            key={option.id}
                            selected={value && value.id === option.id}
                            button
                            onClick={onSelect}
                            data-id={option.id}
                        >
                            {option.name}
                        </ListItem>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

interface OptionEditDialogProps<OptionType extends Option> {
    onClose: () => void;
    onChange: (option: OptionType) => void;
    option: OptionType;
    problems?: Problems;
    renderEditForm: (props: React.ReactNode) => React.ReactNode;
}

function OptionEditDialog<OptionType extends Option>({
    onClose,
    option,
    onChange,
    problems,
    renderEditForm
}: OptionEditDialogProps<OptionType>) {
    const [editingObject, setEditingObject] = useState({ ...option });
    const save = useCallback(() => {
        const suffixRegex = /\(modified\)$/;
        let result = editingObject;
        if (!editingObject.name.match(suffixRegex)) {
            result = { ...editingObject, name: editingObject.name + ' (modified)' };
        }
        onChange(result);
    }, [onChange, editingObject]);
    const objectChange = useCallback(
        (diff: { [key: string]: Option }) => {
            setEditingObject({ ...editingObject, ...diff });
        },
        [editingObject]
    );
    return (
        <Dialog open onClose={onClose}>
            <DialogContent>{renderEditForm({ editingObject, objectChange, problems })}</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={save} variant="contained" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
