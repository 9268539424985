import React from 'react';
import cx from 'classnames';
import styles from './MainConfigPage.module.css';
import { PageHeader } from '../../elements/visual/PageHeader';
import CSS from 'csstype';
import { Typography } from '@equinor/eds-core-react';
import { NavBarMasterData } from '../../components/NavBarMasterData';

interface IProps {
    style?: CSS.Properties;
    className?: string;
}

export const MainConfigPage = (props: IProps): JSX.Element => {
    const element = <div></div>;

    return (
        <>
            <NavBarMasterData />
            <div className={cx(styles.MainConfigPage, props.className)} style={props.style}>
                <PageHeader title={'Configuration'} right_element={element}></PageHeader>
                <div className={styles.main}>
                    <div className={styles.content}>
                        <div className={styles.config_heading}>
                            <Typography variant={'h5'}>Electrical Configuration</Typography>
                        </div>
                        <div className={styles.config_content}>
                            In order to edit the electrical configuration you must apply for the LCoE Electrical Admin
                            role in Access IT. The configuration is however visible for everyone.
                            <br />
                            <br />
                            <li>
                                <a href={'/config/electrical'}>General settings</a>
                            </li>
                            <li>
                                <a href={'/config/cables'}>Cables</a>
                            </li>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.config_heading}>
                            <Typography variant={'h5'}>Foundation</Typography>
                        </div>
                        <div className={styles.config_content}>
                            In order to edit the foundation experience datayou must apply for the LCoE Foundation Admin
                            role in Access IT.
                            <br />
                            <br />
                            <li>
                                <a href={'/foundations/experience-monopile'}>Monopiles - experience data</a>
                            </li>
                        </div>
                        <div className={styles.config_heading}>
                            <Typography variant={'h5'}>WTG Index</Typography>
                        </div>
                        <div className={styles.config_content}>
                            To view details of existing and upload new WTG Indexes you must apply for the LCoE Cost
                            Estimator role in Access IT.
                            <br />
                            <br />
                            <li>
                                <a href={'/wtgindex'}>WTG Index</a>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
