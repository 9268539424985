import { FileResponse, Location, StringResponse } from '@lcoe/lcoe-client';
import { Button, Dialog, Icon, Table, Typography } from '@equinor/eds-core-react';
import { formatTableValue } from '../utils/table/tableUtils';
import React, { useContext } from 'react';
import { WeatherFileMap } from './WeatherFileMap';
import { ApiContext } from '../components/ApiContext';

export type FileResponseWithMetadata = {
    [prop in keyof FileResponse]: FileResponse[prop];
} & {
    metadata: NonNullable<FileResponse['metadata']>;
};

interface IProps {
    visibleDialog: boolean;
    weatherFile: FileResponseWithMetadata;
    location: Location;
    setVisibleDialog: (status: boolean) => void;
    onDelete: (weatherFile: FileResponse) => void;
    showDelete: boolean;
}

export const WeatherFileMeta = (props: IProps): JSX.Element => {
    const api = useContext(ApiContext);

    const loc = props.location.id as string;

    function saveWeatherFile(weatherFile: FileResponse) {
        api.locationApi
            .downloadWeatherFile({ locationId: loc, fileId: weatherFile.fileId as string })
            .then((r: StringResponse) => {
                const element = document.createElement('a');
                const modified = r.value?.replace(/\t/g, ',');
                const file = new Blob([modified as string], { type: 'text/csv' });
                element.href = URL.createObjectURL(file);
                element.download = weatherFile.fileId + '.csv';
                document.body.appendChild(element);
                element.click();
            });
    }

    return (
        <>
            {props.weatherFile && (
                <Dialog open={props.visibleDialog} style={{ width: 600, marginTop: 50 }} isDismissable>
                    <Dialog.Header>
                        <Dialog.Title>
                            <Typography variant={'h3'}>Weather file: {props.weatherFile?.name}</Typography>
                        </Dialog.Title>
                    </Dialog.Header>
                    <Dialog.CustomContent scrollable style={{ height: 400 }}>
                        <QualityIndicator weatherFile={props.weatherFile} />
                        <MetadataTable metadata={props.weatherFile?.metadata as FileResponseWithMetadata} />
                        {props.location.position &&
                            props.weatherFile.metadata &&
                            props.weatherFile.metadata.position && (
                                <WeatherFileMap
                                    style={{ height: window.innerHeight / 4 }}
                                    location={props.location.position}
                                    weatherFile={props.weatherFile.metadata.position}
                                />
                            )}
                    </Dialog.CustomContent>
                    <Dialog.Actions>
                        {props.showDelete && (
                            <>
                                <Button onClick={() => props.onDelete(props.weatherFile)} color="secondary">
                                    Delete
                                </Button>{' '}
                                &nbsp;
                            </>
                        )}
                        {props.weatherFile.metadata.source != 'atmos' ||
                        props.weatherFile.metadata.atmosSystem == 'Era5' ||
                        props.weatherFile.metadata.atmosSystem == 'Nora10' ? (
                            <Button onClick={() => saveWeatherFile(props.weatherFile)} color="primary">
                                Download
                            </Button>
                        ) : (
                            ''
                        )}
                        &nbsp;
                        <Button onClick={() => props.setVisibleDialog(false)} color="primary">
                            Close
                        </Button>
                    </Dialog.Actions>
                </Dialog>
            )}
        </>
    );
};

interface QualityIndicatorProps {
    weatherFile: FileResponse;
}

const QualityIndicator = ({ weatherFile }: QualityIndicatorProps) => {
    let indicatorColor: string;
    let indicatorText: string;

    if (weatherFile.metadata?.source == 'era5') {
        indicatorColor = '#f8ef78';
        indicatorText = 'ERA5 data is considered medium quality';
    } else if (weatherFile.metadata?.source == 'nora10') {
        indicatorColor = '#00ff00';
        indicatorText = 'NORA10 data is considered high quality';
    } else if (weatherFile.metadata?.source == 'atmos') {
        const source = weatherFile.metadata?.atmosSystem;
        if (source == 'Era5') {
            indicatorColor = '#f8ef78';
            indicatorText = source + ' data is considered medium quality';
        } else if (source == 'Nora10' || source == 'SK_continous' || source == 'SK_Typhoon_merge') {
            indicatorColor = '#00ff00';
            indicatorText = source + ' data is considered high quality';
        } else {
            indicatorColor = '#dddddd';
            indicatorText = 'Uploaded data is of unknown quality';
        }
    } else {
        indicatorColor = '#dddddd';
        indicatorText = 'Uploaded data is of unknown quality';
    }

    return (
        <div style={{ backgroundColor: '#444444', paddingTop: 4, paddingBottom: 4 }}>
            <div style={{ color: 'white', textAlign: 'center', fontSize: 16, padding: 4 }}>
                <Icon name={'cloud'} style={{ fill: indicatorColor }} />
                <br />
                {indicatorText}
            </div>
        </div>
    );
};

interface MetadataTableProps {
    metadata: { [key: string]: any };
}

function formatValue(key: string, value: any) {
    if (key === 'version') {
        return value.gitTag;
    }
    return formatTableValue(value);
}

const MetadataTable = ({ metadata }: MetadataTableProps) => {
    return (
        <Table style={{ width: '100%' }}>
            <Table.Body>
                {metadata &&
                    Object.keys(metadata).map((key) => (
                        <Table.Row key={key}>
                            <Table.Cell>{key}</Table.Cell>
                            <Table.Cell>{formatValue(key, metadata[key])}</Table.Cell>
                        </Table.Row>
                    ))}
            </Table.Body>
        </Table>
    );
};
