import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ApiContext } from '../components/ApiContext';
import ConfirmationDialog from '../components/ConfirmationDialog';
import LoadingDialog from '../components/LoadingDialog';
import { getVersion } from '../components/utils/helpers';
import { addCaseCompare } from '../redux/store/caseCompare/actions';
import { convertEpaStringToYearQuarter, updateSupportedCurrencyRates } from '../Settings/EpaUtils';
import { CaseEditForm } from './edit/CaseEditForm';
import { ResultView } from './results/ResultView';
import { Button, Icon } from '@equinor/eds-core-react';
import { NavBar } from '../components/NavBar';
import styles from './CaseView.module.css';
import { useNavigate } from 'react-router-dom';
import { setCaseCopy } from '../redux/store/caseCopy/actions';

const mapDispatchToProps = (dispatch: any) => {
    return {
        addCaseCompare: (caseRef: any) => {
            dispatch(addCaseCompare(caseRef));
        }
    };
};

export const CaseView = connect(
    null,
    mapDispatchToProps
)(({ addCaseCompare, history }: any) => {
    const [deletingCase, setDeletingCase] = useState<boolean>(false);
    const version = getVersion();

    const params = useParams() as {
        caseId: string;
    };

    const caseId = params.caseId;
    const api = useContext(ApiContext);
    const compareThisCase = useCallback(
        () => addCaseCompare({ id: caseId, version: version }),
        [addCaseCompare, caseId, version]
    );

    const [caze, setCaze] = useState<any>(null);
    const [versions, setVersions] = useState([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const copyCase = () => {
        dispatch(setCaseCopy(caze));
        navigate(`/cases/new?copy=true`);
    };

    const editCase = () => {
        let versionUrl = '';
        if (version) {
            versionUrl = `?version=${version}`;
        }
        navigate(`/cases/${caseId}/edit${versionUrl}`);
    };

    useEffect(() => {
        api.getCase({ id: caseId, version }).then((caze) => {
            const [year, quarter] = convertEpaStringToYearQuarter(caze.epaAssumptions);
            api.epaApi.getEpaAssumptions({ year, quarter }).then((epa) => {
                updateSupportedCurrencyRates(epa);
                setCaze(caze);
            });
        });
        api.getCaseVersions(caseId).then((versionResult: any) => {
            setVersions(versionResult.versions);
        });
    }, [caseId, version, api]);

    if (!caze) {
        return <LoadingDialog />;
    }

    const options = {
        locationOptions: [caze.location],
        wtgOptions: [caze.wtg],
        foundationOptions: [caze.foundation],
        collectionGridOptions: [caze.collectionGrid],
        exportGridOptions: [caze.exportSolution],
        onmStrategyOptions: [caze.onmStrategy],
        infrastructureOptions: ['']
    };

    const getVersionByText = (version: any) => {
        const user = version.version === 1 ? version.createdBy : version.updatedBy;
        return `${version.version} - by ${user}`;
    };

    const getVersionByDate = (version: any) => {
        const datetime = version.version === 1 ? version.createdTime : version.updatedTime;
        return `Updated on ${datetime}`;
    };
    const confirmDeleteCase = () => {
        api.deleteCase(caze.id).then(() => history.push('/cases'));
    };

    return (
        <React.Fragment>
            <NavBar />
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Card className={styles.card_margin}>
                        <CardHeader
                            title={caze.name}
                            subheader={`Version ${caze.version}`}
                            action={
                                <div style={{ paddingTop: 10, paddingRight: 10 }}>
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => {
                                            setDeletingCase(true);
                                        }}
                                    >
                                        <Icon name={'delete_to_trash'} /> Delete
                                    </Button>{' '}
                                    <Button variant={'outlined'} onClick={() => copyCase()}>
                                        <Icon name={'copy'} /> Copy
                                    </Button>{' '}
                                    <Button variant={'outlined'} onClick={() => editCase()}>
                                        <Icon name={'edit'} /> Edit
                                    </Button>{' '}
                                    <Button variant={'outlined'} onClick={compareThisCase}>
                                        <Icon name={'shopping_cart_add'} /> Compare
                                    </Button>
                                </div>
                            }
                        />
                        <CardContent className={styles.card_hider_wrapper}>
                            <div className={styles.card_hider} />
                            <CaseEditForm caze={caze} {...options} />
                        </CardContent>
                    </Card>

                    <Card className={styles.card_margin}>
                        <CardHeader title="Versions" />
                        <CardContent>
                            <List>
                                {versions.map((version: any) => {
                                    return (
                                        <ListItem
                                            key={version.version}
                                            to={{
                                                pathname: `/cases/${caseId}`,
                                                search: `version=${version.version}`
                                            }}
                                            component={Link}
                                            button
                                        >
                                            <ListItemIcon>
                                                {version.version === caze.version ? (
                                                    <Icon name={'check'} />
                                                ) : (
                                                    <Icon name={'trip_origin'} />
                                                )}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={getVersionByText(version)}
                                                secondary={getVersionByDate(version)}
                                            />
                                            <ListItemSecondaryAction>
                                                <Button
                                                    onClick={() => {
                                                        addCaseCompare({ id: caseId, version: version.version });
                                                    }}
                                                >
                                                    <Icon name={'shopping_cart_add'} /> Compare
                                                </Button>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ResultView
                        caze={caze}
                        result={caze.result}
                        parkLife={caze.parkLifeYears as any}
                        discountRate={caze.discountRatePercent}
                        wtgCount={caze.wtgCount}
                        wtgCapacity={caze.wtg.capacity}
                    />
                </Grid>
            </Grid>
            {deletingCase && (
                <ConfirmationDialog
                    title="Delete case"
                    open={deletingCase}
                    onConfirm={confirmDeleteCase}
                    onCancel={() => {
                        setDeletingCase(false);
                    }}
                    confirmText="Delete"
                    text={'Are you sure you want to delete this case?'}
                />
            )}
        </React.Fragment>
    );
});
