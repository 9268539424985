import {
    AuthenticateAction,
    AUTHENTICATED,
    SET_ACCOUNT,
    SET_USER_STATE,
    SetAccountAction,
    SetUserStateAction,
    UserState
} from './types';
import { AccountInfo, AuthenticationResult } from '@azure/msal-common';

export function setUserState(userState: UserState): SetUserStateAction {
    return {
        type: SET_USER_STATE,
        userState
    };
}

export function setAccount(value: AccountInfo): SetAccountAction {
    return {
        type: SET_ACCOUNT,
        accountInfo: value
    };
}

export function setAuthentication(value: AuthenticationResult): AuthenticateAction {
    return {
        type: AUTHENTICATED,
        authResult: value
    };
}
