import { ExperienceMonopiles } from '@lcoe/lcoe-client';
import * as React from 'react';
import CSS from 'csstype';
import styles from './MonopileExperienceDashboard.module.css';
import { useState } from 'react';
import { Button, SingleSelect, Table, Typography } from '@equinor/eds-core-react';
import { PageHeader } from '../elements/visual/PageHeader';
import { Chart } from 'react-google-charts';
import { ExperienceMonopile } from '@lcoe/lcoe-client/src/models/index';
import { min, max, std, mean, round } from 'mathjs';

export interface IProps {
    style?: CSS.Properties;
    className?: string;
    monopiles: ExperienceMonopiles;
}

export const MonopileExperienceDashboard = (props: IProps): JSX.Element => {
    const [selectedItem, setSelectedItem] = useState<string>('Monopile Weight vs Design Seabed Elevation');
    const [selectedProject, setSelectedProject] = useState<string>();

    const items = [
        'Monopile Weight vs Design Seabed Elevation',
        'TP Weight vs Design Seabed Elevation',
        'Monopile Length vs Design Seabed Elevation',
        'Total Weight vs Design Seabed Elevation',
        'Monopile Weight vs Monopile Length'
    ];

    const datarows = [] as (string | number)[][];

    const monopiles =
        selectedProject == null || selectedProject == ''
            ? props.monopiles.monopiles
            : props.monopiles.monopiles.filter((el) => el.windProject === selectedProject);

    if (selectedItem == items[0]) {
        datarows.push(['Depth', 'Monopile Weight']);
        monopiles.map((el: ExperienceMonopile) => datarows.push([-el.waterDepth, el.mpWeight]));
    } else if (selectedItem == items[1]) {
        datarows.push(['Depth', 'TP Weight']);
        monopiles.map((el: ExperienceMonopile) =>
            datarows.push([-el.waterDepth, el.tpPrimarySteelWeight + el.tpSecondarySteelWeight])
        );
    } else if (selectedItem == items[2]) {
        datarows.push(['Depth', 'Monopile Length']);
        monopiles.map((el: ExperienceMonopile) => datarows.push([-el.waterDepth, el.mpLength]));
    } else if (selectedItem == items[3]) {
        datarows.push(['Depth', 'Total Weight']);
        monopiles.map((el: ExperienceMonopile) =>
            datarows.push([-el.waterDepth, el.mpWeight + el.tpPrimarySteelWeight + el.tpSecondarySteelWeight])
        );
    } else {
        datarows.push(['Monopile Length', 'Monopile Weight']);
        monopiles.map((el: ExperienceMonopile) => datarows.push([el.mpLength, el.mpWeight]));
    }

    const element = (
        <div className={styles.ButtonArea}>
            <Button href="/foundations/experience-monopile">Table view</Button>
        </div>
    );

    const mpWeight = [] as number[];
    const mpLength = [] as number[];
    const tpPSWeight = [] as number[];
    const tpWeight = [] as number[];
    const waterDepth = [] as number[];

    monopiles.map((el: ExperienceMonopile) => {
        mpWeight.push(el.mpWeight);
        mpLength.push(el.mpLength);
        tpPSWeight.push(el.tpPrimarySteelWeight);
        tpWeight.push(el.tpPrimarySteelWeight + el.tpSecondarySteelWeight);
        waterDepth.push(el.waterDepth);
    });

    let arWindProjects = [] as string[];

    props.monopiles.monopiles.map((el: ExperienceMonopile) => {
        arWindProjects.push(el.windProject);
    });

    arWindProjects = arWindProjects.filter((v, i, a) => a.indexOf(v) === i);

    const statisticsRow = (elArray: number[], title: string) => {
        return (
            <Table.Row>
                <Table.Cell className={styles.StatisticsTableRowTitle}>{title}</Table.Cell>
                <Table.Cell>{max(elArray)}</Table.Cell>
                <Table.Cell>{min(elArray)}</Table.Cell>
                <Table.Cell>{round(mean(elArray))}</Table.Cell>
                <Table.Cell>{round(std(elArray))}</Table.Cell>
            </Table.Row>
        );
    };

    return (
        <>
            <PageHeader title={'Dashboard - Foundation Monopiles'} right_element={element}></PageHeader>
            <div className={styles.MainTable}>
                <div className={styles.Chart}>
                    <div className={styles.SelectItems}>
                        <div style={{ width: 400, marginLeft: 100, height: 60 }}>
                            <SingleSelect
                                items={items}
                                selectedOption={selectedItem}
                                label="Choose chart"
                                handleSelectedItemChange={({ selectedItem }) => {
                                    setSelectedItem(selectedItem == null ? items[0] : (selectedItem as string));
                                }}
                            />
                        </div>

                        <div style={{ width: 400, marginLeft: 100, height: 60 }}>
                            <SingleSelect
                                items={arWindProjects}
                                initialSelectedItem={''}
                                selectedOption={selectedProject}
                                label="Filter on project"
                                handleSelectedItemChange={({ selectedItem }) => {
                                    setSelectedProject(selectedItem as string);
                                }}
                            />
                        </div>
                    </div>

                    <Chart
                        chartType="ScatterChart"
                        width="100%"
                        height="700px"
                        data={datarows}
                        options={{
                            trendlines: {
                                0: {
                                    type: 'exponential',
                                    color: '#999999'
                                }
                            },
                            legend: 'none',
                            hAxis: {
                                title: datarows[0][0].toString()
                            },
                            vAxis: {
                                title: datarows[0][1].toString()
                            },
                            series: {
                                1: { curveType: 'function' }
                            },
                            chartArea: { left: 100, bottom: 160, top: 20, right: 100, width: '100%', height: '100%' }
                        }}
                    />
                </div>
                <div className={styles.Statistics}>
                    <Typography variant="h2">Statistics summary</Typography>
                    <Table className={styles.StatisticsTable}>
                        <Table.Head>
                            <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell>max</Table.Cell>
                                <Table.Cell>min</Table.Cell>
                                <Table.Cell>mean</Table.Cell>
                                <Table.Cell>std</Table.Cell>
                            </Table.Row>
                        </Table.Head>
                        <Table.Body>
                            {statisticsRow(tpPSWeight, 'TP PS Weight')}
                            {statisticsRow(mpWeight, 'MP Weight')}
                            {statisticsRow(tpWeight, 'TP Weight')}
                            {statisticsRow(mpLength, 'MP Length')}
                            {statisticsRow(waterDepth, 'Design SB Elev.')}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    );
};
