import { CalculationResult, OverrideResultStructure, ResultStructure, Case } from '@lcoe/lcoe-client';

import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ResultDetailOverride } from './ResultDetailOverride';
import { asUnit } from '../../uom/UnitConversion';
import { ApplicationState } from '../../redux/store';
import { CoeCapexNode, CoeOpexNode } from './ResultDefinitions';
import CaseLog from './CaseLog';
import { CircularProgress, Typography, Button, Icon } from '@equinor/eds-core-react';
import styles from './ResultView.module.css';
import { ResultDetailCalculated } from './ResultDetailCalculated';

interface IProps {
    caze: Case;
    result: CalculationResult;
    onClickSave?: () => void;
    saving?: boolean;
    wtgCount: number;
    wtgCapacity: number;
    onToggleOverride?: (toggleOverride: boolean) => void;
    onOverrideChange?: (newResult: OverrideResultStructure) => void;
    discountRate: number;
    parkLife: number;
}

export const ResultView = (props: IProps): JSX.Element => {
    const unitConversion = useSelector((state: ApplicationState) => state.unitConversion);
    const [toggleOverride, setToggleOverride] = useState(props.result?.overrideResultSwitch);

    const user = useSelector((state: ApplicationState) => state.user);

    const toggleOverrideSwitch = () => {
        if (props.onToggleOverride) {
            props.onToggleOverride(!toggleOverride as boolean);
        }
        setToggleOverride(!toggleOverride);
    };

    const hierarchy = props.result.hierarchy as ResultStructure;

    if (!hierarchy) {
        return (
            <div className={styles.result_box}>
                <Typography className={styles.warning_text} variant={'h3'}>
                    Warning: invalid case result
                </Typography>
                <br />
                <Typography>
                    This case was calculated with an old version of the LCoE tool and must be recalculated. To update
                    the case, simply press the edit button and save the resulting case.
                </Typography>
            </div>
        );
    }

    const overrideCapexRoot = props.result.overrideHierarchy
        ? new CoeCapexNode(props.result.overrideHierarchy?.capex)
        : new CoeCapexNode(hierarchy.capex);
    const overrideOpexRoot = props.result.overrideHierarchy?.opex
        ? props.result.overrideHierarchy?.opex
        : hierarchy.opex;
    const overrideProductionRoot = props.result.overrideHierarchy?.production
        ? props.result.overrideHierarchy?.production
        : hierarchy.production;
    const capexRoot = new CoeCapexNode(hierarchy.capex);
    const useOverrideHierarchy = !!props.result.overrideHierarchy;
    const opexRoot = new CoeOpexNode(hierarchy.opex);

    const canEdit = props.caze.id ? 'username' in user && user.username === props.caze.createdBy : true;

    // Production
    const maxTheoreticalProduction = asUnit(props.wtgCapacity * props.wtgCount, 'MWh/year').multiply(
        asUnit(1, 'year/h')
    );

    return (
        <>
            <div className={styles.result_box}>
                {window.location.href.includes('edit') && (
                    <div className={styles.switch_row}>
                        <div>
                            <Button variant="ghost_icon" onClick={() => toggleOverrideSwitch()}>
                                {toggleOverride ? (
                                    <Icon size={48} name="switch_on" color={'#ee2b59'}></Icon>
                                ) : (
                                    <Icon size={48} name="switch_off"></Icon>
                                )}
                            </Button>
                        </div>
                        <div style={{ paddingTop: 15 }}>
                            <Typography variant={'body_short_bold'}> &nbsp; Manual override</Typography>
                        </div>
                    </div>
                )}

                {!toggleOverride && (
                    <>
                        <ResultDetailCalculated
                            capexNode={capexRoot}
                            opexNode={opexRoot}
                            hierarchy={hierarchy}
                            maxTheoreticalProduction={maxTheoreticalProduction}
                            unitConversion={unitConversion}
                        />

                        <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                            <Typography variant="meta" color="primary">
                                * OPEX numbers are per year
                            </Typography>
                        </div>
                    </>
                )}
                {toggleOverride && (
                    <ResultDetailOverride
                        unitConversion={unitConversion}
                        capexNode={overrideCapexRoot}
                        opexNode={overrideOpexRoot}
                        productionNode={overrideProductionRoot}
                        handleOverrideChange={props.onOverrideChange as (newResult: OverrideResultStructure) => void}
                        useOverrideHierarchy={useOverrideHierarchy}
                        calcCapexRoot={capexRoot}
                        calcOpex={hierarchy.opex}
                        calcProduction={hierarchy.production}
                        discountRate={props.discountRate}
                        parkLife={props.parkLife}
                    />
                )}

                {props.saving && <CircularProgress />}

                <div className={styles.save_row}>
                    <div>
                        {props.onClickSave && !(props.saving ? props.saving : false) && canEdit && (
                            <Button onClick={props.onClickSave}>
                                <Icon name={'save'}></Icon>Save Case
                            </Button>
                        )}
                    </div>

                    {!toggleOverride && (
                        <div style={{ alignSelf: 'flex-end' }}>
                            {props.result.calcVersion !== undefined &&
                                props.result.calcVersion.gitTag !== undefined && (
                                    <Typography variant="caption">
                                        Computed on version: {props.result.calcVersion.gitTag}
                                    </Typography>
                                )}
                        </div>
                    )}
                </div>

                {!toggleOverride && <CaseLog result={props.result} />}
            </div>
        </>
    );
};
