import { Button, Dialog, Typography } from '@equinor/eds-core-react';
import React from 'react';

interface ConfirmationDialogProps {
    title: string;
    text: string;
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
}

const ConfirmationDialog = ({
    title,
    text,
    open,
    onCancel,
    onConfirm,
    confirmText = 'Confirm',
    cancelText = 'Cancel'
}: ConfirmationDialogProps): JSX.Element => {
    return (
        <>
            {open && (
                <Dialog open={true} onClose={onCancel} isDismissable={false} style={{ width: '315px' }}>
                    <Dialog.CustomContent>
                        <div style={{ textAlign: 'left' }}>
                            <Typography variant={'h3'}>{title}</Typography>
                            <br />
                            {text}
                            <br />
                            <br />
                            <Button color="primary" onClick={onCancel}>
                                {cancelText}
                            </Button>{' '}
                            &nbsp;
                            <Button color="secondary" onClick={onConfirm}>
                                {confirmText}
                            </Button>
                        </div>
                    </Dialog.CustomContent>
                </Dialog>
            )}
        </>
    );
};

export default ConfirmationDialog;
